<div class="dialog-header">
  <h1 mat-dialog-title mat-dialog-draggable-title>{{data.dialogTitle}}</h1>
  <span aria-hidden="true" (click)="onNoClick()">&times;</span>
</div>

<mat-dialog-content class="pt-3">
  <app-edtell-tabset>
    
    <app-edtell-tab title="Last Modified">
      <div *ngIf="lastModifiedLoaded">
        <app-edtell-ag-grid [config]="agGridConfigRecent" *ngIf="agGridConfigRecent"></app-edtell-ag-grid>
      </div>
    </app-edtell-tab>

    <app-edtell-tab title="Last Modified by Me">
      <div *ngIf="userModifiedLoaded">
        <app-edtell-ag-grid [config]="agGridConfigUser" *ngIf="agGridConfigUser"></app-edtell-ag-grid>
      </div>
    </app-edtell-tab>

  </app-edtell-tabset>
</mat-dialog-content>
