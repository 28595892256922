import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { TooltipConfig } from '../interfaces/elements/srs-element-config.interface';
declare var bootstrap: any;
@Directive({
  selector: '[edtellTooltip]'
})
export class TooltipDirective implements AfterViewInit {

  @Input()
  edtellTooltip: TooltipConfig

  elm: HTMLElement

  constructor(private el: ElementRef) {
    
  }

  ngAfterViewInit(): void {

    if (this.edtellTooltip == null) {
      return; // No tooltip data
    }

    let elm: HTMLElement = this.el.nativeElement;

    let text = this.edtellTooltip.text;
    if (typeof text == "function") {
      text = text();
    }

    if (text == null) {

      // Remove Attributes
      elm.removeAttribute("data-toggle")
      elm.removeAttribute("data-placement")
      elm.removeAttribute("title");

      return;
    }

    let orientation = this.edtellTooltip.orientation ?? 'top';

    // Add Tooltip attributes
    elm.setAttribute("data-toggle", "tooltip")
    elm.setAttribute("data-placement", orientation)
    elm.setAttribute("title", text);

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function(element){
        return new bootstrap.Tooltip(element);
    });

  }

}
