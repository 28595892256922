import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectService } from '../../../edtell-admin/services/object.service';


@Component({
  selector: 'app-security-link',
  template: `<span id="srs-security-link-container" [hidden]="!hasObjectId">
                <a *ngIf="routerLink != null" class="security-link" [secure]="securityConfig" (click)="navigateToSecurity()"
                   href="javascript::void">
                <i class="fas fa-shield-alt"></i> Security</a>
            </span>`,
  styleUrls: ['./security-link.component.scss']
})
export class SecurityLinkComponent implements OnInit, OnChanges {

  _securityObject;
  routerLink: string;
  hasObjectId: boolean = false;

  securityConfig = {
    roleSecurity: {
      systemAdmin: true
    }
  };

  @Input()
  set securityObject(input) {
    this._securityObject = input;
  }

  constructor(private router: Router, private objectService: ObjectService) {
  }

  ngOnInit() {
    this.ngOnChanges();
  }

  getSecurityLink() {
    this.objectService.getObjectId(this._securityObject).subscribe((objId) => {
      // object Id will be null if the user does not have access to that object
      this.hasObjectId = objId == null ? false : true;
      this.routerLink = '/admin/object/' + objId + '/security';
    });
  }

  ngOnChanges() {
    if (this._securityObject) {
      this.getSecurityLink();
    }
  }

  navigateToSecurity() {
    this.router.navigateByUrl(this.routerLink);
  }

}
