import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SrsFormsModule } from "../srs-forms/srs-forms.module";
import { ApiKeyManagementComponent } from "./components/api-key-management/api-key-management.component";
import { ApiKeyManagementFormComponent } from "./components/api-key-management-form/api-key-management-form.component";
import { RouterModule, Routes } from "@angular/router";
import { AgGridModule } from "ag-grid-angular";
import { RouteSecurityGenerator } from "../edtell-portal/namespaces/security-generator";
import { ObjectName } from "../edtell-admin/enums/object-name.enum";
import { RouterDataGenerator } from "../edtell-portal/namespaces/router-data-generator";
import { EdtellAgGridModule } from "../edtell-ag-grid/edtell-ag-grid.module";

const routes: Routes = [
  {
    path: "",
    component: ApiKeyManagementComponent
  },
  {
    path: "new",
    component: ApiKeyManagementFormComponent,
    data: {
      security: RouteSecurityGenerator.generateAccessLevelSecurity(ObjectName.SYSTEM_PROPERTIES, 4),
      routerData: RouterDataGenerator.generateSimpleRouterData("New Api Key")
    }
  }
];

@NgModule({
  declarations: [ApiKeyManagementComponent, ApiKeyManagementFormComponent],
  exports: [ApiKeyManagementComponent, ApiKeyManagementFormComponent],
  imports: [CommonModule, SrsFormsModule, EdtellAgGridModule]
})
export class ApiKeyManagementModule {}

@NgModule({
  imports: [CommonModule, ApiKeyManagementModule, RouterModule.forChild(routes)],
  exports: [ApiKeyManagementModule]
})
export class ApiKeyManagementRoutingModule {}
