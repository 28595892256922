<div id="commentContainer">
  <div id="commentHeader">
<!--    {{node.createdBy}} &#45;&#45; {{ownerName.firstName}} {{ownerName.lastName}} at {{formatDate(node.dateCreated)}}-->
    <span class="name">{{node.firstName}} {{node.lastName}}</span> Commented On {{formatDate(node.comment.dateCreated)}}
    <span *ngIf="node.freshness != ''" [ngClass]="['ml-2', node.freshness == 'Today' ? 'green' : node.freshness == 'Past week' ? 'orange' : node.freshness == 'Past month' ? 'red' : '']">
      {{node.freshness}}
    </span>
  </div>

  <div id="commentBody">

      <div id="commentContent">
        <span>{{node.comment.comment}}</span>
      </div>

      <div id="commentFooter">
        <span *ngIf="!showText" [id]="elemId" class="reply-button footer-link" (click)="toggleReply()"><i class="far fa-comment-alt-smile"></i> Reply</span>
        <span *ngIf="showText" [id]="'toggle-comment-box' + elemIdBase" class="toggle-comment-box footer-link" (click)="toggleReply()">Hide Comment Box</span>
        <span *ngIf="node.canDelete"> | <span [id]="'delete-comment-button' + elemIdBase" class="delete-comment-button footer-link" (click)="deleteComment()">Delete</span></span>
      </div>

      <div id="commentTextBox" *ngIf="showText">
        <textarea [id]="'add-comment-text-box' + elemIdBase" class="form-control" [(ngModel)]="commentText" (ngModelChange)="onChange($event)"></textarea>
        <div class="add-comment-container d-flex align-items-end justify-content-end">
          <button [id]="'add-comment-submit-button' + elemIdBase" class="btn btn-tertiary srs-save-btn" (click)="addNew()" [disabled]="commentText == undefined">Add Comment</button>
        </div>
      </div>

  </div>

</div>

<div *ngIf="node.children && node.children.length && !hidden" class="children-container">
  <div [id]="'hide-comment-tree' + elemIdBase" class="side-bar inline" (click)="toggleHideCommentTreeNode()"></div>
  <div class="child-comment-container" *ngFor="let child of node.children; index as i">
    <app-comments-tree-node (commentChangedEmitter)="childChangedHandler($event)" [node]="child" [objectId]="objectId" [itemId]="itemId" [index]="i" (endMe)="deleteChild($event)"></app-comments-tree-node>
  </div>
</div>
<div *ngIf="node.children && node.children.length && hidden">
  <span [id]="'expand-comment-tree' + elemIdBase" class="expand" (click)="toggleHideCommentTreeNode()"><i class="far fa-plus-square"></i> Expand Responses</span>
</div>

