import { ValidatorFn, AbstractControl } from "@angular/forms";
import * as moment from "moment";

export function minDate(min: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if(moment(control.value).diff(min, 'days') < 0) {
      return { "minDate": true };
    }
    return null;
  };
}