import { Injectable, Type } from "@angular/core";
import {ActivationEnd, NavigationEnd, Event, Params, Router, ActivatedRoute, PRIMARY_OUTLET} from "@angular/router";
import {Observable, Observer} from "rxjs";
import {filter, map} from "rxjs/operators";
import { RouterDataConfig } from "../../edtell-portal/interfaces/router-data.config";

@Injectable({
  providedIn: "root"
})
export class EdtellHeaderService {

  private observer: Observer<Type<any>>;

  headerState: Observable<Type<any>>;

  navComponent: Type<any>;

  routeParams: Params;
  routeData: RouterDataConfig;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.headerState = new Observable((o) => {
      this.observer = o;
      this.observer.next(this.navComponent);
    });
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map((route) => {return route}))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        let lastRoute = this.findHeaderComponent(route);
        let navComponent = lastRoute.snapshot.data.header;
        if(lastRoute.snapshot.data.header) {
          this.routeParams = lastRoute.snapshot.params;
          this.routeData = lastRoute.snapshot.data;
          if (this.navComponent != navComponent) {
            this.navComponent = navComponent;
            if (this.observer != null) {
              this.observer.next(lastRoute.snapshot.data.header);
            }
          }
        }
      })
  }

  getRouteParams() {
    return this.routeParams
  }

  getRouteData() {
    return this.routeData;
  }

  getRouteQueryParams() {
    return this.route.snapshot.queryParams;
  }

  findHeaderComponent(route: ActivatedRoute) {
    while (route.firstChild !== null) {
      route = route.firstChild
    }
    return route;
  }

}
