import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { EdtellMaterialModule } from '../material/material.module';
import { SrsFormsModule } from '../srs-forms/srs-forms.module';
import { SrsSecurityModule } from '../srs-security/srs-security.module';
import { EdtellTextCellRendererComponent } from './components/edtell-text-cell-renderer/edtell-text-cell-renderer.component';
import { InfoLinkComponent } from './components/info-link/info-link.component';
import { CodeTextFormat } from './components/json-format/code-text-format.component';
import { PinningGridComponent } from './components/pinning-grid/pinning-grid.component';
import { TextPopUpComponent } from './components/text-pop-up/text-pop-up.component';
import { AttributeGridWidgetComponent } from './components/attribute-grid-widget/attribute-grid-widget.component';
import { GridClearButtonComponent } from '../edtell-ag-grid/components/grid-clear-button/grid-clear-button.component';
import { EntityControlLinkComponent } from '../edtell-ag-grid/components/entity-control-link/entity-control-link.component';
import { EntitySelectComponent } from '../edtell-ag-grid/components/entity-select/entity-select.component';
import { StatusBarPlusComponent } from '../edtell-ag-grid/components/status-bar-plus/status-bar-plus.component';
import { GridResetButtonComponent } from '../edtell-ag-grid/components/grid-reset-button/grid-reset-button.component';
import { EdtellAgGridModule } from '../edtell-ag-grid/edtell-ag-grid.module';
import { AttributeDialogComponent } from './components/attribute-dialog/attribute-dialog.component';
import { AnchorDisabledDirective } from './directives/anchor-disabled.directive';
import { EdtellCalendarWidgetComponent } from './components/edtell-calendar-widget/edtell-calendar-widget.component';
import { EdtellControlsModule } from '../edtell-controls/edtell-controls.module';


export function getHighlightLanguages() {
  return {
    json: () => import('highlight.js/lib/languages/json'),
    xml: () => import('highlight.js/lib/languages/xml')
  };
}


@NgModule({
  declarations: [
    PinningGridComponent,
    CodeTextFormat,
    TextPopUpComponent,
    EdtellTextCellRendererComponent,
    InfoLinkComponent,
    AttributeGridWidgetComponent,
    AttributeDialogComponent,
    AnchorDisabledDirective,
    EdtellCalendarWidgetComponent,
  ],
  imports: [
    CommonModule,
    AgGridModule,
    SrsSecurityModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    AgGridModule.withComponents([
      GridResetButtonComponent,
      StatusBarPlusComponent,
      EntitySelectComponent,
      EntityControlLinkComponent,
      GridClearButtonComponent,
      EdtellTextCellRendererComponent,
    ]),
    HighlightModule,
    EdtellAgGridModule,
    SrsFormsModule,
    EdtellMaterialModule,
    EdtellControlsModule
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages(),
        lineNumbers: true,
      },
    },
  ],
  exports: [
    EntitySelectComponent,
    GridClearButtonComponent,
    PinningGridComponent,
    CodeTextFormat,
    TextPopUpComponent,
    EdtellTextCellRendererComponent,
    InfoLinkComponent,
    AttributeGridWidgetComponent,
    AttributeDialogComponent,
    AnchorDisabledDirective,
    EdtellCalendarWidgetComponent
  ]
})
export class EdtellUtilitiesModule {}
