import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';
import { RouterDataInfo } from '../../interfaces/router-data-info';
import { AttributeService } from '../../services/attribute.service';
import { RouterDataService } from '../../services/router-data.service';


@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss']
})

export class BreadcrumbNavigationComponent implements OnInit {

  private startBreadcrumbDepth = 1;
  private endBreadcrumbDepth = 2;

  subscription: Subscription;

  trail : RouterDataInfo[]
  beginingTrail: RouterDataInfo[];
  hiddenTrail: RouterDataInfo[]
  endTrail: RouterDataInfo[]

  constructor(
    private attributeService: AttributeService,
    private RouterDataService: RouterDataService,
    private router: Router) { }

  ngOnInit() {

    this.attributeService.getAttributeByObjectName(ObjectName.SYSTEM_PROPERTIES, "breadcrumbs.depth.left").subscribe((resp) => {
      if (resp != null && Number.parseInt(resp.attributeValue) != null) {
        this.startBreadcrumbDepth = Number.parseInt(resp.attributeValue);
        this.setupTrailDisplay(this.trail)
      }
    })

    this.attributeService.getAttributeByObjectName(ObjectName.SYSTEM_PROPERTIES, "breadcrumbs.depth.right").subscribe((resp) => {
      if (resp != null && Number.parseInt(resp.attributeValue) != null) {
        this.endBreadcrumbDepth = Number.parseInt(resp.attributeValue);
        this.setupTrailDisplay(this.trail)
      }
    })

    this.RouterDataService.trailState.subscribe((trail) => {
      this.trail = trail;
      this.setupTrailDisplay(trail)
    });
  }

  navigate(path: string) {
    this.router.navigate([path])
  }

  isPathUndefined(path: string) {
    return path === undefined;
  }

  setupTrailDisplay(trail: RouterDataInfo[]) {

    let endBreadcrumbDepth = Math.min(trail.length, this.endBreadcrumbDepth);
    let startBreadcrumbDepth = Math.min(trail.length, this.startBreadcrumbDepth);

    this.beginingTrail = null;
    this.endTrail = null;
    this.hiddenTrail = null;

    trail = trail.filter((t) => {
      return !t.skip
    })


    let endIndex = trail.length - endBreadcrumbDepth;

    if (endIndex - startBreadcrumbDepth < 0) {
      this.endTrail = trail.splice(endIndex, trail.length - endIndex);
    } else {
      this.endTrail = trail.splice(endIndex, trail.length - endIndex);
      this.beginingTrail = trail.splice(0, startBreadcrumbDepth);
      this.hiddenTrail = trail
    }

  }

}
