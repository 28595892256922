import { 
  PinnedEntityResponse, 
  LoginService,
  CacheableMethod
} from "@edtell-common"
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiURL } from '../../../../environments/environment';
import { SubjectTag } from '../../admin/interfaces/subject-tag.interface';
import { DeliveryMethod } from '../../providers/interfaces/delivery-method.interface';
import { ProviderPricingDefaultDetailInterface } from '../../providers/interfaces/provider-pricing-default-detail.interface';
import { CatalogCourseCourseTypeDataInterface } from '../interfaces/catalog-course-course-type-data.interface';
import { CatalogCourseMsrpCogsResponseInterface } from '../interfaces/catalog-course-msrp-cogs-response.interface';
import { CatalogCourseProfileDataResponseInterface } from '../interfaces/catalog-course-profile-data-response.interface';
import { CatalogCourseTypeManagementAvailabilityDataInterface } from '../interfaces/catalog-course-type-management-availability-data.interface';
import { CatalogProviderCourseDetailsInterface } from '../interfaces/catalog-provider-course-details.interface';
import { CatalogSearchDataInterface, CatalogSearchProviderInterface } from '../interfaces/catalog-search-data.interface';
import { CourseDeliveryMethodMappingInterface } from '../interfaces/course-delivery-method-mapping.interface';
import { CourseSearchAutocompleteResultDataInterface } from '../interfaces/course-search-autocomplete-result-data.interface';
import { CourseSearchResultInterface } from '../interfaces/course-search-result.interface';
import { CourseInterface } from '../interfaces/course.interface';
import { CatalogCourseOverview } from "../interfaces/catalog-course-overview.interface";
import { CatalogCourseTypeAvailabilityInterface } from "../interfaces/catalog-course-type-availability.interface";
import { CatalogCourseProgramReport } from "../interfaces/catalog-course-program-report.interface";
import { PassFailReportData } from "../interfaces/pass-fail-report-data.interface";
import { StudentGrade } from "../../customer/interfaces/student-grade.interface";
import { CopyCatalogCourseRequest } from "../interfaces/copy-catalog-course-request";

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private httpOptions: {};
  private textHttpOptions: {};

  constructor(private http: HttpClient,
              private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
    this.textHttpOptions = loginService.textHttpOptions;
  }


  getCourseSearchResults(searchString, courseVersion, subject, courseStatus, providerId, providerCourseNumber, educationLevel, access, maxResults) {

    let searchParamString = '';

    if (searchString != null) {
      searchParamString = searchParamString.concat('searchString=', searchString, '&');
    }

    if (courseVersion != null && courseVersion !== 'null') {
      searchParamString = searchParamString.concat('courseVersion=', courseVersion, '&');
    }
    if (subject != null && subject !== 'null') {
      searchParamString = searchParamString.concat('subject=', subject, '&');
    }
    if (courseStatus != null && courseStatus !== 'null') {
      searchParamString = searchParamString.concat('courseStatus=', courseStatus, '&');
    }
    if (providerId != null && providerId !== 'null') {
      searchParamString = searchParamString.concat('providerId=', providerId, '&');
    }
    if (providerCourseNumber != null && providerCourseNumber !== 'null') {
      searchParamString = searchParamString.concat('providerCourseNumber=', providerCourseNumber, '&');
    }
    if (educationLevel != null && educationLevel !== 'null') {
      searchParamString = searchParamString.concat('educationLevel=', educationLevel, '&');
    }
    if (access != null && access !== 'null') {
      searchParamString = searchParamString.concat('isPrivate=', access, '&');
    }

    if (maxResults != null) {
      searchParamString = searchParamString.concat('maxResults=', maxResults);
    } else {
      searchParamString = searchParamString.concat('maxResults=100');
    }

    return this.http.get<CourseSearchResultInterface[]>(`${apiURL}/catalog-app/catalog/search?${searchParamString}`, this.httpOptions);
  }

  getAllCatalogCourses() {
    return this.http.get<CourseInterface[]>(`${apiURL}/catalog-app/catalog/all`, this.httpOptions);
  }

  getCatalogCourse(id) {
    return this.http.get<CourseInterface>(`${apiURL}/catalog-app/catalog?id=${id}`, this.httpOptions);
  }

  createCatalogCourse(course) {
    return this.http.post<CourseInterface>(`${apiURL}/catalog-app/catalog`, course, this.httpOptions);
  }

  updateCatalogCourse(course) {
    return this.http.put<CourseInterface>(`${apiURL}/catalog-app/catalog`, course, this.httpOptions);
  }

  deleteCatalogCourse(courseId) {
    return this.http.delete(`${apiURL}/providers-app/providers/catalog/course?courseId=${courseId}`, this.httpOptions);
  }

  copyCatalogCourse(request: CopyCatalogCourseRequest) {
    return this.http.post<CourseInterface>(`${apiURL}/providers-app/providers/catalog/course/copy`, request, this.httpOptions);
  } 

  getAutocompleteResults(searchString) {
    return this.http.get<CourseSearchAutocompleteResultDataInterface[]>(`${apiURL}/catalog-app/catalog/autocomplete?searchString=${searchString}`, this.httpOptions);
  }

  getPinnedCourses() {
    return this.http.get<PinnedEntityResponse<CourseInterface>>(`${apiURL}/providers-app/providers/catalog/pins`, this.httpOptions);
  }

  getProviderCatalogSearchData() {
    return this.http.get<CatalogSearchProviderInterface[]>(`${apiURL}/providers-app/providers/catalog/search/data`, this.httpOptions);
  }

  getCatalogSearchData() {
    return this.http.get<CatalogSearchDataInterface>(`${apiURL}/catalog-app/catalog/search/data`, this.httpOptions);
  }

  getCatalogProviderCourseDetailData(providerId) {
    return this.http.get<CatalogProviderCourseDetailsInterface>(`${apiURL}/catalog-app/catalog/search/data/provider?providerId=${providerId}`, this.httpOptions);
  }

  getCatalogProfileData(courseId) {
    if (courseId !== undefined && courseId !== null) {
      return this.http.get<CatalogCourseProfileDataResponseInterface>(`${apiURL}/providers-app/providers/catalog/profile/data?courseId=${courseId}`, this.httpOptions);
    } else {
      return this.http.get<CatalogCourseProfileDataResponseInterface>(`${apiURL}/providers-app/providers/catalog/profile/data`, this.httpOptions);
    }
  }

  getCatalogCourses(catalogId) {
    return this.http.get<CatalogCourseTypeManagementAvailabilityDataInterface[]>(`${apiURL}/providers-app/providers/catalog/profile/courses?catalogId=${catalogId}`, this.httpOptions);
  }

  // ----- Breadcrumb name resolvers -----
  getCourseNameFromCatalogId(catalogId) {
    return this.http.get<CourseInterface>(`${apiURL}/catalog-app/catalog/course-name?catalogId=${catalogId}`, this.httpOptions);
  }

  getProviderNameFromCatalogId(catalogId) {
    return this.http.get(`${apiURL}/providers-app/providers/catalog/provider-name?catalogId=${catalogId}`, this.httpOptions);
  }

  // TODO - Interface
  getProviderRelatedCoursesSidebarData(catalogId) {
    return this.http.get(`${apiURL}/providers-app/providers/catalog/related-courses/data?catalogId=${catalogId}`, this.httpOptions);
  }

  getDeliveryMethodSidebarData(catalogId) {
    return this.http.get<DeliveryMethod[]>(`${apiURL}/providers-app/providers/catalog/delivery-methods/sidebar-data?catalogId=${catalogId}`, this.httpOptions);
  }

  saveDeliveryMethodMappings(deliveryMethods: CourseDeliveryMethodMappingInterface[]) {
    return this.http.post<DeliveryMethod[]>(`${apiURL}/providers-app/providers/catalog/delivery-methods`, deliveryMethods, this.httpOptions);
  }

  /* Course Type Availability */
  getCatalogCourseTypes(courseId) {
    return this.http.get<CatalogCourseCourseTypeDataInterface>(`${apiURL}/providers-app/providers/catalog/course-type/data?courseId=${courseId}`, this.httpOptions);
  }

  getCatalogCourseTypesCount(courseId) {
    return this.http.get<number>(`${apiURL}/providers-app/providers/catalog/course-type/count?courseId=${courseId}`, this.httpOptions);
  }

  deleteCourseTypeAvailability(availabilityId) {
    return this.http.delete<boolean>(`${apiURL}/providers-app/providers/catalog/course-type?availabilityId=${availabilityId}`, this.httpOptions);
  }

  deleteCourseTypeAvailabilities(availabilities) {
    return this.http.post<boolean>(`${apiURL}/providers-app/providers/catalog/course-types/delete`, availabilities, this.httpOptions);
  }

  deleteCourseTypeAvailabilitiesByCourseId(courseId) {
    return this.http.delete<boolean>(`${apiURL}/providers-app/providers/catalog/course-types/delete/courseId?courseId=${courseId}`, this.httpOptions);
  }

  getPricingDefaults(request) {
    return this.http.post<ProviderPricingDefaultDetailInterface[]>(`${apiURL}/providers-app/providers/pricing/default/detail/pricing`, request, this.httpOptions);
  }

  getRemainingPricingDefaults(request) {
    return this.http.post<ProviderPricingDefaultDetailInterface[]>(`${apiURL}/providers-app/providers/pricing/default/detail/remaining-pricing`, request, this.httpOptions);
  }

  duplicateCourseAvailabilityCheck(saveRequest) {
    return this.http.put<boolean>(`${apiURL}/providers-app/providers/catalog/course-type/duplicate-check`, saveRequest, this.httpOptions);
  }

  saveCatalogCourseTypes(saveRequest) {
    return this.http.post<CatalogCourseTypeManagementAvailabilityDataInterface[]>(`${apiURL}/providers-app/providers/catalog/course-type`, saveRequest, this.httpOptions);
  }

  updateCatalogCourseType(saveRequest) {
    return this.http.put<CatalogCourseCourseTypeDataInterface>(`${apiURL}/providers-app/providers/catalog/course-type`, saveRequest, this.httpOptions);
  }

  addCatalogCourseType(saveRequest) {
    return this.http.post<CatalogCourseCourseTypeDataInterface>(`${apiURL}/providers-app/providers/catalog/course-type/add`, saveRequest, this.httpOptions);
  }

  deleteDeliveryMethodMappings(deliveryMethods: CourseDeliveryMethodMappingInterface[]) {
    return this.http.put<DeliveryMethod[]>(`${apiURL}/providers-app/providers/catalog/delivery-methods/delete`, deliveryMethods, this.httpOptions);
  }

  getCourseSubjectTags(courseId) {
    return this.http.get<SubjectTag[]>(`${apiURL}/providers-app/providers/catalog/course/subject-tags?courseId=${courseId}`, this.httpOptions);
  }

  getCatalogMsrpCogsResponse(courseId, rigorId, formatId, structureId, creditId, termId) {
    return this.http.get<CatalogCourseMsrpCogsResponseInterface>(`${apiURL}/providers-app/providers/catalog/msrp-cogs?courseId=${courseId}&rigorId=${rigorId}&formatId=${formatId}&structureId=${structureId}&creditId=${creditId}&termId=${termId}`, this.httpOptions)
  }

  @CacheableMethod()
  getAvailabilitiesByCourseTypeElements(rigorId: number,
                                        formatId: number, 
                                        structureId: number, 
                                        creditId: number, 
                                        termId: number) {

    return this.http.get<CatalogCourseTypeAvailabilityInterface[]>(`${apiURL}/providers-app/providers/catalog/course-type?rigorId=${rigorId}&formatId=${formatId}&structureId=${structureId}&creditId=${creditId}&termId=${termId}`, this.httpOptions)
  }

  getCatalogCourseProgramReport(courseId: number) {
    return this.http.get<CatalogCourseProgramReport[]>(`${apiURL}/catalog-app/catalog/program-report?courseId=${courseId}`, this.httpOptions);
  }

  getPassFailReportData(courseId: number) {
    return this.http.get<PassFailReportData>(`${apiURL}/providers-app/providers/catalog/pass-fail-report/data?courseId=${courseId}`, this.httpOptions)
  }

  @CacheableMethod()
  getPassFailReport(courseNumber) {
    return this.http.get<StudentGrade[]>(`${apiURL}/catalog-app/catalog/pass-fail/details?courseNumber=${courseNumber}`, this.httpOptions)
  }

  courseNumberExists(courseNumber: string) {
    return this.http.get<boolean>(`${apiURL}/providers-app/providers/catalog/course-number/exists?courseNumber=${courseNumber}`, this.httpOptions);
  }
}