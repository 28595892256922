<div class="row">
  <div class="col-sm-12">

    <table class="table table-borderless">
      <thead>
      <th *ngFor="let col of config.colDef" scope="col">
        <label *ngIf="col.title != 'Info Link'" class="label-bold" title="{{getColToolTip(col)}}">{{col.title != null ? col.title : ""}}
          <i *ngIf="col.icon != null" [class]="col.icon"></i>
          <span class="required-asterisk">{{ isColumnRequired(col) ? "*" : "" }}</span>
        </label>
      </th>
      </thead>
      <tbody>
      <tr *ngFor="let row of elementConfigs">
        <td *ngFor="let c of row.col" >
          <app-dynamic-container *ngIf="c.title != 'Info Link'" [config]="c"></app-dynamic-container>
          <app-ownership-link class="fake-link info px-8" 
            *ngIf="c.title == 'Info Link'  && (row.data.id && row.data.attributeName && row.data.attributeValue) != null && state != 'READ'" 
            (click)="openOwnershipDialog(row.data)" [secure]="getSecurityConfig(row)">Info
          </app-ownership-link>
        </td>
        <td class="delete-cell">
          <button *ngIf="!isDanglingCell(row) && state != 'READ'" (click)="removeRow(row)"
                  class="btn btn-secondary btn-sm">X
          </button>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
