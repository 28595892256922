import { ActivatedRouteSnapshot } from "@angular/router";

export namespace ResolverHelpers {

    export function isEndRoute(route: ActivatedRouteSnapshot) : boolean{

        let resolver = route.data.routerData.breadcrumbResolver

        while(route != null){
            let breadcrumb = route.data != null ? route.data.routerData : null
            if(resolver != breadcrumb.breadcrumbResolver){
                return false;
            }
            route = route.firstChild
        }
        return true;
    }

}