import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { SrsCheckboxConfig } from '../../../interfaces/elements/srs-checkbox-config.interface';

@Component({
  selector: 'app-srs-checkbox',
  templateUrl: './srs-checkbox.component.html',
  styleUrls: ['./srs-checkbox.component.scss']
})
export class SrsCheckboxComponent extends SrsFormElement<SrsCheckboxConfig> {

  @ViewChild('checkboxField', {static: true})
  inputFieldRef: ElementRef;

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  onElementInit() {
      if (this.control.value == null) {
        this.control.setValue(false, {emitEvent: false});
        this.control.setIsOriginal(true);
    }
  }

  onChange() {
    this.config.onChange != null ? this.config.onChange(this.control) : null;
  }

  get checkboxPaddingAmount() {
    return this.config.settings != null && this.config.settings.padding != null ? this.config.settings.padding : 26;
  }

  getIcon() {
    return this.config.settings != null && this.config.settings.iconConfig != null ? this.config.settings.iconConfig.icon : false;
  }

  getIconHeight(){
    return this.config.settings.iconConfig.height != null ? this.config.settings.iconConfig.height : '100%';
  }

  getIconWidth(){
    return this.config.settings.iconConfig.width != null ? this.config.settings.iconConfig.width : '100%';
  }

  get inlineDisabledLabelClass(): string {
    if (this.disabled && this.config.settings && this.config.group.state == 'WRITE') {
      return 'inline-checkbox-label-disabled';
    }
    return '';
  }

  get disabled() {
    if (this.config.group.state === 'READ') {
      return true;
    }

    if (this.config.disabled) {
      return this.control.manualDisable ? this.control.disabled : this.config.disabled();
    }

    return false;
  }

}
