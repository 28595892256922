export enum CommonRegExpPattern {

  /**
  * Follows format (x,...xn)
  */
  INTEGER = '^\\d+$',

  /**
  * Follows format $(x,...xn).xx
  */
  MONEY = '^\\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]*)(\\.\\d{0,2})?$',

  /**
  * Follows format $(x,...xn).xx
  */
  MONEY_ALLOW_NEGATIVES = '^\\$?-?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]*)(\\.\\d{0,2})?$',

  /**
  * Follows format $(x,...xn).xx
  */
  MONEY_ALLOW_ONLY_NEGATIVES = '^\\$?-([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]*)(\\.\\d{0,2})?$',

  /**
  * Follows format xx.xx
  */
  PASSING_GRADE = '^\\d{1,2}(\\.\\d{0,2})?$',

  /**
  * Follows format xx/xx/xxxx
  */
  DATE='^\\d{1,2}\\/?\\d{0,2}\\/?\\d{0,4}$',

  /**
   * Follows format (x,...xn).xx
   */
  DECIMAL = '^[0-9]+\\.?([0-9]{1,2})?$',

  /**
   * Requires Hashtag
   */
  SUBJECT_TAG = '^#',

  /**
   * Follows format (x,...x6) digits
   */
  OFFICE_EXTENSION = '^[0-9]{1,6}$',

  /**
   * Follows format (x,...7) digits
   */
  SORT_ORDER = '^-?[0-9]{0,7}$',

  /**
   * Follows format (x, ...x5)
   */
  COURSE_ID = '^[0-9]{1,5}$',

  /**
   * Follows format (x, ...x5) or *
   */
  OVERRIDE_COURSE_ID = '^[0-9]{1,5}|\\*$',

  /**
   * Follows format xxx-xxx-xxxx
   */
  PHONE_NUMBER = '^\\d{0,3}-?\\d{0,3}-?\\d{0,4}$',

  /**
   * Follows format #####-####, with the "-####" optional at the end
   */
  ZIP_CODE = '^\\d{0,5}-?\\d{0,4}$',

  /**
   * Two letters, The letters are converted to uppercase in the pattern-input directive automatically
  */
  ABBREVIATION = '^[A-Za-z]{0,2}$',

    /**
   * Follows format (x,...) digits
  */
  FAX_NUMBER = '^[0-9]*$',

  /**
   * Two Digit Integer
   */
  TWO_DIGIT_INT = '^[0-9]{0,2}$',

  /**
   * Int between 0-100
   */
  BETWEEN_ZERO_AND_ONE_HUNDRED = '^[0-9]$|^[1-9][0-9]$|^(100)$',

  /**
   * Percentage between 0-100
   */
  PERCENTAGE = '^%[0-9]$|^[1-9][0-9]$|^(100)$'
}
