import { Input, OnDestroy, OnInit, Directive, AfterViewInit } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { EdtellFormControl } from "../classes/edtell-form-control";
import { SrsElementConfig } from "../interfaces/elements/srs-element-config.interface";

@Directive()
export abstract class SrsFormElement<T extends SrsElementConfig> implements OnInit, OnDestroy {


  @Input()
  config: T

  control: EdtellFormControl

  private _elementId: string;
  ngOnInit() {

    if (this.config.key != null) {
      this.control = <EdtellFormControl>this.config.group.get(this.config.key)
    }

    this.config.settings = this.config.settings != null ? this.config.settings : {}

    this._elementId = this.findUniqueElementId();

    this.onElementInit();
    this.config.onInit != null ? this.config.onInit(this.config, this) : null

    if (this.config.alerts) {
      for (let alert of this.config.alerts) {
        let alertFunc = alert.showAlertMessage
        alert.showAlertMessage = () => {
          return alertFunc(this.control);
        }
      }
    }
  }

  abstract onElementInit()

  isInvalid() {
    return this.control != null ? this.control.invalid && (this.control.dirty || this.control.touched) : false
  }

  showValidators() {
    return this.config.validators != null && (this.control.touched || this.control.dirty || this.config.group.submitted)
  }

  ngOnDestroy() {
    if (this.config.onDestroy != null) {
      this.config.onDestroy(this.control)
    }
  }

  hasError(key: string) {
    return this.control.errors != null && this.control.errors[key] != null;
  }

  // HTML Value for element, ensures no id repeats itself
  protected findUniqueElementId(increment?: number) {
    let i = increment != null ? "-" + increment : ""; // increment value to append to element id
    let possibleId = 'input-' + this.config.key;

    possibleId = this.config.elementIdPostFix
      ? possibleId + this.config.elementIdPostFix + i
      : possibleId + i

    let elm = document.getElementById(possibleId);
    if (elm != null) {
      return this.findUniqueElementId(increment != null ? increment + 1 : 1);
    }

    return possibleId;
  }

  get required() {
    return this.control.hasValidator(Validators.required);
  }

  get submitted() {
    return this.config.group.submitted;
  }

  get validationClass() {
    return this.control.errors && this.config.group.submitted ? 'is-invalid' : '';
  }

  get formGroupClass() {

    if (this.config.settings == null) {
      return "form-group"
    }

    let m = this.config.settings.formGroupMargin;
    return m != null && m == false ? "xform-group" : "form-group"
  }

  get autoFocus() {
    if (this.config.settings !== undefined) {
      if (this.config.settings.autoFocus) {
        return true;
      }
    }
    return false;
  }

  get hideTitle() {
    return (this.config.settings != null && this.config.settings.hideTitle != null) ? this.config.settings.hideTitle : false
  }

  get disabled(): boolean {
    if (this.config.disabled) {
      return this.control.manualDisable ? this.control.disabled : this.config.disabled(this.control);
    }

    return this.control.disabled;
  }

  get hint(): string {

    if (typeof this.config.hint == "string") {
      return this.config.hint;
    }

    return (<any>this.config.hint)(this.control);
  }


  get showRequired() {
    return this.required && (this.config.settings != null && this.config.settings.showRequired != null ? this.config.settings.showRequired : true);
  }

  get tooltip() {
    return this.config.settings?.tooltip
  }

  get elementId() {
    return this._elementId;
  }

  get class() {
    return this.config.settings ? this.config.settings.class ? this.config.settings.class : [] : [];
  }

  get hiddenTitlePadding() {
    return this.config.settings?.hiddenTitlePadding;
  }
}
