import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DistributionContainerConfig } from '../../../interfaces/distribution-container-config.interface';
import { DistributionDialogConfig } from '../../../interfaces/processed-distribution-dialog-config.interface';
import { DistributionMetaData, ProcessedJobStatus } from '../../../interfaces/processed-job-status.interface';
import { ProcessedApi } from '../../../interfaces/processed-api.interface';

@Component({
  selector: 'app-distribution-dialog',
  templateUrl: './distribution-dialog.component.html',
  styleUrls: ['./distribution-dialog.component.scss'],
})
export class DistributionDialogComponent{

  jobStatus: ProcessedJobStatus;

  selectedDistribution : DistributionMetaData

  distributionContainerConfig : DistributionContainerConfig

  constructor(
    private matDialogRef: MatDialogRef<DistributionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: DistributionDialogConfig,
  ) {

    this.distributionContainerConfig = {
      api: this.config.api,
      jobStatus: this.config.jobStatus
    }

    this.jobStatus = this.config.jobStatus
  }
  
  switchView(distributionMetaData : DistributionMetaData){
    this.selectedDistribution = distributionMetaData;
  }

  onNoClick(){
    this.matDialogRef.close();
  }

  goBack(){
    this.selectedDistribution = null;
  }

  getCurrentViewType(){
    return this.selectedDistribution?.type
  }

  jobDate(){
    return moment(this.jobStatus.dateCreated).format('h:mm A MM/DD/YYYY ')
  }

}
