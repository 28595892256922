<div [formGroup]="formGroup" [ngSwitch]="config.group.state">

  <ng-container *ngSwitchCase="'READ'" [ngSwitch]="isHyperlink">
      <ng-container *ngSwitchCase="false">
          <div [class]="formGroupClass">
              <label *ngIf="!hideTitle && config.title">{{config.title}}</label>
              <input class="form-control" [edtellTooltip]="tooltip" [id]="elementId" [value]="controlValue" [ngClass]="class" readonly>
          </div>
      </ng-container>

      <ng-container *ngSwitchCase="true">
          <div [class]="formGroupClass">
              <label *ngIf="config.title">{{config.title}}</label>
              <div class="form-control-link" [ngSwitch]="config.settings.hyperlinkType" readonly>
                  <a *ngSwitchCase="'WEBSITE'" [href]="formattedWebsite" target="_blank">{{ control.value }}</a>
                  <a *ngSwitchCase="'EMAIL'" [href]="'mailto:' + controlValue">{{ control.value }}</a>
              </div>
          </div>
      </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'WRITE'">
      <div [class]="formGroupClass">
          <label *ngIf="!hideTitle && config.title">{{config.title}} <span *ngIf="showRequired"
                  class="required-asterisk">{{ required ? "*" : "" }}</span></label>

          <div [ngClass]="['input-group', config.settings?.searchIcon ? 'has-search' : '']">
            <span *ngIf="config.settings?.searchIcon" class="far fa-search form-control-feedback"></span>
            <div class="input-group-prepend" *ngIf="config.settings?.inputGroupPrependText != null">
                <div class="input-group-text">{{config.settings.inputGroupPrependText}}</div>
            </div>
            <input [ngClass]="['form-control', validationClass].concat(class)" #inputField (ngModelChange)="onChange($event)" [name]="name" [autocomplete]="name"
                [id]="elementId" [formControlName]="config.key" [maxlength]="config.maxCharacters"
                [autoFocus]="autoFocus" [placeholder]="placeHolder"
                [disableControl]="disabled" [edtellTooltip]="tooltip"
                  (click)="onClick($event)" (input)="onInput($event)"
                patternInput (focusout)="onFocusOut($event)"
                [regExpPattern]="regExpPattern" [type]="type"
            >
          </div>

          <span *ngIf="showMaxCharacters; else noMaxChars">
              <small class="d-inline-block form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
              <small
                  [ngClass]="['char-hint', 'form-text', 'text-muted', 'text-right', maxCharWarningClass, noCharsLeftClass]">{{ currCharacterCount + '/' + config.maxCharacters}}</small>
          </span>
          <ng-template #noMaxChars>
              <small class="form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
          </ng-template>
    
          <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
              <div class="invalid-feedback d-block" *ngIf="hasError(v.key) == true">{{v.message}}</div>
          </ng-template>

          <div class="info-container d-inline-block" *ngIf="config.settings.infoConfig != null">
            <div class="icon-container d-inline-block" #infoButton (keydown.enter)="toggleInfoText()" (click)="toggleInfoText()" tabindex="0">
                <i class="fal fa-question-circle"></i>
            </div>
            <div class="info-text" *ngIf="showInfoText" #infoText>
                {{config.settings.infoConfig.message}}
            </div>
        </div>

            <div *ngFor="let alert of config.alerts">
                <div class="row mt-2" *ngIf="alert.showAlertMessage()">
                    <div class="col-12 alert-message">
                        <i class="fas fa-exclamation-triangle"></i> 
                        {{alert.message}}
                    </div>
                </div>
            </div>
      </div>
  </ng-container>

</div>
