import {Component} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';
import {SrsFormElement} from '../../../abstracts/srs-form-element.abstract';
import {EdtellFormControl} from '../../../classes/edtell-form-control';
import {EdtellFormGroup} from '../../../classes/edtell-form-group.class';
import {SrsDateTimePickerConfig} from '../../../interfaces/elements/srs-datetime-picker-config';
import {minDate} from '../../../validators/min-date.validator';
import {maxDate} from '../../../validators/max-date.validator';

@Component({
  selector: 'app-edtell-datetime-picker',
  templateUrl: './edtell-datetime-picker.component.html',
  styleUrls: ['./edtell-datetime-picker.component.scss']
})
export class EdtellDatetimePickerComponent extends SrsFormElement<SrsDateTimePickerConfig> {

  formGroup: EdtellFormGroup;
  dateTimeFormGroup: EdtellFormGroup;
  dateTimeControl: EdtellFormControl;

  constructor() {
    super();
  }

  onElementInit() {
    this.config.dateTime = this.config.dateTime ?? 'date';

    this.initFormGroupListeners();
    this.initDateTimeFormGroup();
    this.formGroup = this.dateTimeFormGroup;

    const control: AbstractControl = this.formGroup.get(this.config.key);

    const minMaxValidators: ValidatorFn[] = [];
    if (this.config.min) {
      minMaxValidators.push(minDate(this.config.min()));
      this.config.validators.push({
        key: 'minDate',
        message: `Input must be no earlier than ${this.config.min()}`
      });
    }
    if (this.config.max) {
      minMaxValidators.push(maxDate(this.config.max()));
      this.config.validators.push({
        key: 'maxDate',
        message: `Input must be no later than ${this.config.max()}`
      });
    }
    control.addValidators(minMaxValidators);
  }

  initDateTimeFormGroup() {
    let configGroup = this.config.group;
    this.dateTimeFormGroup = new EdtellFormGroup(configGroup.state, {});

    let value = this.control.value;
    if (this.config.dateTime == 'time') {
      let localValue = null;
      if (this.control.value) {

        let date = new Date();
        let time = this.control.value.split(':');

        date.setHours(time[0]);
        date.setMinutes(time[1]);

        localValue = this.utcToLocal(date.toLocaleString());
      }
      let localTime = moment(localValue).format('HH:mm');
      value = localTime;
    } else if (this.config.dateTime == 'dateTime') {
      value = this.utcToLocal(value);
    }

    this.dateTimeControl = new EdtellFormControl(value);

    this.dateTimeFormGroup.addControl(this.config.key, this.dateTimeControl);
  }

  // This is here to prevent the form from updating the control when the control is updated programmatically
  isProgrammaticChange = false;

  initFormGroupListeners() {
    let configGroup = this.config.group;
    configGroup.get(this.config.key).valueChanges.subscribe(value => {
      if (!this.isProgrammaticChange) {
        if (this.config.dateTime == 'time') {
          let localValue = null;
          let time = '1994-11-14T' + value;
          if (value) {
            localValue = this.utcToLocal(time);
          }
          let localTime = moment(localValue).format('HH:mm');
          this.dateTimeFormGroup.get(this.config.key).setValue(localTime);
        } else if (this.config.dateTime == 'dateTime') {
          let local = this.utcToLocal(value);
          this.dateTimeFormGroup.get(this.config.key).setValue(local);
        } else {
          this.dateTimeFormGroup.get(this.config.key).setValue(value);
        }
      }
    });
  }

  onChange() {

    this.isProgrammaticChange = true;

    if (this.config.dateTime == 'dateTime') {
      let utcValue = null;
      if (this.dateTimeControl.value) {
        utcValue = this.localToUtc(this.dateTimeControl.value);
      }
      this.control.setValue(utcValue, {emitEvent: false});
    }
    if (this.config.dateTime == 'time' && this.dateTimeControl.value != null && this.dateTimeControl.value != '') {
      let utcValue = null;


      let date = new Date();
      let time = this.dateTimeControl.value.split(':');

      date.setHours(time[0]);
      date.setMinutes(time[1]);

      if (this.dateTimeControl.value) {
        utcValue = this.localToUtc(date.toISOString());
      }
      let utcTime = moment(utcValue).format('HH:mm');
      this.control.setValue(utcTime, {emitEvent: false});
    }
    if (this.config.dateTime == 'date') {
      let dateControl = this.dateTimeFormGroup.get(this.config.key);
      let value = dateControl.value == '' ? null : dateControl.value;

      if (this.control.value != value) {
        this.control.setValue(value);
      }
    }

    this.config.onChange ? this.config.onChange(this.control) : null;

    this.isProgrammaticChange = false;
  }

  showValidators() {
    const dateTimeControl: AbstractControl = this.dateTimeFormGroup.get(this.config.key);
    return super.showValidators() || dateTimeControl.touched || dateTimeControl.dirty;
  }

  localToUtc(date: string) {
    return moment(date).utc().format('yyyy-MM-DDTHH:mm');
  }

  utcToLocal(date: string) {
    return moment.utc(date).local().format('yyyy-MM-DDTHH:mm');
  }

  get dateType() {
    if (this.config.dateTime == 'dateTime') {
      return 'datetime-local';
    }

    if (this.config.dateTime == 'time') {
      return 'time';
    }

    return 'date';
  }

  get min() {
    let min = this.config.min ? this.config.min() : null;
    if (this.config.dateTime) {
      min += 'T00:00';
    }
    return min;
  }

  get max() {
    let max = this.config.max ? this.config.max() : '9999-12-31';
    if (this.config.dateTime) {
      max += 'T00:00';
    }
    return max;
  }
}
