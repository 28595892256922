import { Component, OnInit, Input } from "@angular/core";
import { SystemRoleService } from "../../services/system-role.service";
import { SystemRole } from "../../interfaces/system-role.interface";
import { take } from "rxjs/operators";
import { SrsCheckboxConfig } from "../../../srs-forms/interfaces/elements/srs-checkbox-config.interface";
import { EdtellFormGroup } from "../../../srs-forms/classes/edtell-form-group.class";
import { SrsFormState } from "../../../srs-forms/enumerations/form-state.enum";
import { EdtellFormControl } from "../../../srs-forms/classes/edtell-form-control";
import { SystemRoleSelectorConfig } from "../../interfaces/system-role-selector-config.interface";
import { SrsFormFieldConfig } from "../../../srs-forms/interfaces/elements/srs-form-field-config.interface";

@Component({
  selector: "app-role-selector",
  templateUrl: "./role-selector.component.html",
  styleUrls: ["./role-selector.component.scss"],
})
export class RoleSelectorComponent implements OnInit {
  @Input()
  config: SystemRoleSelectorConfig<any>;

  selected: any[];
  unselected: any[];

  searchSet: Set<any> = new Set();

  searchFieldConfig: SrsFormFieldConfig;
  optionCheckboxConfigMap = new Map<any, SrsCheckboxConfig>();

  loaded: boolean = false;
  formGroup: EdtellFormGroup;

  constructor() {}

  async ngOnInit() {
    this.selected = this.config.selected;
    this.unselected = this.config.unselected;

    // This group is used to manage checkboxes
    this.formGroup = new EdtellFormGroup(SrsFormState.WRITE, {
      "selector-search": new EdtellFormControl(),
    });

    this.searchFieldConfig = {
      title: "",
      onInput: (c) => {
        this.searchSet.clear();;

        this.handleFilter((s) => {return this.config.getElementTitle(s).toLowerCase().includes(c.value)}, this.selected, this.searchSet)
        this.handleFilter((s) => {return this.config.getElementTitle(s).toLowerCase().includes(c.value)}, this.unselected, this.searchSet)

      },
      key: "selector-search",
      size: 12,
      group: this.formGroup,
      settings: {
        placeholder: "Filter",
      },
    };

    this.setupList(this.selected, true);
    this.setupList(this.unselected, false);
    

    this.handleFilter((s) => {return true}, this.selected, this.searchSet)
    this.handleFilter((s) => {return true}, this.unselected, this.searchSet)

    this.loaded = true;
  }

  handleFilter(predicate: Function, source: any[], dest: Set<any>) {
    for (let s of source) {
      if (predicate(s)) {
        dest.add(s);
      }
    }
  }

  setupList(list: any[], value : boolean) {
    for (let i of list) {
      let id = this.config.getElementKey(i);
      this.formGroup.addControl(id, new EdtellFormControl(value));
      this.optionCheckboxConfigMap.set(i, {
        key: id,
        size: 12,
        title: this.config.getElementTitle(i),
        group: this.formGroup,
        onChange: (c) => {

          let to = c.value ? this.selected : this.unselected;
          let from = !c.value ? this.selected : this.unselected;

          let index = from.findIndex((j) => {
            return j == i;
          });

          from.splice(index, 1);
          to.push(i);

          if (this.config.onChange != null) {
            this.config.onChange({
              elm: i,
              list: to,
              value: c.value,
            });
          }
        },
        settings: {
          padding: 0,
        },
      });
    }
  }
}
