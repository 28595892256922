import { Component } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-reset-button',
  templateUrl: './grid-reset-button.component.html',
  styleUrls: ['./grid-reset-button.component.scss'],
})
export class GridResetButtonComponent {
  private params: IStatusPanelParams;
  private columnApi;
  private gridApi;

  agInit(params: IStatusPanelParams): void {
    this.params = params;
    this.columnApi = params.columnApi;
    this.gridApi = params.api;
  }

  resetGrid() {
    this.gridApi.dispatchEvent({type: "resetGrid", columnApi: this.columnApi});
  }
}
