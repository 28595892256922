import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';
import { EntityControlButtonOption, EntityControlConfig } from '../../interfaces/entity-control-config';

@Component({
  selector: 'app-entity-control',
  templateUrl: './entity-control.component.html',
  styleUrls: ['./entity-control.component.scss']
})
export class EntityControlComponent implements OnInit, OnChanges {


  @Input()
  config: EntityControlConfig

  private securityConfig: SecurityConfig
  public securityObject: ObjectName;
  public options: EntityControlButtonOption[] = []
  public routerLink: string

  
  gridSettingsSecurity: SecurityConfig = {
    accessLevels: {
      accessLevel: 1,
      objectName: ObjectName.GRID_SETTINGS
    }
  }

  constructor() { }

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    if(this.config == null){
      throw new Error("The Entity Control Component that was implemented does not have a config binding")
    }

    this.options = this.config.controlButtons
    this.securityObject = this.config.securityObject;

    this.securityConfig = {
      roleSecurity: {
        systemAdmin: true
      }
    }

  }

  fullScreen() {
    this.config.fullScreenGridCallback();
  }

  gridSettings() {
    this.config.gridSettingsCallback();
  }
}