import { HttpClient } from "@angular/common/http";

import { apiURL } from "app-environment";
import { Injectable } from "@angular/core";
import { Comment } from "../interfaces/comment.interface";
import { LoginService } from "../../edtell-portal/services/login.service";
import { CommentResponse } from "../components/comments/comments.component";

@Injectable({
  providedIn: "root",
})
export class CommentsService {
  httpOptions: any;
  textHttpOptions: any;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
    this.textHttpOptions = loginService.textHttpOptions;
  }

  // Gets the list of comments for a specified object and item
  getMap(objectId: number, itemId: number) {
    return this.http.get<any[]>(
      `${apiURL}/comments-app/comments/map?objectId=${objectId}&itemId=${itemId}`,
      this.httpOptions
    );
  }

  addComment(comment: Comment) {
    return this.http.post<CommentResponse>(
      `${apiURL}/comments-app/comments/`,
      comment,
      this.httpOptions
    );
  }

  deleteComment(commentId: number) {
    return this.http.delete<boolean>(
      `${apiURL}/comments-app/comments/?commentId=${commentId}`,
      this.httpOptions
    );
  }

  getLastAccessed(objectId: number, itemId: number) {
    const accessString = objectId + "-" + itemId;
    return localStorage.getItem(accessString);
  }

  setLastAccessed(objectId: number, itemId: number) {
    const accessString = objectId + "-" + itemId;
    localStorage.removeItem(accessString);
    localStorage.setItem(accessString, Date.now().toString());
  }

  getItemCount(itemList: CommentResponse[], date: string): any {
    let count = 0;
    if (itemList != undefined) {
      itemList.forEach((item) => {
        if (item.children) {
          count += this.getItemCount(item.children, date);
        }
        if (Date.parse(item.comment.dateCreated) > Number(date)) {
          count++;
        }
      });
    }
    return count;
  }

  getFreshnessIndicator(objectId: number, itemId: number) {
    return this.http.get<string>(
      `${apiURL}/comments-app/comments/freshness?objectId=${objectId}&itemId=${itemId}`,
      this.textHttpOptions
    );
  }

  getCommentCount(objectId: number, itemId: number) {
    return this.http.get<number>(
      `${apiURL}/comments-app/comments/count?objectId=${objectId}&itemId=${itemId}`,
      this.textHttpOptions
    );
  }
}
