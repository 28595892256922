export abstract class SidebarConditionalService{

    hidden(): any {
        return false;
    }

    disabled(): any {
        return false;
    }

}