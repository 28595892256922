import { 
  SrsSearch, 
  SrsSearchInfo, 
  AutocompleteInterface, 
  ObjectName
} from "@edtell-common"
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RegistrationGridSearchService } from "../services/registration-grid-search.service";
import { RegistrationSearchAutocompleteResultDataInterface } from "../../customer/interfaces/registration-search-autocomplete-result-data.interface";
import { groupBy } from 'lodash';

@Injectable()
@SrsSearchInfo({
  label: "Registrations",
  security: {
    accessLevels: {
      accessLevel: 1,
      objectName: ObjectName.REGISTRATIONS
    }
  }
})
export class RegistrationSearch extends SrsSearch {

  results: RegistrationSearchAutocompleteResultDataInterface[];

  constructor(
    private registrationGridSearchService: RegistrationGridSearchService,
  ) { super(); }

  async search(router: Router, text: string, autoCompleteObject: any) {
    if (autoCompleteObject) {
      console.log("autoCompleteObject", autoCompleteObject)
      router.navigate([`./registrations/${autoCompleteObject.regId}`])//.then(() => {
        //window.location.reload();
      //});
    } else {
      if (this.results.length == 1) {
        router.navigate([`./registrations/${this.results[0].regId}`])//.then(() => {
        //window.location.reload();
        //});
      } else {
        router.navigate([`./registrations/courses`], { queryParams: { 'searchString': text } });
      }
    }
  }

  async autocomplete(text: string) {

    this.results = await this.registrationGridSearchService.getAutocompleteResults(text).toPromise();
    let options: AutocompleteInterface<RegistrationSearchAutocompleteResultDataInterface>[] = [];

    for (let i of this.results) {
      options.push({
        text: i.registrationData,
        object: i
      })
    }

    return options;
  }

}