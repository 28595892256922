import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoginService } from "../../edtell-portal/services/login.service";
import { SystemRole } from "../interfaces/system-role.interface";
import { SimpleResponse } from "../../edtell-portal/interfaces/simple-response.interface";
import { apiURL } from "app-environment";

@Injectable({
  providedIn: "root",
})
export class SystemRoleService {

  private httpOptions: {};

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
  }

  getRole(id : number){
    return this.http.get<SystemRole>(`${apiURL}/administration-app/roles?id=${id}`, this.httpOptions)
  }

  isRoleTitleUnique(title : string){
    return this.http.get<SimpleResponse<boolean>>(`${apiURL}/administration-app/roles/title/is-unique?title=${title}`, this.httpOptions)
  }

  createRole(role : SystemRole){
    return this.http.post<SystemRole>(`${apiURL}/administration-app/roles`, role, this.httpOptions)
  }

  updateRole(role : SystemRole){
    return this.http.put<SystemRole>(`${apiURL}/administration-app/roles`, role, this.httpOptions)
  }

  getAllRoles() {
    return this.http.get<SystemRole[]>(`${apiURL}/administration-app/roles/all`, this.httpOptions)
  }

  getMyRoles(){
    return this.http.get<SystemRole[]>(`${apiURL}/administration-app/roles/me`, this.httpOptions)
  }

  getRolesForUser(id : number){
    return this.http.get<SystemRole[]>(`${apiURL}/administration-app/roles/user?id=${id}`, this.httpOptions)
  }

}
