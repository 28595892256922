<div>

  <ng-container *ngIf="config.group.state == 'READ' || disabled">
    <div class="form-group">
      <label *ngIf="config.title">{{config.title}}</label>
      <textarea #viewArea class="form-control" [id]="elementId" [value]="readValue" readonly
        rows="1"></textarea>
    </div>
  </ng-container>

  <ng-container *ngIf="config.group.state == 'WRITE' && !disabled">
    <div class="form-group multi-select-dropdown">
      <label>{{config.title}} <span *ngIf="showRequired"
        class="required-asterisk">{{ required ? "*" : "" }}</span></label>
      <div class="form-control multi-select-box" [ngClass]="[invalidClass]" matAutocompleteOrigin #origin="matAutocompleteOrigin"
        (click)="searchFocus($event)">
        <div
          [ngClass]="['selected-option', i === control.value.length - 1 ? (unselectWarning > 1 ? 'unselect-warning' : '') : '']"
          *ngFor="let option of selectedOptions; index as i">{{option.text}}
          <span (click)="toggleSelectOption($event, option);"
            class="remove-selected-option-button">&times;</span>
        </div>
        <input #searchInput [edtellTooltip]="tooltip" [id]="elementId" placeholder="Search Options"
          aria-label="Number" [(ngModel)]="searchString" (keyup)="tryBackspaceUnselect($event)"
          #trigger="matAutocompleteTrigger" [matAutocomplete]="auto" [matAutocompleteConnectedTo]="origin" [autoFocus]="autoFocus">
      </div>
      <mat-autocomplete [panelWidth]="autocompletePanelWidth" autoActiveFirstOption #auto="matAutocomplete">
        <cdk-virtual-scroll-viewport [itemSize]="48" [style.height.px]="viewportHeight" [style.width.px]="autocompletePanelWidth"  minBufferPx="250" maxBufferPx="500">
          <div class="option-container">

            <div class="select-deselect-container d-flex srs-btn" (click)="$event.stopPropagation();">
              <span [ngClass]="['fake-link', selectAllDisabled ? 'disabled-button ' : '']" (click)="selectAll()">Select All</span>
              <span [ngClass]="['fake-link ml-auto', deselectAllDisabled ? 'disabled-button' : '']" (click)="deselectAll()">Deselect All</span>
            </div>
              <mat-option *cdkVirtualFor="let option of filteredOptions" (onSelectionChange)="selectByEnter($event, option)">
                <div class="multi-select-click-area" (click)="toggleSelectOption($event, option)">
                  <div class="checkbox-container form-inline" >
                    <div class="custom-control custom-checkbox">
                      <input [edtellTooltip]="tooltip" type="checkbox" [id]="elementId" (click)="$event.preventDefault(); $event.stopPropagation();"
                        class="custom-control-input form-control" [ngModel]="getIsSelected(option)" [disabled]="getDisabled(option)">
                      <label class="custom-control-label fake-label" [for]="elementId"></label>
                    </div>
                  </div>
                  <span [ngClass]="['option-text-container', getDisabled(option) ? 'disabled-option' : '']">{{option.text}}</span>
                </div>
              </mat-option>
          </div>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>

      <small class="form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
      <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
        <div class="invalid-feedback d-block" *ngIf="hasError(v.key) == true">{{v.message}}</div>
      </ng-template>

      <div *ngFor="let alert of config.alerts">
        <div class="row mt-2" *ngIf="alert.showAlertMessage()">
            <div class="col-12 mx-0">
              <div class="col-12 alert-message px-0">
                <i class="fas fa-exclamation-triangle"></i> 
                {{alert.message}}
              </div>
            </div>
          </div>
        </div>
    </div>
  </ng-container>

</div>
