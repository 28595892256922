import { Component, Input, OnInit } from '@angular/core';
import { ProcessedApiComponent } from '../../../interfaces/processed-api.interface';
import { EmailDistributionApiData } from '../../../interfaces/email-distrubtion-api-data.interface';
import { DistributionDialogConfig } from '../../../interfaces/processed-distribution-dialog-config.interface';
import { ProcessedJobStatusBarConfig } from '../../../interfaces/processed-job-status-bar-config';
import { DistributionMetaData } from '../../../interfaces/processed-job-status.interface';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-distribution-email-view',
  templateUrl: './distribution-email-view.component.html',
  styleUrls: ['./distribution-email-view.component.scss']
})
export class DistributionEmailViewComponent implements OnInit {

  @Input()
  config : ProcessedApiComponent;

  @Input()
  distribution : DistributionMetaData;

  emailData : EmailDistributionApiData;
  html : SafeHtml;
  loaded = false;

  constructor(private sanitizer: DomSanitizer) { }

  async ngOnInit() {
    this.emailData = await this.config.api.emailDistribution(this.distribution.id);
    // If the email comes in and starts with <pre> and ends with </pre>, chop it off so it can display properly as innerHTML
    if(this.emailData.content.startsWith("<pre>") && this.emailData.content.endsWith("</pre>")){
      this.emailData.content = this.emailData.content.substring(5,this.emailData.content.length - 7)
    }
    this.emailData.content = this.emailData.content.replace(/\n\r/gi, "\n");
    this.emailData.content = this.emailData.content.replace(/\r\n/gi, "\n");
    this.emailData.content = this.emailData.content.replace(/\n<br>/gi, "<br>");
    this.emailData.content = this.emailData.content.replace(/<br>\n/gi, "<br>");
    this.emailData.content = this.emailData.content.replace(/\n/gi, "<br>");
    this.html = this.sanitize(this.emailData.content);
    this.loaded = true;
  }

  async downloadAttachment(fileId : number){
    await this.config.api.downloadDistribution(fileId) 
  }

  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
