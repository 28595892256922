import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
    providedIn: "root"
})
export class LocalStorageService {
    
    private localStorage: Map<string, localStorageItem> = new Map();

    constructor() {
        let browserStorage = window.localStorage.getItem("edtell");
        if (browserStorage) {
          this.localStorage = JSON.parse(browserStorage, reviver);
        }
        this.validateLocalStorageItems();
    }

    private checkExpired(localStorageItem: localStorageItem): boolean {
      if (localStorageItem.expiration == null) {
        return false;
      }

      return moment(localStorageItem.expiration).isSameOrBefore(moment());
    }

    private validateLocalStorageItems() {
      for (let [key, localStorageItem] of this.localStorage) {
        if (this.checkExpired(localStorageItem)) {
          this.removeLocalStorageItem(key);
        }
      }
    }


    getLocalStorageItem(key: string) {
      let localStorageItem = this.localStorage.get(key);

      if (localStorageItem == null) {
        return;
      }

      if (this.checkExpired(localStorageItem)) {
        this.removeLocalStorageItem(key);
      }

      return localStorageItem?.data;
    }


    /**
     * 
     * @param key 
     * @param item 
     * @param expiration js Date or time  in milliseconds
     */
    setLocalStorageItem(key: string, item: any, expiration?: Date | number) {
      let expirationDate: Date = new Date();
      if (typeof expiration == 'number') {
        var time = expirationDate.getTime();
        var expireTime = time + expiration;
        expirationDate.toUTCString()
        expirationDate.setTime(expireTime);
      } else {
        expirationDate = expiration;
      }

      this.localStorage.set(key, {
        data: item,
        expiration: expirationDate
      });
      this.setBrowserStorage();
    }

    removeLocalStorageItem(key: string) {
        this.localStorage.delete(key);
        this.setBrowserStorage();
    }

    /**
     * DO NOT USE UNLESS YOU ARE CERTAIN YOU WANT TO CLEAR ALL OF THE EDTELL LOCAL STORAGE.
     * CONSIDER USING removeLocalStorageItem METHOD INSTEAD.
    */ 
    clearStorage(areYouSure: boolean) {
      if (areYouSure) {
        this.localStorage.clear();
        this.setBrowserStorage();
      }
    }

    private setBrowserStorage() {
        let browserStorage = JSON.stringify(this.localStorage, replacer);
        window.localStorage.setItem("edtell", browserStorage);
    }
}

// From https://stackoverflow.com/questions/29085197/how-do-you-json-stringify-an-es6-map
function replacer(key, value) {
    if(value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
}

function reviver(key, value) {
    if(typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
}

interface localStorageItem {
  data: any,
  expiration: Date;
}