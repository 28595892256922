import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectService } from '../../../edtell-admin/services/object.service';
import { CommentsService } from '../../services/comments.service';
import { EmitterEvent } from '../../../edtell-portal/enums/emitter-events.enum';
import { EventEmitterService } from '../../../edtell-portal/services/event-emitter.service';
import { SidebarIndicatorComponent } from '../../../srs-sidebar/abstracts/sidebar-indicator.abstract';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-freshness-indicator',
  templateUrl: './freshness-indicator.component.html',
  styleUrls: ['./freshness-indicator.component.scss']
})
export class FreshnessIndicatorComponent extends SidebarIndicatorComponent implements OnDestroy {

  commentCount: any = 0;
  freshnessIndicator: any = null;
  private notesId: number;

  private sidebarEventSub : Subscription

  constructor(
    private objectService: ObjectService,
    private router: Router,
    private route: ActivatedRoute,
    private commentsService: CommentsService,
    private eventEmitterService: EventEmitterService
  ) {
    super();
  }

  onIndicatorInit() {
    this.sidebarEventSub = this.eventEmitterService.subscribe(EmitterEvent.SIDEBAR_NOTE_EVENT, (data) => {
      this.refresh(true);
    });
  }

  async refresh(force?: boolean) {

    let id = +this.data._sidebar._route.paramMap.get('id');
    if (id == this.notesId && !force) {
      return;
    }
    this.notesId = id;

    let objectName = this.data.data;
    if (objectName == null){
      throw new Error('Sidebar is not configured correctly, please include an object name into the configuration.')
    }
    let objectId = await this.objectService.getObjectId(objectName).pipe(first()).toPromise();

    this.commentCount = await this.commentsService.getCommentCount(objectId, id).toPromise();
    this.freshnessIndicator = await this.commentsService.getFreshnessIndicator(objectId, id).toPromise();

    if (this.freshnessIndicator === '') {
      this.freshnessIndicator = null;
    }

    if (this.freshnessIndicator === 'old') {
      this.freshnessIndicator = null;
    }
  }

  ngOnDestroy(): void {
    if(this.sidebarEventSub != null){
      this.sidebarEventSub.unsubscribe()
    }
  }

}
