import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CREDIT_CARD_LIBRARY_DIRECTIVES } from "./src/lib/credit-card-directives.module";

// This module was taken from this Repo https://github.com/thekip/angular-cc-library

@NgModule({
    declarations: [
        CREDIT_CARD_LIBRARY_DIRECTIVES
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        CREDIT_CARD_LIBRARY_DIRECTIVES
    ]
})
export class AngularCCLibraryModule {}