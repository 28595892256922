<div class="container-fluid">
    <div class="row">
        <div class="col-12 d-flex justify-content-end">
            <span class="close" (click)="closeDialog()">&times;</span>
        </div>
    </div>

    <div class="row" *ngIf="config.data">
        <div class="col-12 ag-grid-container">
            <ag-grid-angular
                class="ag-theme-balham"
                [rowData]="data"
                [columnDefs]="config.columnDefs"
                [gridOptions]="gridOptions"
            >
          </ag-grid-angular>
        </div>
    </div>
</div>