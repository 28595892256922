import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import * as _ from 'lodash';
import { cloneDeep, remove } from 'lodash';
import { AttributeService } from "../../../edtell-portal/services/attribute.service";
import { NotificationService } from "../../../edtell-portal/services/notification.service";
import { Attribute } from "../../../edtell-portal/interfaces/attribute.interface";
import { ObjectService } from '../../../edtell-admin/services/object.service';
import { UserService } from '../../../edtell-admin/services/user.service';
import { EntityControlConfig } from '../../../edtell-controls/interfaces/entity-control-config';
import { setGridFilterQueryParams } from "../../../edtell-portal/functions/grid-filter.function";
import { AttributeGridConfig } from "../../../edtell-ag-grid/interfaces/attribute-grid-config.interface";
import { EdtellColumnDef } from "../../../edtell-ag-grid/interfaces/edtell-column-def.interface";
import { EdtellAgGridService } from "../../../edtell-ag-grid/services/edtell-ag-grid.service";
import { DialogActionType } from "../../../edtell-portal/enums/dialog-action-type.enum";
import { EdtellDialogComponent } from "../../../srs-forms/abstracts/edtell-dialog.component";
import { EntityControlLinkComponent } from "../../../edtell-ag-grid/components/entity-control-link/entity-control-link.component";
import { GridPreferenceService } from "../../../edtell-portal/services/grid-preference.service";
import { globalGridOptions } from "../../../edtell-ag-grid/interfaces/global-grid-config";
import { AttributeDialogComponent } from "../attribute-dialog/attribute-dialog.component";
import { EdtellDialogConfig } from "../../../srs-forms/interfaces/abstracts/edtell-dialog-config.interface";
import { EdtellDialogData } from "../../../srs-forms/interfaces/abstracts/edtell-dialog-data.interface";
import { EntityControlLinkConfig } from "../../../edtell-controls/interfaces/entity-control-link-config.interface";
import { EdtellAgGridConfig } from "../../../edtell-ag-grid/interfaces/edtell-ag-grid-config.interface";
import { ObjectName } from "../../../edtell-admin/enums/object-name.enum";

@Component({
  selector: 'app-attribute-grid-widget',
  templateUrl: './attribute-grid-widget.component.html',
  styleUrls: ['./attribute-grid-widget.component.scss']
})
export class AttributeGridWidgetComponent implements OnInit {

  @Input()
  attributeGridConfig: AttributeGridConfig;

  attributes: Attribute[];

  addAttributeControlConfig: EntityControlConfig;
  gridAPI: GridApi;
  columnAPI: ColumnApi;

  attributeLinkConfig: EntityControlLinkConfig = {
    callback: (rowData) => {
      return this.editAttribute(rowData);
    }
  };

  columnDefs: EdtellColumnDef[] = [
    {
      headerName: 'Attribute Name', colId: 'attributeName', field: 'attributeName', suppressSizeToFit: true,
      cellRendererFramework: EntityControlLinkComponent,
      cellRendererParams: this.attributeLinkConfig,
      cellStyle: {color: 'blue', 'text-decoration': 'underline', 'cursor': 'pointer'},
      //onCellClicked: this.editAttribute.bind(this)
    },
    { headerName: 'Attribute Value', field: 'attributeValue', type:'text'},
    { headerName: 'Attribute Description', field: 'attributeDescription', type: 'text'},
    { headerName: 'Visible On View', colId: 'visibleOnView', field: 'visibleOnView', type: 'text'},
    { headerName: 'System Attribute', colId: 'systemAttribute', field: 'systemAttribute', type: 'text'},
    // { headerName: 'Date Created', field: 'dateCreated', hide: true, type: 'date' },
    // { headerName: 'Created By', field: 'createdBy', hide: true, type: 'number' },
    // { headerName: 'Date Modified', field: 'dateModified', hide: true, type: 'date' },
    // { headerName: 'Modified By', field: 'modifiedBy', hide: true, type: 'number' }
  ];

  agGridConfig: EdtellAgGridConfig;

  constructor(
              private route: ActivatedRoute,
              private dialog: MatDialog) { }

  initGridConfig() {
    let onGridReady = (params) => {
      this.gridAPI = params.api;
      this.columnAPI = params.columnApi;
      this.gridAPI.sizeColumnsToFit();
      this.columnAPI.autoSizeColumns(['attributeName', 'visibleOnView', 'systemAttribute']);
    };

    let onFirstDataRendered = () => {
      setGridFilterQueryParams({
        gridApi: this.gridAPI,
        route: this.route,
        params: [
          { column: "attributeName", param: "attributeName" },
          { column: "attributeValue", param: "attributeValue" },
          { column: "attributeDescription", param: "attributeDescription" }
        ]
      })
    }

    this.agGridConfig = {
      columnDefs: this.columnDefs,
      data: () => { return this.attributeGridConfig.attributes },
      gridPreferenceName: this.attributeGridConfig.gridPreferenceName,
      entityControl: this.addAttributeControlConfig,
      onGridReady: onGridReady.bind(this),
      onFirstDataRendered: onFirstDataRendered.bind(this),
      object: ObjectName.OBJECT
    }
  }

  ngOnInit() {

    this.attributes = this.attributeGridConfig.attributes;

    this.addAttributeControlConfig = {
      controlButtons: [
        {
          text: 'Add',
          callback: async () => {
            return await this.addAttribute();
          },
          icon: 'fa fa-plus-square',
          security: {
            accessLevels: {
              accessLevel: 3,
              objectName: this.attributeGridConfig.parentObject
            },
            localOwnership: this.attributeGridConfig.ownershipObject != null,
            ownershipData: this.attributeGridConfig.ownershipObject
          }
        }
      ],
    };

    this.initGridConfig();
  }



  async addAttribute() {
    let dialogConfig: EdtellDialogConfig = {
      width: '800px',
      data: {
        action: DialogActionType.ADD,
        objectData: {
          itemId: this.attributeGridConfig.parentId,
          itemType: this.attributeGridConfig.attributeType,
          blacklist: false, //?
          otherAttributes: this.attributeGridConfig.attributes,
          owner: this.attributeGridConfig.ownershipObject?.owner
        },
        securityObject: this.attributeGridConfig.parentObject,
        securityOverrides: {
          add: {
            accessLevels: [
              {
                accessLevel: 2,
                objectName: this.attributeGridConfig.parentObject
              }
            ],
            localOwnership: true,
            ownershipData: this.attributeGridConfig.ownershipObject
          }
        }
      }
    };

    await this.attributeDialog(dialogConfig)
  }

  async editAttribute(rowData) {
    
    let otherAttributes = cloneDeep(this.attributeGridConfig.attributes);
    remove(otherAttributes, rowData);
    let dialogConfig: EdtellDialogConfig = {
      width: '800px',
      data: {
        action: DialogActionType.EDIT,
        objectData: {
          ...rowData,
          otherAttributes: otherAttributes,
          owner: this.attributeGridConfig.ownershipObject?.owner
        },
        securityObject: this.attributeGridConfig.parentObject,
        securityOverrides: {}
      }
    };

    await this.attributeDialog(dialogConfig);
  }

  async attributeDialog(dialogConfig: EdtellDialogConfig) {
    let dialogRef: MatDialogRef<EdtellDialogComponent> = this.dialog.open(AttributeDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (dialogResponse: EdtellDialogData) => {
      if (dialogResponse == null) {
        return;
      }
      
      this.attributeGridConfig.onDialogCloseCallback(dialogResponse.action, dialogResponse.objectData)
      this.attributeGridConfig.attributes
    });
  }

}
