import {AbstractControl, ValidatorFn} from '@angular/forms';

/***
 * This is a validator that returns a 'notUnique' error if the current value in a field matches a criteria in a supplied list.
 * Also accepts the current object that is being edited to allow for resubmission on edit.
 *
 * @param compareList - This is a list of items that the current value will be compared against
 * @param compareFcn - This is a function that compares a given item in the supplied list against the current control value
 * @param currentItem - This is the current object in the form that is used to check if the current object is being edited
 */
export function matchExistingItems(compareList: any[],
                                   compareFcn: (listItem: any, controlValue) => boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (control.pristine) {
      return null;
    }

    let controlValue = control.value;

    if ((compareList != null ? compareList.length == 0 : false) || controlValue == null) {
      return null;
    }

    for (let item of compareList) {
      if (compareFcn(item, controlValue)) {
        return {'notUnique': 'notUnique'};
      }
    }

    return null;
  };
}
