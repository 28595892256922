import { Component, Input, OnInit } from '@angular/core';
import { SecurityConfig } from '../../../../edtell-admin/interfaces/route-security-config.interface';
import { EdtellTabConfig } from '../../../interface/edtell-tabset.config';

@Component({
  selector: 'app-edtell-tab',
  templateUrl: './edtell-tab.component.html',
  styleUrls: ['./edtell-tab.component.scss']
})
export class EdtellTabComponent implements OnInit {
  
  @Input() title: string;

  @Input() config?: EdtellTabConfig;

  security: SecurityConfig;

  tabOpen: boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.config && this.config.security) {
      this.security = this.config.security
    }
  }

  ngDoCheck() {
    this.ngOnInit();
  }
}
