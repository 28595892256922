import { Directive, DoCheck, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ApplicationFrameService } from '../../edtell-portal/services/application-frame.service';

@Directive({
  selector: '[headerOffset]'
})
export class HeaderOffsetDirective implements OnInit, DoCheck {

  constructor(
    private el: ElementRef,
    private applicationFrame: ApplicationFrameService,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, "top", this.applicationFrame.headerElement.clientHeight + "px")
  }

  ngDoCheck() {
    this.ngOnInit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.ngOnInit();
  }

}
