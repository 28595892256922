import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiDocSidebarEntry } from '../../../interfaces/api-doc-sidebar-entry.interface';
import { SidebarEntriesObject } from '../../../interfaces/sidebar-entries-object.interface';
import { DocumentationUIService } from '../../../services/documentationUI.service';

@Component({
  selector: 'app-documentation-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class DocumentationSidebarComponent implements OnInit {

  entriesObj: SidebarEntriesObject[];

  constructor(private documentationUiService: DocumentationUIService, private route: ActivatedRoute) { }

  async ngOnInit() {
    if (this.documentationUiService.entriesObj == undefined) {
      // sidebar data is sorted on the backend
      let sidebarData: ApiDocSidebarEntry[] = await this.documentationUiService.getSidebarData().toPromise();
      
      this.entriesObj = [{
        title: sidebarData[0].groupTitle,
        entries: []
      }]
      let groupIndex = 0;
      let currGroup = sidebarData[0].groupId;
      
      for (let entry of sidebarData) {
        // if next entry isnt in the group as the previous
        if (entry.groupId !== currGroup) {
          this.entriesObj.push({
            title: entry.groupTitle,
            entries: []
          })
          groupIndex++;
          currGroup = entry.groupId
        }
        this.entriesObj[groupIndex].entries.push(entry);
      }
      
      this.documentationUiService.gotSidebarData.next(this.entriesObj);
    }
  }

  compare(a,b) {
    if (a.title.toLowerCase() < b.title.toLowerCase())
      return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase())
      return 1;
    return 0;
  }
}
