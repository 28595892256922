import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityConfig } from '../../edtell-admin/interfaces/route-security-config.interface';
import { ObjectService } from '../../edtell-admin/services/object.service';
import { SecurityService } from '../services/security.service';

@Injectable({
  providedIn: "root"
})
export class AccessGuard implements CanActivate, CanActivateChild {

  constructor(
    private securityService: SecurityService,
    private objectService: ObjectService,
    private router: Router
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.checkRoute(route);
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.checkRoute(childRoute);
  }

  async checkRoute(route: ActivatedRouteSnapshot) : Promise<boolean> {

    if(route.data.skipAuth == true){
      return true;
    }

    return new Promise<boolean>((finished) => {

      // Make sure the objects are loaded
      let objectLoader = this.objectService.getObjectMap();

      objectLoader.subscribe(async () => {

        // Check to make sure the user has access
        let securityConfigs: SecurityConfig[] = this.securityService.getRouteSecurityConfigs(route);
        let access = await this.securityService.hasPermission(route, securityConfigs);

        if (!access) {
          this.router.navigate([`/403`], {});
          finished(false);
        }

        finished(true);
      });

    });
  }

}
