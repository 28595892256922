import { Component, OnInit } from '@angular/core';
import { AutocompleteInterface } from '../../interfaces/autocomplete-result.interface';
import { HideSearch } from '../../searches/system/hide.search';
import { SearchTypeSearch } from '../../searches/system/search-type.search';
import { SrsSearchService } from '../../services/srs-search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  label: string
  searchText: string
  autocompleteOption: AutocompleteInterface<any>
  autocompleteResults: AutocompleteInterface<any>[]

  showSearch: boolean = true;
  searchingSearches: boolean = false;
  
  constructor(private searchService: SrsSearchService) {

    searchService.searchState.subscribe((data) => {
      this.label = data.label
      this.showSearch = !(data.type == HideSearch) && data.type !== undefined
    })

  }

  ngOnInit() {
    this.searchText = null;
    this.searchingSearches = this.searchService.getActiveSearch() instanceof SearchTypeSearch;
  }

  submitSearch(optionClicked: AutocompleteInterface<any>) {
    // console.log('SEARCH')
    if (optionClicked != null) {
      this.autocompleteOption = optionClicked;
    } else if (this.searchText == null) {
      return;
    } else if(this.autocompleteResults.length == 1){
      this.autocompleteOption = this.autocompleteResults[0];
    }
    this.searchService.search(this.searchText, this.autocompleteOption);

    if (this.searchingSearches == true) {
      this.searchingSearches = false;
    }

    this.searchText = null;
    this.autocompleteOption = null;
    this.autocompleteResults = null;
  }

  getSelectedOption(option: AutocompleteInterface<any>) {
    this.autocompleteOption = option
    this.searchText = option.text
  }

  async getAutocompleteResults() {

    if (typeof this.searchText !== "string") { return; }

    // Logic to trigger and hide the search of seachces (search-type-search)
    let delimcheck = this.searchText.substr(0, 1) == ":"
    if (delimcheck && !this.searchingSearches) {
      // Check and setup for searching searches
      this.searchService.setActiveSearch(SearchTypeSearch);
      this.searchingSearches = true;

    } else if (!delimcheck && this.searchingSearches) {
      this.searchingSearches = false;
      this.searchService.reset();
    } else if (this.searchingSearches) {
      return;
    }


    this.autocompleteResults = await this.searchService.getAutoComplete(this.searchText);
  }

  isVisible() {
    return this.showSearch;
  }

}
