import { HostListener, Directive } from '@angular/core';

@Directive()
export abstract class ComponentCanDeactivate {

  abstract canDeactivatePrompt: string | null;
  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}

// Src: https://medium.com/front-end-weekly/angular-how-keep-user-from-lost-his-data-by-accidentally-leaving-the-page-before-submit-4eeb74420f0d
