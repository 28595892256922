<ng-template [ngIf]="loaded">
  <ng-template [ngIf]="selectedViewConfig == null">
    <div class="row pt-2">
      <div class="col-12">
        <ag-grid-angular
          class="ag-theme-balham"
          style="height: 500px;"
          [rowData]="rowData"
          [gridOptions]="gridOptions"
          [columnDefs]="columnDefs"
        ></ag-grid-angular>
      </div>
    </div>
  </ng-template>
</ng-template>
