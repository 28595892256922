<div class="dialog-header">
  <h1 mat-dialog-title mat-dialog-draggable-title>Pick a Document</h1>
  <span aria-hidden="true" (click)="onNoClick()">&times;</span>
</div>

<mat-dialog-content>
  <div class="row mt-4">
    <div class="col-12">
      Document Type: {{ documentTypeText }}
    </div>
  </div>
  <app-edtell-ag-grid *ngIf="agGridConfig" [config]="agGridConfig"></app-edtell-ag-grid>
</mat-dialog-content>
