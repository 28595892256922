import { isEqual } from 'lodash';
import { EdtellFormGroup } from '../classes/edtell-form-group.class';

/***
 * Returns true if values differ from the original values in the Form Group.
 *
 * @param formGroup The form group that is used for checking values
 */
export function checkInitialValues(formGroup: EdtellFormGroup) {

  // having to declare outside the for loop since returning from inside the loop wont return from function
  let valuesChanged = false;

  for (let c in formGroup.controls) {

      // checking to see if the control's current value differs from the originalValue attribute on the controller
      if (!isEqual(formGroup.controls[c].value, formGroup.controls[c]['originalValue']) && !formGroup.controls[c]['excludeFromCheck']) {
        if (Number(formGroup.controls[c].value) === Number(formGroup.controls[c]['originalValue'])) {
          // Doing the number check because the money input has the possibility to change the value in forms
        } else {
          // console.log('changed control', c, formGroup.controls[c]);
          valuesChanged = true;
        }
      }
  }

  return valuesChanged;
}
