import { 
  LoginService
} from "@edtell-common"
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiURL } from "../../../../environments/environment";
import { CourseViewSearchResult } from "../interfaces/grid-view/course-view-search-results.interface";
import { PaymentViewSearchResult } from "../interfaces/grid-view/payment-view-search-result.interface";
import {RegistrationSearchAutocompleteResultDataInterface} from "../../customer/interfaces/registration-search-autocomplete-result-data.interface";

// Used in search component - provided globally
@Injectable({
  providedIn: "root"
})
export class RegistrationGridSearchService {

  private httpOptions: {};
  private textHttpOptions: {};

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
    this.textHttpOptions = loginService.textHttpOptions;
  }

  courseViewApplySettings(numDaysToShow, serviceRepId, cartStatus, salesRepId, operationStatus, providerId, weStatus, ncaaProspect, timeOffset) {

    const req = {
      numDaysToShow,
      cartStatus: cartStatus === "all" ? null : cartStatus,
      operationStatus: operationStatus === "all" ? null : operationStatus,
      weStatus: weStatus === "all" ? null : weStatus,
      salesRepId,
      serviceRepId,
      ncaaProspect: ncaaProspect === "all" ? null : ncaaProspect,
      providerId,
      timeOffset
    };

    return this.http.post<CourseViewSearchResult[]>(`${apiURL}/registrations-app/registrations/course/search/settings`, req, this.httpOptions);
  }

  courseViewQuickFind(target: string, condition: string, searchTerm: string) {
    const req = {
      target,
      condition,
      searchTerm
    };
    return this.http.post<CourseViewSearchResult[]>(`${apiURL}/registrations-app/registrations/course/search/quick-find`, req, this.httpOptions);
  }
   
  getRegistrationSearchResults(searchString) {
    return this.http.get<CourseViewSearchResult[]>(`${apiURL}/registrations-app/registrations/profile-search?searchString=${searchString}`, this.httpOptions);
  }

  getAutocompleteResults(searchString) {
    return this.http.get<RegistrationSearchAutocompleteResultDataInterface[]>(`${apiURL}/registrations-app/registrations/profile-search/autocomplete?searchString=${searchString}`, this.httpOptions);
  }

  paymentViewApplySettings(numDaysToShow, serviceRepId, cartStatus, salesRepId, operationStatus, paymentMethod, weStatus, timeOffset){

    let req = {
      numDaysToShow,
      cartStatus: cartStatus === "all" ? null : cartStatus,
      operationStatus: operationStatus === "all" ? null : operationStatus,
      weStatus: weStatus === "all" ? null : weStatus,
      serviceRepId, 
      salesRepId, 
      paymentMethod: paymentMethod === "all" ? null : paymentMethod,
      timeOffset: timeOffset
    };

    return this.http.post<PaymentViewSearchResult[]>(`${apiURL}/registrations-app/registrations/payment/search/settings`, req, this.httpOptions);
  }

  paymentViewQuickFind(target: string, condition: string, searchTerm: string) {
    const req = {
      target,
      condition,
      searchTerm
    };
    return this.http.post<PaymentViewSearchResult[]>(`${apiURL}/registrations-app/registrations/payment/search/quick-find`, req, this.httpOptions);
  }
}