<div class="container">
  <div class="row pt-2">
    <div class="col-12">
      <div class="center">
        <img src="../../assets/edtell_logo_745_x_356_with_tagline.png" style="width: 30%; height: 30%">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="center gray-text">
        403
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="center gray-text">
        It looks like you don't have permission to view this page.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="center gray-text">
        <button (click)="goHome()" class="btn btn-tertiary">Go Back</button>
      </div>
    </div>
  </div>
</div>