<button *ngIf="config?.matMenu == null" [disabled]="buttonDisabled" [secure]="config?.security"
    [type]="config?.buttonType ?? 'button'" [ngClass]="['btn', typeClass, config?.class != undefined ? config.class : '']"
    (click)="doCallBack()">
    <ng-content></ng-content>
    <i *ngIf="config?.icon != '' && config?.icon != undefined" [class]="config?.icon"></i>
    <span *ngIf="buttonText">{{buttonText}}</span>
    <div class="spinner-border text-light" role="status" *ngIf="showSpinner">
        <span class="sr-only">Loading...</span>
    </div>
</button>
<button *ngIf="config?.matMenu != null" [disabled]="buttonDisabled" [secure]="config?.security"
    [type]="config?.buttonType ?? 'button'" [ngClass]="['btn', typeClass, config?.class != undefined ? config.class : '']"
    [mat-menu-trigger-for]="config.matMenu" (click)="doCallBack()">
    <i *ngIf="config?.icon != '' && config?.icon != undefined" [class]="config?.icon"></i>
    <span *ngIf="buttonText">{{buttonText}}</span>
    <div class="spinner-border text-light" role="status" *ngIf="showSpinner">
        <span class="sr-only">Loading...</span>
    </div>
</button>