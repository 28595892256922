import { LangUtils } from "../../edtell-portal/namespaces/lang-utils.namespace";
import { EdtellCacheApi } from "../classes/edtell-cache-api.class";
import { CacheInternal } from "../enums/cache-internals.enum";
import { EdtellInternalCacheUtil } from "./edtell-internal-cache-utils.namespace";

export namespace EdtellCacheUtil {
    export function getCacheApi(service: any): EdtellCacheApi {
      if (LangUtils.isConstructor(service)) {
        throw new Error(
          "Can not retrieve instance cache api from an class. Please input an instance."
        );
      }
  
      let api = service[CacheInternal.CACHE_API];
      if (api == null) {
        let classApi = EdtellInternalCacheUtil.getClassCacheApi(service);
        api = EdtellInternalCacheUtil.initCacheApi(service);
        api._copyClassApi(classApi);
      }
  
      return service[CacheInternal.CACHE_API];
    }
  }
  