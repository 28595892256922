import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { cloneDeep } from 'lodash';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';
import { EntityControlLinkConfig } from '../../../edtell-controls/interfaces/entity-control-link-config.interface';
import { SecurityFailureBehavior } from '../../../srs-security/enums/access-secure-behavior.enum';
import { AccessSecureCallback } from '../../../srs-security/interfaces/access-secure-failure-callback';

@Component({
  selector: 'app-entity-control-link',
  styleUrls: ['./entity-control-link.component.scss'],
  templateUrl: './entity-control-link.component.html',
})
export class EntityControlLinkComponent implements OnInit, ICellRendererAngularComp, AfterViewInit {

  @ViewChild('entityLink', { static: false })
  private linkElement: ElementRef;

  @Input()
  config: EntityControlLinkConfig;

  title: string;
  data: any;
  callback: Function;
  routerLinkUrl: string;
  target: string = "_self";
  security: SecurityConfig;
  behavior: SecurityFailureBehavior;
  successCallback: AccessSecureCallback;
  failureCallback: AccessSecureCallback;

  disabled: boolean = false;

  constructor() {

  }

  agInit(params: any) {
    this.config = params;
    if (this.config.callback == null && this.config.routerLinkConfig == null) {
      console.error("EntityControlLink must have a callback or routerLinkConfig defined.");
      return;
    }

    if (this.config.callback != null && this.config.routerLinkConfig != null) {
      console.error("EntityControlLink cannot have both a callback and routerLinkConfig defined.");
      return;
    }
    this.setup(params);
  }

  ngOnInit() {
    if (this.config != null) {
      this.setup(this.config);
    }
  }
  
  ngAfterViewInit(): void {
    this.setup(this.config)
  }

  setup(params: any) {

    this.data = params.data;
    this.callback = params.callback;
    if (this.config.routerLinkConfig) {
      this.routerLinkUrl = this.config.routerLinkConfig.url(params.data);
      this.target = this.config.routerLinkConfig.target ?? "_self";
    }

    if (this.config.useTitleRendererFunction) {
      this.title = this.config.titleRendererFunction(params);
    } else {
      this.title = params.title == null ? params.value : this.config.title;
    }

    // TODO: This can be optimized by prefroming an ownership check before cloning, if the user is not the owner do not clone, use reference instead
    this.security = cloneDeep(this.config.security);
    if (this.security) {
      this.security.ownershipData = params.data;
    }

    this.behavior = this.config.securityFailBehavior;
    this.successCallback = this.config.securitySuccessCallback;
    this.failureCallback = this.config.securityFailCallback;

    this.isDisabled();
  }

  doCallback(data) {
    if(!this.disabled){
      this.callback(data);
    }
  }

  onClick() {
    if (!this.disabled) {
      this.callback(this.data);
    }
  }

  refresh() {
    return true;
  }

  isVisible() {
    return this.config != null && this.config.visible != null ?
      this.config.visible(this.data) : true;
  }
  
  isDisabled() {
    this.disabled = this.config.disabled != null && this.config.disabled(this.data);
    if (this.disabled && this.linkElement != null) {
      let elm: HTMLAnchorElement = this.linkElement.nativeElement;
      elm.classList.add('delete-link-disabled');
      elm.style.cursor = 'not-allowed';
      elm.style.textDecoration = 'none';
      elm.removeAttribute('href');
      switch (typeof this.config.disabledTooltip) {
        case 'function': {
          elm.title = (<any>this.config.disabledTooltip)(this.data);
          break;
        }
        case 'string': {
          elm.title = <string>this.config.disabledTooltip;
          break;
        }
      }
    }

    return;
  }

  // I don't know 100% sure why the title stuff needs to be duplicated here, but it is how it stops the title from showing undefined
  get displayTitle() {
    if (this.disabled) {
      let elm: HTMLAnchorElement = this.linkElement.nativeElement;
      switch (typeof this.config.disabledTooltip) {
        case 'function': {
          elm.title = (<any>this.config.disabledTooltip)(this.data);
          break;
        }
        case 'string': {
          elm.title = <string>this.config.disabledTooltip;
          break;
        }
      }
    } else {
      return this.title;
    }
  }

}
