import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiURL } from "app-environment";
import { CopyUserRequestBody } from "../interfaces/copy-user-request.interface";
import { RoleSecurityConfig } from "../interfaces/route-security-config.interface";
import { UserInterface } from "./user.interface";
import { LoginService } from "../../edtell-portal/services/login.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private httpOptions: {};
  private textHttpOptions: {};

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
    this.textHttpOptions = loginService.textHttpOptions;
  }

  getAllUsers() {
    return this.http.get<UserInterface[]>(
      `${apiURL}/administration-app/user/all`,
      this.httpOptions
    );
  }

  getUser(id) {
    return this.http.get<UserInterface>(
      `${apiURL}/administration-app/user?id=${id}`,
      this.httpOptions
    );
  }

  createUser(user) {
    return this.http.post<UserInterface>(
      `${apiURL}/administration-app/user`,
      user,
      this.httpOptions
    );
  }

  copyUser(copyUserObject: CopyUserRequestBody) {
    return this.http.post<UserInterface>(
      `${apiURL}/administration-app/user/copy`,
      copyUserObject,
      this.httpOptions
    );
  }

  updateUser(user) {
    return this.http.put(
      `${apiURL}/administration-app/user`,
      user,
      this.httpOptions
    );
  }

  isUniqueEmail(email, userIdToExclude?) {
    let url = `${apiURL}/administration-app/user/unique-email?email=${email}`;
    if (userIdToExclude) {
      url += `&userIdToExclude=${userIdToExclude}`;
    }
    return this.http.get(url, this.httpOptions);
  }

  getAllServiceReps() {
    return this.http.get<UserInterface[]>(
      `${apiURL}/administration-app/user/all/servicerep`,
      this.httpOptions
    );
  }

  getAllSalesReps() {
    return this.http.get<UserInterface[]>(
      `${apiURL}/administration-app/user/all/salesrep`,
      this.httpOptions
    );
  }

  getAllActiveUsers() {
    return this.http.get<UserInterface[]>(
      `${apiURL}/administration-app/user/all/active`,
      this.httpOptions
    );
  }

  getAllUsersForOwnershipChange(userId) {
    return this.http.get<UserInterface[]>(
      `${apiURL}/administration-app/user/all/ownership-change?userId=${userId}`,
      this.httpOptions
    );
  }

  hasRoles(config: RoleSecurityConfig) {
    let user = this.loginService.getCurrentUser();
    for (let k of Object.keys(config)) {
      if (user[k] == true || user[k] == config[k]) {
        continue;
      }

      return false;
    }

    return true;
  }

  disableUser(id: number) {
    return this.http.put<UserInterface>(
      `${apiURL}/administration-app/user/disable?id=${id}`,
      null,
      this.httpOptions
    );
  }

  enableUser(id: number) {
    return this.http.put<UserInterface>(
      `${apiURL}/administration-app/user/enable?id=${id}`,
      null,
      this.httpOptions
    );
  }
}
