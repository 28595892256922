import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SrsFormsModule } from '../srs-forms/srs-forms.module';
import { SrsSecurityModule } from '../srs-security/srs-security.module';
import { EdtellFormContainerComponent } from './components/edtell-form-container/edtell-form-container.component';
import { EdtellTabComponent } from './components/edtell-tabset/edtell-tab/edtell-tab.component';
import { EdtellTabsetComponent } from './components/edtell-tabset/edtell-tabset.component';
import { EdtellProfileHeaderComponent } from './components/edtell-profile-header/edtell-profile-header.component';
import { EdtellProfileCardContainerComponent } from './components/edtell-profile-card-container/edtell-profile-card-container.component';
import { EdtellCardContainerComponent } from './components/edtell-card-container/edtell-card-container.component';
import { EdtellFrontFacingDialogComponent } from './components/edtell-front-facing-dialog/edtell-front-facing-dialog.component';
import { EdtellFrontFacingCardComponent } from './components/edtell-front-facing-card/edtell-front-facing-card.component';
import { EdtellFrontFacingTabsetComponent } from './components/edtell-front-facing-tabset/edtell-front-facing-tabset.component';
import { EdtellFrontFacingTabComponent } from './components/edtell-front-facing-tabset/edtell-front-facing-tab/edtell-front-facing-tab.component';
import { FrontFacingTabInfoDialogComponent } from './components/edtell-front-facing-tabset/front-facing-tab-info-dialog/front-facing-tab-info-dialog.component';

@NgModule({
  declarations: [
    EdtellFormContainerComponent,
    EdtellTabComponent,
    EdtellTabsetComponent,
    EdtellProfileHeaderComponent,
    EdtellProfileCardContainerComponent,
    EdtellCardContainerComponent,
    EdtellFrontFacingDialogComponent,
    EdtellFrontFacingCardComponent,
    EdtellFrontFacingTabsetComponent,
    EdtellFrontFacingTabComponent,
    FrontFacingTabInfoDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SrsSecurityModule,
    ReactiveFormsModule,
    SrsFormsModule
  ],
  exports: [
    EdtellFormContainerComponent,
    EdtellTabComponent,
    EdtellTabsetComponent,
    EdtellProfileHeaderComponent,
    EdtellProfileCardContainerComponent,
    EdtellCardContainerComponent,
    EdtellFrontFacingDialogComponent,
    EdtellFrontFacingCardComponent,
    EdtellFrontFacingTabsetComponent,
    EdtellFrontFacingTabComponent
  ]
})
export class EdtellContainersModule { }
