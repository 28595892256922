<ng-template [ngIf]="loaded">
    <div class="row">
        <div class="col-12">
            API Key Manager
        </div>
    </div>
    
    <div class="row mb-1">
        <div class="col-12">
            <button class="btn btn-primary" (click)="createApiKey()">Create Api Key</button>
        </div>
    </div>
    
    <app-edtell-ag-grid [config]="agGridConfig" *ngIf="agGridConfig"></app-edtell-ag-grid>
</ng-template>