<div class="row px-3 clickable-entity-header" (click)="togglePinnedEntitiesTab()">
  <h3 *ngIf="minimizeTab">{{title}}
    <span class="clickable-entity-header"><i [ngClass]="['fas','fa-chevron-up', 'dropdown-symbol']"></i></span>
  </h3>
  <h3 *ngIf="!minimizeTab">{{title}}
    <span class="clickable-entity-header"><i [ngClass]="['fas','fa-chevron-down', 'dropdown-symbol']"></i></span>
  </h3>
</div>
<div class="row pl-0 pr-3" cdkDropListGroup [hidden]="minimizeTab" *ngIf="!config.isAssetPin">
  <div
    cdkDropList class="first-drop-list"
    (cdkDropListDropped)="dropListDropped()"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  ></div>
  <div
    cdkDropList
    *ngFor="let e of pinnedEntities; index as i"
    class="col-xxl-3 col-lg-4 col-md-6 col-xs-12"
    (cdkDropListDropped)="dropListDropped()"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  >
    <div cdkDrag class="example-box">
      <div (click)="onClick(e)" class="pinned-entity-card">
        <span class="pinned-entity-card-header">{{ truncateString(getEntityTitle(e)) }}</span>
        <span class="pinned-entity-card-content"
              *ngIf="config.contentRenderFunction">{{config.contentRenderFunction(e)}}</span>
        <img *ngIf="e.asset != null" [src]="e.sanatizedAsset" [alt]="getEntityTitle(e)">
      </div>
    </div>
  </div>
</div>
<div class="row pl-0 pr-3" cdkDropListGroup [hidden]="minimizeTab" *ngIf="config.isAssetPin">
  <div
    cdkDropList class="first-drop-list"
    (cdkDropListDropped)="dropListDropped()"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  ></div>
  <div
    cdkDropList
    *ngFor="let e of pinnedEntities; index as i"
    class="col-xxl-3 col-lg-4 col-md-6 col-xs-12"
    (cdkDropListDropped)="dropListDropped()"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  >
    <div cdkDrag class="example-box">
      <div (click)="onClick(e)" class="pinned-entity-card">
        <span class="pinned-entity-card-header">{{ truncateString(getEntityTitle(e)) }}</span>
        <img *ngIf="e.asset != null" [src]="e.sanatizedAsset" [alt]="getEntityTitle(e)">
      </div>
    </div>
  </div>
</div>
