<span>

  <!-- Begining -->
  <span *ngFor="let t of beginingTrail">
    <!-- <a routerLink="{{t.path}}">{{ t.title }}</a> -->
    <button mat-button (click)="navigate(t.path)">{{t.title}}</button>
    <span> > </span>
  </span>

  <!-- Hidden -->
  <ng-template [ngIf]="hiddenTrail != null && hiddenTrail.length > 0">

    <mat-menu #appMenu="matMenu">
      <button mat-menu-item *ngFor="let t of hiddenTrail" (click)="navigate(t.path)">{{t.title}}</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="appMenu">...</button>

    <span> > </span>
  </ng-template>

  <!-- End -->
  <span *ngFor="let t of endTrail; index as i;">
    <span *ngIf="i < endTrail.length - 1; else ending">
      <span *ngIf="isPathUndefined(t.path); else notNull">
        <span class="ending-text"> {{t.title}} </span>
      </span>
      <ng-template #notNull>
        <button mat-button (click)="navigate(t.path)">{{t.title}}</button>
      </ng-template>
      <span> > </span>
    </span>

    <ng-template #ending>
      <span class="ending-text">{{t.title}}</span>
    </ng-template>

  </span>

</span>