import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { PreviousRouteService } from '../services/previous-route.service';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { LocalStorageService } from '../../edtell-caching/services/local-storage.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private loginService : LoginService,
    private notificationService: NotificationService,
    private prviousRouteService: PreviousRouteService,
    private localStorageService : LocalStorageService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(catchError(err => {
      let enrollment = window.location.pathname.startsWith("/enrollment");
      let preview = window.location.pathname.startsWith("/preview");

      if(err.status == 308){
        // This is silly because we could be using the redirect for other things
        // however, the only place it is happening right now is on the registration.
        // We really need a seperate interceptor at the root level for redirects,
        // it should have functionality for a user to provide an override function for redirects
        localStorage.removeItem("CARTID") 
        this.localStorageService.removeLocalStorageItem('shoppingCart');
        if(enrollment){
          this.router.navigate([err.headers.get("ERROR_ID")])
        }else{
          this.prviousRouteService.removeRoute(this.router.url) // Since this url is not valid, remove it from the history
          this.router.navigate(['/404']);
          return throwError(err);
        }

        return [];
      }

      if(err.error == null){
        return []
      }

      if (err.status == 401) {
        if(!enrollment){
          this.router.navigate(["/login"])
        }
        return throwError(err);
      }

      if (err.status == 402) {
        let error = err.error;
        // scrape the error message from the html
        // get second p tag in body
        let errorText = error.split("<body>")[1].split("<p>")[2].split("</p>")[0];
        // remove Message label
        errorText = errorText.replace("<b>Message</b>", "");
        errorText = errorText.replace("&quot;", "");
        errorText = errorText.replace("&quot;", "").trim();
        this.notificationService.notificationEmitter.emit({ message: errorText, duration: 30000 });
        return throwError(err);
      }

      if (err.status == 403) {
        if(enrollment || preview){
          // this.prviousRouteService.removeRoute(this.router.url) // Since this url is not valid, remove it from the history
          this.router.navigate(['/enrollment/school/404'], { replaceUrl: true })
        }else{
          this.notificationService.notificationEmitter.emit({ message: 'You are not authorized to make that call', duration: 30000 });
        }   
        // TODO: We should talk about redirecting to 403 in cases where api calls fail due to permissions.
        // As of writing this, you are only redirected to the 403 page when the application hits a page.
        // This does not cover api calls, only navigation.
        return throwError(err);
      }

      if (err.status == 404) {
        this.prviousRouteService.removeRoute(this.router.url) // Since this url is not valid, remove it from the history

        // TODO: Currently all errors resulting in 404 redirect to the 404 page
        // This may be ok for now, but we may want to handle this differently later
        // We may want to write a decorator, or router event that handles this in cases where we do not want a redirect
        this.router.navigate(['/404']);
        return throwError(err);
      }

      let actions = [];
      if (!enrollment) {
        actions.push({
          title: "View",
          action: () => {
            this.showErrorDialog(err)
          }
        })
      }
      this.notificationService.notificationEmitter.emit({
        message: 'There was an error in completing the request', actions: actions
      });

      return throwError(err);
    }))
  }

  showErrorDialog(err: HttpErrorResponse) {
    this.dialog.open(ErrorDialogComponent, {
      width: "30%",
      data: err
    })
  }

}


export let ErrorInterceptorProvider = {
  // This is the provider that gets used in a module. This statement allows for import of the provider instead of the component.
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
};
