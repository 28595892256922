import { Component } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'app-status-bar-plus',
  template: `
    <div class="ag-name-value" *ngIf="localParams.api.getDisplayedRowCount() >= maxLength">
      Maximum Results Returned. You may need to refine your search.
    </div>
  `,
  styleUrls: ['./status-bar-plus.component.scss'],
})
export class StatusBarPlusComponent {
  public localParams: IStatusPanelParams;
  public maxLength;

  agInit(params: IStatusPanelParams): void {
    this.localParams = params;
    this.maxLength = params['maxLength'];
  }

}
