<div class="index-container">
  <h1>Command Index</h1>
  <ng-container *ngFor="let ig of indexGroups">
    <div *ngIf="ig.commands.length > 0">
      <h2>{{ ig.letter }}</h2>
      <ul>
        <li *ngFor="let c of ig.commands">
          <span class="fake-link" (click)="goToElement(c)" >{{ c.title }}</span>
        </li>
      </ul>
    </div>

  </ng-container>
</div>