<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <h4>Error Report:</h4>
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3 bold-text">
      Status:
    </div>
    <div class="col-sm-9 mb-2">
        <div>{{err.statusText}} ({{err.status}})</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3 bold-text">
      Message:
    </div>
    <div class="col-sm-9">
        <div>{{err.message}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <app-srs-text-area [config]="textAreaConfig"></app-srs-text-area>
    </div>
  </div>
  <div class="row mt-3">
    <div class="ml-auto">
      <button (click)="sendReport()" class="btn btn-tertiary mr-2" [disabled]=this.reportSent>Send Bug Report</button>
      <button (click)="close()" class="btn btn-tertiary">Close</button>
    </div>
  </div>
</div>
