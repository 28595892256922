import { SrsDateTimePickerConfig } from "../interfaces/elements/srs-datetime-picker-config";
import { SrsFormFieldConfig } from "../interfaces/elements/srs-form-field-config.interface";
import { SrsRadioGroupConfig } from "../interfaces/elements/srs-radio-group.config.interface";
import { SrsRangeSelectorConfig } from "../interfaces/elements/srs-range-selector-config.interface";
import { SrsSelectBoxConfig } from "../interfaces/elements/srs-select-box-config.interface";
import { SrsTextAreaConfig } from "../interfaces/elements/srs-textarea-config.interface";

export namespace SrsFormHelper {

    export function createFormFieldConfig(config: SrsFormFieldConfig) { return config }

    export function createRadioGroupConfig(config: SrsRadioGroupConfig) { return config }

    export function createSelectConfig<E>(config: SrsSelectBoxConfig<E>) { return config }

    export function createRangeConfig<E>(config: SrsRangeSelectorConfig<E>) { return config }

    export function createDateTimePickerConfig(config : SrsDateTimePickerConfig){return config}

    export function createTextArea(config : SrsTextAreaConfig){return config};

}