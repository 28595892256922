import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { apiURL } from 'app-environment';
import { LoginService } from '../../edtell-portal/services/login.service';
import { AccessEventDataInterface } from '../interfaces/access-event-data.interface';
import { ApiEventDataInterface } from '../interfaces/api-event-data.interface';
import { BugReport } from '../interfaces/bug-report.interface';
import { GeneralEventDataInterface } from '../interfaces/general-event-data.interface';


@Injectable()
export class EventLogService {

  private httpOptions: {};

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private router : Router
  ) {

    this.httpOptions = loginService.httpOptions;
  }

  getApiEvent(id : number) {
    return this.http.get<ApiEventDataInterface>(`${apiURL}/event-log-app/logging/api?id=${id}`, this.httpOptions);
  }

  getApiEventsByCreatedBy(id : number){
    return this.http.get<ApiEventDataInterface []>(`${apiURL}/event-log-app/logging/api/search/user?id=${id}`, this.httpOptions);
  }

  getAllApiEvents() {
    return this.http.get<ApiEventDataInterface []>(`${apiURL}/event-log-app/logging/api/all`, this.httpOptions);
  }

  getAccessEvent(id : number){
    return this.http.get<AccessEventDataInterface>(`${apiURL}/event-log-app/logging/access?id=${id}`, this.httpOptions);
  }

  getAccessEventsByCreatedBy(id : number){
    return this.http.get<AccessEventDataInterface []>(`${apiURL}/event-log-app/logging/access/search?id=${id}`, this.httpOptions);
  }

  getAllAccessEvents(){
    return this.http.get<AccessEventDataInterface []>(`${apiURL}/event-log-app/logging/access/all`, this.httpOptions);
  }

  getAllGeneralEvents() {
    return this.http.get<GeneralEventDataInterface []>(`${apiURL}/event-log-app/logging`, this.httpOptions);
  }

  getGeneralEventsByCreatedBy(createdBy : number){
    return this.http.get<GeneralEventDataInterface []>(`${apiURL}/event-log-app/logging/search?id=${createdBy}`, this.httpOptions);
  }

  sendBugReport(bugReport : BugReport) {
    return this.http.post(`${apiURL}/event-log-app/logging/bug-report`, bugReport, this.httpOptions);
  }
}
