import { AfterContentInit, Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogActionType } from '../../../edtell-portal/enums/dialog-action-type.enum';
import { SecurityService } from '../../../edtell-portal/services/security.service';
import { EdtellDialogConfig } from '../../../srs-forms/interfaces/abstracts/edtell-dialog-config.interface';
import { EdtellTabsetComponent } from '../edtell-tabset/edtell-tabset.component';
import { EdtellFrontFacingTabComponent } from './edtell-front-facing-tab/edtell-front-facing-tab.component';
import { FrontFacingTabInfoDialogComponent } from './front-facing-tab-info-dialog/front-facing-tab-info-dialog.component';

@Component({
  selector: 'app-edtell-front-facing-tabset',
  templateUrl: './edtell-front-facing-tabset.component.html',
  styleUrls: ['./edtell-front-facing-tabset.component.scss']
})
export class EdtellFrontFacingTabsetComponent extends EdtellTabsetComponent implements OnInit, AfterContentInit {

  @ContentChildren(EdtellFrontFacingTabComponent) edtellTabs: QueryList<EdtellFrontFacingTabComponent>;

  constructor(
    protected securityService: SecurityService,
    protected route: ActivatedRoute,
    private matDialog: MatDialog
    ) {
    super(securityService, route);
  }

  showTabInfo(tab: EdtellFrontFacingTabComponent) {
    let config: EdtellDialogConfig = {
      data: { 
        action: DialogActionType.VIEW,
        objectData: {
          text: tab.config.tabInfoText 
        },
        securityObject: tab.config.tabInfoSecurityObject
      },
      width: '300px'
    };
    this.matDialog.open(FrontFacingTabInfoDialogComponent, config)
  }
}
