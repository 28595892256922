import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { SerializationApi } from '../../../interfaces/api/serialization-api.interface';
import { SrsElementConfig } from '../../../interfaces/elements/srs-element-config.interface';
import { SrsMultiElementConfig } from '../../../interfaces/elements/srs-multi-element-config.interface';
import { EdtellForumElementAttribute } from '../../../interfaces/states/forum-config-state.interface';
import { SrsMultiElementWidgetConfig } from '../../../interfaces/widgets/srs-multi-element-widget-config';

@Component({
  selector: 'app-srs-multi-element-widget',
  templateUrl: './srs-multi-element-widget.component.html',
  styleUrls: ['./srs-multi-element-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SrsMultiElementWidgetComponent implements OnInit, OnDestroy {


  @Input()
  config: SrsMultiElementWidgetConfig<SrsElementConfig>

  @Input()
  data: Observable<any> | Promise<any>

  @Output()
  serializationApi : EventEmitter<SerializationApi> = new EventEmitter<SerializationApi>();

  private serializationApiImpl : SerializationApi;
  private changeDetectionInterval = null;

  constructor(private cd : ChangeDetectorRef) { }

  ngOnInit() {
    if (this.config.elements != null) {
      for (let r of this.config.elements) {
        for (let c of r) {
          c.config.group = c.config.group == null ? this.config.formGroup : c.config.group
        }
      }
    }

    if (this.config.skipChangeDetectionInterval == false || this.config.skipChangeDetectionInterval == null) {
      this.config.formGroup.setChangeDetectionDelay(this.cd, 100)
      this.changeDetectionInterval = setInterval(() => {
        this.cd.markForCheck()
      }, 500)
      this.cd.markForCheck()
    }

    this.serializationApiImpl = {
      serialize: this.serialize.bind(this),
      deserialize: this.deserialize.bind(this)
    }

    this.serializationApi.emit(this.serializationApiImpl);
  }

  async serialize(){
    let elements : EdtellForumElementAttribute[] = [];
    for (let r of this.config.elements) {
      for (let c of r) {
        if(c.serialization?.serializers != null){
          for(let s of c.serialization.serializers){
            let attr = await s.serialize(c.config);
            if(attr != null){
              elements.push(attr);
            }
          }
        }
      }
    }
    return elements;
  }

  async deserialize(attributes : EdtellForumElementAttribute[]){

    let keysToAttributeMap = new Map<string, EdtellForumElementAttribute[]>();
    for(let attr of attributes){
      if(!keysToAttributeMap.has(attr.key)){
        keysToAttributeMap.set(attr.key, []);
      }
      keysToAttributeMap.get(attr.key).push(attr);
    }

    for (let r of this.config.elements) {
      for (let c of r) {
        if(c.serialization?.serializers != null){
          let attrs = keysToAttributeMap.get(c.config.key);
          if(attrs == null){
            continue;
          }
          for(let s of c.serialization.serializers){
            for(let attr of attrs){
              if(s.attribute == attr.attribute){
                await s.deserialize(attr, c.config);
              }
            }
          }
        }
      }
    }
    
  }

  getSize(elm: SrsMultiElementConfig<SrsElementConfig>) {
    let classList = ["col-md-" + elm.config.size]
    if (elm.config.settings && elm.config.settings.class) {
      let klass = elm.config.settings.class;
      // if string, else array of strings
      if (typeof klass === 'string') {
        classList.push(klass);
      } else {
        classList = classList.concat(klass);
      }
    }
    return classList;
  }

  ngOnDestroy(){
    clearInterval(this.changeDetectionInterval)
  }

  isIncluded(elm: SrsMultiElementConfig<SrsElementConfig>){
    return this.config.settings?.showNotIncluded === true || (elm.config.settings?.included == null || elm.config.settings.included()) !== false;
  }


}
