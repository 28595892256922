import * as _ from 'lodash';

/**
 * @returns An array of objects based upon string comparison comparing the passed through object's objectSearchStrings and a searchString.
 *          Rank 1 is an exact match
 *          Rank 2 is if the searchString is a substring starting from the beginning of a option search string value
 *          Rank 3 is based upon similarity using the Levenshtein Distance algorithm
 */

export function fuzzyAutoComplete(options: FuzzyAutoCompleteObject[], searchString?: string): any[] {

  console.log('OPTIONS', options);

  let searchLower = searchString.toLowerCase();

  for(let option of options) {
    option.similarityValue = null;
  }

    // if no searchString return all options
    if (searchString == null || searchString.length == 0) {
        options.sort((a,b) => { return a.objectSearchString > b.objectSearchString ? 1 : a.objectSearchString < b.objectSearchString ? -1 : 0});
        return options.map((o) => {
            return o.returnObject;
        })
    }

    for (let option of options) {
        // Rank 1: check if searchString is a substring on beginning of string (value = .99)
        if (option.similarityValue == null) {
            let searchLength = searchString.length;
            if (option.objectSearchStringLower.substr(0, searchLength) == searchLower) {
                option.similarityValue = .99;
            }
        }

        // Rank 2: check similarity using Levenshtein Distance
        if (option.similarityValue == null) {
            option.similarityValue = similarity(option.objectSearchString, searchString);
        }
    }

    options.sort((a,b) => { return a.similarityValue > b.similarityValue ? -1 : a.similarityValue < b.similarityValue ? 1 : 0});

    // remove all options with 0 similarity
    _.remove(options, {similarityValue: 0});

    return options.map((o) => {
        return o.returnObject;
    })
}

function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
  
    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0)
          costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0)
        costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

export interface FuzzyAutoCompleteObject {
    returnObject: any;
    objectSearchString: string;
    objectSearchStringLower?: string;
    similarityValue?: number;
}