import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {ColDef, ColumnApi, ColumnState, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {find, merge, remove} from 'lodash';
import {first} from 'rxjs/operators';
import {ObjectName} from '../../../edtell-admin/enums/object-name.enum';
import {SecurityConfig} from '../../../edtell-admin/interfaces/route-security-config.interface';
import {ObjectService} from '../../../edtell-admin/services/object.service';
import { LocalStorageService } from '../../../edtell-caching/services/local-storage.service';
import {DialogActionType} from '../../../edtell-portal/enums/dialog-action-type.enum';
import {Attribute} from '../../../edtell-portal/interfaces/attribute.interface';
import {AttributeService} from '../../../edtell-portal/services/attribute.service';
import {GridPreferenceService} from '../../../edtell-portal/services/grid-preference.service';
import {EdtellDialogConfig} from '../../../srs-forms/interfaces/abstracts/edtell-dialog-config.interface';
import {EdtellDialogData} from '../../../srs-forms/interfaces/abstracts/edtell-dialog-data.interface';
import {EdtellAgGridConfig} from '../../interfaces/edtell-ag-grid-config.interface';
import {actionColumn, globalGridOptions} from '../../interfaces/global-grid-config';
import {EntitySelectComponent} from '../entity-select/entity-select.component';
import {EdtellAgGridFullScreenDialogComponent} from './edtell-ag-grid-full-screen-dialog/edtell-ag-grid-full-screen-dialog.component';
import {EdtellAgGridSettingsDialogComponent} from './edtell-ag-grid-settings-dialog/edtell-ag-grid-settings-dialog.component';

@Component({
  selector: 'app-edtell-ag-grid',
  templateUrl: './edtell-ag-grid.component.html',
  styleUrls: ['./edtell-ag-grid.component.scss']
})
export class EdtellAgGridComponent implements OnInit {

  @Input()
  config: EdtellAgGridConfig;

  gridOptions: GridOptions;

  noEntityControls: boolean = false;

  gridApi: GridApi;
  columnApi: ColumnApi;

  gridAttribute: Attribute;
  attributeName = 'grid-settings';
  filterStorageKey = 'filter';

  loaded = false;

  columnSettings: { colId: string, width: string, hide: boolean, sort?: 'asc' | 'desc' | null, sortIndex?: number }[];

  gridSettingsSecurity: SecurityConfig = {
    accessLevels: {
      accessLevel: 1,
      objectName: ObjectName.GRID_SETTINGS
    }
  };

  useFilterCache = true;

  itemId: number;

  constructor(
    private gridPreferenceService: GridPreferenceService,
    private dialog: MatDialog,
    private objectService: ObjectService,
    private attributeService: AttributeService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {
  }

  async ngOnInit() {

    this.attributeName += `.${this.config.gridPreferenceName}`.replace('.preferences','');

    this.setFilterStorageKey();

    // console.log('edtell ag grid config', this.config)
    if (this.config.object) {
      this.itemId = await this.objectService.getObjectId(this.config.object).pipe(first()).toPromise();
    }

    if (this.itemId) {
      this.gridAttribute = await this.attributeService.getAttributeValueByItemIdAndAttributeName(this.itemId, this.attributeName).pipe(first()).toPromise();
    } else {
      console.warn(`Could not find Object Id for the Grid Object. Consider configuring the ${this.config.object} object.`);
    }

    this.setColumnSettings();
    this.initGridOptions();

    if (this.config.entityControl) {
      this.config.entityControl = {
        ...this.config.entityControl,
        fullScreenGridCallback: this.openFullScreen.bind(this),
        gridSettingsCallback: this.openGridSettings.bind(this)
      };
    }

    if (this.config.entityControl == undefined && this.config.entityFormControl == undefined) {
      this.noEntityControls = true;
    }


    if (!!this.config.onEdtellAGGridLoadedCallback) {
      this.config.onEdtellAGGridLoadedCallback();
    }

    this.setUseFilterCache();

    this.loaded = true;
  }

  setFilterStorageKey() {
    // prepend param values to filterStorageKey
    // this ensures grid cache applies only to the current grid for the current entity
    let filterPreFix = ".";
    let paramMap = this.route.snapshot.paramMap;
    let paramMapKeys = paramMap.keys;
    for (let key of paramMapKeys) {
      let paramValue = paramMap.get(key);
      filterPreFix += `${key}-${paramValue}.`
    }
    this.filterStorageKey += `${filterPreFix}${this.config.gridPreferenceName}`.replace('.preferences','');
  }

  initGridOptions() {
    let columnTypes = {};
    if (this.config.actionConfig) {
      columnTypes = {
        actions: actionColumn(EntitySelectComponent, this.config.actionConfig)
      };
    }

    let columnStates = this.getColumnStates();

    this.gridOptions = merge(globalGridOptions(this.config.gridPreferenceName, this.gridPreferenceService, this.onGridReady.bind(this), this.config.onFirstDataRendered, this.onFilterChange.bind(this), {
      columnTypes: columnTypes,
      chartSelection: this.config.chartSelection ?? false,
      columnStates: columnStates,
    }), this.config.gridOptions);

    this.config.gridOptions = this.gridOptions;
  }

  onGridReady(event?: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.gridApi.addEventListener('viewGridSettings', this.openGridSettings.bind(this));
    this.gridApi.addEventListener('viewFullScreen', this.openFullScreen.bind(this));
    this.gridApi.addEventListener('resetGrid', this.resetGrid.bind(this));

    setTimeout(() => { 
      this.setFiltersFromCache(); 
    })

    if (this.config.onGridReady != null) {
      this.config.onGridReady(event);
    }
  }

  async resetGrid() {
    this.columnApi.resetColumnState();
    this.mergeAndSetColumnState();
    this.gridApi.sizeColumnsToFit();
  }

  setColumnSettings() {
    // set ColIds
    for (let columnDef of this.config.columnDefs) {
      if (columnDef.type == 'actions') {
        columnDef.colId = 'action';
        continue;
      }
      let colId = columnDef.colId == null ? columnDef.field : columnDef.colId;
      if (colId == null) {
        colId = camelCase(columnDef.headerName);
      }
      columnDef.colId = colId;
    }

    if (this.gridAttribute) {
      this.columnSettings = JSON.parse(this.gridAttribute.attributeValue).columnSettings;
    } else {
      return;
    }

    // Create unplaced columns Map
    // This handles the case that a column was added programmatically after the grid settings attribute was created.
    let unplacedColumnsMap: Map<ColDef, number> = new Map();
    let settingsToBeRemoved = [];

    for (let i in this.config.columnDefs) {
      let colDef = this.config.columnDefs[+i];
      unplacedColumnsMap.set(colDef, +i);
    }

    // Set the columnDefs
    let columnDefs = [];

    for (let setting of this.columnSettings) {
      let columnDef;
      try {
        columnDef = find(this.config.columnDefs, {colId: setting.colId});

        // column no longer exists
        // This can happen if the column was removed or if the colId is changed
        if (columnDef == undefined) {
          settingsToBeRemoved.push(setting);
          continue;
        }

        // handle action column
        if (setting.colId == 'action') {
          let actionColumn = find(this.config.columnDefs, {type: 'actions'});
          actionColumn.colId = 'action';
          actionColumn.width = +setting.width;
          actionColumn.minWidth = null;
          columnDefs.push(actionColumn);
          unplacedColumnsMap.delete(actionColumn);
          continue;
        }
  
        if (setting?.width != null && setting.width != '') {
          columnDef.width = +setting.width;
          columnDef.minWidth = null;
        }
  
        if (setting?.hide != null) {
          columnDef.hide = setting.hide;
        }
        columnDefs.push(columnDef);
        unplacedColumnsMap.delete(columnDef);

      } catch(error) {
        console.error(error);
        console.log(columnDef, setting)
      }

    }

    // add unplaced columns to the columnDefs
    for (let [colDef, index] of unplacedColumnsMap) {
      columnDefs.splice(index, 0, colDef);
    }

    // remove unused settings
    for (let setting of settingsToBeRemoved) {
      remove(this.columnSettings, {colId: setting.colId});
    }

    this.config.columnDefs = columnDefs;
  }

  // gets column state properties for that are added onGridReady
  getColumnStates(): ColumnState[] {
    let columnStates: ColumnState[] = [];
    if (this.columnSettings == null) {
      return columnStates;
    }
    
    for (let columnSetting of this.columnSettings) {
      // check for properties that warrant a column state
      if (columnSetting.sort == null) {
        continue;
      }
      columnStates.push({
        colId: columnSetting.colId,
        sort: columnSetting.sort,
        sortIndex: columnSetting.sortIndex
      })
    }

    return columnStates;
  }

  openFullScreen() {
    this.dialog.open(EdtellAgGridFullScreenDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        ...this.config
      }
    });
  }

  setUseFilterCache() {
    let gridAttributeValue = this.gridAttribute?.attributeValue;
    if (gridAttributeValue) {
      gridAttributeValue = JSON.parse(gridAttributeValue);
      this.useFilterCache = gridAttributeValue.filterCache;
    }
  }

  setFiltersFromCache() {
    let cachedFilters = this.localStorageService.getLocalStorageItem(this.filterStorageKey);
    if (cachedFilters == null) {
      return;
    }

    if (!this.useFilterCache) {
      return;
    }

    for (let [colId, model] of cachedFilters) {
      let instance  = this.gridApi.getFilterInstance(colId);
      instance.setModel(model)
      this.gridApi.onFilterChanged();
    }
  }

  onFilterChange() {
    if (this.config.gridPreferenceName == null) {
      return;
    }
    
    if (!this.useFilterCache) {
      return;
    }

    this.setFilterCache();
  }

  setFilterCache() {
    let filterValueMap: Map<string, any> = new Map();
    for (let column of this.columnDefs) {
      let model: { [key: string]: any } = this.gridApi.getFilterInstance(column.colId).getModel();
      if (model != null) {
        filterValueMap.set(column.colId, model)
      }
    }
    this.localStorageService.setLocalStorageItem(this.filterStorageKey, filterValueMap, 1000*36000);
  }

  openGridSettings() {
    this.setFilterCache();
    if (this.itemId == null) {
      window.alert('No object for Grid Settings to save to.');
      console.warn('No object for Grid Settings to save to.');
      return;
    }

    let dialogConfig: EdtellDialogConfig = {
      data: {
        action: DialogActionType.VIEW,
        objectData: {
          config: this.config,
          gridApi: this.gridApi,
          columnApi: this.columnApi,
          columnSettings: this.columnSettings
        },
        securityObject: this.config.object
      },
      width: '700px',

    };
    let dialogRef = this.dialog.open(EdtellAgGridSettingsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogData: EdtellDialogData) => {
      if (dialogData.action != DialogActionType.CANCEL) {
        dialogRef.componentInstance.save(dialogData).subscribe((data) => {
          this.gridAttribute = data.resp;
          this.setUseFilterCache();
          this.updateGrid();
        });
      }
    });
  }

  updateGrid() {
    this.setColumnSettings();
    setTimeout(() => {
      this.columnApi.resetColumnState();
      this.gridApi.sizeColumnsToFit();
      this.mergeAndSetColumnState();
    })
  }

  mergeAndSetColumnState() {
    let columnState = this.columnApi.getColumnState();
    let colStateUpdates = this.getColumnStates();
    for (let col of columnState) {
      for (let colUpdate of colStateUpdates) {
        if (col.colId == colUpdate.colId) {
          col.sort = colUpdate.sort;
          col.sortIndex = colUpdate.sortIndex;
          continue;
        }
      }
    }
    this.columnApi.setColumnState(columnState);
  }

  getStyle() {
    let heightDiff = this.config.heightOffset ?? 170;

    return {
      'height': this.config.autoHeight ? 'auto' : (this.config.maxHeight ? `${this.config.maxHeight}px` : `calc(100vh - ${heightDiff}px)`),
      'min-height': this.config.minHeight ? `${this.config.minHeight}px` : (this.config.maxHeight ? `${this.config.maxHeight}px` : '500px'),
      'margin-bottom': '1em'
    };
  }

  get data() {
    if (typeof this.config.data == 'function') {
      return this.config.data();
    }

    return this.config.data;
  }

  get columnDefs() {
    return this.config.columnDefs;
  }
}

// https://www.geeksforgeeks.org/how-to-convert-string-to-camel-case-in-javascript/#
// Function to convert into camel Case
function camelCase(str) {
  // Using replace method with regEx
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}