import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { EdtellDeleteLinkConfig } from '../interfaces/edtell-delete-link-config.interface';


@Directive({
  selector: '[deleteLink]'
})
export class DeleteLinkDirective implements OnInit {

  @Input()
  deleteLink: EdtellDeleteLinkConfig;

  elm: HTMLAnchorElement

  public constructor(private elmRef: ElementRef) {
    this.elm = elmRef.nativeElement
  }

  async ngOnInit() {

    this.elm.classList.add('delete-link', 'align-bottom');
    let disabled = this.deleteLink.isDisabledCallback != null ? await this.deleteLink.isDisabledCallback() : false;

    if (disabled) {
      this.elm.classList.add('delete-link-disabled');
      this.elm.style.cursor = "not-allowed"
    } else {
      this.elm.classList.add('delete-link-enabled');
      this.elm.onclick = this.deleteLink.deleteLinkCallback;
    }

  }

}
