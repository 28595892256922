import { Type } from "@angular/core";
import { Observable } from "rxjs";
import { EntityResolver } from "../../edtell-portal/abstracts/entity.resolver";
import { ObjectName } from "../enums/object-name.enum";

export interface AccessLevelSecurityConfig{
    objectName : ObjectName,
    accessLevel : 0 | 1 | 2 | 3 | 4
}

export interface RoleSecurityConfig{
    serviceRep? : boolean
    salesRep? : boolean
    regAdmin? : boolean
    systemAdmin? : boolean
}


export interface SecurityConfig{
    accessLevels? : AccessLevelSecurityConfig | AccessLevelSecurityConfig[]
    roleSecurity? : RoleSecurityConfig
    localOwnership? : boolean
    routeOwnershipResolver? : Type<EntityResolver>
    ownershipData? : any
}

// Dummy classes used for type checking
export class SecurityConfigImpl implements SecurityConfig{
    accessLevels : AccessLevelSecurityConfig | AccessLevelSecurityConfig[]
    roleSecurity : RoleSecurityConfig
    ownerCheck : Observable<{"owner" : number}>
}

/**
 * Used to wrap the config in order to use instance of
 */
export class AccessLevelSecurity extends SecurityConfigImpl{

    public readonly config : AccessLevelSecurityConfig

    constructor(config : AccessLevelSecurityConfig){
        super()
        this.config = config
    }

}

/**
 * Used to wrap the config in order to use instance of
 */
export class RoleSecurity extends SecurityConfigImpl{

    public readonly config : RoleSecurityConfig

    constructor(config : RoleSecurityConfig){
        super()
        this.config = config
    }

}

