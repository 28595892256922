import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[sidebarHelper]'
})
export class SidebarHelperDirective {

  constructor(public viewContainerRef : ViewContainerRef) { }

}
