export enum StudentRelationship {
    // MOTHER = 'mother',
    // FATHER = 'father',
    // GRANDFATHER = 'grandfather',
    // GRANDMOTHER = 'grandmother',
    PARENT_GUARDIAN = 'parentGuardian',
    GRANDPARENT = 'grandparent',
    SIBLING = 'sibling',
    FAMILY = 'familyOther',
    SCHOOL_STAFF = 'schoolStaffOther'
    // EXTENDED_FAMILY = 'extendedFamily',
    // TUTOR = 'tutor',
    // MENTOR = 'mentor',
    // TEACHER = 'teacher',
    // SPECIAL_ED_ADVOCATE = 'specialEdAdvocate',
    // COUNSELOR = 'counselor'
}