import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiURL } from "app-environment";
import { LoginService } from "../../edtell-portal/services/login.service";
import { ApiKeyGenerateRequest } from "../interfaces/api-key-generate-request.interface";
import { ApiKeyRecord } from "../interfaces/api-key-record.interface";

@Injectable({
  providedIn: "root"
})
export class ApiKeyService {
  constructor(private http: HttpClient, private loginService: LoginService) {}

  getAllApiKeyRecords() {
    return this.http.get<ApiKeyRecord[]>(`${apiURL}/auth-validation-app/auth/services/key/records`, this.loginService.httpOptions);
  }

  createApiKey(req: ApiKeyGenerateRequest) {
    return this.http.post<{ jwt: string }>(`${apiURL}/auth-validation-app/auth/services/key/generate`, req, this.loginService.httpOptions);
  }

  setApiKeyActiveStatus(req: { id: number; active: boolean }) {
    return this.http.put<ApiKeyRecord>(`${apiURL}/auth-validation-app/auth/services/key/active`, req, this.loginService.httpOptions);
  }
}
