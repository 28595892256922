import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ObjectService } from '../../../edtell-admin/services/object.service';
import { AttributeService } from '../../../edtell-portal/services/attribute.service';
import { NotificationService } from '../../../edtell-portal/services/notification.service';
import { AssetService } from '../../services/asset.service';
import { LinkedEntitiesDialogComponent } from '../linked-entities-dialog/linked-entities-dialog.component';
import { EdtellFormGroup } from '../../../srs-forms/classes/edtell-form-group.class';
import { EdtellFormControl } from '../../../srs-forms/classes/edtell-form-control';
import { SrsFormState } from '../../../srs-forms/enumerations/form-state.enum';
import { SrsRadioGroupConfig } from '../../../srs-forms/interfaces/elements/srs-radio-group.config.interface';
import { SrsFormFieldConfig } from '../../../srs-forms/interfaces/elements/srs-form-field-config.interface';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';

@Component({
  selector: 'app-asset-picker-dialog',
  templateUrl: './asset-picker-dialog.component.html',
  styleUrls: ['./asset-picker-dialog.component.scss']
})
export class AssetPickerDialogComponent implements OnInit {


  assetListCount = 6;
  assetList;

  totalAssetCount;
  
  formGroup: EdtellFormGroup;
  
  usedStateRadioConfig: SrsRadioGroupConfig;
  searchStringFormFieldConfig: SrsFormFieldConfig;
  
  isLoading = false;

  constructor(public dialogRef: MatDialogRef<AssetPickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { assetTypeId: number | number[] },
              private assetService: AssetService,
              private attributeService: AttributeService,
              private sanitizer: DomSanitizer,
              private dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  searchString = '';
  searchStringMinLength = 0;
  // getUsedState has three states:
  // -1: get linked and unlinked assets
  //  0: get only unlinked assets
  //  1: get only linked assets
  getUsedState = -1;

  ngOnInit() {
    this.attributeService.getAttributeByObjectName(ObjectName.ASSET, 'assetPickerCount').subscribe(resp => {
      this.assetListCount = JSON.parse(resp.attributeValue);
      console.log('this.assetListCount', this.assetListCount);
    });

    this.initAssetList();
  }

  initAssetList() {
    // Pulling the first set of assets to display
    this.initFormGroup();
    this.initTemplateConfigs();
    this.search();
  }
  

  initFormGroup() {
    this.formGroup = new EdtellFormGroup(SrsFormState.WRITE, {
      searchByUsedState: new EdtellFormControl(-1),
      searchString: new EdtellFormControl(this.searchString)
    })
  }

  initTemplateConfigs() {
    this.usedStateRadioConfig = {
      group: this.formGroup,
      key: 'searchByUsedState',
      size: 8,
      title: 'Filter Assets',
      options: [
        {
          text: 'Used and Unused Assets',
          value: -1
        },
        {
          text: 'Used Assets Only',
          value: 1
        },
        {
          text: 'Unused Assets Only',
          value: 0
        }
      ],
    }

    this.searchStringFormFieldConfig = {
      group: this.formGroup,
      key: 'searchString',
      size: 12,
      title: 'Search',
      settings: {
        placeholder: 'Search',
        hideTitle: true
      }
    }
  }

  updateSearchAssetList(limit, pageSize) {
    this.assetService.getAssetsBySearch(limit, this.assetListCount, this.searchString, this.getUsedState, this.data.assetTypeId).subscribe(resp => {
      this.assetList = resp;

      // Turing the blob into something useful
      this.assetList.forEach(item => {
        let base64 = item.assetBase64;
        let unsanitizedUrl = 'data:image/' + item.assetType + ';base64,' + base64;
        item.image = this.sanitizer.bypassSecurityTrustUrl(unsanitizedUrl);
      });
    });
  }

  // This gets called by the pagination to get the next batch of assets to load
  pageEvent(e) {
    let limit = e.pageIndex * this.assetListCount;

    this.assetListCount = e.pageSize;

    this.updateSearchAssetList(limit, this.assetListCount);
  }

  searchQueryCount = 0;
  searchQueryMax = 8;


  async search() {
    this.isLoading = true;
    this.totalAssetCount = 0;

    let searchString = this.formGroup.get('searchString').value;
    let searchByUsedState = this.formGroup.get('searchByUsedState').value;

    if (searchString.length >= this.searchStringMinLength) {

      await this.getAssetSearch().then((resp) => { });

      await this.getAssetSearchCount(searchString, searchByUsedState);

      try {
        this.paginator.firstPage(); // fix paginator to match new results
      } catch (err) {
        console.error(err);
      }

    }
    this.isLoading = false;
  }

  getAssetSearch() {
    this.totalAssetCount = 0;
    return new Promise((resolve, reject) => {
      let searchedString = this.formGroup.get('searchString').value;
      let searchedByUsedState = this.formGroup.get('searchByUsedState').value;
      
      this.assetService.getAssetsBySearch(0, this.assetListCount, searchedString, searchedByUsedState, this.data.assetTypeId).subscribe(resp => {
        this.assetList = resp;

        if (this.assetList == null) {
          return;
        }

        // Turing the blob into something useful
        for (let item of this.assetList) {
          let base64 = item.assetBase64;
          let unsanitizedUrl = 'data:image/' + item.assetType + ';base64,' + base64;
          item.image = this.sanitizer.bypassSecurityTrustUrl(unsanitizedUrl);
        }

        resolve({ searchedString: searchedString, usedState: searchedByUsedState});
      });
    });
  }

  async getAssetSearchCount(searchString, getUsedState) {
    this.totalAssetCount = 0;
    let resp = await this.assetService.getAssetSearchCount(searchString, getUsedState, this.data.assetTypeId).toPromise();

    this.totalAssetCount = resp;
  }

  // This function is to get the count of the unlinked assets to make sure the pagination will work properly
  updateRecordCount() {
    this.assetService.getAssetSearchCount(this.searchString, this.getUsedState, this.data.assetTypeId).subscribe(resp => {
      this.totalAssetCount = resp;
    });
  }
  

  openViewLinkedAssets(asset) {
    let dialogRef = this.dialog.open(LinkedEntitiesDialogComponent, {
      width: '400px',
      data: asset
    });
    
    dialogRef.afterClosed().subscribe(data => {
      if (data == null) {
        return;
      }
      if (data.navigate) {
        this.dialogRef.close();
      }
    })
  }

  dateFormat(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  submit(i) {
    this.dialogRef.close(i);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  truncateString(string) {
    return _.truncate(string, {'length': 25});
  }


}
