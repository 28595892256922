import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ProcessedJobStatus } from '../../interfaces/processed-job-status.interface';
import { DistributionContainerConfig } from '../../interfaces/distribution-container-config.interface';
import { JobHistoryViewComponentConfig } from '../../interfaces/job-history-component-config.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-job-history-view',
  templateUrl: './job-history-view.component.html',
  styleUrls: ['./job-history-view.component.scss'],
})
export class JobHistoryViewComponent implements OnInit {
  loaded: boolean = false;
  data: ProcessedJobStatus;
  originalScrollHeightMap = new Map<string, number>();

  distributionContainerConfig: DistributionContainerConfig;

  @Input()
  config: JobHistoryViewComponentConfig;

  @Output()
  goBackEvent: EventEmitter<void> = new EventEmitter();

  isDialog: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogConfig: JobHistoryViewComponentConfig,
    @Optional() private matDialogRef: MatDialogRef<JobHistoryViewComponent>,
  ) {}

  async ngOnInit() {
    if (this.config == null && this.dialogConfig != null) {
      this.config = this.dialogConfig;
      this.isDialog = true;
    }

    let archiveId =
      this.config.jobArchiveId != null ? this.config.jobArchiveId : +this.route.snapshot.paramMap.get('archiveId');
    this.data = await this.config.api.getArchivedJobData(archiveId);

    this.distributionContainerConfig = {
      jobStatus: this.data,
      api: this.config.api,
    };

    this.loaded = true;
  }

  expandTextArea(id: string) {
    let ta = document.getElementById(id);
    let currentHeight = this.originalScrollHeightMap.get(id);
    if (currentHeight == null) {
      this.originalScrollHeightMap.set(id, ta.scrollHeight + 10);
      return this.expandTextArea(id);
    }

    ta.style.height = currentHeight + 'px';
  }

  goBack() {
    this.goBackEvent.emit();
  }

  onNoClick() {
    this.matDialogRef.close()
  }
}
