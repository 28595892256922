<span *ngIf="isOpen else closed" class="group-container">
  <span class="caret-d" (click)="toggleOpen()">&#9660;</span>
  <img src="assets/folder-opened.png" alt="" (click)="toggleOpen()">
  <span class="title bold" (click)="titleClick()">{{ entriesObj.title }}</span>
</span>
<ng-template #closed >
    <span (click)="toggleOpen()" class="group-container">
      <span class="caret-r">&#9654;</span>
      <img src="assets/folder-closed.png" alt="">
      <span class="title" (click)="titleClick()">{{ entriesObj.title }}</span>
      </span>
</ng-template>
<ul *ngIf="isOpen">
  <li *ngFor="let c of entriesObj.entries;" (click)="goToElement(c)">
    <span [ngClass]="c.requestType" class="type">{{ c.requestType }}</span>
    <span class="title">{{ c.title }}</span>
  </li>
</ul>