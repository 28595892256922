import { Component, EventEmitter, Output } from '@angular/core';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { SrsSpacerConfig } from '../../../interfaces/elements/srs-spacer-config.interface';

@Component({
  selector: 'app-srs-spacer',
  templateUrl: './srs-spacer.component.html',
  styleUrls: ['./srs-spacer.component.scss']
})
export class SrsSpacerComponent extends SrsFormElement<SrsSpacerConfig> {

  @Output()
  change = new EventEmitter<any>();

  constructor() {
    super();
  }

  onElementInit() {

  }

  onChange(event: any) {

  }

}
