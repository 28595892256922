import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { EmitterEvent } from "../../../edtell-portal/enums/emitter-events.enum";
import { EventEmitterService } from "../../../edtell-portal/services/event-emitter.service";
import { Comment } from "../../interfaces/comment.interface";
import { NotificationService } from "../../../edtell-portal/services/notification.service";
import { CommentsService } from "../../services/comments.service";
import { CommentResponse } from "../comments/comments.component";

@Component({
    selector: 'app-comments-inline',
    templateUrl: './comments-inline.component.html',
    styleUrls: ['./comments-inline.component.scss'],
    providers: [CommentsService]
})
export class CommentInlineComponent implements OnInit {

  @Output()
  canDeactivateEmitter = new EventEmitter<any>();

  @Input()
  commentChanged: boolean;


  @Input()
  set objectId(input) {
    this._objectId = input;
  }

  @Input()
  set itemId(input) {
    this._itemId = input;
  }

  @Input()
  set title(input) {
    this._title = input;
  }

  // These are the variables for the data that gets passed in.
  _title = '';
  _objectId;
  _itemId;

  newCommentText;
  submitClicked = false;

  commentResponseMap;

  hidden: boolean[] = [];

  constructor(private commentService: CommentsService, private notificationService: NotificationService, private router: Router, private emitterService: EventEmitterService) {

  }

  ngOnInit(): void {
    // Getting the comment data
    this.commentService.getMap(this._objectId, this._itemId).subscribe(resp => {
      this.commentResponseMap = resp;
      this.hidden = new Array(this.commentResponseMap.length).fill(false);
      this.setFreshnessIndicators();
    });
    this.canDeactivateEmitter.emit(true);
  }

  setFreshnessIndicators() {
    this.commentResponseMap.forEach(comment => {
      let dateCreated = new Date(comment.comment.dateCreated);
      let date = new Date();
      let isToday = date.getDate() == dateCreated.getDate() && date.getMonth() == dateCreated.getMonth() && date.getFullYear() == dateCreated.getFullYear();
      let daysAgo = (Date.now() - +dateCreated + ((date.getTimezoneOffset() - date.getUTCDate()) * 60000)) / 86400000; // 86,400,000ms in a day
      let freshness = '';
      if (isToday) {
        freshness = 'Today';
      } else if (daysAgo < 7) {
        freshness = 'Past week';
      } else if (daysAgo < 30) {
        freshness = 'Past month';
      }
      comment.freshness = freshness;
    });
  }

  onChange(event: any) {
    this.canDeactivateEmitter.emit(event != '');
  }

  disableSubmit() {
    return this.newCommentText != undefined ? this.newCommentText.length == 0 : true;
  }

  commentChangedHandler(event) {
    this.canDeactivateEmitter.emit(event);
  }

  addComment() {
    this.submitClicked = true;

    // Creating a new comment object for use by the backend
    const newCommentObject: Comment = {
      id: null,
      createdBy: null,
      dateCreated: null,
      owner: null,
      objectId: this._objectId,
      itemId: this._itemId,
      parentId: 0,
      comment: this.newCommentText,
      children: []
    };

    this.commentService.addComment(newCommentObject).subscribe(resp => {
      // Setting the textfield to an empty string
      this.newCommentText = '';

      // adding the new comment to the start of the commentResponses to preserve the reverse chronological order
      this.commentResponseMap.unshift(resp as unknown as CommentResponse);

      // Sending a notificaiton to tell the user the call has been successfully made
      this.notificationService.notificationEmitter.emit({message: 'New Comment Has Been Added.', duration: 5000});


      this.emitterService.emit(EmitterEvent.SIDEBAR_NOTE_EVENT, null);

    });
  }

  // Used for deleting a comment / comment tree at the root level
  deleteRootNode(event) {
    console.log('deleteRootNode Event', event);
    if (confirm('Are you sure you want to delete this comment / comment thread')) {
      this.commentService.deleteComment(this.commentResponseMap[event].comment.id).subscribe(resp => {

        // The backend return true if it was able to successfully delete the comment / comment tree
        if (resp) {
          // Using lodash to remove the comment from the frontend to reflect the change
          _.pullAt(this.commentResponseMap, event);

          // Notify the user of the successful delete
          this.notificationService.notificationEmitter.emit({
            message: 'The Comment / Comment thread has been successfully deleted',
            duration: 5000
          });
        }

        this.emitterService.emit(EmitterEvent.SIDEBAR_NOTE_EVENT, null);
      });
    } else {
      console.log('lol jk');
    }
  }

  toggleHideCommentTreeNode(i) {
    this.hidden[i] = !this.hidden[i];
  }

  formatDate(date) {
    return moment.utc(date).utcOffset(moment().utcOffset()).format('MMMM Do YYYY, h:mm:ss a');

    // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }

}