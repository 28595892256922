import { AbstractControl } from "@angular/forms";
import { ComponentCanDeactivate } from "../../edtell-portal/abstracts/component-can-deactivate";
import { EdtellFormArray, EdtellFormGroup } from "../classes/edtell-form-group.class";
import { SrsFormState } from "../enumerations/form-state.enum";
import { Directive } from "@angular/core";
import { updateTreeValidity } from "../../edtell-utilities/functions/update-tree-validity.function";

@Directive()
export abstract class EdtellFormComponent extends ComponentCanDeactivate {

    abstract formGroup: EdtellFormGroup;

    abstract onSubmit(props?: any) : void;
    abstract onDelete(props?: any) : void;

    deleteDeactivate: boolean = false;
    canDeactivatePrompt = null;

    constructor() {
      super();

      let onSubSubmit = this.onSubmit.bind(this);
      this.onSubmit = async (props?: any) => {
        this.formGroup.submitted = true;
        if (this.formGroup.valid) {
          await onSubSubmit(props);
        }
      }

      let onSubDelete = this.onDelete.bind(this);

      this.onDelete = (props?: any) => {
        this.deleteDeactivate = true;

        onSubDelete(props);
      }
    }

    public canDeactivate() {
      if (this.deleteDeactivate) {
        return true;
      }
        if (this.formGroup) {
            return this.formGroup.isOriginal || this.formGroup.state == SrsFormState.READ || this.formGroup.submitted;
        }
        return true;
    }

    protected disableSave() {
        return this.formGroup.isOriginal;
    }

}
