import { cloneDeep, assign } from "lodash";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { LangUtils } from "../../edtell-portal/namespaces/lang-utils.namespace";
import { EdtellCacheApi } from "../classes/edtell-cache-api.class";
import { EdtellMethodCache } from "../classes/edtell-method-cache.class";
import { CacheInternal } from "../enums/cache-internals.enum";
import { EdtellCacheConfig } from "../interfaces/edtell-cache-config.interface";

/**
 * Namespace for use only with the internals of the caching framework.
 * If you are using this namespace for somthing outside the caching module...
 * https://www.youtube.com/watch?v=l60MnDJklnM
 */
 export namespace EdtellInternalCacheUtil {
  export function initCacheApi(target: any) {
    let api: EdtellCacheApi = new EdtellCacheApi();
    target[CacheInternal.CACHE_API] = api;
    return api;
  }

  export function getClassCacheApi(service: any): EdtellCacheApi {
    // Copy Constructor Level API
    return service[CacheInternal.CLASS_CACHE_API];
  }

  export function initClassCacheApi(target: any) {
    let api: EdtellCacheApi = new EdtellCacheApi();
    target[CacheInternal.CLASS_CACHE_API] = api;
    return api;
  }

  export function formatForCache(
    cacheFnMap: Map<string, Function>,
    config: EdtellCacheConfig,
    cacheQueue: string[],
    cacheKey: string,
    value: any,
    cache : EdtellMethodCache
  ) {
    if (value instanceof Observable) {
      // We dont immediately cache the observable because the request technically has
      // not been made until the observable it subscribed to. 
      let prom = LangUtils.toPromise(value);
      return () => {
        return new Observable((obs) => {
          prom.then((v) => {
            obs.next(getCacheReturnValue(cache, config, v));
          });
        });
      };
    } else if (value instanceof Promise) {
      // The same logic as to why we dont cache the observable above could be said about
      // the promises, however because promises can be chained.
      let cacheFn = async () => {
        let v = await value;
        return getCacheReturnValue(cache, config, v);
      };
      return cacheFn;
    } else {
      // Just returns the stored value, this one was fun
      return () => {
        return getCacheReturnValue(cache, config, value);
      };
    }
  }

  function getCacheReturnValue(cache : EdtellMethodCache, config: EdtellCacheConfig, value: any) {
    if (typeof value == 'object') {
      if(isReplacable(config) && cache.previousRawValue != null){
        value = replaceValues(cache.previousRawValue, value, config.replace)
      }

      cache.previousRawValue = value;

      if(config.copy == true){
        return cloneDeep(value);
      }
    }

    cache.previousRawValue = value;
    return value;
  }

  function replaceValues(dst : any, src : any, mergeStrategy : boolean | Function) {

    if (typeof mergeStrategy == "function") {
      return mergeStrategy(dst, src);
    }
    
    let keySet = new Set<string | number>(); // just in case an expected key is tied to a null value
    for(let k in src){
      dst[k] = src[k]
      keySet.add(k)
    }

    for(let k in Object.keys(dst)){
      if(!keySet.has(k)){
        delete dst[k]
      }
    }

    return dst
  }

  function isReplacable(config : EdtellCacheConfig){
    return config.replace != null && config.replace != false
  }

  export const ACTIVE_EDTELL_CACHES = new Set<EdtellMethodCache>();
}
