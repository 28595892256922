<div class="form" *ngIf="dataLoaded">
  <div class="dialog-header">
    <h1 mat-dialog-title mat-dialog-draggable-title [disableOutsideClickExit]="true">{{objectTitle}}</h1>
    <span aria-hidden="true" (click)="onNoClick()">&times;</span>
  </div>

  <div class="row text-right mb-3" *ngIf="isAdd">
    <div class="col-12">
      <app-entity-form-control [config]="addFormControlConfig"></app-entity-form-control>
    </div>
  </div>
  <div class="row text-right mb-3" *ngIf="isEdit">
    <div class="col-12">
      <app-entity-form-control [config]="editFormControlConfig"></app-entity-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-srs-multi-element-widget [config]="attributeConfig"></app-srs-multi-element-widget>
    </div>
  </div>

</div>
