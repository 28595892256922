<div [formGroup]="config.group">
  <div [class]="formGroupClass">

    <div [style.padding-top.px]="checkboxPaddingAmount" [class]="class">
      <div class="custom-control custom-checkbox unselectable">
        <input [edtellTooltip]="tooltip" type="checkbox" [id]="elementId"
          [ngClass]="['custom-control-input', 'form-control', control.errors && config.group.submitted ? 'is-invalid' : '']"
          [formControlName]="config.key" [autoFocus]="autoFocus" (ngModelChange)="onChange()" [disableControl]="disabled">
        <label [ngClass]="['custom-control-label','horizontal-label', inlineDisabledLabelClass, disabled ? 'disabledHover' : 'enabledHover']" [for]="elementId">
          <div *ngIf="getIcon(); else noIcon">
            <img [src]="getIcon()" [ngStyle]="{height: getIconHeight(), width: getIconWidth()}"
                  alt="{{config.title}}" title="{{config.title}}">
          </div>
          <ng-template #noIcon>
            <span *ngIf="!hideTitle && config.title">
              {{config.title}} 
            </span>
          </ng-template>
          <span class="required-asterisk">{{ required ? "*" : "" }}</span>
        </label>
        <!-- Bootstrap custom-checkbox requires a label as a sibling with the checkbox input element. -->
        <!-- Having it would break the convention of the label being above all form elemnts, so I've put this empty one here. -->
        <!-- Cannot be a hidden label for it to render. Strange dontyathink? -->
        <!-- <label [ngClass]="['custom-control-label', 'fake-label', disabled ? 'disabledHover' : 'enabledHover']" [ngClass]="disabled ? 'disabled-option-thing' : 'enabled-option-thing'"
        [for]="elementId"></label> -->
      </div>
    </div>

    <small class="form-text text-muted unselectable" *ngIf="config.hint != null">{{hint}}</small>
    <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
      <div class="invalid-feedback" *ngIf="hasError(v.key) == true">{{v.message}}</div>
    </ng-template>
  </div>
</div>
