import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EdtellFrontFacingDialogConfig } from '../../../../../edtell-containers/interface/edtell-front-facing-dialog-config.interface';
import { DialogActionType } from '../../../../../edtell-portal/enums/dialog-action-type.enum';
import { NotificationService } from '../../../../../edtell-portal/services/notification.service';
import { EdtellDialogComponent } from '../../../../abstracts/edtell-dialog.component';
import { EdtellFormGroup } from '../../../../classes/edtell-form-group.class';
import { SrsFormState } from '../../../../enumerations/form-state.enum';
import { EdtellDialogData } from '../../../../interfaces/abstracts/edtell-dialog-data.interface';

@Component({
  selector: 'app-what-is-csc-dialog',
  templateUrl: './what-is-csc-dialog.component.html',
  styleUrls: ['./what-is-csc-dialog.component.scss'],
})
export class WhatIsCscDialogComponent extends EdtellDialogComponent {
  dialogData: EdtellDialogData;
  formGroup = new EdtellFormGroup(SrsFormState.READ, {});
  objectTitle: string;

  frontFacingDialogConfig: EdtellFrontFacingDialogConfig = {
    edtellDialogRef: this,
    header: 'Card Security Code Validation',
  };

  constructor(
    public dialogRef: MatDialogRef<WhatIsCscDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EdtellDialogData,
    public notificationService: NotificationService,
  ) {
    super(data.action as DialogActionType, notificationService);
    this.dialogData = data;
  }

  initForm() {}

  dialogOnInit() {}
  create(reqBody: any): Observable<any> {
    return null;
  }
  update(reqBody: any): Observable<any> {
    return null;
  }
  delete(id: number): Observable<any> {
    return null;
  }
}
