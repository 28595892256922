import { Component, OnInit } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';

@Component({
  selector: 'app-view-grid-settings',
  templateUrl: './view-grid-settings.component.html',
  styleUrls: ['./view-grid-settings.component.scss']
})
export class ViewGridSettingsComponent {

  private gridApi;
  private columnApi;

  gridSettingsSecurity: SecurityConfig = {
    accessLevels: {
      accessLevel: 1,
      objectName: ObjectName.GRID_SETTINGS
    }
  }

  agInit(params: IStatusPanelParams): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  viewGridSettings() {
    this.gridApi.dispatchEvent({type: "viewGridSettings", columnApi: this.columnApi});
  }

}
