<div [ngSwitch]="config.group.state">
  <ng-container *ngSwitchCase="'READ'">
    <div class="form-group">
      <label *ngIf="config.title">{{ config.title }}</label>
      <input class="form-control" [(ngModel)]="searchString" [disabled]="true" />
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'WRITE'">
    <div [class]="formGroupClass">
      <label *ngIf="config.title">
        {{ config.title }} <span *ngIf="showRequired" class="required-asterisk">{{ required ? "*" : "" }}</span>
      </label>

      <div [ngClass]="['input-group', config.hasSearch ? 'has-search' : '']">
        <span *ngIf="config.hasSearch" class="far fa-search form-control-feedback"></span>
        <input
          class="form-control"
          [attr.list]="datalistId"
          autocomplete="off"
          [placeholder]="config.placeholder ? config.placeholder : ''"
          [id]="elementId"
          [ngClass]="['form-control', validationClass]"
          [(ngModel)]="searchString"
          (ngModelChange)="onChange()"
          (click)="click$.next($any($event).target.value)"
          (focus)="onFocus()"
          (focusout)="unfocus()"
          [disableControl]="disabled"
        />
      </div>

      <!-- Revert if unfocus -->
      <!-- Change selection if unfocus and new item is selected or clicked -->
      <!-- Turn text into numbies -->

      <datalist [id]="datalistId">
        <option *ngFor="let o of options" [ngValue]="getOptionValue(o) ">{{ o.text }}</option>
      </datalist>

      <small class="form-text text-muted" *ngIf="config.hint != null">{{ hint }}</small>
      <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
        <div class="invalid-feedback d-block" *ngIf="hasError(v.key) == true">{{ v.message }}</div>
      </ng-template>
    </div>
  </ng-container>
</div>
