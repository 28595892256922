import { FormControl, ValidatorFn } from "@angular/forms";
import { find } from "lodash";

export function uniqueValidator(otherElements: any[]): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
  
      if (control.value) {

        let controlValue = control.value;
        let otherElems = [];

        if (typeof controlValue == 'string') {
          controlValue = controlValue.toLowerCase();
        }

        if(controlValue.endsWith(" ")){
          controlValue = controlValue.slice(0, -1)
        }

        for (let elem of otherElements) {
          let elemValue = elem
          if (elemValue && typeof elemValue == 'string') {
            elemValue = elemValue.toLowerCase();
          }
          if (elemValue) {
            otherElems.push({value: elemValue});
          }
        }

        // console.log(otherElems, controlValue, find(otherElems, {value: controlValue}))

        if (find(otherElems, {value: controlValue})) {
            return {nonunique: true}
        }
      }
      
      return null;
    }
  }
  