import {AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { EdtellFormControl } from '../../../classes/edtell-form-control';
import { SrsTwoLineFormFieldConfig } from '../../../interfaces/elements/srs-two-line-form-field-config.interface';

@Component({
  selector: 'app-srs-two-line-form-field',
  templateUrl: './srs-two-line-form-field.component.html',
  styleUrls: ['./srs-two-line-form-field.component.scss']
})
export class SrsTwoLineFormFieldComponent extends SrsFormElement<SrsTwoLineFormFieldConfig> implements AfterViewInit {

  @ViewChild("inputField", { static: true })
  inputFieldRef: ElementRef
  @ViewChild("inputField2", { static: true })
  inputFieldRef2: ElementRef

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>(); // TODO maybe remove if this can just be imported/extended

  constructor() {
    super();
  }
  
  control2: EdtellFormControl;
  
  onElementInit() {
    this.control2 = <EdtellFormControl>this.config.group.get(this.config.key2)
  }

  ngAfterViewInit() {
    if (this.config.attributes != null) {
      let i: HTMLInputElement = this.inputFieldRef.nativeElement;
      for (let c of this.config.attributes) {
        i.setAttribute(c.key, c.value + "")
      }
    }
  }

  onChange1(event: any) {
    this.change.emit(event);
    this.config.onChange1 != null ? this.config.onChange1(this.control) : null;
  }

  onChange2(event: any) {
    this.change.emit(event);
    this.config.onChange2 != null ? this.config.onChange2(this.control2) : null;
  }

  getHint() {

    if (typeof this.config.hint == "string") {
      return this.config.hint
    }

    return (<any>this.config.hint)(this.control);
  }
}
