import { GridOptions } from "ag-grid-community";

export function gridWrappingHeader(onGridReady?: (params?) => void, firstDataRenderedCallback?: (params?) => void): GridOptions {

    function headerHeightGetter() {
        let columnHeaderTexts = [];
        document.querySelectorAll('.ag-header-cell-text').forEach(elem => columnHeaderTexts.push(elem))
        let clientHeights = columnHeaderTexts.map(
            headerText => headerText.clientHeight
        )
        var tallestHeaderTextHeight = Math.max(...clientHeights);
    
        return tallestHeaderTextHeight;
    }
    
    function headerHeightSetter(params) {
        var padding = 20;
        var height = headerHeightGetter() + padding;
        params.api.setHeaderHeight(height);
    }
    
    return {
        onFirstDataRendered: (params) => {
            headerHeightSetter(params);
            if (firstDataRenderedCallback != null) {
                firstDataRenderedCallback(params);
            }
        },
        onColumnResized: headerHeightSetter,
        onGridReady: (params) => {
            params.api.sizeColumnsToFit();
            headerHeightSetter(params);
            if (onGridReady != null) {
                onGridReady(params);
            }
        },
    }

}
