import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiDocSidebarEntry } from '../../../../interfaces/api-doc-sidebar-entry.interface';
import { DocumentationUIService } from '../../../../services/documentationUI.service';

@Component({
  selector: 'app-commands-index',
  templateUrl: './commands-index.component.html',
  styleUrls: ['./commands-index.component.scss']
})
export class CommandsIndexComponent implements OnInit {

  entriesObj: ApiDocSidebarEntry[];
  indexGroups = [
    { "letter" : "A",
      "commands" : [] 
    },
    { "letter" : "B",
      "commands" : [] 
    },
    { "letter" : "C",
      "commands" : [] 
    },
    { "letter" : "D",
      "commands" : [] 
    },
    { "letter" : "E",
      "commands" : [] 
    },
    { "letter" : "F",
      "commands" : [] 
    },
    { "letter" : "G",
      "commands" : [] 
    },
    { "letter" : "H",
      "commands" : [] 
    },
    { "letter" : "I",
      "commands" : [] 
    },
    { "letter" : "J",
      "commands" : [] 
    },
    { "letter" : "K",
      "commands" : [] 
    },
    { "letter" : "L",
      "commands" : [] 
    },
    { "letter" : "M",
      "commands" : [] 
    },
    { "letter" : "N",
      "commands" : [] 
    },
    { "letter" : "O",
      "commands" : [] 
    },
    { "letter" : "P",
      "commands" : [] 
    },
    { "letter" : "Q",
      "commands" : [] 
    },
    { "letter" : "R",
      "commands" : [] 
    },
    { "letter" : "S",
      "commands" : [] 
    },
    { "letter" : "T",
      "commands" : [] 
    },
    { "letter" : "U",
      "commands" : [] 
    },
    { "letter" : "V",
      "commands" : [] 
    },
    { "letter" : "W",
      "commands" : [] 
    },
    { "letter" : "X",
      "commands" : [] 
    },
    { "letter" : "Y",
      "commands" : [] 
    },
    { "letter" : "Z",
      "commands" : [] 
    },
  ]

  constructor(private http: HttpClient, private documentationUIService: DocumentationUIService, private router: Router) { }

  async ngOnInit() {
    this.documentationUIService.currGroupId = null;
    this.entriesObj = await this.documentationUIService.getSidebarData().toPromise();
    
    for (let entry of this.entriesObj) {
      this.indexGroups[entry.title.toUpperCase().charAt(0).charCodeAt(0) - 65].commands.push(entry);
    }
  }
  
  async goToElement(command: ApiDocSidebarEntry) {
    this.router.navigate([`/apidocumentation/${command.groupTitle}/${command.title}`]);
    this.documentationUIService.docEntryGroups = [];
    await this.documentationUIService.getDocEntryGroup(command.groupId);
    
    // make sure page loads
    setTimeout(() => {
      var myElement = document.getElementById(command.title);
      var topPos = myElement.offsetTop - 64;
      document.getElementById('commands-page-container').scrollTop = topPos;
    }, 500)
  }
}
