import { Component, OnInit } from "@angular/core";
import { SystemRoleService } from "../../services/system-role.service";
import { SystemRole } from "../../interfaces/system-role.interface";
import { take } from "rxjs/operators";
import { EntityControlConfig } from "../../../edtell-controls/interfaces/entity-control-config";
import { Router, ActivatedRoute } from "@angular/router";
import { GridApi, ColDef } from "ag-grid-community";
import { actionColumn, globalGridOptions } from "../../../edtell-ag-grid/interfaces/global-grid-config";
import { EntitySelectComponent } from "../../../edtell-ag-grid/components/entity-select/entity-select.component";
import { ObjectName } from "../../../edtell-admin/enums/object-name.enum";
import { EntitySelectConfig } from "../../../edtell-utilities/interfaces/entity-select-config";
import { EdtellAgGridConfig } from "../../../edtell-ag-grid/interfaces/edtell-ag-grid-config.interface";

@Component({
  selector: "app-role-management",
  templateUrl: "./role-management.component.html",
  styleUrls: ["./role-management.component.scss"],
})
export class RoleManagementComponent implements OnInit {

  public entityControlConfig: EntityControlConfig = {
    controlButtons: [
      {
        text: "Add",
        callback: () => {
          this.router.navigate(["./add"], {relativeTo: this.route})
        },
        icon: "fa fa-plus-square",
        security: {
          accessLevels: {
            accessLevel: 2,
            objectName: ObjectName.OBJECT,
          },
        },
      },
    ],
    securityObject: ObjectName.OBJECT,
  };

  entitySelectConfig: EntitySelectConfig = {
    options: [
      {
        title: "Edit",
        security: {
          accessLevels: {
            accessLevel: 3,
            objectName: ObjectName.OBJECT,
          },
          localOwnership: true,
        },
        callback: (rowData) => {
          this.router.navigate([`./${rowData.id}`], { relativeTo: this.route });
        },
      },
    ],
  };


  columnDefs : ColDef[] = [
    { type: "actions" },
    {
      headerName: "Role Title",
      field: "title",
      width: 800
    },
  ];

  agGridConfig: EdtellAgGridConfig;

  roles: SystemRole[];
  loaded: boolean = false;

  constructor(private roleService: SystemRoleService, private router: Router, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.roles = await this.roleService.getAllRoles().pipe(take(1)).toPromise();

    this.agGridConfig = {
      columnDefs: this.columnDefs,
      data: () => { return this.roles },
      gridPreferenceName: null,
      actionConfig: this.entitySelectConfig,
      entityControl: this.entityControlConfig,
      object: ObjectName.OBJECT
    }
    this.loaded = true;
  }
}
