import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';
import { BasicInformation } from '../../../edtell-portal/interfaces/basic-information.interface';
import { OwnershipLinkConfig } from '../../interfaces/edtell-ownership-link-config.interface';
import { OwnershipDialogComponent } from '../ownership-dialog/ownership-dialog.component';


@Component({
  selector: 'app-ownership-link',
  template: '<a class="info-link" (click)="viewOwnership()" [secure]="securityConfig" >Info</a>',
  styleUrls: ['./ownership-link.component.scss']
})
export class OwnershipLinkComponent {

  _object;
  _saveFunc;

  createUID;
  createUser;
  createDate;

  modifyUID;
  modifyUser;
  modifyDate;

  users;

  securityConfig: SecurityConfig = {
    accessLevels: [
      {
        accessLevel: 1,
        objectName: ObjectName.INFO_DIALOG
      }
    ]
  }

  @Input()
  set object(input) {
    this._object = input;
  }

  @Input()
  set saveFunc(input) {
    this._saveFunc = input;
  }

  @Input()
  config: OwnershipLinkConfig

  constructor(private router: Router, public dialog: MatDialog) {
  }

  viewOwnership() {
    let config = this.config;
    if(config != null){
      if(typeof config.object == "function"){
        console.log(config.object())
        config.object = config.object() as BasicInformation;
      }
    }

    const dialogRef = this.dialog.open(OwnershipDialogComponent, {
      width: '400px',
      data: config != null ? { config: config } : { object: this._object, saveFunc: this._saveFunc }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  refresh(): boolean {
    return false;
  }

}
