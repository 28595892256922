import { Component } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community';


@Component({
  selector: 'status-component',
  template: `
    <div class="ag-name-value grid-clear-button">
            <span class="component"><input style="padding: 1px;" type="button" class="btn btn-tertiary" (click)="onClick()"
                                           value="Clear"/></span>
    </div>
  `,
  styleUrls: ['./grid-clear-button.component.scss']
})
export class GridClearButtonComponent {
  private params: IStatusPanelParams;

  agInit(params: IStatusPanelParams): void {
    this.params = params;
  }

  onClick(): void {
    this.params.api.setFilterModel(null);
    this.params.api.deselectAll();
  }
}
