import { Injectable } from "@angular/core";
import { EntityResolver } from "../../edtell-portal/abstracts/entity.resolver";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { RouterDataInfo } from "../../edtell-portal/interfaces/router-data-info";
import { LoginService } from "../../edtell-portal/services/login.service";
import { take } from "rxjs/operators";
import { SystemRoleService } from "../services/system-role.service";
import { SystemRole } from "../interfaces/system-role.interface";
import { RouterProcessor } from "../../edtell-portal/interfaces/router-processor";

@Injectable({
  providedIn: "root",
})
export class SystemRoleResolver extends EntityResolver implements RouterProcessor {
  previousRole: SystemRole;

  constructor(private systemRoleService: SystemRoleService, private router: Router, private loginService: LoginService) {
    super();
  }

  async resolve(route: ActivatedRouteSnapshot) {

    let data = route.data;
    let id = route.params["systemRoleId"];

    
    if (data.routerData.paramId != null) {
      id = data[data.routerData.paramId];
    }

    if (this.previousRole == null || this.previousRole.id != id) {
      this.previousRole = await this.systemRoleService.getRole(id).pipe(take(1)).toPromise();
    }

    return this.previousRole;
  }

  async process(route: ActivatedRouteSnapshot, routerData: RouterDataInfo) {
    await this.resolve(route);
    routerData.title = this.previousRole.title;
  }

  async getStoredData() {
    return this.previousRole;
  }
}
