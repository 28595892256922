import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as _ from 'lodash';
import { find, isEqual, remove } from 'lodash';
import { Subscription } from 'rxjs';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import {
  SrsMultiSelectDropdownConfig,
  SrsMultiSelectDropdownOption,
} from '../../../interfaces/elements/srs-multi-select-dropdown-config.interface';

@Component({
  selector: 'app-srs-multi-select-dropdown',
  templateUrl: './srs-multi-select-dropdown.component.html',
  styleUrls: ['./srs-multi-select-dropdown.component.scss'],
})
export class SrsMultiSelectDropdownComponent extends SrsFormElement<SrsMultiSelectDropdownConfig<any>>
  implements AfterViewInit, OnDestroy {
  @ViewChildren('searchInput')
  searchInput: QueryList<ElementRef>;

  @ViewChildren('viewArea')
  viewArea: QueryList<ElementRef>;

  @ViewChildren(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  trigger: QueryList<MatAutocompleteTrigger>;

  @ViewChildren(CdkVirtualScrollViewport)
  scrollViewport: QueryList<CdkVirtualScrollViewport>;

  selectedOptions: SrsMultiSelectDropdownOption<any>[];
  filteredOptionsLst: SrsMultiSelectDropdownOption<any>[];

  searchString: string;
  previousSearchString : string

  preventSort = false;

  wildCardSelected = false;

  hasWildcard: boolean = false;

  subscription: Subscription;

  // Used for differencing
  private addedValues = new Set();

  sortFunc: (a: SrsMultiSelectDropdownOption<any>, b: SrsMultiSelectDropdownOption<any>) => number;

  constructor() {
    super();
  }

  unselectWarning = 1; // flag used to make sure backspace is pressed twice before removing option from selected list

  onElementInit() {

    if(this.control.value == null){
      this.control.setValue([])
      this.control.setOriginalValueToCurrentValue()
    }

    let alphabeticSort = (a, b) => {
      return a.text > b.text ? 1 : a.text < b.text ? -1 : 0;
    };
    this.preventSort = this.config.preventSort;

    this.sortFunc = this.config.sortFunc ? this.config.sortFunc : alphabeticSort;

    if (!this.preventSort) {
      this.config.options.sort(this.sortFunc);
    }

    this.filteredOptionsLst = this.config.options;
    this.selectedOptions = this.getSelectedOptions();

    this.subscription = this.control.valueChanges.subscribe(value => {
      this.selectedOptions = this.getSelectedOptions();
      if (this.config.onSelected != null || this.config.onDeselected != null) {
        this.emitDifferences(value);
      }
    });

    // Ensure we have original selections
    if (this.control.value != null) {
      for (let v of this.control.value) {
        this.addedValues.add(v);
      }
    }

    let foundWildcard = false;

    for (let option of this.config.options) {
      if (option.wildcard) {
        if (foundWildcard) {
          console.error('A MultiSelectDropdown can only at most one wildcard option.');
          break;
        }
        foundWildcard = true;
        this.hasWildcard = true;
      }
    }
  }

  ngAfterViewInit() {
    if (this.viewArea.first !== undefined) {
      this.viewAreaSize();
    }
    // detects when read/write is switched
    this.viewArea.changes.subscribe(qList => {
      if (qList.first !== undefined) {
        this.viewAreaSize();
        // resets component so the WRITE page is accurate in case of cancel
        this.onElementInit();
      }
    });
  }

  toggleSelectOption(event, option: SrsMultiSelectDropdownOption<any>) {
    if (event) {
      event.stopPropagation();
    }

    if (this.control.value.length > 0 && option.wildcard) {
      if (!this.getIsSelected(option)) {
        return;
      }
    } else if (this.control.value.length > 0 && this.wildCardSelected && !option.wildcard) {
      return;
    }

    this.config.group.markAsDirty();

    this.unselectWarning = 0;

    let controlValue = this.control.value;

    if (!this.getIsSelected(option)) {
      controlValue.push(option.value);
      this.wildCardSelected = option.wildcard;
    } else {
      _.remove(controlValue, value => {
        return _.isEqual(value, option.value);
      });
      this.wildCardSelected = false;
    }

    this.control.setValue(controlValue);

    if (this.config.onChange) {
      this.config.onChange(this.control);
    }
  }

  selectByEnter(event, option) {
    if (event.source.selected) {
      // prevents weird bug where select is triggered by previously selected element
      this.searchString = undefined;
      this.toggleSelectOption(null, option);
    }
  }

  searchFocus(event) {
    // event.stopPropagation();

    this.searchInput.first.nativeElement.focus();
    setTimeout(() => {
      // trigger viewport to rerender the viewport by programmatically scrolling
      this.scrollViewport.first.scrollTo({top: 1});
      this.scrollViewport.first.scrollTo({top: 0});
      
      this.trigger.first.openPanel();
    });
  }

  selectAll() {
    if (this.selectAllDisabled) {
      return;
    }

    let newControlValue: any[] = this.control.value;
    if (!this.wildCardSelected) {
      for (let option of this.filteredOptions) {
        // make sure wildcards are unselected when clicking select all
        if (option.wildcard) {
          continue;
        }

        if (this.getIsSelected(option)) {
          continue;
        }

        if (!option.wildcard) {
          newControlValue.push(option.value);
        }
      }

      this.control.setValue(newControlValue, { markAsDirty: true });

      if (this.config.onChange) {
        this.config.onChange(this.control);
      }
    }
  }

  deselectAll() {
    if (this.deselectAllDisabled) {
      return;
    }
    let newControlValue: any[] = [];
    for (let value of this.control.value) {
      if (
        find(this.filteredOptions, option => {
          return isEqual(option.value, value);
        }) != undefined
      ) {
        continue;
      }

      newControlValue.push(value);
    }

    this.wildCardSelected = false;

    this.control.setValue(newControlValue, { markAsDirty: true });

    if (this.config.onChange) {
      this.config.onChange(this.control);
    }
  }

  get filteredOptions(): SrsMultiSelectDropdownOption<any>[] {

    if(this.searchString == "" || this.searchString == null){
      this.filteredOptionsLst = this.config.options
    }else if(this.previousSearchString != this.searchString){
      let hasWildcard = false;

      // This could be faster if a dictionary was built out of the options terms, you could easily subdivide the options into sets with the next letter as the key to another set
      // you could also make this async, although I dont know how much it would help if the filter internal loop blocks execution
      let options = this.config.options.filter(option => {
        hasWildcard = hasWildcard || option.wildcard;
        return option.text != null ? option.text.toLowerCase().includes(this.searchString.toLowerCase()) : false;
      });
  
      this.hasWildcard = hasWildcard;
      this.previousSearchString = this.searchString;
      
      this.filteredOptionsLst = options;
    }

    return this.filteredOptionsLst;
  }

  // unselect last option if backspace twice on empty input value
  tryBackspaceUnselect(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      if (event.target['value'] === '') {
        this.unselectWarning++;
        if (this.unselectWarning > 2 && this.selectedOptions.length > 0) {
          this.unselectWarning = 1;
          this.toggleSelectOption(null, this.selectedOptions[this.selectedOptions.length - 1]);
        }
      }
    } else {
      this.unselectWarning = 0;
    }
  }

  get readValue(): string {
    let readValue: string = '';

    let controlValue = this.selectedOptions;

    if (!this.preventSort) {
      controlValue.sort((a, b) => {
        return a.text > b.text ? 1 : a.text < b.text ? -1 : 0;
      });
    }

    for (let i in controlValue) {
      let option = controlValue[i];
      readValue += option.text;

      if (+i < controlValue.length - 1) {
        readValue += ', ';
      }
    }

    return readValue;
  }

  getSelectedOptions(): SrsMultiSelectDropdownOption<any>[] {
    let selectedOpt: SrsMultiSelectDropdownOption<any>[] = [];
    let options: any[] = this.config.isOptionSelected != null ? this.config.options : this.control.value;

    if (options) {
      for (let option of options) {
        let foundSelected = this.validateOptionIsSelected(option);
        if (foundSelected) {
          selectedOpt.push(foundSelected);
        }
      }
    }

    return selectedOpt;
  }

  private validateOptionIsSelected(option: any) {
    if (this.config.isOptionSelected != null) {
      return this.config.isOptionSelected(option) ? option : null;
    }
    return _.find(this.config.options, { value: option });
  }

  getIsSelected(option): boolean {
    let controlList = this.control.value;
    return this.config.isOptionSelected != null
      ? this.config.isOptionSelected(option)
      : controlList.filter(value => {
          return _.isEqual(value, option.value);
        }).length > 0;
  }

  viewAreaSize() {
    let viewAreaElement = this.viewArea.first.nativeElement;

    viewAreaElement.style.height = viewAreaElement.scrollHeight + 2 + 'px';
  }

  get invalidClass() {
    if (this.showValidators() && this.control.invalid) {
      return 'multi-select-dropdown-invalid';
    }
    return '';
  }

  getDisabled(option): boolean {
    if (this.control.value.length > 0) {
      if (option.wildcard && this.wildCardSelected) {
        return false;
      } else if (option.wildcard && !this.wildCardSelected) {
        return true;
      } else if (this.wildCardSelected) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get selectAllDisabled() {
    let wildcardOffset = this.hasWildcard ? 1 : 0;
    return this.selectedOptions.length == this.filteredOptions.length - wildcardOffset;
  }

  get deselectAllDisabled() {
    return this.selectedOptions.length == 0;
  }

  get viewportHeight() {
    let height = Math.min(256, this.filteredOptions.length * 48 + 30);
    return height;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private emitDifferences(values: any[]) {
    let valueSet = new Set(values);

    let added = [];
    let removed = [];

    // Additions
    for (let v of values) {
      if (!this.addedValues.has(v)) {
        this.addedValues.add(v);
        added.push(v);
      }
    }

    // Subtrations
    for (let v of this.addedValues) {
      if (!valueSet.has(v)) {
        removed.push(v);
      }
    }

    // Remove values
    for (let v of removed) {
      this.addedValues.delete(v);
    }

    // Emit events if viable
    for (let a of added) {
      this.config?.onSelected(a);
    }

    for (let r of removed) {
      this.config?.onDeselected(r);
    }
  }

  get autocompletePanelWidth() {
    if (this.config.autocompletePanelWidth != null) {
      return this.config.autocompletePanelWidth;
    } else {
      return "";
    }
  }
}
