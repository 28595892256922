import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarEntriesObject } from '../../../../interfaces/sidebar-entries-object.interface';
import { DocumentationUIService } from '../../../../services/documentationUI.service';
import { ApiDocSidebarEntry } from '../../../../interfaces/api-doc-sidebar-entry.interface';

@Component({
  selector: 'app-command-group',
  templateUrl: './command-group.component.html',
  styleUrls: ['./command-group.component.scss']
})
export class CommandGroupComponent implements OnInit {

  @Input()
  entriesObj: SidebarEntriesObject;
  @Input()
  index;
  isOpen = false;

  constructor(private router: Router, private documentationUIService: DocumentationUIService) { }

  ngOnInit() {
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  async goToElement(command: ApiDocSidebarEntry) {
    let timeout = 0;
    if (this.entriesObj.entries[0].groupId !== this.documentationUIService.currGroupId) {
      this.router.navigate([`/apidocumentation/${command.groupTitle}/${command.title}`]);
      await this.updateApiDocService();
      timeout = 400;
    } else {
      window.history.replaceState({}, '', `/apidocumentation/${command.groupTitle}/${command.title}`);
    }
    
    try {
      setTimeout(() => {
        if (command.title != '-1') {
          var myElement = document.getElementById(command.title);
          var topPos = myElement.offsetTop - 64;
        } else {
          topPos = 0;
        }
        document.getElementById('commands-page-container').scrollTop = topPos;
      }, timeout)
    } catch (error) {
      console.error(error)
    }

  }
  
  async titleClick() {
    let timeout = 0;
    if (this.documentationUIService.currGroupId == null) { // no group or on index
      timeout = 400
    }
    
    if (this.entriesObj.entries[0].groupId !== this.documentationUIService.currGroupId) {
      this.router.navigate([`/apidocumentation/${this.entriesObj.title}`]);
      this.updateApiDocService();
    }
    
    setTimeout(() => {
      try {
        document.getElementById('commands-page-container').scrollTop = 0;
      } catch (error) {
        console.log(error)
      }
    }, timeout);
  }
  
  updateApiDocService() {
    this.documentationUIService.getDocEntryGroup(this.entriesObj.entries[0].groupId);
  }

  compare(a,b) {
    if (a.title < b.title)
      return -1;
    if (a.title > b.title)
      return 1;
    return 0;
  }
}
