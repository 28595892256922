import { CommonModule } from "@angular/common";
import {
  ANALYZE_FOR_ENTRY_COMPONENTS,
  ModuleWithProviders,
  NgModule,
  Type,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { SrsSecurityModule } from "../srs-security/srs-security.module";
import { SidebarIndicatorComponent } from "./abstracts/sidebar-indicator.abstract";
import { SidebarWidgetComponent } from "./abstracts/sidebar-widget.abstract";
import { SidebarButtonGroupComponent } from "./components/sidebar-button-group/sidebar-button-group.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { HeaderOffsetDirective } from "./directives/header-offset.directive";
import { SidebarDropdownPositionDirective } from "./directives/sidebar-dropdown-position.directive";
import { SidebarDropdownDirective } from "./directives/sidebar-dropdown.directive";
import { SidebarHelperDirective } from "./directives/sidebar-helper.directive";
import { SidebarIndicatorDirective } from "./directives/sidebar-indicator.directive";
import { SidebarButtonComponent } from './components/sidebar-button-group/sidebar-button/sidebar-button.component';

@NgModule({
  declarations: [
    SidebarComponent,
    SidebarIndicatorDirective,
    SidebarButtonGroupComponent,
    SidebarHelperDirective,
    HeaderOffsetDirective,
    SidebarDropdownDirective,
    SidebarDropdownPositionDirective,
    SidebarButtonComponent,
  ],
  imports: [CommonModule, SrsSecurityModule, RouterModule],
  exports: [SidebarComponent, SidebarButtonGroupComponent],
})
export class SrsSidebarModule {
  static withWidgets(
    widgets?: (Type<SidebarWidgetComponent> | Type<SidebarIndicatorComponent>)[]
  ): ModuleWithProviders<SrsSidebarModule> {
    return {
      ngModule: SrsSidebarModule,
      providers: [
        {
          provide: ANALYZE_FOR_ENTRY_COMPONENTS,
          useValue: widgets,
          multi: true,
        },
      ],
    };
  }
}
