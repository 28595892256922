<div class="d-flex align-items-end h-100">
  <div *ngFor="let o of options; index as i" >

    <ng-template [ngIf]="o.hidden == null ? true : !o.hidden()">

      <ng-container [ngSwitch]="o.type">

        <ng-container *ngSwitchCase='"LINK"'>
          <a href="javascript: void(0)" (click)="o.callback == null ? null : doCallBack(o)" [secure]="o.security">{{o.text}}</a>
        </ng-container>

        <!-- Button Type -->
        <ng-container *ngSwitchDefault>
          <app-edtell-button [ngClass]="[ i != options.length - 1 ? 'mr-2' : '' ]" [config]="o" ></app-edtell-button>
        </ng-container>

      </ng-container>
    </ng-template>

  </div>
</div>