import { Injectable, Injector, Type } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { EdtellRouterInterceptor } from "../abstracts/edtell-router-interceptor.abstract"
import { tap } from 'rxjs/operators';

// This may be better off on a guard instead of during the router interceptor chain
// This would report to a root level interceptor. This would only work if the guard could be applied to lazy
// loaded modules dynamically. This way we wouldnt have to worry about multiple router outlet displayed at the same time.
// It would also allow us to parse route segments instead of the entire route on every page change.

@Injectable({providedIn: "root"})
export class EdtellRouterInterceptorManager implements HttpInterceptor {

  interceptorChain : EdtellRouterInterceptor[] = [];

  constructor(private activatedRoute : ActivatedRoute, private router : Router, private injector : Injector) {
    this.router.events.subscribe((e : NavigationEnd) => {
      if(e instanceof NavigationEnd){
        this.processRoute();
      }
    })
    this.processRoute()
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let chain = this.interceptorChain;
    let appliedChain : EdtellRouterInterceptor[] = []
    for(let i of chain){
      if(i.applyInterceptor(request) == true){
        request = i.onRequest(request)
        appliedChain.push(i)
      }
    }

    return next.handle(request).pipe(
      tap((event) => {
        if(event instanceof HttpResponse){
          for(let i of appliedChain){
            i.onResponse(event)
          }
        }
      })
    );
  }

  processRoute(){
    this.interceptorChain = [...this.parseInterceptorsFromRoute(this.activatedRoute.snapshot, new Set())].map(I => {
      let i : EdtellRouterInterceptor = this.injector.get<EdtellRouterInterceptor>(I, null);
      if(i == null){
        throw new Error(`Edtell Router interceptor ${I.constructor.name} is not accessiable from the injector. Please check to ensure the interceptor has @Injector({providedIn: "root"}) attached to it`)
      }
      return i;
    })
  }

  parseInterceptorsFromRoute(snapshot : ActivatedRouteSnapshot, interceptors : Set<Type<EdtellRouterInterceptor>>){

    if(snapshot.children != null){
      for(let c of snapshot.children){
        this.parseInterceptorsFromRoute(c, interceptors)
      }
    }

    if(snapshot.data?.interceptors != null){
      let routerInterceptors = snapshot.data?.interceptors
      if(Array.isArray(routerInterceptors)){
        for(let I of routerInterceptors){
          interceptors.add(I)
        }
      }
    }

    return interceptors;
  }
  
}

export const EdtellRouterInterceptorManagerRegistration = {
  // This is the provider that gets used in a module. This statement allows for import of the provider insted of the component.
  provide: HTTP_INTERCEPTORS,
  useClass: EdtellRouterInterceptorManager,
  multi: true,
};