import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApplicationFrameService } from '../../../edtell-portal/services/application-frame.service';
import { SidebarHelperDirective } from '../../directives/sidebar-helper.directive';
import { SidebarConfig } from '../../interfaces/sidebar-config.interface';

@Component({
  selector: 'app-dynamic-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements AfterViewInit {
  
  @ViewChildren(SidebarHelperDirective) sidebarHelpers: QueryList<SidebarHelperDirective>;

  @Input()
  config: SidebarConfig

  @ViewChild("sidebarContainer", { static: true }) siderbarContainer: ElementRef

  topNavElm: HTMLElement

  constructor(
    private applicationFrameService: ApplicationFrameService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngAfterViewInit() {

    // Unfortunaly ngAfterViewInit is considered a change detection hook.
    // As a result components generated during this lifecycle event and are not reliably part of the angular lifcycle until the next cycle. 
    // The ng-template that hosts this component only exists after the view is initialized, 
    // the result being that it children are not accesible in the initialization lifecycle.
    // The solution to this seems to be to wrap it in a timeout, because the execution context for the timeout exists
    // outside of the change lifecycle. This problem seems to be addressed in angular 9.

    // Dynamic Component Creation for Indicators
    // TODO: Revist removing this timeout after upgrade to angular 9
    setTimeout(async () => {

      // Widgets
      let sidebarHelpers = this.sidebarHelpers.toArray();
      for (let i in sidebarHelpers) {

        let indicatorContainer = sidebarHelpers[i];
        let widget = this.config.widgets[i]

        if (widget == null) { continue }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(widget);
        const viewRef = indicatorContainer.viewContainerRef

        viewRef.clear();

        const componentRef = viewRef.createComponent(componentFactory);
        (<any>componentRef).data = this.config
      }

      this.topNavElm = this.applicationFrameService.headerElement;
    })


  }



}