import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContainer, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import {NotificationService} from '../../../edtell-portal/services/notification.service';
import {BasicInformation} from '../../../edtell-portal/interfaces/basic-information.interface';
import {UserService} from '../../../edtell-admin/services/user.service';
import {EdtellFormControl} from '../../../srs-forms/classes/edtell-form-control';
import {EdtellFormGroup} from '../../../srs-forms/classes/edtell-form-group.class';
import {SrsFormState} from '../../../srs-forms/enumerations/form-state.enum';
import {srsFormatDateTime} from '../../../srs-forms/functions/srs-format-date.func';
import {SrsSelectBoxConfig} from '../../../srs-forms/interfaces/elements/srs-select-box-config.interface';
import {LoginService} from '../../../edtell-portal/services/login.service';
import {UserInterface} from '../../../edtell-admin/services/user.interface';
import { EntityFormControlConfig } from '../../interfaces/entity-form-control-config';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';


@Component({
  selector: 'app-ownership-dialog',
  templateUrl: './ownership-dialog.component.html',
  styleUrls: ['./ownership-dialog.component.scss']
})
export class OwnershipDialogComponent implements OnInit {

  createUID: number;
  createUser: UserInterface;
  createDate: string;

  modifyUID: number;
  modifyUser: UserInterface;
  modifyDate: string;

  users: UserInterface[];

  dialogReady = false;

  _saveFunc: Function;
  _object: BasicInformation;

  owner: number;

  viewFormControlConfig: EntityFormControlConfig;
  editFormControlConfig: EntityFormControlConfig;

  selectBoxConfig: SrsSelectBoxConfig<number>;

  formGroup: EdtellFormGroup;

  message: string;

  currentUser: UserInterface;

  editSecureConfig: SecurityConfig = {
    accessLevels: [
      {
        accessLevel: 3,
        objectName: ObjectName.INFO_DIALOG
      }
    ]
  }

  constructor(
    public matDialogRef: MatDialogRef<any>,
    private container: MatDialogContainer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private userService: UserService,
    private loginService: LoginService) {
    this.matDialogRef.disableClose = true;
  }

  async ngOnInit() {

    console.log(this.data);

    if (this.data.config != null) {
      this._object = this.data.config.object;
      this._saveFunc = this.data.config.saveCallback;
      this.message = this.data.config.message ? this.data.config.message : 'Ownership Successfully Updated.';
    } else {
      this._object = this.data.object;
      this._saveFunc = this.data.saveFunc;
    }

    this.createUID = this._object.createdBy;
    this.createDate = this._object.dateCreated;
    this.modifyUID = this._object.modifiedBy;
    this.modifyDate = this._object.dateModified;

    this.owner = this._object.owner;
    await this.parse();

    this.currentUser = this.loginService.getCurrentUser();

    this.initFormGroup();
    this.initSelectConfig();
    this.initSecurityConfigs();

    this.dialogReady = true;
  }

  initFormGroup() {
    this.formGroup = new EdtellFormGroup(SrsFormState.READ, {
      owner: new EdtellFormControl(this.owner)
    });
  }

  initSelectConfig() {
    this.selectBoxConfig = {
      group: this.formGroup,
      title: 'Owner',
      key: 'owner',
      size: 12,
      options: 
      [
        {
          text: 'Anonymous User',
          value: 0,
          active: false
        },
        ...this.users.map(user => {
          return {
            text: `${user.firstName} ${user.lastName}`,
            value: user.id
          };
        })
      ]  

    };
  }

  initSecurityConfigs() {
    this.viewFormControlConfig = {
      entityControl: {
        controlButtons: [
          {
            text: 'Cancel',
            typeClass: 'cancel',
            callback: () => {
              return this.onNoClick();
            },
            security: {
              roleSecurity: {
                systemAdmin: false
              }
            }
          },
          {
            text: 'Edit',
            callback: () => {
              return this.onEditClick();
            },
            security: {
              roleSecurity: {
                systemAdmin: true
              }
            }
          }
        ]
      }
    };

    this.editFormControlConfig = {
      entityControl: {
        controlButtons: [
          {
            text: 'Cancel',
            typeClass: 'cancel',
            callback: () => {
              return this.onNoClick();
            },
            security: {
              roleSecurity: {
                systemAdmin: false
              }
            }
          },
          {
            text: 'Save',
            typeClass: 'save',
            callback: async () => {
              return await this.onOwnerChange();
            },
            security: {
              roleSecurity: {
                systemAdmin: true
              },
              localOwnership: true,
              ownershipData: this._object
            }
          }
        ]
      }
    };
  }

  onNoClick(): void {

    if (this._object.owner !== this.owner) {
      if (confirm('There are unsaved changes. Are you sure you want to cancel?')) {
        this._object.owner = this.owner;
        this.matDialogRef.close();
      }
    } else {
      this._object.owner = this.owner;
      this.matDialogRef.close();
    }

  }

  onEditClick() {
    this.formGroup.state = SrsFormState.WRITE;
  }

  // Parse Information
  async parse() {

    // TODO - We should have a call that just grabs the name of a user instead of all their user data
    this.createUser = this.createUID == null ? {} as UserInterface : await this.userService.getUser(this.createUID).toPromise();
    this.modifyUser = this.modifyUID == null ? {} as UserInterface : await this.userService.getUser(this.modifyUID).toPromise();

    // TODO - maybe this should only be people who can be owners. circle back to this. Dave - 04/07/2022
    this.users = this.owner == null ? await this.userService.getAllUsers().toPromise() : await this.userService.getAllUsersForOwnershipChange(this.owner).toPromise();
  }

  formatDate(date) {
    let formattedDate = srsFormatDateTime(date)
    return formattedDate == 'Invalid date' ? 'N/A' : formattedDate
  }

  async onOwnerChange() {

    this._object.owner = this.formGroup.get('owner').value;
    this._object = await this._saveFunc(this._object);

    // TODO: When all areas of the application have been updated to use the config instead of params
    // remove the null check here and have message always default
    if (this.message != null) {
      this.notificationService.sendNotification({
        message: this.message,
        duration: 5000
      });
    }
    this.matDialogRef.close();
  }


  get disableChangeOwnership(): boolean {
    let disabled = true;

    if (this.owner === this.currentUser.id || this.currentUser.systemAdmin) {
      disabled = false;
    }

    return disabled;
  }

  formatName(user: UserInterface): string {
    let firstName = user.firstName == null || user.firstName === '' ? '' : user.firstName;
    let lastName = user.lastName == null || user.lastName === '' ? '' : user.lastName;

    // This case should never be met, but it's here just in case
    // Mostly just to handle the case of an entity not having an owner/createdBy
    if (firstName === '' && lastName === '') {
      return 'N/a';
    }

    return firstName + ' ' + lastName;
  }

}
