<span>
    <span *ngIf="callback != null"
        #entityLink
        class="fake-link" 
        [secure]="security" 
        [behavior]="behavior"
        [success]="successCallback" 
        [failure]="failureCallback" 
        (click)="doCallback(data)">
            {{title}}
    </span>
    <a #entityLink *ngIf="routerLinkUrl != null"
        [secure]="security" 
        [behavior]="behavior"
        [success]="successCallback" 
        [failure]="failureCallback" 
        [routerLink]="routerLinkUrl"
        [target]="target">
        {{title}}
    </a>
</span>