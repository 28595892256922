import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HTTP_INTERCEPTORS,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { LoginService } from "../services/login.service";

@Injectable()
export class ShoppingCartInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let headers = this.copyHeaders(request.headers)
    if (localStorage.getItem("CARTID") != null) {
      headers["CARTID"] = localStorage.getItem("CARTID");
    }

    if(localStorage.getItem("JWT") != null){
      headers["JWT"] = localStorage.getItem("JWT");
    }

    request = request.clone({
      setHeaders: headers,
      withCredentials: true,
    });

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          // Checks for cart auth
          if(event.headers.has("CARTID")){
            localStorage.setItem("CARTID", event.headers.get("CARTID"));
          }
        }
      })
    );
  }

  private copyHeaders(headers : HttpHeaders){
    let headersCopy = {}
    for(let k of headers.keys()){
      headersCopy[k] = headers.get(k)
    }
    return headersCopy
  }
}

export const ShoppingCartInterceptorProvider = {
  // This is the provider that gets used in a module. This statement allows for import of the provider insted of the component.
  provide: HTTP_INTERCEPTORS,
  useClass: ShoppingCartInterceptor,
  multi: true,
};
