<div class="row">
    <div class="col-12 mb-3">
        <div class="edtell-card p-3">
            <div class="row">
                <div class="col-12 bill-to">
                    <h5>Payor:</h5> <span>(Name and address as it appears on your account)</span>
                </div>
                <div class="col-2">
                    <app-srs-form-field [config]="firstNameFormFieldConfig"></app-srs-form-field>
                </div>
                <div class="col-2">
                    <app-srs-form-field [config]="lastNameFormFieldConfig"></app-srs-form-field>
                </div>
                <div class="col-12">
                    <app-srs-full-address-widget [config]="fullAddressConfig"></app-srs-full-address-widget>
                </div>
                <div *ngIf="config.schoolAsPayerConfig" class="col-12">
                    <p class="text-danger">*IF SCHOOL IS PAYING WITH A CHECK OR MONEY ORDER* Please check the box below.</p>
                    <app-srs-checkbox [config]="config.schoolAsPayerConfig"></app-srs-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="edtell-card p-3">
            <div class="row">
                <div class="col-12">
                    <h5>{{paymentModule.checkLanguage}} Information</h5>
                </div>

                <div class="col-12 d-flex justify-content-between mb-2">
                    <div>
                        <span class="sender-label">Make check or money order payable to:</span> <span class="sender-field">{{paymentModule.checkPayableTo}}</span>
                    </div>
                </div>

                <div class="col-12 d-flex">
                    <p class="mr-2">
                        Payments can be sent to:
                    </p>
                    <p>
                        <br>
                        <span class="bold">
                            {{paymentModule.checkPayableTo}}<br>
                            {{paymentModule.checkAddress1}}<br>
                            <span *ngIf="paymentModule.checkAddress2">{{paymentModule.checkAddress2}}<br></span>
                            {{paymentModule.checkCity}}, {{paymentModule.checkState}} {{paymentModule.checkZip}}
                            <span *ngIf="hasAttn">
                                <br>
                                <br>
                                ATTN: {{paymentModule.checkAttn}}
                            </span>
                        </span>
                    </p>
                </div>
                <div class="col-12 text-red">
                    <span>*Note: Actual set up of a course is pending receipt of payment.</span>
                </div>
            </div>
        </div>
    </div>
</div>