import { ChangeDetectorRef } from '@angular/core';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { find } from 'lodash';
import * as moment from 'moment';
import { container } from 'webpack';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { EdtellMonthBlockPickerConfig } from '../../../interfaces/elements/edtell-month-block-picker-config.interface';

@Component({
  selector: 'app-edtell-month-block-picker',
  templateUrl: './edtell-month-block-picker.component.html',
  styleUrls: ['./edtell-month-block-picker.component.scss']
})
export class EdtellMonthBlockPickerComponent extends SrsFormElement<EdtellMonthBlockPickerConfig> implements OnInit, AfterViewInit {

  @ViewChild('monthBlockContainer')
  monthBlockContainer: ElementRef;

  @ViewChildren('monthBlocks')
  monthBlocks: QueryList<ElementRef>;

  months: string[] = [];
  visibleMonths: string[] = [];
  hiddenMonths: string[] = [];
  moreDropDown = false;
  monthWidth;

  dropDownMonth;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  onElementInit() {
    this.getMonths(this.config.numOfMonths);
    this.moreDropDown = this.config.moreDropDown ?? false;
    this.visibleMonths = [
      this.months[0]
    ];
    window.onresize = this.setVisisbleMonths.bind(this);
    this.setDropDownMonth();
  }

  ngAfterViewInit() {
    this.setVisisbleMonths();
    this.cdr.detectChanges();
  }

  setVisisbleMonths() {

    if (!this.moreDropDown) {
      return this.visibleMonths = this.months;
    }

    let monthContainer = this.monthBlockContainer?.nativeElement;
    if (monthContainer == undefined) {
      return [];
    }
    let containerWidth = monthContainer.clientWidth;

    if (this.monthWidth == undefined) {
      this.monthWidth = this.calculateMonthWidth();
    }

    let numberOfMonthsFit = Math.max(0, Math.floor((containerWidth / this.monthWidth) - 1.5));

    this.hiddenMonths = [];
    this.visibleMonths = [];
    for (let i in this.months) {
      if (+i < numberOfMonthsFit) {
        this.visibleMonths.push(this.months[+i])
        continue;
      }

      this.hiddenMonths.push(this.months[+i]);
    }

    this.setDropDownMonth();
    
  }

  calculateMonthWidth() {
    let monthBlock = this.monthBlocks.first.nativeElement;
    let style = window.getComputedStyle(monthBlock, null);
    let marginRightStr = style.marginRight;
    let marginRightPx = +marginRightStr.substring(0, marginRightStr.length - 2);
    let monthWidth = monthBlock.offsetWidth + marginRightPx;

    return monthWidth;
  }

  getMonths(numOfMonths = 12) {
    let now = moment();
    
    if (this.config.startLastMonth) {
      now.subtract(1, 'months');
    }

    for (let i = 0; i < numOfMonths; i++) {
      this.months.push(now.format('YYYY-MM'));
      now.subtract(1, 'months');
    }
    
  }

  selectMonthBox(month: string) {
    this.selectMonth(month);
    if (this.config.onMonthBlockClick) {
      this.config.onMonthBlockClick(month);
    }
  }

  selectMonth(month: string) {
    this.control.setValue(month);
    this.setDropDownMonth();

    if (this.config.onChange) {
      this.config.onChange(this.control);
    }
  }

  onSelectChange() {
    this.selectMonth(this.dropDownMonth);
  }

  setDropDownMonth() {
    let isHidden = !!find(this.hiddenMonths, (month) => { return this.control.value == month });
    if (isHidden) {
      this.dropDownMonth = this.control.value;
      return;
    }

    this.dropDownMonth = null;
  }

  onArrowClick() {
    let month = this.control.value;
    let isHidden = !!find(this.hiddenMonths, (month) => { return this.control.value == month });

    month = isHidden ? month : null;
    this.config.onArrowClick(month);
  }

}
