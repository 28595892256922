import { 
  ObjectName, 
  SrsSearch, 
  SrsSearchInfo, 
  AutocompleteInterface
} from "@edtell-common"
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ProviderAutocompleteInterface } from "src/app/modules/providers/interfaces/provider-autocomplete.interface";
import { ProviderService } from "src/app/modules/providers/services/provider.service";

@Injectable()
@SrsSearchInfo({
    label: "Providers",
    security: {
        accessLevels: {
            accessLevel: 1,
            objectName: ObjectName.PROVIDER_PROFILE
        }
    }
})
export class ProviderSearch extends SrsSearch {

    constructor(private providerService: ProviderService) { super(); }

    async search(router: Router, text: string) {
        let results = await this.providerService.getProviderSearchResults(text).toPromise()
        if (results.length == 1) {
            router.navigate([`/providers/${results[0].id}`]);
        } else {
            router.navigate([`/providers`], { queryParams: { 'searchString': text } });
        }
    }

    async autocomplete(text: string) {

        let results = await this.providerService.getAutocompleteResults(text).toPromise();
        let options: AutocompleteInterface<ProviderAutocompleteInterface>[] = []

        for (let i of results) {
            options.push({
                text: i.providerName,
                object: i
            })
        }

        return options;
    }

}