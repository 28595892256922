import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SidebarConditionalService} from '../../abstracts/sidebar-conditional-service.abstract';
import {ButtonConditionalConfig, SidebarButtonConfig, SidebarConfig} from '../../interfaces/sidebar-config.interface';
import {SidebarIndicatorConfig} from '../../interfaces/sidebar-indicator-data';
import {EmitterEvent} from '../../../edtell-portal/enums/emitter-events.enum';
import {EventEmitterService} from '../../../edtell-portal/services/event-emitter.service';
import {SidebarButtonComponent} from './sidebar-button/sidebar-button.component';
import {SidebarHelperDirective} from '../../directives/sidebar-helper.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar-button-group',
  templateUrl: './sidebar-button-group.component.html',
  styleUrls: ['./sidebar-button-group.component.scss'],
})
export class SidebarButtonGroupComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(SidebarHelperDirective)
  customButtons: QueryList<SidebarHelperDirective>;

  @ViewChildren(SidebarButtonComponent)
  sidebarButtons: QueryList<SidebarButtonComponent>;

  @ViewChild('flyMenu', {static: false}) flyMenu: ElementRef;
  flyMenuOpen = false;

  @Input()
  config: SidebarConfig;

  @Input()
  buttons: SidebarButtonConfig[];

  activeButton: SidebarButtonConfig;

  hiddenMap: Map<SidebarButtonConfig, boolean> = new Map();
  disabledMap: Map<SidebarButtonConfig, boolean> = new Map();

  sub : Subscription

  constructor(
    private router: Router,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private eventEmitterService: EventEmitterService
  ) {
    this.sub = this.eventEmitterService.subscribe(EmitterEvent.SIDEBAR_BUTTON_REFRESH, () => {
      this.refresh();
    });
  }

  async ngOnInit() {

    await this.generateActiveButtonMaps();

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {

        await this.generateActiveButtonMaps();

        this.activeButton = null;
        this.activeButton = await this.setActiveButton(this.buttons);
        this.closeAllDropdowns();
      }
    });
  }

  ngAfterViewInit() {
    // Unfortunaly ngAfterViewInit is considered a change detection hook.
    // As a result components generated during this lifecycle event and are not reliably part of the angular lifcycle until the next cycle.
    // The ng-template that hosts this component only exists after the view is initialized,
    // the result being that it children are not accesible in the initialization lifecycle.
    // The solution to this seems to be to wrap it in a timeout, because the execution context for the timeout exists
    // outside of the change lifecycle. This problem seems to be addressed in angular 9.

    // Dynamic Component Creation for Indicators
    // TODO: Revist removing this timeout after upgrade to angular 9
    setTimeout(async () => {
      // Button Indicators
      let sidebarIndicators = this.sidebarButtons.toArray().map(button => {
        return button.sidebarIndicator;
      });
      for (let i in sidebarIndicators) {
        let indicatorContainer = sidebarIndicators[i];
        let b = this.config.buttons[i];

        if (b.indicator == null) {
          continue;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
          <any>b.indicator
        );
        const viewRef = indicatorContainer.viewContainerRef;

        viewRef.clear();

        const componentRef = viewRef.createComponent(componentFactory);
        let data: SidebarIndicatorConfig = {
          _button: b,
          _sidebar: this.config,
          data: b.indicatorData.data,
        };
        (<any>componentRef.instance).data = data;
      }
      this.activeButton = null;
      this.activeButton = await this.setActiveButton(this.buttons);

      // render custom buttons
      let customButtonConfigs: SidebarButtonConfig[] = this.buttons.filter(button => {
        return button.customButtonComponent != null;
      });

      let customButtons = this.customButtons.toArray();
      // console.log(customButtonConfigs, this)
      for (let i in customButtonConfigs) {
        let customButtonConfig = customButtonConfigs[i];
        let buttonContainer = customButtons[i];
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(customButtonConfig.customButtonComponent);
        const viewRef = buttonContainer?.viewContainerRef;
        viewRef.clear();
        const componentRef = viewRef.createComponent(componentFactory);
        componentRef.instance.buttonConfig = customButtonConfig;
        componentRef.instance.activeButton = this.activeButton;
        componentRef.instance.sidebarConfig = this.config;
      }
    });
  }

  onButtonClick(b: SidebarButtonConfig) {
    // Grabs all router link params
    this.activeButton = b;
    this.router.navigate([this.getRouterLink(b)]);
  }

  openDropdown(b: SidebarButtonConfig) {
    b._showDropdown = true;
  }

  closeDropdown(b: SidebarButtonConfig) {
    b._showDropdown = false;
    if (Array.isArray(b.route)) {
      for (let cb of b.route) {
        this.closeDropdown(cb);
      }
    }
  }

  closeAllDropdowns() {
    for (let b of this.config.buttons) {
      this.closeDropdown(b);
    }
  }

  previewDropdown(b: SidebarButtonConfig) {
    b._showDropdown = true;
  }

  private async setActiveButton(buttons: SidebarButtonConfig[]) {
    let activeButton: SidebarButtonConfig = null;
    let currentRoute = this.router.url;

    for (let b of buttons) {
      let child: SidebarButtonConfig = Array.isArray(b.route)
        ? await this.setActiveButton(b.route)
        : null;
      let routerlink = this.getRouterLink(b);

      if (child != null) {
        return b;
      } else if (currentRoute.includes(routerlink)) {
        if (routerlink.length == currentRoute.length) {
          return b;
        } else if (
          activeButton == null ||
          b.route.length > activeButton.route.length
        ) {
          activeButton = b;
        }
      }
    }

    return activeButton;
  }

  hasChildren(button: SidebarButtonConfig): boolean {
    return Array.isArray(button.route);
  }

  childrenShowing(b: SidebarButtonConfig): boolean {
    if (b._showDropdown) {
      return true;
    }

    if (!Array.isArray(b.route)) {
      return false;
    }

    for (let c of b.route) {
      if (c._showDropdown) {
        return true;
      }

      if (Array.isArray(c.route)) {
        for (let child of c.route) {
          if (this.childrenShowing(child)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  async generateActiveButtonMaps() {
    for (let b of this.buttons) {
      this.hiddenMap.set(b, b.conditionals != null ? await this.isHidden(b) : false);
      this.disabledMap.set(b, b.conditionals != null ? await this.isDisabled(b) : false);
    }
  }

  async isHidden(config: SidebarButtonConfig) {
    for (let c of config.conditionals) {
      if (await this.checkConditional(c, 'hidden')) {
        return true;
      }
    }
    return false;
  }

  async isDisabled(config: SidebarButtonConfig) {
    for (let c of config.conditionals) {
      if (await this.checkConditional(c, 'disabled')) {
        return true;
      }
    }
    return false;
  }

  async checkConditional(condition: Type<SidebarConditionalService> | ButtonConditionalConfig, check: 'hidden' | 'disabled') {
    if (!!condition['callback']) { // checking for the existance of callback function, ie: ButtonConditionalConfig
      let c = <ButtonConditionalConfig>condition;
      if ((c.type == check && await c.callback(this.router.routerState.snapshot.url))) {
        return true;
      }
    } else { // else: its a Conditional Service
      let ConditionalService = <Type<SidebarConditionalService>>condition;
      let service = this.injector.get(ConditionalService);
      let r = await service[check]();
      if (r) {
        return true;
      }
    }
    return false;
  }

  private getRouterLink(button: SidebarButtonConfig): string {
    let routerLink = button.route;
    if (typeof routerLink == 'string') {
      while (routerLink.includes(':')) {
        // Grab the param key
        let index = routerLink.indexOf(':');
        let paramKey = routerLink.substr(index);
        let endingIndex = paramKey.indexOf('/') - 1;

        paramKey = paramKey.substr(
          1,
          endingIndex > 0 ? endingIndex : paramKey.length
        );

        let value = this.config._route.paramMap.get(paramKey);
        return routerLink.replace(':' + paramKey, value);
      }
    }
  }

  async refresh() {
    await this.generateActiveButtonMaps();
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
