import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetDocumentPickerDialogComponent } from '../../../../edtell-asset-management/components/asset-document-picker-dialog/asset-document-picker-dialog.component';
import { Asset } from '../../../../edtell-asset-management/interfaces/asset.interface';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { AssetType } from '../../../enumerations/asset-type.enum';
import { EntityControlButtonOption } from '../../../../edtell-controls/interfaces/entity-control-config';
import { SrsAssetPickerWidgetConfig } from '../../../interfaces/widgets/srs-asset-picker-widget-config';

@Component({
  selector: 'app-asset-picker-widget',
  templateUrl: './asset-picker-widget.component.html',
  styleUrls: ['./asset-picker-widget.component.scss']
})
export class AssetPickerWidgetComponent extends SrsFormWidget<SrsAssetPickerWidgetConfig> {

  @ViewChild('fileInput') fileInput: ElementRef;
  assetTypeId: number;

  originalAsset: Asset;
  asset: Asset;
  assetDownloadLinkData: Asset;
  
  constructor(
    private sanitizer: DomSanitizer,
    private dialog: MatDialog) {
    super();
  }

  async onWidgetInit() {
    this.config.newAsset = null;
    this.asset = await this.config.loadAssetCallback();
    if (this.asset != null) {
      // more stuff perhaps
      this.originalAsset = this.asset;
    }
    
    switch (this.config.type) {
      case AssetType.LOGO:
        this.assetTypeId = 1000;
        break;
      case AssetType.COURSE_SYLLABUS:
        this.assetTypeId = 1003;
        break;
      case AssetType.EDUCERE_ENROLLMENT_FORM:
        this.assetTypeId = 1004;
        break;
      case AssetType.ENROLLMENT_FORM:
        this.assetTypeId = 1005;
        break;
      case AssetType.PROCTOR_REQUEST_FORM:
        this.assetTypeId = 1006;
        break;
      case AssetType.TECHNICAL_REQUIREMENTS:
        this.assetTypeId = 1007;
        break;
    }
    
    // cancel behavior
    let cancelButton: EntityControlButtonOption;
    for (let button of this.config.formControlConfig.entityControl.controlButtons) {
      if (button.text.toLowerCase() == 'cancel') {
        cancelButton = button;
        break;
      }
    }
    if (cancelButton) {
      let originalCallback = cancelButton.callback;
      cancelButton.callback = () => {
        originalCallback();
        this.resetComponent();
      }
    }
  }
  
  resetComponent() {
    this.asset = this.originalAsset;
    this.config.newAsset = null;
  }

  onChooseClick() {
    let dialogRef = this.dialog.open(AssetDocumentPickerDialogComponent, {
      width: '750px',
      data: { assetTypeId: this.assetTypeId }
    });

    dialogRef.afterClosed().subscribe(async resp => {
      if (resp != undefined) {
        this.config.formGroup.get(this.config.key).setValue(resp);
        this.asset = await this.config.loadAssetCallback();
        this.config.newAsset = null;
      }
    });
  }

  onFileChanged(event) {
    if (this.asset === null || confirm('There is already an existing asset. Are you sure you want to overwrite it?')) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let ImageURL = reader.result.toString();

        // Split the base64 string in data and contentType
        let block = ImageURL.split(';');

        // Get the content type of the image
        let contentType = block[0].split(':')[1];

        // get the real base64 content of the file
        let realData = block[1].split(',')[1];

        this.asset = {
          assetName: file.name,
          assetSize: file.size,
          assetFileType: "pdf", // TODO configure multiple types of assets
          assetTypeId: this.assetTypeId,
          assetBase64: realData,
        };

        this.config.newAsset = this.asset;
        this.config.formGroup.get(this.config.key).setValue(file.id);
        if (this.config.loadAssetCallback) {
          this.config.loadAssetCallback();
        }
      };
    }
  }

  onRemoveClick() {
    if (confirm('Are you sure you want to remove the asset?')) {
      this.fileInput.nativeElement.value = '';
      this.asset = null;
      this.config.formGroup.get(this.config.key).setValue(null);
      this.config.newAsset = null;
    }
  }
  
  get state() {
    return this.config.formGroup.state;
  }
}
