<div class="row">
  <div class="col-12 pt-2">
    <h5>Api Key Generator</h5>
  </div>
</div>

<div class="row">
  <div class="col-12 pt-2">
    <span>Generate jwt keys used to make api calls to this system. Use them with the JWT header to authenticate using these keys.</span>
  </div>
</div>

<ng-template [ngIf]="jwt != null">
  <div class="row">
    <div class="col-12 pt-2">
      Make sure to save this jwt key. You will not be able to retrieve after navigating away from this page.
    </div>
  </div>

  <div class="row">
    <div class="col-12 pt-1">
      <span class="jwt-display">{{ jwt }}</span>
    </div>
  </div>
</ng-template>

<div class="row">
  <div class="col-12">
    <app-srs-multi-element-widget [config]="widget"></app-srs-multi-element-widget>
  </div>
</div>

<button class="btn btn-primary" (click)="generateKey()">Generate</button>
