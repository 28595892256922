<!-- TODO: This needs to be refactored to use the srs-form-field element -->
<!-- If you are seeing an warning that two elements are using the same ID and this -->
<!-- element is the problem, than its time to refactor this. See the srs-range-selector -->
<!-- for an example of how this should be setup --> 
<div [formGroup]="config.group" [ngSwitch]="config.group.state">

  <ng-container *ngSwitchCase="'READ'">
    <div [class]="formGroupClass">
      <label *ngIf="config.title" class="label-bold">{{config.title}}</label>
      <input [edtellTooltip]="tooltip" class="form-control mb-2" [value]="control.value" readonly>
      <input [edtellTooltip]="tooltip" class="form-control" [value]="control2.value" readonly>
    </div>
  </ng-container>


  <ng-container *ngSwitchCase="'WRITE'">
    <div [class]="formGroupClass">
      <label *ngIf="config.title" class="label-bold">{{config.title}}
        <span class="required-asterisk">{{ required ? "*" : "" }}</span>
      </label>
      <input patternInput [edtellTooltip]="tooltip" (ngModelChange)="onChange1($event)"
        [ngClass]="['form-control', 'mb-2', control.errors && config.group.submitted ? 'is-invalid' : '']" #inputField
        [id]="config.key" [formControlName]="config.key" [maxlength]="config.maxCharacters"
        [placeholder]="config.placeholder1" [autoFocus]="autoFocus" [disableControl]="disabled"> 
      <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
        <div class="invalid-feedback d-block mb-2" *ngIf="control.hasError(v.key)">{{v.message}}</div>
      </ng-template>
      <input patternInput [edtellTooltip]="tooltip" (ngModelChange)="onChange2($event)"
        [ngClass]="['form-control']" #inputField2
        [id]="config.key2" [formControlName]="config.key2" [maxlength]="config.maxCharacters"
        [placeholder]="config.placeholder2" [disableControl]="disabled">
      <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators2">
        <div class="invalid-feedback d-block" *ngIf="control2.hasError(v.key)">{{v.message}}</div>
      </ng-template>
      <span *ngIf="config.maxCharacters; else noMaxChars">
        <small class="d-inline-block form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
        <small
          [ngClass]="['char-hint', 'form-text', 'text-muted', 'text-right', maxCharWarningClass, noCharsLeftClass]">{{ currCharacterCount + '/' + config.maxCharacters}}</small>
      </span>
      <ng-template #noMaxChars>
        <small class="form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
      </ng-template>
    </div>
  </ng-container>

</div>
