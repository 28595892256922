<div class="dialog-header">
  <h1 mat-dialog-title mat-dialog-draggable-title>Pick a New Logo</h1>
  <span aria-hidden="true" (click)="onNoClick()">&times;</span>
</div>

<div mat-dialog-content id="assetPicker">
  <div class="row">
    <div class="col-12">
      <app-srs-radio-button [config]="usedStateRadioConfig"></app-srs-radio-button>
    </div>
    <div class="col-12">
      <app-srs-form-field [config]="searchStringFormFieldConfig"></app-srs-form-field>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <button class="btn btn-tertiary" (click)="search()" [disabled]="isLoading">Search Assets</button>
    </div>
  </div>
  <div class="row">
    <div *ngIf="isLoading; else haveResults" class="col-12 d-flex justify-content-center align-items-center py-4">
      <app-loading-icon></app-loading-icon>
    </div>
    <ng-template #haveResults>
      <div class="col-12">
        <div class="row" *ngIf="assetList.length > 0; else noResults">
          <div class="col-4 pr-4 pb-4" *ngFor="let i of assetList">
            <div class="asset-container">
              <div class="row">
                <div class="col-12 spacer">
                  <span class="label" [title]="i.assetName">{{truncateString(i.assetName)}}</span>
                  <span class="link-symbol" *ngIf="i.isUsed" title="Asset is linked to an entity." (click)="openViewLinkedAssets(i)">
                    <i class="far fa-link"></i>
                  </span>
                  <span class="date-created">{{dateFormat(i.dateCreated)}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 py-1 spacer">
                  <div class="image-container">
                    <i *ngIf="i.assetName.endsWith('.pdf')" class="fas fa-lg fa-file-alt document-placeholder"></i>
                    <img *ngIf="!i.assetName.endsWith('.pdf')" [src]="i.image" alt="{{i.assetName}}">
                  </div>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12 spacer">
                  <button class="btn srs-view-btn btn-tertiary mr-2" (click)="submit(i)">Pick</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <ng-template #noResults>
        <div class="row">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <h4>No results. ¯\_(ツ)_/¯</h4>
          </div>
        </div>
      </ng-template>
    </ng-template>

    <mat-paginator [length]="totalAssetCount" [pageSize]="assetListCount" [pageSizeOptions]="[6, 9, 12, 24]"
                   (page)="pageEvent($event)"
                   [showFirstLastButtons]="true">

    </mat-paginator>
  </div>

</div>
