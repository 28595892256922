export enum AssetType {
  LOGO,
  COURSE_SYLLABUS,
  EDUCERE_ENROLLMENT_FORM,
  ENROLLMENT_FORM,
  PROCTOR_REQUEST_FORM,
  TECHNICAL_REQUIREMENTS,
  KNOWLEDGE_BASE,
  STATIC_ASSET
}
