import { EdtellMethodCacheInfo } from "../interfaces/edtell-cache-method-info.interface";
import { EdtellMethodCache } from "./edtell-method-cache.class";

export class EdtellCacheApi {
  protected methodCacheInfoMap = new Map<
    Function | string,
    EdtellMethodCache
  >();
  protected cachesInfoSet: Set<EdtellMethodCache> = new Set();

  constructor() {}

  getCacheByMethodName(methodName: string) {
    return this.methodCacheInfoMap.get(methodName);
  }

  clear(methodName?: string, item?: any) {
    if (methodName != null) {
     
    } else {
      for (let c of this.methodCacheInfoMap.values()) {
        c.clear();
      }
    }
  }

  /**
   * Do not manually add caches. This is here to support the decorator.
   * Please pretend this does not exist.
   */
  _addCache(cache: EdtellMethodCache) {
    if (this.methodCacheInfoMap.has(cache.methodName)) {
      throw new Error("This method has already been added to the cache");
    }

    this.methodCacheInfoMap.set(cache.methodName, cache);
  }

  // Copies the class level map, so it has its own instance.
  // This way when a non static level scope is declared, modifications
  // to the data key will not affect the other instantiations of the api.
  _copyClassApi(classApi: EdtellCacheApi) {
    for (let key of classApi.methodCacheInfoMap.keys()) {
      let info = classApi.methodCacheInfoMap.get(key);
      this.methodCacheInfoMap.set(key, info.copy());
    }
  }
}

export interface InternalEdtellCacheApi {
  addCache: (method: Function, info: EdtellMethodCacheInfo) => void;
}
