import { Injectable, Type } from "@angular/core";
import { Router } from "@angular/router";
import { SecurityService } from "../../../edtell-portal/services/security.service";
import { SrsSearch } from "../../classes/srs-search.abstract";
import { SrsSearchInfo } from "../../decorators/srs-search.decorator";
import { AutocompleteInterface } from "../../interfaces/autocomplete-result.interface";
import { SrsSearchService } from "../../services/srs-search.service";
import { HideSearch } from "./hide.search";

@Injectable()
@SrsSearchInfo({
    label: "Searches"
})
export class SearchTypeSearch extends SrsSearch {

    private processedSearches: AutocompleteInterface<Type<SrsSearch>>[] = []

    constructor(
        private srsSearchService: SrsSearchService,
        private srsSecurityService: SecurityService
    ) {

        super();

        // This is needed in because this service is used during contruction.
        // On startup of the application reflection has to be done on each class
        // Since reflection can not be done without instantiation, we need to instatiate the object outside
        // the angular injector, which means srsSearchService will be null for that singular use case.
        if (this.srsSearchService == null) { return; }

        try {
            this.setupSeachTypeList();
        } catch (err) {
            console.error(err);
        }

    }

    async setupSeachTypeList() {

        // Get the search list
        let searches = this.srsSearchService.getAllSearchTypes();

        for (let Search of searches) {

            if (Search == SearchTypeSearch || Search == HideSearch) {
                continue;
            }

            // Filter out all that dont pass security permissions
            let info = this.srsSearchService.getSearchInfo(Search)
            if (info.security != null) {
                let passesSecurity = await this.srsSecurityService.hasPermission(null, info.security)
                if (!passesSecurity) {
                    continue;
                }
            }

            this.processedSearches.push({
                object: Search,
                text: info.label
            })
        }
    }

    async search(router: Router, text: string, autocompleteObj: Type<SrsSearch>) {

        if (autocompleteObj != null) {
            this.srsSearchService.setActiveSearch(autocompleteObj)
            return;
        }

        if (text == null || text.length == 0) { return; }

        for (let search of this.processedSearches) {
            if (text.toUpperCase() == search.text.toUpperCase()) {
                this.search(null, null, search.object)
                return;
            }
        }
    }

    async autocomplete(text: string) {

        if (text.length <= 1) {
            return this.processedSearches;
        }

        text = text.substr(1, text.length).replace(/ /g, '')

        return this.processedSearches.filter((obj) => {
            if (obj.text.toUpperCase().includes(text.toUpperCase())) {
                return true;
            }
            return false;
        });
    }

}