import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { includes } from 'lodash';
import { ProviderInterface } from '../../modules/providers/interfaces/provider.interface';
import { ProviderResolver } from '../resolvers/provider.resolver';

@Injectable()
export class ProviderChildrenGuard implements CanActivateChild {

    public previousObject: ProviderInterface;

    constructor(private router: Router, private providerResolver: ProviderResolver) { }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let route = childRoute.routeConfig.path;

        let courseTypeRoutes = ['rigor-level', 'format', 'structure', 'credit-equivalent', 'term'];
        if (includes(courseTypeRoutes, route)) {
            route = 'catalog';
        }

        let canActivate = true;
        let provider = await this.providerResolver.resolve(childRoute);
        let providerOrgType = provider.providerOrgType;

        switch (route) {
            case 'catalog':
                canActivate = providerOrgType.includesCatalog && providerOrgType.includesPricingManagement;
                break;
            case 'delivery-methods':
                canActivate = providerOrgType.includesDeliveryMethods;
                break;
            case 'welcome-emails':
                canActivate = providerOrgType.includesWelcomeEmails;
                break;
            case 'pricing-management':
                canActivate = providerOrgType.includesPricingManagement;
                break;
            case 'pricing-overrides':
                canActivate = providerOrgType.includesPricingManagement && providerOrgType.includesPricingOverrides;
                break;
        }

        if (!canActivate) {
            this.router.navigate(['/404', {}]);
        }

        return canActivate;
    }

}
