import { AfterContentInit, AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';
import { SecurityService } from '../../../edtell-portal/services/security.service';
import { EntityControlConfig } from '../../../edtell-controls/interfaces/entity-control-config';
import { EdtellTabsetConfig } from '../../interface/edtell-tabset.config';
import { EdtellTabComponent } from './edtell-tab/edtell-tab.component';

@Component({
  selector: 'app-edtell-tabset',
  templateUrl: './edtell-tabset.component.html',
  styleUrls: ['./edtell-tabset.component.scss']
})
export class EdtellTabsetComponent implements OnInit, AfterContentInit {

  @Input()
  config?: EdtellTabsetConfig;
  
  @ContentChildren(EdtellTabComponent) edtellTabs: QueryList<EdtellTabComponent>;

  tabComponentArray: EdtellTabComponent[];

  activeTabComponent: EdtellTabComponent;

  security: SecurityConfig;

  entityControlConfig: EntityControlConfig;

  constructor(protected securityService: SecurityService, protected route: ActivatedRoute) { }

  ngOnInit() {
    if (this.config) {
      if (this.config.security) {
        this.security = this.config.security;
      }
    }
  }

  async ngAfterContentInit() {
    this.tabComponentArray = this.edtellTabs.toArray();

    // If there is a default tab
    if (this.config && this.config.defaultTab) {
      for (let tabComponent of this.tabComponentArray) {
        if (this.config.defaultTab.toLowerCase() == tabComponent.title.toLowerCase()) {
          let pass = true;
          if (tabComponent.config && tabComponent.config.security) {
            pass = await this.securityService.hasPermission(this.route.snapshot, tabComponent.config.security);
          }
          if (pass) {
            this.activeTabComponent = tabComponent;
            tabComponent.tabOpen = true;
            this.setEntityControlConfig();
            return; // we are done
          } else {
            // if user does not have access to default tab, proceed to find default tab normally
            break;
          }
        }
      }
    }

    for (let tabComponent of this.tabComponentArray) {
      let pass = true;
      if (tabComponent.config && tabComponent.config.security) {
        pass = await this.securityService.hasPermission(this.route.snapshot, tabComponent.config.security);
      }
      if (!pass) {
        continue;
      }
      this.activeTabComponent = tabComponent;
      tabComponent.tabOpen = true;
      break;

    }

    this.setEntityControlConfig();
  }

  private setEntityControlConfig() {
    if (this.activeTabComponent && this.activeTabComponent.config && this.activeTabComponent.config.securityObject) {
      this.entityControlConfig = {
        securityObject: this.activeTabComponent.config.securityObject
      }
      return;
    }

    if (this.config && this.config.securityObject) {
      this.entityControlConfig = {
        securityObject: this.config.securityObject
      }
      return;
    }

    this.entityControlConfig = null;
  }

  private setTabActive(tab) {
    // if not already active
    if (!tab.tabOpen) {
      if (this.activeTabComponent) {
        this.activeTabComponent.tabOpen = false;
      }
      tab.tabOpen = true;
      this.activeTabComponent = tab;
      this.setEntityControlConfig();
      this.activeTabComponent.config?.selectTabCallback();
    }
  }

  public setTabActiveByTitle(title: string): EdtellTabComponent {
    for (let tab of this.tabComponentArray) {
      if (tab.title.toLowerCase() == title.toLowerCase()) {
        this.setTabActive(tab);
        return tab;
      }
    }
    return null;
  }
}
