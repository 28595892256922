import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { SrsFormState } from '../../../enumerations/form-state.enum';
import { SrsSelectionChangeEvent } from '../../../events/srs-selection-change-event.interface';
import { SrsSelectBoxConfig, SrsSelectOption } from '../../../interfaces/elements/srs-select-box-config.interface';
import { ValidatorConfig } from '../../../interfaces/utils/validator-config.interface';

@Component({
  selector: 'app-srs-select-box',
  templateUrl: './srs-select-box.component.html',
  styleUrls: ['./srs-select-box.component.scss']
})
export class SrsSelectBoxComponent extends SrsFormElement<SrsSelectBoxConfig<any>> implements OnDestroy {

  @Output()
  change: EventEmitter<SrsSelectionChangeEvent<any>> = new EventEmitter<SrsSelectionChangeEvent<any>>();
  originalControlValue: string;
  stateSubscribe: Subscription;
  size:number;
  multiple:boolean = false;

  constructor() { super(); }

  onElementInit() {
    this.config.placeholder = this.config.placeholder != null ? this.config.placeholder : "Select an Option";
    this.originalControlValue = this.control.value;

    this.config.settings?.size > 0 ? (this.config.settings.size > 25 ? this.size = 25 : this.size = this.config.settings.size) : this.size = 0;
    this.multiple = this.config.settings?.multiple ?? false;

    // If we change the state to READ, reset the originControlValue.
    // This insures that if an inactive selected option is changed, it will not show up if
    // the user edits the form again.
    this.stateSubscribe = this.config.group.onStateChange().subscribe(state => {
      if (state == SrsFormState.READ) {
        setTimeout(() => {
          this.originalControlValue = this.control.value;
        })
      }
    });

    this.determineInactiveOptionSelected();
  }

  onChange(event) {

    // dirty fix to a bug where setting an option value as null, the control
    // value ends up as a string down the line
    if (this.control.value == 'null') {
      this.control.setValue(null);
    }

    if (this.config.onChange !== undefined) {
      this.config.onChange(this.control);
    }
  }

  determineInactiveOptionSelected() {
    if (!this.config.settings?.allowInactiveOptions) {
      let validators: ValidatorFn[] = this.control.getValidators();
      let invalidValues: any [] = [];
      for (let option of this.config.options) {
        if (option.active != null && !option.active) {
          invalidValues.push(option.value)
        }
      }
      validators.push(this.invalidOptionSelectedValidator(invalidValues));
      this.control.setValidators(validators);

      let config: ValidatorConfig = {
        key: 'invalid option',
        message: 'You must assign a new value. The option is inactive.'
      }

      if (this.config.validators) {
        this.config.validators.push(config);
      }
      else {
        this.config.validators = [config];
      }
    }
  }

  isInvalid() {
    // console.log(!this.control.valid && (this.control.dirty || this.control.untouched))
    return !this.control.valid && (this.control.dirty || this.control.untouched);
  }

  getReadText(): string {
    let option = _.find(this.config.options, { value: this.control.value });
    // try converting the control value to a string
    if (option === undefined) {
      option = _.find(this.config.options, { value: this.control.value + "" });
    }
    // try convering the control value to a number
    if (option === undefined) {
      option = _.find(this.config.options, { value: +this.control.value });
    }

    // try by text if option is still undefined
    if (option === undefined) {
      option = _.find(this.config.options, { text: this.control.value }) as SrsSelectOption<any>; // throws error without casting
    }

    // if STILL undefined, nothing selected
    if (option === undefined) {
      return "";
    }

    let text;
    if (option.text === undefined) {
      text = option.value;
    } else {
      text = option.text;
    }
    return text;
  }

  getOptionValue(option: SrsSelectOption<any>) {
    if (option.allowNullValue) {
      return option.value;
    }
    return option.value != null ? option.value : option.text;
  }

  optionDisabled(option: SrsSelectOption<any>): boolean {
    // if option is inactive - inactive option will only show if it was the original value
    if (option.active != undefined && !option.active) {
      return true;
    }
    if (option.disabled != undefined) {
      if (typeof option.disabled == "boolean") {
        return option.disabled;
      }
      return option.disabled();
    }
    return false;
  }

  get placeholder(): string {
    return this.config.placeholder;
  }

  get visibleOptions(): SrsSelectOption<any>[] {
    let options = this.config.options;
    if (!options) {
      return []
    }
    return options.filter(option => {
      let optionValue = this.getOptionValue(option);
      if (optionValue == this.originalControlValue || optionValue == this.control.value) {
        return true;
      }
      else return option.active == undefined ? true : option.active;
    });
  }

  ngOnDestroy() {
    this.stateSubscribe.unsubscribe();
  }

  private invalidOptionSelectedValidator(invalidValues: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return invalidValues.includes(control.value) ? { 'invalid option' : true } : null;
    };
  }
}
