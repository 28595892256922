import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EmitterEvent } from '../enums/emitter-events.enum';
import { EventEmitterService } from './event-emitter.service';

@Injectable({ providedIn: "root" })
export class PreviousRouteService {

  private readonly MAX_HISTORY_AMOUNT = 5;

  private initialRoute = window.location.pathname;
  private currentRoute: string
  private lastRemovedRoute : string
  private previousRoutes: string[] = []

  constructor(private router: Router, private eventEmitterService : EventEmitterService) {
    this.currentRoute = router.url; // more reliable than router.url for initial path
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url != this.currentRoute) {
        if(this.currentRoute != this.lastRemovedRoute){
          this.addRoute(this.currentRoute)
        }
        this.currentRoute = event.url
      }
    });

    this.eventEmitterService.subscribe(EmitterEvent.SIGN_OUT, () => {
      this.initialRoute = "/";
      this.currentRoute = "/"
      this.previousRoutes = []
      this.lastRemovedRoute = null;
      console.log("Wiping router data")
    })
  }

  /**
   * Adds a url to the history
   */
  public addRoute(url: string) {
    this.previousRoutes.push(url);
    if (this.previousRoutes.length > this.MAX_HISTORY_AMOUNT) {
      this.previousRoutes.shift();
    }
  }

  /**
   * Removes a url from the url histroy
   * @param url Url to remove
   */
  public removeRoute(url: string) {
    this.lastRemovedRoute = url;
    this.previousRoutes = this.previousRoutes.filter((item) => { return item != url })
  }

  /** 
   * Returns the previous viable url.
   * Will not return a url if the page resulted in a 404 or 403.
   * If the user navigated in on a url that throws an error this returns "".
   * This would take you to HomeComponent.
   */
  public getPreviousRoute() {
    return this.previousRoutes.length > 0 ? this.previousRoutes[this.previousRoutes.length - 1] : ""
  }

  /**
   * Will return the currently tracked url history.
   */
  public getRouteHistory() {
    return this.previousRoutes;
  }

  public getInitialRoute(){
    return this.initialRoute;
  }

}

// Src: https://community.wia.io/d/22-access-the-previous-route-in-your-angular-5-app
