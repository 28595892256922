import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from "@angular/router";
import { SidebarConfig } from "../interfaces/sidebar-config.interface";
import { EmitterEvent } from "../../edtell-portal/enums/emitter-events.enum";
import { EventEmitterService } from "../../edtell-portal/services/event-emitter.service";

@Injectable({
    providedIn: "root"
})
export class RouterRefreshGuard implements CanActivateChild{

    activeSidebarConfig : SidebarConfig

    constructor(private eventEmitterService : EventEmitterService){}

    async canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
          
            let routeConfig = await this.findSidebarConfig(next)

            this.activeSidebarConfig = routeConfig;
            
            return true;
      }

      async findSidebarConfig(route : ActivatedRouteSnapshot){

        let node = route;

        while(node != null){
            
            let data = node.data;
            if(data == null || data.sidebar == null){
                node = node.parent;
                continue;
            }

            return data.sidebar
        }

        return null;
    }

}