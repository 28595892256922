import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, ParamMap, PRIMARY_OUTLET, Router, UrlSegment } from "@angular/router";
import { filter, map } from "rxjs/operators";

type NavigationResult = {
  path : string
  queryParams? : ParamMap
}

@Injectable({
  providedIn: "root",
})
export class EdtellLocationService {

  root: NavigationResult;
  current : NavigationResult;
  previous: NavigationResult;

  constructor() {}

}
