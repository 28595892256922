<div class="btn-group-vertical w-100">
  <ng-container *ngFor="let b of buttons">
      <ng-template sidebarHelper *ngIf="b.customButtonComponent != null; else normalButton">
      </ng-template>
      <ng-template #normalButton>
          <app-sidebar-button
            class="w-100"
            [activeButton]="activeButton"
            [buttonConfig]="b"
            [sidebarConfig]="config"
            [hiddenMap]="hiddenMap"
            [disabledMap]="disabledMap"
          ></app-sidebar-button>
      </ng-template>
  </ng-container>
</div>
