<h1 mat-dialog-title style="border-bottom: 1px solid black">
  {{data.title}} - Comments and Discussions
</h1>
<div mat-dialog-content>

  <div class="create-comment-container">
    <textarea class="form-control" [(ngModel)]="newCommentText"></textarea>
    <button class="btn btn-tertiary srs-save-btn" (click)="addComment()">Share Comment</button>
  </div>


  <div class="comment-tree-container" *ngFor="let node of data.commentData; index as i">
    <app-comments-tree-node [node]="node" [objectId]="data.objectId" [itemId]="data.itemId" [index]="i"
                            (endMe)="deleteRootNode($event)"></app-comments-tree-node>
  </div>

</div>
<div mat-dialog-actions style="border-top: 1px solid black;">
  <button class="btn btn-tertiary srs-cancel-btn" (click)="onNoClick()">Close</button>
  <!--  The data being referenced in the mat-dialog-close is what gets returned by the dialog reference -->
  <!--  <button mat-stroked-button [mat-dialog-close]="data.commentData">Save</button>-->
</div>
