import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { falsey } from '../../../../edtell-utilities/functions/falsey.function';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { SrsTextAreaConfig } from '../../../interfaces/elements/srs-textarea-config.interface';

@Component({
  selector: 'app-srs-text-area',
  templateUrl: './srs-text-area.component.html',
  styleUrls: ['./srs-text-area.component.scss']
})
export class SrsTextAreaComponent extends SrsFormElement<SrsTextAreaConfig> {

  @ViewChild("textArea") 
  textAreaElement: ElementRef;

  constructor() {
    super();
  }

  onElementInit() {
    if(falsey(this.config.autoHeightToContent)) {
      this.config.startingRows = this.config.startingRows != null && this.config.startingRows > 0 ? this.config.startingRows : 1;
      return;
    }
  }

  get currCharacterCount(): number {
    if (this.control.value === null) {
      return 0;
    }
    return this.control.value.length;
  }

  get maxCharWarningClass(): string {
    return this.config.maxCharacters - this.currCharacterCount < 21 ? 'max-char-warning' : '';
  }

  get noCharsLeftClass(): string {
    return this.config.maxCharacters === this.currCharacterCount ? 'no-chars-left' : '';
  }

  onClick(event: any) {
    this.config.onClick != null ? this.config.onClick(this.control) : null;
  }

  resizeHeightToContent() {
    if(!falsey(this.config.autoHeightToContent)) {
      this.textAreaElement.nativeElement.style.height = "1px";
      this.textAreaElement.nativeElement.style.height = (25+this.textAreaElement.nativeElement.scrollHeight)+"px";
    }
  }

}
