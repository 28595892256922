<div class="row" *ngIf="loaded">
  <div class="col-12">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Date Created</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of distributions">
          <td>
            {{ d.name }}
          </td>
          <td>
            {{ d.type }}
          </td>
          <td>
            {{ d.dateCreated }}
          </td>
          <td>
            {{ d.success ? "Success" : "Failure" }}
          </td>
          <td>
            <ng-template [ngIf]="d.success">
              <container-element [ngSwitch]="d.type">
                <a *ngSwitchCase="'download'" [routerLink]="" (click)="downloadFile(d)"
                  >Download {{ d.additionalData.fileName }}</a
                >
                <a *ngSwitchCase="'email'" [routerLink]="" (click)="switchView(d)">View Email</a>
                <a *ngSwitchCase="'google-chat'" [routerLink]="" (click)="switchView(d)">View Message</a>
              </container-element>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
