import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AssetPickerDialogComponent } from '../../../../edtell-asset-management/components/asset-picker-dialog/asset-picker-dialog.component';
import { Asset } from '../../../../edtell-asset-management/interfaces/asset.interface';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { SrsFormState } from '../../../enumerations/form-state.enum';
import { EntityControlButtonOption } from '../../../../edtell-controls/interfaces/entity-control-config';
import { SrsAssetPickerWidgetConfig } from '../../../interfaces/widgets/srs-asset-picker-widget-config';
import { AssetType } from '../../../enumerations/asset-type.enum';

@Component({
  selector: 'app-image-asset-picker-widget',
  templateUrl: './image-asset-picker-widget.component.html',
  styleUrls: ['./image-asset-picker-widget.component.scss']
})
export class ImageAssetPickerWidgetComponent extends SrsFormWidget<SrsAssetPickerWidgetConfig>{

  @Input()
  state : SrsFormState

  imageUrl: SafeUrl
  
  asset: Asset;
  originalAsset: Asset;
  assetTypeId: number = 1000;

  constructor(
    private sanitizer: DomSanitizer,
    private dialog: MatDialog)
  { 
    super();
  }

  onWidgetInit() {
    this.getAsset();
    
    // cancel behavior
    let cancelButton: EntityControlButtonOption;
    for (let button of this.config.formControlConfig.entityControl.controlButtons) {
      if (button.text.toLowerCase() == 'cancel') {
        cancelButton = button;
        break;
      }
    }
    if (cancelButton) {
      let originalCallback = cancelButton.callback;
      cancelButton.callback = async () => {
        await originalCallback();
        this.resetComponent();
      }
    }
  }
  
  async getAsset() {
    this.config.newAsset = null;
    this.asset = await this.config.loadAssetCallback();
    if (this.asset != null) {
      let unsanitizedUrl = 'data:image/' + this.asset.assetType + ';base64,' + this.asset.assetBase64;
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsanitizedUrl);
      this.originalAsset = this.asset;
    } else {
      this.imageUrl = null;
    }
  }

  onChooseClick(){
    let dialogRef = this.dialog.open(AssetPickerDialogComponent, {
      width: '750px',
      data: { assetTypeId: [1000] }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp == null) {
        return;
      }
      if(this.asset != null && !confirm('There is already an existing asset. Are you sure you want to overwrite it?')){
        return;
      }
      this.config.formGroup.get(this.config.key).setValue(resp.id);
      this.imageUrl = resp.image;
      this.asset = resp;
      this.config.newAsset = null;
    });
  }

  onUploadClick(){

  }

  onFileChanged(event) {
    if (this.asset === null || confirm('There is already an existing asset. Are you sure you want to overwrite it?')) {
      console.log(event.target.files[0]);
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let ImageURL = reader.result.toString();

        // Split the base64 string in data and contentType
        let block = ImageURL.split(';');

        // Get the content type of the image
        let contentType = block[0].split(':')[1]; // In this case "image/gif"

        // get the real base64 content of the file
        let realData = block[1].split(',')[1];
        
        this.imageUrl = ImageURL;
        this.asset = {
          assetName: file.name,
          assetType: file.type.substring(6, file.type.length),
          assetSize: file.size,
          assetBase64: realData,
          assetFileType: contentType,
          assetTypeId: this.assetTypeId
        };

        this.config.newAsset = this.asset;
        
        this.config.formGroup.get(this.config.key).setValue(file.id);

      };
    }
  }
  
  onRemoveClick() {
    if (confirm('Are you sure you want to remove the asset?')) {
      this.imageUrl = null;
      this.asset = null;
      this.config.formGroup.get(this.config.key).setValue(null);
      this.config.newAsset = null;
    }
  }
  

  resetComponent() {
    this.asset = this.originalAsset;
    this.getAsset();
  }
}
