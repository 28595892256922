import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { EdtellMaterialModule } from '../material/material.module';
import { SrsSecurityModule } from "../srs-security/srs-security.module";
import { AgButtonComponent } from './components/ag-button/ag-button.component';
import { AgLinkComponent } from './components/ag-link/ag-link.component';
import { EntityControlLinkComponent } from './components/entity-control-link/entity-control-link.component';
import { EntitySelectComponent } from './components/entity-select/entity-select.component';
import { GridClearButtonComponent } from './components/grid-clear-button/grid-clear-button.component';
import { GridResetButtonComponent } from './components/grid-reset-button/grid-reset-button.component';
import { StatusBarPlusComponent } from './components/status-bar-plus/status-bar-plus.component';
import { AgRadioCellComponent } from './components/ag-radio-cell/ag-radio-cell.component';
import { EdtellAgGridComponent } from './components/edtell-ag-grid/edtell-ag-grid.component';
import { SrsFormsModule } from '../srs-forms/srs-forms.module';
import { EdtellControlsModule } from '../edtell-controls/edtell-controls.module';
import { EdtellAgGridFullScreenDialogComponent } from './components/edtell-ag-grid/edtell-ag-grid-full-screen-dialog/edtell-ag-grid-full-screen-dialog.component';
import { EdtellAgGridSettingsDialogComponent } from './components/edtell-ag-grid/edtell-ag-grid-settings-dialog/edtell-ag-grid-settings-dialog.component';
import { ViewGridSettingsComponent } from './components/view-grid-settings/view-grid-settings.component';
import { ViewFullScreenGridComponent } from './components/view-full-screen-grid/view-full-screen-grid.component';



@NgModule({
  declarations: [
    EntitySelectComponent,
    StatusBarPlusComponent,
    GridResetButtonComponent,
    GridClearButtonComponent,
    EntityControlLinkComponent,
    AgButtonComponent,
    AgLinkComponent,
    AgRadioCellComponent,
    EdtellAgGridComponent,
    EdtellAgGridFullScreenDialogComponent,
    EdtellAgGridSettingsDialogComponent,
    ViewGridSettingsComponent,
    ViewFullScreenGridComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SrsSecurityModule,
    AgGridModule,
    EdtellControlsModule,
    EdtellMaterialModule,
    SrsFormsModule
  ],
  exports: [
    EntitySelectComponent,
    StatusBarPlusComponent,
    GridResetButtonComponent,
    GridClearButtonComponent,
    EntityControlLinkComponent,
    AgButtonComponent,
    AgLinkComponent,
    EdtellAgGridComponent
  ]
})
export class EdtellAgGridModule { }
