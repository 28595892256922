import { Component } from '@angular/core';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { EdtellFormControl } from '../../../classes/edtell-form-control';
import { SrsDateRangeConfig } from '../../../interfaces/elements/srs-date-range-config.interface';
import { SrsDateTimePickerConfig } from '../../../interfaces/elements/srs-datetime-picker-config';
import { SrsFormFieldConfig } from '../../../interfaces/elements/srs-form-field-config.interface';

@Component({
  selector: 'app-srs-date-range',
  templateUrl: './srs-date-range.component.html',
  styleUrls: ['./srs-date-range.component.scss'],
})
export class SrsDateRangeWidgetComponent extends SrsFormWidget<SrsDateRangeConfig> {
  minControl: EdtellFormControl;
  maxControl: EdtellFormControl;

  minConfig: SrsDateTimePickerConfig;
  maxConfig: SrsDateTimePickerConfig;

  constructor() {
    super();
  }

  onWidgetInit() {
    this.minControl = <EdtellFormControl>this.config.formGroup.get(this.config.minConfig.key);
    this.maxControl = <EdtellFormControl>this.config.formGroup.get(this.config.maxConfig.key);

    let minValidators = this.minControl.getValidators();
    let maxValidators = this.maxControl.getValidators();

    minValidators.push(this.rangeValidator());
    maxValidators.push(this.rangeValidator());

    this.minControl.setValidators(minValidators);
    this.maxControl.setValidators(maxValidators);

    this.config.minConfig.validators = this.config.minConfig.validators || [];
    this.config.minConfig.validators.push({
      key: 'valueError',
      message: this.config.minErrorMessage,
    });

    this.config.maxConfig.validators = this.config.maxConfig.validators || [];
    this.config.maxConfig.validators.push({
      key: 'valueError',
      message: this.config.maxErrorMessage,
    });

    // TODO change the minConfig and maxConfig setup to allow for mutations after init
    // TODO potentially set these on a subscriber to rerun when changes to the config are made
    this.minConfig = {
      key: this.config.minConfig.key,
      title: this.config.minConfig.title,
      group: this.config.minConfig.group == null ? this.config.formGroup : this.config.minConfig.group,
      size: this.config.minConfig.size,
      validators: this.config.minConfig.validators,
      dateTime: this.config.minConfig.dateTime,
      onInput: e => {
        if (this.config.minConfig.onInput) {
          this.config.minConfig.onInput(e);
        }
        this.config.formGroup.get(this.maxConfig.key).updateValueAndValidity();
      },
      onChange: this.config.minConfig.onChange,
      disabled: this.config.minConfig.disabled,
      settings: {
        ...this.config.minConfig.settings,
        showRequired: this.config.minConfig.settings != null ? this.config.minConfig.settings.showRequired : false,
      },
    };

    // set the min and max date for the minConfig if either exists
    if (this.config.minConfig.min) {
      this.minConfig.min = this.config.minConfig.min;
    }
    if (this.config.minConfig.max) {
      this.minConfig.max = this.config.minConfig.max;
    }

    this.maxConfig = {
      key: this.config.maxConfig.key,
      title: this.config.maxConfig.title,
      size: this.config.maxConfig.size,
      group: this.config.maxConfig.group == null ? this.config.formGroup : this.config.maxConfig.group,
      validators: this.config.maxConfig.validators,
      dateTime: this.config.maxConfig.dateTime,
      onInput: e => {
        if (this.config.maxConfig.onInput) {
          this.config.maxConfig.onInput(e);
        }
        this.config.formGroup.get(this.minConfig.key).updateValueAndValidity();
      },
      onChange: this.config.maxConfig.onChange,
      disabled: this.config.maxConfig.disabled,
      settings: {
        ...this.config.maxConfig.settings,
        showRequired: this.config.maxConfig.settings != null ? this.config.maxConfig.settings.showRequired : false,
      },
    };

    // set the min and max date for the maxConfig if either exists
    if (this.config.maxConfig.min) {
      this.maxConfig.min = this.config.maxConfig.min;
    }
    if (this.config.maxConfig.max) {
      this.maxConfig.max = this.config.maxConfig.max;
    }

    this.minControl.valueChanges.subscribe(() => {
      this.maxControl.updateValueAndValidity({ emitEvent: false });
    });

    this.maxControl.valueChanges.subscribe(() => {
      this.minControl.updateValueAndValidity({ emitEvent: false });
    });

    this.config.minConfig.group =
      this.config.minConfig.group == null ? this.config.formGroup : this.config.minConfig.group;
    this.config.maxConfig.group =
      this.config.maxConfig.group == null ? this.config.formGroup : this.config.maxConfig.group;
  }

  rangeValidator() {
    return () => {
      if (
        this.minControl.value == null ||
        this.maxControl.value == null ||
        this.minControl.value == '' ||
        this.maxControl.value == '' ||
        +this.minControl.value == NaN ||
        +this.maxControl.value == NaN
      ) {
        return null;
      }

      if (this.minControl.value > this.maxControl.value) {
        return {
          valueError: 'valueErr',
        };
      }
    };
  }

  get columnSize() {
    return this.minConfig.size == 12 ? 'col-sm-12' : 'col-sm-6';
  }
}
