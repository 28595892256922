import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SecurityService } from "../../edtell-portal/services/security.service";
import { EdtellColumnDef } from "../interfaces/edtell-column-def.interface";

@Injectable({
    providedIn: 'root'
})
export class EdtellAgGridService {
  
    constructor(
        private securityService: SecurityService,
        private route: ActivatedRoute
    ) {   }

    async filterColumnDefsByUserPermission(columnDefs: EdtellColumnDef[]): Promise<EdtellColumnDef[]> {
        let accessColumnDefs: EdtellColumnDef[] = [];

        for (let columnDef of columnDefs) {
            if (columnDef.security) {
                if (await this.securityService.hasPermission(this.route.snapshot, columnDef.security)) {
                    accessColumnDefs.push(columnDef);
                }
                continue;
            }
            // If no security config assume access
            accessColumnDefs.push(columnDef);
        }
        return accessColumnDefs;
    }


}
  