<div [formGroup]="formGroup" [ngSwitch]="config.group.state">
    <ng-container *ngSwitchCase="'READ'">
        <div [class]="formGroupClass">
            <label *ngIf="!hideTitle && config.title">{{config.title}}</label>
            <input class="form-control" [type]="dateType" [edtellTooltip]="tooltip" [id]="elementId" [formControlName]="config.key" [ngClass]="class" readonly>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase='"WRITE"'>
        <div [class]="formGroupClass">
            <label *ngIf="!hideTitle">{{config.title}} <span class="required-asterisk">{{ required ? "*" : "" }}</span></label>
            <input patternInput [edtellTooltip]="tooltip" [id]="elementId" [ngClass]="['form-control', validationClass, disabled ? 'disabledHover' : ''].concat(class)"
                [formControlName]="config.key" [autoFocus]="autoFocus" [disableControl]="disabled" [type]="dateType"
                [min]="min" [max]="max"
                (change)="onChange()"
            >
            <small class="form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
            <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
                    <div class="invalid-feedback d-block" *ngIf="hasError(v.key) == true">{{v.message}}</div>
            </ng-template>

            <div *ngFor="let alert of config.alerts">
                <div class="row mt-2" *ngIf="alert.showAlertMessage()">
                    <div class="col-12 alert-message">
                        <i class="fas fa-exclamation-triangle"></i> 
                        {{alert.message}}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>