<div [formGroup]="config.group" [ngSwitch]="config.group.state">

    <ng-container *ngSwitchCase="'READ'">
        <div [class]="formGroupClass">
            <label *ngIf="!hideTitle && config.title">{{config.title}}</label>
            <input [edtellTooltip]="tooltip" [id]="elementId" class="form-control" [value]="getReadText()" readonly>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'WRITE'">
        <div [class]="formGroupClass" [ngClass]="[hiddenTitlePadding ? 'hidden-title-padding' : '']">
            <label *ngIf="!hideTitle && config.title">{{config.title}} <span
                    class="required-asterisk">{{ required ? "*" : "" }}</span></label>
            <select *ngIf="multiple" [multiple]="multiple" [size]="size" [edtellTooltip]="tooltip" [id]="elementId" [ngClass]="['form-control', validationClass]"
                (change)="onChange($event)" [formControlName]="config.key"
                [autoFocus]="autoFocus" [disableControl]="disabled">
                <option id="option-null" hidden [value]="null">{{ placeholder}}</option>
                <option class="all-copy" [id]="'option-' + o.text + '-' + elementId" *ngFor="let o of visibleOptions; index as i"
                    [disabled]="optionDisabled(o)" [value]="getOptionValue(o)">{{o.text}}</option>
            </select>
            <select *ngIf="!multiple" [size]="size" [edtellTooltip]="tooltip" [id]="elementId" [ngClass]="['form-control', validationClass]"
        (change)="onChange($event)" [formControlName]="config.key"
                [autoFocus]="autoFocus" [disableControl]="disabled">
                <option id="option-null" hidden [value]="null">{{ placeholder}}</option>
                <option class="all-copy" [id]="'option-' + o.text + '-' + elementId" *ngFor="let o of visibleOptions; index as i"
                    [disabled]="optionDisabled(o)" [value]="getOptionValue(o)">{{o.text}}</option>
            </select>
            <small class="form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
            <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
                <div class="invalid-feedback d-block" *ngIf="hasError(v.key) == true">{{v.message}}</div>
            </ng-template>
        </div>
    </ng-container>

</div>
