import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SecurityService } from "./security.service";
import { NavigationSegmentDataInterface } from "../interfaces/navigation-segment-data.interface";
import { LoginService } from "./login.service";
import { apiURL } from "app-environment";
import { NavigationElement } from "../../edtell-admin/interfaces/navigation-element.interface";
import { NavElementsBySegmentResult } from "../../edtell-admin/interfaces/nav-elements-by-segment-result.interface";
import { NavigationSegment } from "../../edtell-admin/interfaces/navigation-segment.interface";
import { AccessEventRequest } from "../interfaces/access-event-request.interface";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  httpOptions: {};

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private securityService: SecurityService,
    private router: Router
  ) {
    this.httpOptions = loginService.httpOptions;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var info = <NavigationEnd>event;
        let req = this.createAccessEventRequest(info.url);
        this.securityService.createAccessEvent(req).subscribe(() => {});
      }
    });

    let req = this.createAccessEventRequest(this.router.url);
    this.securityService.createAccessEvent(req).subscribe(() => {})
  }

  createAccessEventRequest(rawUrl: string): AccessEventRequest {
    let urlParts = rawUrl.split('?');
    let url = urlParts[0];
    let queryParams = urlParts[1] ? urlParts[1] : null;
    return {
      url: url,
      queryParams: queryParams
    }
  }

  // Gets Tree Structure for Navigation
  getNavigationBySegmentId(segmentId) {
    return this.http.get<NavigationElement[]>(
      `${apiURL}/navigation-app/navigation/map?segmentId=${segmentId}`,
      this.httpOptions
    );
  }

  // Gets the segment object based on the id
  getSegmentById(id) {
    return this.http.get<NavigationSegmentDataInterface>(
      `${apiURL}/navigation-app/navigation/segment?id=${id}`,
      this.httpOptions
    );
  }

  // Gets All the Navigation Elements
  getNavigationData() {
    return this.http.get(
      `${apiURL}/navigation-app/navigation/element/all`,
      this.httpOptions
    );
  }

  getAllNavElementsBySegment(id) {
    return this.http.get<NavElementsBySegmentResult[]>(
      `${apiURL}/navigation-app/navigation/element/segment?segmentId=${id}`,
      this.httpOptions
    );
  }

  // Gets all of the Navigation Segments
  getNavSegments() {
    return this.http.get<NavigationSegment[]>(
      `${apiURL}/navigation-app/navigation/segment/all`,
      this.httpOptions
    );
  }

  getHasChild(id: number) {
    return this.http.get<boolean>(
      `${apiURL}/navigation-app/navigation/element/has-child?id=${id}`,
      this.httpOptions
    );
  }

  // ==========================

  createNavSegment(item) {
    return this.http.post(
      `${apiURL}/navigation-app/navigation/segment`,
      item,
      this.httpOptions
    );
  }

  updateNavSegment(item) {
    return this.http.put(
      `${apiURL}/navigation-app/navigation/segment`,
      item,
      this.httpOptions
    );
  }

  deleteNavSegment(id) {
    return this.http.delete(
      `${apiURL}/navigation-app/navigation/segment?id=${id}`,
      this.httpOptions
    );
  }

  deleteElement(id) {
    return this.http.delete(
      `${apiURL}/navigation-app/navigation/element?elementId=${id}`,
      this.httpOptions
    );
  }

  canDeleteNavSegment(id) {
    return this.http.get(
      `${apiURL}/navigation-app/navigation/segment/can-delete?id=${id}`,
      this.httpOptions
    );
  }

  // ======================================

  createNavElement(item) {
    if (item.name.endsWith(" ")) {
      item.name = item.name.slice(0, -1);
    }

    return this.http.post<
      [
        {
          children: object;
          createdBy: number;
          dateCreated: string;
          dateModified: string;
          description: string;
          elementObjectId: number;
          id: number;
          modifiedBy: number;
          name: string;
          navSegmentId: number;
          order: number;
          owner: number;
          parentElementId: number;
          target: string;
          tooltip: string;
          url: string;
        }
      ]
    >(`${apiURL}/navigation-app/navigation/element`, item, this.httpOptions);
  }

  updateNavElement(item) {
    if (item.name.endsWith(" ")) {
      item.name = item.name.slice(0, -1);
    }

    return this.http.put(
      `${apiURL}/navigation-app/navigation/element`,
      item,
      this.httpOptions
    );
  }

  getNavElement(id) {
    return this.http.get<NavigationElement>(
      `${apiURL}/navigation-app/navigation/element?id=${id}`,
      this.httpOptions
    );
  }

  // ----------------------------------

  isAlive() {
    return this.http.get(
      `${apiURL}/administration-app/user/alive`,
      this.httpOptions
    );
  }

  createAccessEvent(url: string) {
    return this.http.post<boolean>(
      `${apiURL}/event-log-app/logging/access?url=${url}`,
      {},
      this.httpOptions
    );
  }
}
