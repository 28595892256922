import { AbstractControl } from "@angular/forms";
import { EdtellFormArray, EdtellFormGroup } from "../../srs-forms/classes/edtell-form-group.class";


export function updateTreeValidity(group: EdtellFormGroup | EdtellFormArray): void {
  for (let key of Object.keys(group.controls)) {
    const abstractControl: AbstractControl = group.controls[key];

    if (abstractControl instanceof EdtellFormGroup || abstractControl instanceof EdtellFormArray) {
      if (abstractControl instanceof EdtellFormGroup) {
        abstractControl.submitted = true;
      }

      updateTreeValidity(abstractControl)
    } else {
      abstractControl.updateValueAndValidity();
    }
  }
}