import {Component, HostBinding, Injector, Input, OnInit, Type, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {SidebarConditionalService} from '../../../abstracts/sidebar-conditional-service.abstract';
import {SidebarIndicatorDirective} from '../../../directives/sidebar-indicator.directive';
import {ButtonConditionalConfig, SidebarButtonConfig, SidebarConfig} from '../../../interfaces/sidebar-config.interface';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss']
})
export class SidebarButtonComponent implements OnInit {

  @ViewChild(SidebarIndicatorDirective)
  sidebarIndicator: SidebarIndicatorDirective;

  @Input()
  buttonConfig: SidebarButtonConfig;

  @Input()
  activeButton: SidebarButtonConfig;

  @Input()
  sidebarConfig: SidebarConfig;

  @Input()
  hiddenMap: Map<SidebarButtonConfig, boolean> = new Map();

  @Input()
  disabledMap: Map<SidebarButtonConfig, boolean> = new Map();

  @HostBinding('class')
  classes = 'w-100';

  constructor(
    protected router: Router,
    protected injector: Injector,
  ) {
  }

  async ngOnInit() {

  }

  onButtonClick(b: SidebarButtonConfig) {
    if (this.activeButton == this.buttonConfig) {
      return;
    }
    // Grabs all router link params
    this.activeButton = b;

    let openInNewTab = this.buttonConfig.openInNewTab ?? false;

    if (openInNewTab) {
      window.open(this.getRouterLink(), '_blank');
    } else {
      // console.log(this.getRouterLink())
      this.router.navigate([this.getRouterLink()]);
    }


  }

  openDropdown(b: SidebarButtonConfig) {
    b._showDropdown = true;
  }

  closeDropdown(b: SidebarButtonConfig) {
    b._showDropdown = false;
    if (Array.isArray(b.route)) {
      for (let cb of b.route) {
        this.closeDropdown(cb);
      }
    }
  }

  previewDropdown(b: SidebarButtonConfig) {
    this.buttonConfig._showDropdown = true;
  }

  childrenShowing(b?: SidebarButtonConfig): boolean {
    if (b == null) {
      b = this.buttonConfig;
    }

    if (b._showDropdown) {
      return true;
    }

    if (!Array.isArray(b.route)) {
      return false;
    }

    for (let c of b.route) {
      if (c._showDropdown) {
        return true;
      }

      if (Array.isArray(c.route)) {
        for (let child of c.route) {
          if (this.childrenShowing(child)) {
            return true;
          }
        }
      }
    }

    return false;

  }

  private getRouterLink(): string {
    let routerLink = this.buttonConfig.route;
    if (typeof routerLink == 'string') {
      while (routerLink.includes(':')) {
        // Grab the param key
        let index = routerLink.indexOf(':');
        let paramKey = routerLink.substr(index);
        let endingIndex = paramKey.indexOf('/') - 1;

        paramKey = paramKey.substr(
          1,
          endingIndex > 0 ? endingIndex : paramKey.length
        );

        let value = this.sidebarConfig._route.paramMap.get(paramKey);
        routerLink = routerLink.replace(':' + paramKey, value);
      }
      return routerLink;
    }
  }

  async checkConditional(condition: Type<SidebarConditionalService> | ButtonConditionalConfig, check: 'hidden' | 'disabled') {
    if (!!condition['callback']) { // checking for the existance of callback function, ie: ButtonConditionalConfig
      let c = <ButtonConditionalConfig>condition;
      if ((c.type == check && await c.callback(this.router.routerState.snapshot.url))) {
        return true;
      }
    } else { // else: its a Conditional Service
      let ConditionalService = <Type<SidebarConditionalService>>condition;
      let service = this.injector.get(ConditionalService);
      let r = await service[check]();
      if (r) {
        return true;
      }
    }
    return false;
  }

  hasChildren(): boolean {
    return Array.isArray(this.buttonConfig.route);
  }

  async isHidden(config: SidebarButtonConfig) {
    for (let c of config.conditionals) {
      if (await this.checkConditional(c, 'hidden')) {
        return true;
      }
    }
    return false;
  }

  async isDisabled(config: SidebarButtonConfig) {
    for (let c of config.conditionals) {
      if (await this.checkConditional(c, 'disabled')) {
        return true;
      }
    }
    return false;
  }


}
