import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { SrsFormState } from '../../../enumerations/form-state.enum';
import { RadioButtonConfig, SrsRadioGroupConfig } from '../../../interfaces/elements/srs-radio-group.config.interface';

@Component({
  selector: 'app-srs-radio-button',
  templateUrl: './srs-radio-button.component.html',
  styleUrls: ['./srs-radio-button.component.scss']
})
export class SrsRadioButtonComponent extends SrsFormElement<SrsRadioGroupConfig> implements AfterViewInit, OnDestroy {

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('radioInput')
  radioInputs: QueryList<ElementRef>;

  formStateSubscription: Subscription;

  _name: string;

  constructor() {
    super();
  }

  onElementInit() {
    this.formStateSubscription = this.config.group.onStateChange().subscribe(state => {
      if (state === SrsFormState.WRITE) {
        setTimeout(() => {
          this.setNameAttributes()
        })
      }
    })

    this._name = this.getNameAttribute();
  }

  ngAfterViewInit() {
    if (this.config.group.state === SrsFormState.WRITE) {
      this.setNameAttributes();
    }
  }

  getNameAttribute(increment?: number) {
    let i = increment != null ? "-" + increment : ""; // increment value to append to element id
    let possibleName = this.config.key + i;

    let elems: NodeListOf<HTMLElement> = document.getElementsByName(possibleName);

    if (Array.from(elems).length < 1) {
      return possibleName;
    }

    return this.getNameAttribute(increment ? increment + 1 : 1);
  }

  // The name attribute is required for tabbing to work properly.
  // Name cannot be set on the template because the formControlName directive overrides the name attribute with
  // ng-reflect-name - which breaks tabbing in some cases with multiple radio groups. Doing it programatically
  // ensures that the name attribute is on the element.
  setNameAttributes() {
    for (let radio of this.radioInputs.toArray()) {
      radio.nativeElement.setAttribute('name', this._name);
    }
  }

  onChange() {
    this.config.onChange != null ? this.config.onChange(this.control) : null;
  }

  checkDeselect() {}

  getHint() {

    if (typeof this.config.hint == 'string') {
      return this.config.hint;
    }

    return (<any>this.config.hint)(this.control);
  }

  getReadText(): string {

    let selectedOption = _.find(this.config.options, {value: this.control.value});

    if (selectedOption === undefined) {
      return '';
    }

    let text;

    if (selectedOption.text === undefined) {
      text = selectedOption.value;
    } else {
      text = selectedOption.text;
    }

    return text;
  }

  getLabelOrientation() {
    return this.config.settings != null && this.config.settings.horizontalLabel == true;
  }

  areOptionsVertical() {
    return this.config.settings != null && this.config.settings.verticalOptions == true;
  }

  getValue(option: RadioButtonConfig) {
    if (option.allowNullValue) {
      return option.value;
    }
    return option.value ?? option.text;
  }

  get hasRequiredError() {
    return this.config.validators != null && this.config.validators.find(x => x.key === 'required') != null;
  }

  get requiredError() {
    return this.config.validators.find(x => x.key === 'required');
  }

  ngOnDestroy() {
    this.formStateSubscription.unsubscribe();
  }

  findUniqueElementId(increment?: number) {
    let i = increment != null ? "-" + increment : ""; // increment value to append to element id
    let possibleId = 'input-' + this.config.key + i;

    let elm = document.getElementById(possibleId + "-option-0");
    if (elm != null) {
        return this.findUniqueElementId(increment != null ? increment + 1 : 1);
    }

    return possibleId;
  }

  hasAlert() {
    let alerts = this.config.alerts ?? [];
    for (let alert of alerts) {
      if (alert.showAlertMessage()) {
        return true;
      }
    }
    return false;
  }

}
