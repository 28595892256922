import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { cloneDeep } from "lodash";
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { SrsFormState } from '../../../enumerations/form-state.enum';
import { SrsRangeSelectorConfig } from '../../../interfaces/elements/srs-range-selector-config.interface';
import { SrsSelectBoxConfig, SrsSelectOption } from '../../../interfaces/elements/srs-select-box-config.interface';

// TODO required asterisk
@Component({
  selector: 'app-srs-range-selector',
  templateUrl: './srs-range-selector.component.html',
  styleUrls: ['./srs-range-selector.component.scss']
})
export class SrsRangeSelectorComponent extends SrsFormElement<SrsRangeSelectorConfig<any>> {

  @Output()
  minChange : EventEmitter<any> = new EventEmitter<any>();

  @Output()
  maxChange : EventEmitter<any> = new EventEmitter<any>();

  minConfig: SrsSelectBoxConfig<string>
  maxConfig: SrsSelectBoxConfig<string>

  minOptions : SrsSelectOption<any>[]
  maxOptions : SrsSelectOption<any>[]

  minControl: AbstractControl
  maxControl: AbstractControl

  constructor() {
    super();
  }

  onElementInit() {

    let placeholder = "-";
    if (this.config.placeholder) {
      placeholder = this.config.placeholder;
    }

    this.minControl = this.config.group.get(this.config.minKey)
    this.maxControl = this.config.group.get(this.config.maxKey)

    this.minOptions = cloneDeep(this.config.options)
    this.maxOptions = cloneDeep(this.config.options)

    this.minConfig = {
      group: this.config.group,
      key: this.config.minKey,
      size: 12,
      options: this.minOptions,
      placeholder: placeholder,
      validators: this.config.minValidators,
      settings: this.config.minSettings
    }

    this.maxConfig = {
      group: this.config.group,
      key: this.config.maxKey,
      size: 12,
      options: this.maxOptions,
      placeholder: placeholder,
      validators: this.config.maxValidators,
      settings: this.config.maxSettings
    }

    // set initial disabled elements
    let minValue = this.minControl.value;
    let maxValue = this.maxControl.value;

    if (minValue !== null) {
      this.setMaxDisabledElements();
    }

    if (maxValue !== null) {
      this.setMinDisabledElements();
    }

    this.minControl.valueChanges.subscribe((value) => {
      this.onMinChange(value)
    })
    this.maxControl.valueChanges.subscribe((value) => {
      this.onMaxChange(value)
    })

  }

  onMinChange(value : any){
    if (value == null) {
      this.setAllMaxEnabled();
    } else {
      this.setMaxDisabledElements();
    }
    this.minChange.emit(value)
  }

  onMaxChange(value : any){
    if (value == null) {
      this.setAllMinEnabled();
    } else {
      this.setMinDisabledElements();
    }
    this.maxChange.emit(value)
  }

  setDisableElementsInRange() {
    this.setMaxDisabledElements();
    this.setMinDisabledElements();
  }

  setMinDisabledElements() {

    for (let option of this.minConfig.options) {
      option.disabled = false;
    }

    for (let i = this.minConfig.options.length - 1; i > 0; i--) {
      let option = this.minConfig.options[i];
      if (option.value == this.maxControl.value) {
        break;
      }
      option.disabled = true;
    }
  }

  setMaxDisabledElements() {

    for (let option of this.maxConfig.options) {
      option.disabled = false;
    }

    for (let option of this.maxConfig.options) {
      if (option.value == this.minControl.value) {
        break;
      }
      option.disabled = true;
    }

  }

  setAllMinEnabled() {
    for (let option of this.minConfig.options) {
      option.disabled = false;
    }
  }

  setAllMaxEnabled() {
    for (let option of this.maxConfig.options) {
      option.disabled = false;
    }
  }

  isInvalid() {
    return this.minControl.valid && (this.minControl.dirty || this.minControl.touched) &&
      this.maxControl.valid && (this.maxControl.dirty || this.maxControl.touched)
  }

  get required(): boolean {
    if (this.minControl.hasValidator(Validators.required) &&
        this.maxControl.hasValidator(Validators.required) &&
        this.config.group.state === SrsFormState.WRITE) {
      return true;
    }
  }

}
