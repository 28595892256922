<!--Title -->
<div class="row" *ngIf="config.title != null">
  <div class="col-md-12">
    <div class="title-container label-bold">{{config.title}}</div>
  </div>
</div>

<div class="form-group">
  <app-asset-download-link [assetDownloadLinkData]="asset"></app-asset-download-link>

  <input style="display: none" type="file" accept=".pdf" (change)="onFileChanged($event)" #fileInput>

  <!-- Edit Buttons EDIT MODE ONLY -->
  <div class="row" *ngIf='state != null && state == "WRITE"'>
    <div class="col-md-12">
      <button class="btn btn-tertiary mr-2" (click)="fileInput.click()">Upload</button>
      <button class="btn btn-tertiary mr-2" (click)="onChooseClick()">Choose</button>
      <button class="btn btn-tertiary" [disabled]="asset === null" (click)="onRemoveClick()">Remove</button>
    </div>
  </div>
</div>