import { ActivatedRoute } from "@angular/router";
import { GridApi } from "ag-grid-community";

/**
 * A quick function for programtically adding filter text on the grid column
 * @param gridApi 
 * @param column - key for the column on the grid
 * @param filterText - text to filter for
 * @param type - AgGrid text filter type (startsWith, endsWith, etc)
 */
export function setGridColumnFilter(gridApi : GridApi, column : string, filterText : string, type? : string){
    this.gridAPI.getFilterInstance(column).setModel({ filter: filterText, type: type != null ? type : "startsWith" })
}

export function setGridFilterQueryParams(config : GridFilterQueryParamsConfig){
    config.route.queryParams.subscribe((params) => {
        for(let p of config.params){
            config.gridApi.getFilterInstance(p.column).setModel({ filter: params[p.param], type: p.type != null ? p.type :"startsWith" })
            config.gridApi.onFilterChanged();
        }
    })
}


interface GridFilterQueryParamsConfig{
    route : ActivatedRoute,
    gridApi : GridApi
    params : GridFilterQueryParam[]
}

interface GridFilterQueryParam{
    column : string,
    param : string,
    type? : string
}