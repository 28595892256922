import { OnInit, Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import { InfoLinkConfig } from '../../edtell-utilities/interfaces/edtell-info-link-config.interface';
import { EdtellFormGroup } from '../classes/edtell-form-group.class';
import { SrsFormState } from '../enumerations/form-state.enum';
import { EntityControlButtonOption } from '../../edtell-controls/interfaces/entity-control-config';
import { NotificationService } from '../../edtell-portal/services/notification.service';
import { DialogActionType } from '../../edtell-portal/enums/dialog-action-type.enum';
import { updateTreeValidity } from '../../edtell-utilities/functions/update-tree-validity.function';
import { EntityFormControlConfig } from '../../edtell-controls/interfaces/entity-form-control-config';
import { EdtellDialogData, EdtellDialogResponseData } from '../interfaces/abstracts/edtell-dialog-data.interface';

@Directive()
export abstract class EdtellDialogComponent implements OnInit {

  // These public properties can be used in the dialog component/template.
  public actionTypeState: DialogActionType;
  public viewFormControlConfig: EntityFormControlConfig;
  public editFormControlConfig: EntityFormControlConfig;
  public addFormControlConfig: EntityFormControlConfig;

  // required to define abstract properties
  abstract dialogData: EdtellDialogData;
  // any type option is temporary so that dialogs dont break which are type FormGroup.
  // All dialogs should be refactored to use the EdtellFormGroup after form generation is implemented.
  abstract formGroup: EdtellFormGroup;
  abstract dialogRef: MatDialogRef<any>;
  // used for the notification service.
  abstract objectTitle: string;

  protected objectDataBeforeEdit: any;
  protected cancelButton: EntityControlButtonOption;
  protected editButton: EntityControlButtonOption;
  protected ownershipConfig: InfoLinkConfig;

  protected addSaveButton: EntityControlButtonOption;
  protected editSaveButton: EntityControlButtonOption;

  protected previousDialogAction = DialogActionType.CANCEL;

  /**
   * @param startingActionType requires initial action type to determine form control behavior.
   * @param notificationService needs to be passed through from subclass for snackbar.
   */
  constructor(startingActionType: DialogActionType, protected notificationService: NotificationService) {
    this.actionTypeState = startingActionType;
  }

  async ngOnInit() {
    this.dialogData.action = this.actionTypeState;

    await this.initForm();
    await this.initSecurityObjects();

    this.formGroup.state = this.actionTypeState === DialogActionType.VIEW ? SrsFormState.READ : SrsFormState.WRITE;

    if (this.actionTypeState === DialogActionType.VIEW) {
      this.dialogRef.disableClose = false;
    } else { // edit or add
      this.objectDataBeforeEdit = _.cloneDeep(this.formGroup.value);
      this.dialogRef.disableClose = true;
    }

    if (this.actionTypeState !== DialogActionType.ADD) {
      this.ownershipConfig = {
        object: this.dialogData.objectData,
        saveCallback: this.ownershipUpdate()
      };
    }
    await this.dialogOnInit();
  }

  /**
   * Use this method instead on ngOnInit in sub-class.
   */
  abstract dialogOnInit();

  /**
   * Should return an http request observable for creation of your object.
   */
  abstract create(reqBody: any): Observable<any>;

  /**
   * Should return an http request observable for updating of your object.
   */
  abstract update(reqBody: any): Observable<any>;

  /**
   * Should return an http request observable for deleting of your object.
   */
  abstract delete(id: number): Observable<any>

  /**
   * Function needs to be created but not called in subclass. Is called in intialization of EdtellDialogComponent.
   */
  abstract initForm();

  // Public Methods - May be overriden.
  // ######################################################

  onEditClick() {
    this.objectDataBeforeEdit = _.cloneDeep(this.formGroup.value);
    this.actionTypeState = DialogActionType.EDIT;
    this.dialogData.action = DialogActionType.EDIT;
    this.formGroup.state = SrsFormState.WRITE;
    this.dialogRef.disableClose = true;
    this.previousDialogAction = DialogActionType.VIEW;
  }

  onCancelClick() {
    // go back to view if we started there.
    if (this.previousDialogAction === DialogActionType.VIEW) {
      if (!this.formGroup.isOriginal) {
        if (!confirm('Do you want to cancel your changes?')) {
          return true;
        }
      }
      this.actionTypeState = DialogActionType.VIEW;
      this.dialogData.action = DialogActionType.VIEW;
      this.formGroup.state = SrsFormState.READ;
      this.dialogRef.disableClose = false;
      // this.formGroup.setValue(this.objectDataBeforeEdit);
      this.formGroup.resetValues();
      this.previousDialogAction = DialogActionType.EDIT;
    } else { // must have been edit or cancel (is cancel on dialog init)
      // in this case we want to close the dialog
      this.onNoClick();
    }

    return false;
  }

  cancelDialog() {
    this.dialogData.action = DialogActionType.CANCEL;
    this.dialogRef.close(this.dialogData);
  }



  /**
   * Material method required for defining behavior when clicking outside of the dialog.
   */
  onNoClick() {
    console.log("no click!")
    if (!this.formGroup.isOriginal) {
      if (confirm('There is unsaved data. Are you sure you want to close?')) {
        this.dialogData.action = DialogActionType.CANCEL;
        this.dialogRef.close(this.dialogData);
      }
    } else {
      this.dialogData.action = DialogActionType.CANCEL;
      this.dialogRef.close(this.dialogData);
    }
  }

  ownershipUpdate() {
    return (foo) => {
      this.update(this.dialogData.objectData).subscribe();
    };
  }

  submit() {
    this.formGroup.submitted = true;
    updateTreeValidity(this.formGroup);
    
    if (this.formGroup.valid) {
      for (let key in this.formGroup.controls) {
        this.dialogData.objectData[key] = this.formGroup.controls[key].value;
      }

      this.dialogRef.close(this.dialogData);
    }
  }

  /**
   * Save changes made in the dialog.
   * @param data Dialog data object
   * @returns any; response from http request. returns null if does not make a request.
   */
  save(data: EdtellDialogData): Observable<EdtellDialogResponseData> {
    data = _.cloneDeep(data);
    let func: Observable<any> = null;
    let notificationMessage: string = null;
    switch (data.action) {
      case DialogActionType.ADD:
        func = this.create(data.objectData);
        notificationMessage = this.objectTitle + ' successfully created.';
        break;
      case DialogActionType.EDIT:
        func = this.update(data.objectData);
        notificationMessage = this.objectTitle + ' successfully updated.';
        break;
      case DialogActionType.DEL:
        func = this.delete(data.objectData.id);
        notificationMessage = this.objectTitle + ' successfully deleted.';
        break;
      case DialogActionType.CANCEL:
        return new Observable((observer) => {
          observer.next(null);
        });
    }

    return new Observable((observer) => {
      func.subscribe(resp => {
        this.notificationService.notificationEmitter.emit({message: notificationMessage, duration: 5000});
        observer.next({
          dialogData: data,
          resp: resp
        });
      }, (err) => {
        observer.error(err)
      });
    });
  }

  protected deleteDisabled(): boolean | Promise<boolean> {
    return false;
  }


  // Private Methods - I cannot find a usecase for them to be public.
  // May be changed to public if a developer finds a need for it.
  // ######################################################

  private doDelete() {
    this.actionTypeState = DialogActionType.DEL;
    this.dialogData.action = DialogActionType.DEL;
    this.dialogRef.close(this.dialogData);
  }

  protected disableSave() {
    if (this.actionTypeState !== DialogActionType.ADD) {
      return this.formGroup.isOriginal;
    } else {
      return false;
    }
  }

  protected initSecurityObjects() {

    if (this.dialogData.securityOverrides == null) {
      this.dialogData.securityOverrides = {};
    }

    this.cancelButton = {
      text: 'Cancel',
      typeClass: 'cancel',
      callback: () => {
        return this.onCancelClick();
      },
      security: {
        accessLevels: {
          accessLevel: 0,
          objectName: this.dialogData.securityObject
        }
      }
    };

    this.editButton = {
      text: 'Edit',
      callback: () => {
        this.onEditClick();
      },
      security:
        this.dialogData.securityOverrides.edit != null ? this.dialogData.securityOverrides.edit :
          {
            accessLevels: {
              accessLevel: 3,
              objectName: this.dialogData.securityObject
            },
            localOwnership: true,
            ownershipData: this.dialogData.objectData
          },
      focusOnClick: true
    };

    this.addSaveButton = {
      text: 'Save',
      typeClass: 'save',
      callback: async () => {
        await this.submit();
      },
      disabledValidator: () => {
        return this.disableSave();
      },
      security: this.dialogData.securityOverrides.add != null ? this.dialogData.securityOverrides.add :
        {
          accessLevels: {
            accessLevel: 2,
            objectName: this.dialogData.securityObject
          }
        },
      focusOnClick: true
    };

    this.editSaveButton = {
      text: 'Save',
      typeClass: 'save',
      callback: async () => {
        await this.submit();
      },
      disabledValidator: () => {
        return this.disableSave();
      },
      security: this.dialogData.securityOverrides.edit != null ? this.dialogData.securityOverrides.edit :
        {
          accessLevels: {
            accessLevel: 3,
            objectName: this.dialogData.securityObject
          },
          localOwnership: true,
          ownershipData: this.dialogData.objectData
        },
      focusOnClick: true
    };

    this.viewFormControlConfig = {
      entityControl: {
        controlButtons: [this.cancelButton, this.editButton]
      }
    };

    this.editFormControlConfig = {
      // isDeleteDisabledCallback: () => {return true},
      isDeleteDisabledCallback: () => {
        return this.deleteDisabled();
      },
      deleteCallback: () => {
        if (this.dialogData.showDelete === undefined ? true : this.dialogData.showDelete) {
          this.doDelete();
        }
      },
      deleteSecurityConfig:
        this.dialogData.securityOverrides.delete != null ? this.dialogData.securityOverrides.delete :
          {
            accessLevels: [
              {
                accessLevel: 4,
                objectName: this.dialogData.securityObject
              }],
            ownershipData: this.dialogData.objectData,
            localOwnership: true
          },
      entityControl: {
        controlButtons: [this.cancelButton, this.editSaveButton]
      }
    };

    this.addFormControlConfig = {
      entityControl: {
        controlButtons: [this.cancelButton, this.addSaveButton]
      }
    };
  }

  // TODO:
  // While this is useful, using this on the template will not refresh the
  // init for the template, meaning the delete will not be rendered properly.
  // We probably need a more elegant solution than an ngSwitch statement most components are using for this.
  // Maybe a wrapper component could work. I'd rather have angulars change detection handle it
  // instead of manually invoking it to refresh the template
  get entityFormControlConfig() {
    if (this.actionTypeState == DialogActionType.ADD) {
      return this.addFormControlConfig;
    } else if (this.actionTypeState == DialogActionType.EDIT) {
      return this.editFormControlConfig;
    }
    return this.viewFormControlConfig;
  }


  setObjectDataBeforeEdit(data) {
    this.objectDataBeforeEdit = data;
  }

  setPreviousDialogAction(action) {
    this.previousDialogAction = action;
  }

  setSaveEnableValidator(callback: SaveValidatorFn) {
    this.addSaveButton.disabledValidator = callback;
    this.editSaveButton.disabledValidator = callback;
  }

}

interface SaveValidatorFn {
  (): boolean
}