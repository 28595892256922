<div *ngIf="doneLoading">
  <div class="row" *ngIf="doneLoading; else pleaseWait">
    <div id="monthBox" [ngClass]="['month-box', 'pt-3', calendarSizeClass]" *ngFor="let m of getCounterArray(numberOfMonths); index as mIndex">
  
      <h6 class="month-name">{{getMonthName(startDate, mIndex)}} {{getYear(startDate, mIndex)}}</h6>
      <table style='width:100%;border-collapse:collapse;padding:0px;'>
        <tr>
          <th class="cal-header cal-sun-header">Sun</th>
          <th class="cal-header">Mon</th>
          <th class="cal-header">Tue</th>
          <th class="cal-header">Wed</th>
          <th class="cal-header">Thur</th>
          <th class="cal-header">Fri</th>
          <th class="cal-header cal-sat-header">Sat</th>
        </tr>
  
        <tr *ngFor="let n of getCounterArray(getRowCount(startDate, mIndex)); index as rIndex">
          <td [id]="getIDValue(startDate, mIndex, rIndex, cIndex)"
              [ngClass]="getFieldStyling(startDate, rIndex, cIndex, mIndex)"
              [attr.data-date]="getCellValue(startDate, rIndex, cIndex, mIndex)"
              (click)="onDayClick($event)"
              class="cal-date" *ngFor="let c of getCounterArray(7); index as cIndex">
            <span [attr.data-date]="getCellValue(startDate, rIndex, cIndex, mIndex)">
              {{getDayDisplayNumber(startDate, rIndex, cIndex, mIndex)}}
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>