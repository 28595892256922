import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EdtellMaterialModule} from '../material/material.module';
import {SrsFormsModule} from '../srs-forms/srs-forms.module';
import {EdtellAgGridModule} from '../edtell-ag-grid/edtell-ag-grid.module';
import {AgGridModule} from 'ag-grid-angular';
import {GridResetButtonComponent} from '../edtell-ag-grid/components/grid-reset-button/grid-reset-button.component';
import {StatusBarPlusComponent} from '../edtell-ag-grid/components/status-bar-plus/status-bar-plus.component';
import {EntitySelectComponent} from '../edtell-ag-grid/components/entity-select/entity-select.component';
import {GridClearButtonComponent} from '../edtell-ag-grid/components/grid-clear-button/grid-clear-button.component';
import {EntityControlLinkComponent} from '../edtell-ag-grid/components/entity-control-link/entity-control-link.component';
import {RouterModule} from '@angular/router';
import {EdtellResultIntervalPickerComponent} from './components/edtell-result-interval-picker.component';

@NgModule({
  declarations: [
    EdtellResultIntervalPickerComponent
  ],
  imports: [
    CommonModule,
    EdtellMaterialModule,
    SrsFormsModule,
    EdtellAgGridModule,
    RouterModule,
    AgGridModule.withComponents([
      GridResetButtonComponent,
      StatusBarPlusComponent,
      EntitySelectComponent,
      GridClearButtonComponent,
      EntityControlLinkComponent
    ])
  ],
  exports: [
    EdtellResultIntervalPickerComponent
  ]
})
export class EdtellResultIntervalPickerModule {
}
