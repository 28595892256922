import {ChangeDetectorRef, Component, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {cloneDeep} from 'lodash';
import {Subscription} from 'rxjs';
import {Attribute} from '../../../../edtell-portal/interfaces/attribute.interface';
import {LangUtils} from '../../../../edtell-portal/namespaces/lang-utils.namespace';
import {SrsFormWidget} from '../../../abstracts/srs-form-widget.abstract';
import {EdtellFormGroup} from '../../../classes/edtell-form-group.class';
import {SrsFormState} from '../../../enumerations/form-state.enum';
import {SrsCheckboxConfig} from '../../../interfaces/elements/srs-checkbox-config.interface';
import {SrsFormFieldConfig} from '../../../interfaces/elements/srs-form-field-config.interface';
import {SrsAttributeWidgetConfig} from '../../../interfaces/widgets/srs-attribute-widget-config.interface';
import {SrsTableWidgetConfig} from '../../../interfaces/widgets/srs-table-widget-config.interface';
import {SrsCheckboxComponent} from '../../elements/srs-checkbox/srs-checkbox.component';
import {SrsFormFieldComponent} from '../../elements/srs-form-field/srs-form-field.component';
import {SrsTableWidgetComponent} from '../srs-table-widget/srs-table-widget.component';

@Component({
  selector: 'app-srs-attribute-widget',
  templateUrl: './srs-attribute-widget.component.html',
  styleUrls: ['./srs-attribute-widget.component.scss']
})
export class SrsAttributeWidgetComponent extends SrsFormWidget<SrsAttributeWidgetConfig> implements OnDestroy {

  @ViewChild(SrsTableWidgetComponent)
  tableWidget: SrsTableWidgetComponent;


  uniqueKeySet: Map<AbstractControl, string>;

  srsTableWidgetConfig: SrsTableWidgetConfig<any>;
  formStateSubscription: Subscription;

  showAttrTable = false;

  originalData: Attribute[];

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  onWidgetInit() {
    this.originalData = cloneDeep(this.config.attributes);
    this.uniqueKeySet = new Map();

    this.srsTableWidgetConfig = {
      data: this.getTableData(),
      formGroup: this.config.attributeFormGroup,
      state: this.config.attributeFormGroup.state,
      continuous: true,
      colDef: [
        {
          title: 'Info Link',
          size: 1, // Size does not do anything here
          component: SrsFormFieldComponent,
          config: LangUtils.type<SrsFormFieldConfig>({
            title: '',
            key: '',
            size: 1
          }),

        },
        {
          title: 'Attribute Name',
          size: 2, // Size does not do anything here
          component: SrsFormFieldComponent,
          validators: [Validators.required, uniqueField(this.uniqueKeySet)],
          config: LangUtils.type<SrsFormFieldConfig>({
            title: '',
            key: 'attributeName',
            size: 12,
            settings: {
              formGroupMargin: false,
              placeholder: 'Attribute Name',
              showRequired: false
            },
            onChange: (c) => {
              updateTreeValidity(this.config.attributeFormGroup);
            },
            onInit: (config) => {

              for (let key in config.group.value) {

                let indexStr = '';
                let charLst = key.split('');
                for (let c of charLst) {
                  if (isNaN(+c)) {
                    break;
                  }
                  indexStr += c;
                }

                if (indexStr == '') {
                  throw new Error('Something went wrong while initializing attributes table.');
                }

                let index = +indexStr;
                let value = this.config.attributes[index];

                if (value == null) {
                  return;
                }

                if (value.systemAttribute == true) {
                  config.group.state = SrsFormState.READ;
                }

                return; // We only needed the first key
              }
            },
            onDestroy: (control) => {
              this.uniqueKeySet.delete(control);
            },
            validators: [
              {
                key: 'required',
                message: 'Attribute Name is required.'
              },
              {
                key: 'uniqueKeyError',
                message: 'Attribute Name must be unique.'
              }
            ]
          })
        },
        {
          title: 'Attribute Value',
          size: 4, // Size does not do anything here
          component: SrsFormFieldComponent,
          validators: [Validators.required],
          config: LangUtils.type<SrsFormFieldConfig>({
            title: '',
            key: 'attributeValue',
            size: 12,
            validators: [
              {
                key: 'required',
                message: 'Attribute Value is required.'
              }
            ],
            settings: {
              formGroupMargin: false,
              placeholder: 'Attribute Value',
              showRequired: false
            }
          })
        },
        {
          title: 'Attribute Description',
          size: 3, // Size does not do anything here
          component: SrsFormFieldComponent,
          config: LangUtils.type<SrsFormFieldConfig>({
            title: '',
            key: 'attributeDescription',
            size: 12,
            settings: {
              formGroupMargin: false,
              placeholder: 'Attribute Description',
              showRequired: false
            }
          })
        },
        {
          title: '',
          icon: 'fas fa-eye',
          size: 1, // Size does not do anything here
          component: SrsCheckboxComponent,
          config: LangUtils.type<SrsCheckboxConfig>({
            title: '',
            key: 'visibleOnView',
            size: 12,
            settings: {
              hideTitle: true,
              padding: 0
            }
          }),
          tooltip: 'Determines if this attribute will be displayed on profiles'
        },
        {
          icon: 'fas fa-lock',
          title: '',
          size: 1, // Size does not do anything here
          component: SrsCheckboxComponent,
          config: LangUtils.type<SrsCheckboxConfig>({
            title: '',
            key: 'systemAttribute',
            size: 12,
            settings: {
              hideTitle: true,
              padding: 0
            }
          }),
          tooltip: 'This is a system attribute and is locked from any further editing'
        }
      ]
    };

    this.showAttrTable = true;

    this.formStateSubscription = this.config.formGroup.onStateChange().subscribe(() => {
      this.showAttrTable = false;
      this.formStateSubscription.unsubscribe();
      setTimeout(() => {
        this.onWidgetInit();
      });
    });

    // Used to re-render table widget
    this.showAttrTable = true;
  }

  ngOnDestroy() {
    this.formStateSubscription.unsubscribe();
  }

  get dataChanged(): boolean {
    if (this.tableWidget) {
      return this.tableWidget.dataChanged;
    }
    return false;
  }

  refreshTable() {
    this.srsTableWidgetConfig.data = this.getTableData();
    this.tableWidget.onWidgetInit();
    this.cd.detectChanges();
  }

  getTableData() {
    return this.config.formGroup.state == SrsFormState.READ ? this.config.attributes.filter((i) => {
      return i.visibleOnView;
    }) : this.config.attributes;
  }
}

/**
 * Re-calculates the value and validation status of the entire controls tree.
 */
function updateTreeValidity(group: EdtellFormGroup | FormArray): void {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];

    if (abstractControl instanceof EdtellFormGroup || abstractControl instanceof FormArray) {
      updateTreeValidity(abstractControl);
    } else {
      abstractControl.updateValueAndValidity({emitEvent: false});
    }
  });
}

function uniqueField(uniqueKeySet): ValidatorFn {

  return (control: AbstractControl): ValidationErrors => {

    let value = control.value.toLowerCase().trim();
    uniqueKeySet.set(control, control.value);

    if (value != '') {
      for (let c of uniqueKeySet.keys()) {
        if (c != control && value == c.value.toLowerCase().trim()) {
          return {
            'uniqueKeyError': 'uniqueKeyError'
          };
        }
      }
    }

    return null;
  };
}
