import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from "lodash"
import { EntitySelectConfig, OptionConfig } from '../../../edtell-utilities/interfaces/entity-select-config';

@Component({
  selector: 'app-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.scss']
})
export class EntitySelectComponent implements OnInit, ICellRendererAngularComp {

  @Input()
  private config: EntitySelectConfig;

  public options: OptionConfig[];

  public width: string;

  private rowData: any;

  selection = null;

  constructor() {
  }

  ngOnInit() {
    if (this.config != null) {
      this.options = this.config.options;
    }
  }

  agInit(data: any) {

    this.rowData = data.data;

    this.config = data.colDef.cellRendererParams;

    this.options = this.config.options;

    let cloned = false;

    if (this.rowData.owner != null) {

      for (let i in this.options) {
        
        let option = this.options[i];

        if (option.security != null && option.security.localOwnership) {

          if(cloned == false){
            this.config = _.cloneDeep(this.config);
            this.options = this.config.options;
            option = this.options[i];
            cloned = true
          }

          option.security.ownershipData = this.rowData;
          this.options[i] = option
        }
        
      }

    }

  }

  isVisible(o : OptionConfig){
    return o.visible != null ? o.visible(this.rowData, o.data) : true;
  }

  getWidth() {
    return '100%'
  }

  refresh(params) {
    return true;
  }

  changed(event) {

    this.selection = event;

    if (event == null) {
      return;
    }

    let item = this.options[event];

    if (item == null) {
      return;
    }

    item.callback(this.rowData);

    setTimeout(() => {
      this.selection = null;
    }, 0);
  }

}
