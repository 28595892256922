import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColDef, GridApi, GridOptions } from "ag-grid-community";
import { merge } from "lodash";
import { take } from "rxjs/operators";
import { actionColumn, globalGridOptions } from "../../../edtell-ag-grid/interfaces/global-grid-config";
import { GridPreferenceName } from "../../../edtell-portal/enums/grid-preference-name.enum";
import { ApiKeyRecord } from "../../interfaces/api-key-record.interface";
import { ApiKeyService } from "../../services/api-key.service";
import { EntitySelectComponent } from "../../../edtell-ag-grid/components/entity-select/entity-select.component";
import { EntitySelectConfig } from "../../../edtell-utilities/interfaces/entity-select-config";
import { EdtellAgGridConfig } from "../../../edtell-ag-grid/interfaces/edtell-ag-grid-config.interface";
import { ObjectName } from "../../../edtell-admin/enums/object-name.enum";

@Component({
  selector: "app-api-key-management",
  templateUrl: "./api-key-management.component.html",
  styleUrls: ["./api-key-management.component.scss"]
})
export class ApiKeyManagementComponent implements OnInit {
  data: ApiKeyRecord[];
  loaded: boolean = false;
  gridApi : GridApi

  colDefs: ColDef[] = [
    { type: "actions" },
    {
      headerName: "Name",
      field: "name"
    },
    {
      headerName: "Type",
      field: "type"
    },
    {
      headerName: "Active",
      field: "active",
      onCellValueChanged: () => {
        console.log("vChange")
      }
    },
    {
      headerName: "External Id",
      field: "externalId"
    },
    {
      headerName: "Expiration",
      field: "expiration",
      valueGetter: (params) => {
        return params.data.expiration != null ? new Date(params.data.expiration).toISOString() : null;
      }
    }
  ];

  entitySelectConfig: EntitySelectConfig = {
    options: [
      {
        title: "Activate",
        visible: (data: ApiKeyRecord) => {
          return data.active == false;
        },
        security: null,
        callback: (data: ApiKeyRecord) => {
          data.active = true;
          this.gridApi.refreshCells()
          this.apiKeyService.setApiKeyActiveStatus({ id: data.id, active: data.active }).subscribe();

        }
      },
      {
        title: "Deactivate",
        visible: (data: ApiKeyRecord) => {
          return data.active == true;
        },
        security: null,
        callback: (data: ApiKeyRecord) => {
          data.active = false;
          this.gridApi.refreshCells()
          this.apiKeyService.setApiKeyActiveStatus({ id: data.id, active: data.active }).subscribe();
        }
      }
    ]
  };

  onGridReady = (grid) => {
    this.gridApi = grid.api;
  }

  agGridConfig: EdtellAgGridConfig;

  constructor(private apiKeyService: ApiKeyService, private router: Router, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.data = await this.apiKeyService
      .getAllApiKeyRecords()
      .pipe(take(1))
      .toPromise();
    this.initGridConfig();
    this.loaded = true;
  }

  initGridConfig() {
    this.agGridConfig = {
      columnDefs: this.colDefs,
      data: () => { return this.data },
      gridPreferenceName: null,
      actionConfig: this.entitySelectConfig,
      onGridReady: this.onGridReady.bind(this),
      object: ObjectName.API_MANAGEMENT
    }
  }

  createApiKey() {
    this.router.navigate(["./new"], { relativeTo: this.route });
  }

  toggleActive(event) {
    console.log(event);
  }
}
