import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CreditCardFormatDirective } from '../../../../angular-cc-library/src/lib/directives/credit-card-format.directive';
import { CreditCardValidators } from '../../../../angular-cc-library/src/lib/validators';
import { ObjectName } from '../../../../edtell-admin/enums/object-name.enum';
import { DialogActionType } from '../../../../edtell-portal/enums/dialog-action-type.enum';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { EdtellFormControl } from '../../../classes/edtell-form-control';
import { EdtellFormGroup } from '../../../classes/edtell-form-group.class';
import { CreditCardBrand } from '../../../enumerations/credit-card.enum';
import { EdtellDialogConfig } from '../../../interfaces/abstracts/edtell-dialog-config.interface';
import { SrsFormFieldConfig } from '../../../interfaces/elements/srs-form-field-config.interface';
import { SrsSelectOption } from '../../../interfaces/elements/srs-select-box-config.interface';
import { ValidatorConfig } from '../../../interfaces/utils/validator-config.interface';
import { EdtellCreditCardPaymentWidgetConfig } from '../../../interfaces/widgets/edtell-credit-card-payment-widget-config.interface';
import { SrsFullAddressWidgetConfig } from '../../../interfaces/widgets/srs-full-address-widget-config.interface';
import { WhatIsCscDialogComponent } from './what-is-csc-dialog/what-is-csc-dialog.component';

@Component({
  selector: 'app-edtell-credit-card-payment-widget',
  templateUrl: './edtell-credit-card-payment-widget.component.html',
  styleUrls: ['./edtell-credit-card-payment-widget.component.scss'],
})
export class EdtellCreditCardPaymentWidgetComponent extends SrsFormWidget<EdtellCreditCardPaymentWidgetConfig>
  implements AfterViewInit {
  formGroup: EdtellFormGroup;

  monthOptions: SrsSelectOption<number>[];
  yearOptions: SrsSelectOption<number>[];

  numberOfFutureYearsAvailable: number = 12;
  credtiCardBrand: CreditCardBrand;
  fullAddressConfig: SrsFullAddressWidgetConfig;
  firstNameFormFieldConfig: SrsFormFieldConfig;
  lastNameFormFieldConfig: SrsFormFieldConfig;

  creditLanguage : string

  ccNumberValidators: ValidatorConfig[] = [
    {
      key: 'ccNumber',
      message: 'Please enter a valid Credit Card Number.'
    },
    {
      key: 'wrongCreditBrand',
      message: 'Please use a Mastercard, Visa, or Discover Credit Card.'
    },
    {
      key: 'required',
      message: 'Credit Card Number is required.'
    }
  ]

  cscValidators: ValidatorConfig[] = [
    {
      key: 'required',
      message: 'CSC is required.'
    }
  ]

  monthValidators: ValidatorConfig[] = [
    {
      key: 'required',
      message: 'Expiration Month is required.'
    }
  ]

  yearValidators: ValidatorConfig[] = [
    {
      key: 'required',
      message: 'Expiration Year is required.'
    }
  ]
  

  @ViewChild(CreditCardFormatDirective) ccNumberDirective: CreditCardFormatDirective;

  constructor(private dialog: MatDialog) {
    super();
  }

  async onWidgetInit() {
    this.formGroup = this.config.formGroup;

    if (this.config.titleLineCheckboxConfig) {

      let onChange = this.config.titleLineCheckboxConfig.onChange ? this.config.titleLineCheckboxConfig.onChange : null;
      this.config.titleLineCheckboxConfig = {
        ...this.config.titleLineCheckboxConfig,
        onChange: (control) => {
          
          if (onChange) {
            onChange(control);
          }

          if (control.value) {
            this.formGroup.get(this.config.address1Key).disable();
            this.formGroup.get(this.config.address2Key).disable();
            this.formGroup.get(this.config.cityKey).disable();
            this.formGroup.get(this.config.stateKey).disable();
            this.formGroup.get(this.config.zipKey).disable();
            this.formGroup.get(this.config.countryKey).disable();
            return;
          }

          this.formGroup.get(this.config.address1Key).enable();
          this.formGroup.get(this.config.address2Key).enable();
          this.formGroup.get(this.config.cityKey).enable();
          this.formGroup.get(this.config.stateKey).enable();
          this.formGroup.get(this.config.zipKey).enable();
          this.formGroup.get(this.config.countryKey).enable();
        }
      }
    }

    if(!this.config.disabled) {
      this.formGroup.get(this.config.ccNumberKey).setValidators([CreditCardValidators.validateCCNumber]);
    }
    this.creditLanguage = this.config.creditLanguage

    this.monthOptions = [
      {
        text: '01',
        value: 1,
      },
      {
        text: '02',
        value: 2,
      },
      {
        text: '03',
        value: 3,
      },
      {
        text: '04',
        value: 4,
      },
      {
        text: '05',
        value: 5,
      },
      {
        text: '06',
        value: 6,
      },
      {
        text: '07',
        value: 7,
      },
      {
        text: '08',
        value: 8,
      },
      {
        text: '09',
        value: 9,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '11',
        value: 11,
      },
      {
        text: '12',
        value: 12,
      },
    ];

    let currYear = new Date().getFullYear();
    let yearOptions: SrsSelectOption<number>[] = [];

    for (let i = 0; i < this.numberOfFutureYearsAvailable; i++) {
      yearOptions.push({
        text: `${currYear + i}`,
        value: currYear + i,
      });
    }

    this.yearOptions = yearOptions;

    this.fullAddressConfig = {
      formGroup: this.formGroup,
      title: this.config.title ?? 'Address',
      addressConfig: {
        group: this.formGroup,
        key: this.config.address1Key,
        key2: this.config.address2Key,
        placeholder1: 'Address Line 1',
        placeholder2: 'Address Line 2',
        size: 12,
        disabled: this.config.disabled,
        validators: [
          {
            key: 'required',
            message: 'Address is required.'
          }
        ]
      },
      cityConfig: {
        group: this.formGroup,
        key: this.config.cityKey,
        size: 5,
        title: 'City',
        disabled: this.config.disabled,
        validators: [
          {
            key: 'required',
            message: 'City is required.'
          }
        ]
      },
      stateConfig: {
        key: this.config.stateKey,
        options: this.config.stateOptions,
        size: 2,
        title: 'State',
        group: this.formGroup,
        disabled: this.config.disabled,
        onChange: (control) => {
          this.config.onStateControlChange ? this.config.onStateControlChange(control) : null
        },
        validators: [
          {
            key: 'required',
            message: 'State is required.'
          }
        ]
      },
      zipConfig: {
        key: this.config.zipKey,
        size: 2,
        title: 'Zip-Code',
        group: this.formGroup,
        disabled: this.config.disabled,
        settings: {
          numberInput: true
        },
        validators: [
          {
            key: 'required',
            message: 'Zip-Code is required.'
          }
        ]
      },
      countryConfig: {
        key: this.config.countryKey,
        options: this.config.countryOptions,
        size: 3,
        group: this.formGroup,
        title: 'Country',
        disabled: this.config.disabled,
        validators: [
          {
            key: 'required',
            message: 'Country is required.'
          }
        ]
      },
      titleLineCheckboxConfig: this.config.titleLineCheckboxConfig
    }

    this.firstNameFormFieldConfig = {
      key: this.config.firstNameKey,
      size: 12,
      title: 'First Name',
      group: this.formGroup,
      disabled: this.config.disabled,
      validators: [
        {
          key: 'required',
          message: 'First name is required.'
        }
      ]
    }

    this.lastNameFormFieldConfig = {
      key: this.config.lastNameKey,
      size: 12,
      title: 'Last Name',
      group: this.formGroup,
      disabled: this.config.disabled,
      validators: [
        {
          key: 'required',
          message: 'Last name is required.'
        }
      ]
    }
  }

  ngAfterViewInit(): void {
    this.ccNumberDirective.resolvedScheme$.subscribe(brand => {
      this.credtiCardBrand = brand as CreditCardBrand;
    })

    this.formGroup.get(this.config.ccNumberKey).setValidators([CreditCardValidators.validateCCNumber, creditBrandValidator(() => { return this.getCreditCardBrand() })])
  }

  getCreditClass(creditCard: string) {
    let ccValue: string = this.formGroup.get(this.config.ccNumberKey).value;
    if (ccValue == null) {
      return 'enabled';
    }

    if (ccValue.length > 1) {
      if (creditCard != this.credtiCardBrand) {
        return 'disabled';
      }
    }
    return 'enabled';
  }

  validationClass(controlKey: string) {
    let control = this.formGroup.get(controlKey)
    return control.errors && this.formGroup.submitted ? 'is-invalid' : '';
  }
  
  hasError(formControlName: string, error: string) {
    let control = this.formGroup.get(formControlName) as EdtellFormControl;
    if (control.pristine && !this.formGroup.submitted) {
      return false;
    }
    return control.errors != null && control.errors[error] != null;
  }

  cscWhatsThis() {
    let dialogConfig: EdtellDialogConfig = {
      width: '600px',
      data: {
        action: DialogActionType.VIEW,
        objectData: {},
        securityObject: ObjectName.CUSTOMER_PROGRAM,
      },
    };
    this.dialog.open(WhatIsCscDialogComponent, dialogConfig);
  }
  
  getCreditCardBrand() {
    return this.credtiCardBrand;
  }

}
export function creditBrandValidator(brandCallback: () => CreditCardBrand): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (brandCallback() == CreditCardBrand.UNKOWN) {
      return null;
    }
    let brands = [CreditCardBrand.DISCOVER, CreditCardBrand.MASTERCARD, CreditCardBrand.VISA];
    if (!brands.includes(brandCallback())) {
      return {
        wrongCreditBrand: true
      }
    }
    return null;
  }
}