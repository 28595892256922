<ng-template [ngIf]="loaded == true">
  <div class="row">
    <div class="col-12">
      <span>Subject: {{ emailData.subject }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span>Date Created: {{ emailData.dateCreated }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span>Recipients: {{ emailData.email }}</span>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-12" *ngIf="emailData.includedAttachments == true">
      <button type="button" class="btn btn-outline-primary pr-1" (click)="downloadAttachment(a.id)" *ngFor="let a of emailData.attachments">
        <i class="fa fa-paperclip" aria-hidden="true"></i> {{ a.filename }} 
      </button>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-12">
      <p class="emailViewer" [innerHTML]="html"></p>
    </div>
  </div>
</ng-template>
