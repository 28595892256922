import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RoleManagementComponent } from "./components/role-management/role-management.component";
import { RoleEditorComponent } from "./components/role-editor/role-editor.component";
import { RolePermissionSliderComponent } from "./components/role-editor/role-permissions-slider.component";
import { RouterModule } from "@angular/router";
import { RouterDataGenerator } from "../edtell-portal/namespaces/router-data-generator";
import { SrsSecurityModule } from "../srs-security/srs-security.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EdtellPortalModule } from "../edtell-portal/edtell-portal.module";
import { RouteSecurityGenerator } from "../edtell-portal/namespaces/security-generator";
import { AgGridModule } from "ag-grid-angular";
import { EntitySelectComponent } from "../edtell-ag-grid/components/entity-select/entity-select.component";
import { EdtellMaterialModule } from "../material/material.module";
import { SrsFormsModule } from "../srs-forms/srs-forms.module";
import { SystemRoleResolver } from "./resolvers/system-role.resolver";
import { RoleSelectorComponent } from "./components/role-selector/role-selector.component";
import { JwtInterceptorProvider } from "../edtell-portal/interceptors/jwt.interceptor";
import { EdtellAgGridModule } from "../edtell-ag-grid/edtell-ag-grid.module";
import { EdtellControlsModule } from "../edtell-controls/edtell-controls.module";

@NgModule({
  declarations: [
    RoleManagementComponent, RoleEditorComponent, RolePermissionSliderComponent, RoleSelectorComponent],
  exports: [
    RoleManagementComponent, 
    RoleEditorComponent, 
    RolePermissionSliderComponent, 
    RoleSelectorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SrsSecurityModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EdtellPortalModule,
    AgGridModule.withComponents([EntitySelectComponent, RolePermissionSliderComponent]),
    SrsFormsModule,
    EdtellMaterialModule,
    EdtellAgGridModule,
    EdtellControlsModule
  ],
  providers: [
    JwtInterceptorProvider
  ]
})
export class SystemRoleModule {}

@NgModule({
  imports: [
    SystemRoleModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            component: RoleManagementComponent,
          },
          {
            path: "add",
            component: RoleEditorComponent,
            data: {
              routerData: RouterDataGenerator.generateSimpleRouterData("Create Role"),
            },
          },
          {
            path: ":systemRoleId",
            component: RoleEditorComponent,
            data: {
              routerData: RouterDataGenerator.generateDynamicRouterData(SystemRoleResolver),
            },
          },
        ],
      },
    ]),
  ],
  exports: [SystemRoleModule],
})
export class RoleRoutingModule {}
