import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { appRoutes } from "./api-documentation.routing";
import { AppContainerComponent } from "./components/app-container/app-container.component";
import { ApiConsoleComponent } from "./components/app-container/body/api-console/api-console.component";
import { BodyComponent } from "./components/app-container/body/body.component";
import { CommandsIndexComponent } from "./components/app-container/body/commands-index/commands-index.component";
import { CommandsPageComponent } from "./components/app-container/body/commands-page/commands-page.component";
import { CommandGroupComponent } from "./components/app-container/sidebar/command-group/command-group.component";
import { DocumentationSidebarComponent } from "./components/app-container/sidebar/sidebar.component";
import { EdtellUtilitiesModule } from "../edtell-utilities/edtell-utilities.module";
import { StyleTemplateComponent } from "./components/style-template/style-template.component";
import { EdtellMaterialModule } from "../material/material.module";
import { ApiDocumentationComponent } from "./components/api-documentation/api-documentation.component"

@NgModule({
  declarations: [
    BodyComponent,
    CommandGroupComponent,
    ApiConsoleComponent,
    ApiDocumentationComponent,
    CommandsIndexComponent,
    CommandsPageComponent,
    AppContainerComponent,
    StyleTemplateComponent,
    DocumentationSidebarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    EdtellUtilitiesModule,
    EdtellMaterialModule,
    RouterModule
  ],
  providers: [],
  exports: [
    DocumentationSidebarComponent,
    BodyComponent,
    ApiDocumentationComponent,
    CommandGroupComponent,
    ApiConsoleComponent,
    CommandsIndexComponent,
    CommandsPageComponent,
    AppContainerComponent,
    StyleTemplateComponent
  ],
  bootstrap: [],
})
export class ApiDocumentationModule {}

@NgModule({
  imports: [CommonModule, ApiDocumentationModule, RouterModule.forChild(appRoutes)],
  exports: [ApiDocumentationModule]
})
export class ApiDocumentationRoutingModule{

}