export enum GridPreferenceName {
  // -------- SHARED ---------
  //#region 
  CUSTOMER_CONTACTS = 'customer-contacts.grid.preferences',
  OBJECT = 'object.grid.preferences',
  USERS = 'users.grid.preferences',
  USER_OBJECT_SECURITY = 'user-object-security.grid.preferences',
  LICENSE_TYPES = 'license-types.grid.preferences',
  EVENT_LOG_ALL = 'all-event-view.grid.preferences',
  EVENT_LOG_ACCESS = 'access-event-view.grid.preferences',
  EVENT_LOG_API = 'api-event-view.grid.preferences',
  ORGANIZATION_TYPE = 'orgtype.grid.preferences',
  CUSTOMER = 'customer.grid.preferences',
  CUSTOMER_LICENSE = 'license.grid.preferences',
  EMAIL_TEMPLATE = 'email-template.grid.preferences',
  CUSTOMER_LICENSE_PAYMENT = 'license-payment.grid.preferences',
  CUSTOMER_LICENSE_SEAT = 'license-seat.grid.preferences',
  NAVIGATION_ELEMENT = 'navigation-element.grid.preferences',
  NAVIGATION_SEGMENT = 'navigation-segment.grid.preferences',
  USER_PERMISSIONS = 'user-permissions.grid.preferences',
  TERRITORY_MANAGEMENT = 'territory-manager.grid.preferences',
  TERRITORY_MANAGEMENT_CONFIRMATION = 'territory-manager-confirm.grid.preferences',
  CUSTOMER_CONTACT_ASSOCIATIONS = 'customer-contact-associations.grid.preferences',
  COURSE_RIGOR_LEVEL = 'course-rigor-level.grid.preferences',
  COURSE_FORMAT = 'course-format.grid.preferences',
  COURSE_CREDIT = 'course-credit.grid.preferences',
  COURSE_STRUCTURE = 'course-structure.grid.preferences',
  COURSE_TERM = 'course-term.grid.preferences',
  PROVIDER_DELIVERY_METHOD = 'provider-delivery-method.grid.preferences',
  GRADE_LEVEL = 'grade-level.grid.preferences',
  CUSTOMER_RECENTLY_MODIFIED = 'customer-recently-modified.grid.preferences',
  CUSTOMER_USER_MODIFIED = 'customer-user-modified.grid.preferences',
  ASSET_RECENTLY_MODIFIED = 'asset-recently-modified.grid.preferences',
  ASSET_USER_MODIFIED = 'asset-user-modified.grid.preferences',
  EDUCATION_LEVEL = 'education-level.grid.preferences',
  MANDRILL_BLACKLIST = 'mandrill-blacklist.grid.preferences',
  MANDRILL_SENDERLIST = 'mandrill-senderlist.grid.preferences',
  MANDRILL_TAGLIST = 'mandrill-taglist.grid.preferences',
  MANDRILL_RECENT_WELCOME_EMAILS = 'mandrill-recent-welcome-emails',
  PROVIDERS_SEARCH = 'providers-search.grid.preferences',
  PROVIDERS_RECENTLY_MODIFIED = 'providers-recently-modified.grid.preferences',
  PROVIDERS_USER_MODIFIED = 'providers-user-modified.grid.preferences',
  PROVIDER_CONTACTS = 'provider-contacts.grid.preferences',
  PROVIDER_DEPARTMENTS = 'provider-departments.grid.preferences',
  API_MANAGEMENT = 'api-managemnet.grid.preferences',
  ASSET_DOCUMENT_PICKER = 'asset-document-picker.grid.preferences',
  DATABASE_EVENTS = 'database-events.grid.preferences',
  PROVIDER_ORG_TYPE = 'provider-org-type.grid.preferences',
  PROVIDER_TERM_DURATIONS = 'provider-term-duration.grid.preferences',
  PROVIDER_PRICING_DEFAULTS = 'provider-pricing-defaults.grid.preferences',
  CONTACT_TITLE_MANAGEMENT = 'contact-title-management.grid.preferences',
  PROVIDER_PRICING_DEFAULTS_ADD = 'provider-pricing-defaults-add.grid.preferences',
  PROVIDER_PRICING_DEFAULTS_EDIT = 'provider-pricing-defaults-edit.grid.preferences',
  PROVIDER_PRICING_OVERRIDES = 'provider-pricing-overrides.grid.preferences',
  PROVIDER_PRICING_OVERRIDES_LINE_ITEMS = 'provider-pricing-overrides-line-items.grid.preferences',
  LMS_LOCATION = 'lms.location.gride.preferences',
  //#endregion
  // -------- SIS ---------
  //#region 

  DATA_SECURITY = 'data-security.grid.preferences',
 
  //#endregion

  // -------- SRS ---------
  //#region
  ATTRIBUTES_NOT_LINKED_REPORT = 'attributes-not-linked-report.grid.preferences',
  CUSTOMER_CONTACTS_SEARCH = 'customer-contacts-search.grid.preferences',
  OBJECT_ATTRIBUTES = 'object-attributes.grid.preferences',
  USER_ATTRIBUTES = 'user-attributes.grid.preferences',
  CUSTOMER_MARKUPS = 'customer-markups.grid.preferences',
  CUSTOMER_MARKUP_DETAILS = 'customer-markup-details.grid.preferences',
  CUSTOMER_PAYMENT_MODULES = 'customer-payment-modules.grid.preferences',
  SUBJECT_TAG_MANAGEMENT = 'subject-tag-management.grid.preferences',
  SUBJECT_MANAGEMENT = 'subhect-management.grid.preferences',
  CUSTOMER_LICENSE_PAYMENT_DIALOG = 'customer-license-payment-dialog.grid.preferences',
  CUSTOMER_BROWSE_TRANSACTIONS = 'customer-browse-transactions.grid.preferences',
  SCHOOL_TYPE = 'school-type.grid.preferences',
  CATALOG_SEARCH = 'catalog-search.grid.preferences',
  CATALOG_PROFILE_COURSES = 'catalog-profile-courses.grid.preferences',
  CATALOG_COURSE_TYPE_AVAILABILITY = 'catalog-course-type-availability.grid.preferences',
  CATALOG_COURSE_TYPE_AVAILABILITY_ADD = 'catalog-course-type-availability-add.grid.preferences',
  CATALOG_COURSE_TYPE_AVAILABILITY_BULK_ADD = 'catalog-course-type-availability-bulk-add.grid.preferences',
  CUSTOMER_PROGRAMS = 'customer-programs.grid.preferences',
  DUPLICATE_ATTRIBUTES_REPORT = 'duplicate-attributes-report.grid.preferences',
  OVERRIDES_ERROR = 'overrides-error.grid.preferences',
  OVERRIDE_ERROR_DIALOG_NEGATIVE_PRICE = 'override-error-dialog-negative-price.grid.preferences',
  OVERRIDE_ERROR_DIALOG_DUPLICATE = 'override-error-dialog-duplicate.grid.preferences',
  OVERRIDE_ERROR_DIALOG_GOOD_ITEMS = 'override-error-dialog-good-items.grid.preferences',
  REGISTRATIONS_COURSE_VIEW = 'registrations-course-view.grid.preferences',
  REGISTRATIONS_PAYMENTS_VIEW = 'registrations-payment-view.grid.preferences',
  REGISTRATIONS_DELETED = 'registrations-deleted.grid.preferences',
  REGISTRATIONS_BOUNCED_EMAIL_CALLS = 'registrations-bounced-email-calls.grid.preferences',
  REGISTRATIONS_FOUNDERS_WELCOME_CALLS = 'registrations-founders-welcome-calls.preferences',
  REGISTRATIONS_FOUNDERS_PROGRESS_CALLS = 'registrations-founders-progress-calls.grid.preferences',
  REGISTRATION_FOUNDERS_NCAA_GRADE_REPORT = 'registrations-founders-ncaa-grade-report.grid.preferences',
  REGISTRATION_WELCOME_FOLLOW_UP_CALLS = 'registrations-founders-welcome-follow-up-calls.grid.preferences',
  REGISTRATION_PAYMENTS = 'registration-payments.grid.preferences',
  COMMUNICATIONS_WELCOME = 'communications-welcome.grid.preferences',
  COMMUNICATIONS_SEND_VOLUMES = 'communications-send-volumes.grid.preferences',
  COMMUNICATIONS_RECENT = 'communications-recent.grid.preferences',
  COMMUNICATIONS_EMAILS = 'communications-emails.grid.preferences',
  COMMUNICATIONS_EMAIL_ERROR_ALL = 'communications-email-error-all.grid.preferences',
  COMMUNICATIONS_WELCOME_EMAIL_ERROR = 'communications-welcome-email-error.grid.preferences',
  COMMUNICATIONS_GRADE_EMAIL_ERROR = 'communications-grade-email-error.grid.preferences',
  COMMUNICATIONS_REG_CONFIRM_EMAIL_ERROR = 'communications-reg-confirm-email-error.grid.preferences',
  COMMUNICATIONS_TRANSACTION_EMAIL_ERROR = 'communications-transaction-email-error.grid.preferences',
  COMMUNICATIONS_EMAIL_BOUNCE_ALL = 'communications-email-bounce-all.grid.preferences',
  COMMUNICATIONS_WELCOME_EMAIL_BOUNCE = 'communications-welcome-email-bounce.grid.preferences',
  COMMUNICATIONS_GRADE_EMAIL_BOUNCE = 'communications-grade-email-bounce.grid.preferences',
  COMMUNICATIONS_REG_CONFIRM_EMAIL_BOUNCE = 'communications-reg-confirm-email-bounce.grid.preferences',
  COMMUNICATIONS_TRANSACTION_EMAIL_BOUNCE = 'communications-transaction-email-bounce.grid.preferences',
  CUSTOMER_CONTACT_RELATIONS_REPORT = 'customer-contact-relations-report.grid.preferences',
  FINAL_GRADE_PREVIEW = 'final-grade-preview.grid.preferences',
  EXTERNAL_APPLICATIONS = 'external-applications.grid.preferences',
  EXTERNAL_APPLICATIONS_LIST = 'external-applications-list.grid.preferences',
  INVOICE_AUDIT = 'invoice-audit.grid.preferences',
  BLACKOUT_DATES = 'blackout-dates.grid.preferences',
  CUSTOMER_REPORTS_STUDENT_REGISTRATIONS = 'customer-reports-student-registrations.grid.preferences',
  PLC_MONTHLY_SUMMARY = 'plc-monthly-summary.grid.preferences',
  PLC_COUNTS_BY_CUSTOMER = 'plc-counts-by-customer.grid.preferences',
  ESTIMATED_REVENUE_THIS_MONTH = 'estimated-revenue-this-month.grid.preferences',
  PASS_FAIL_BY_STATE = 'pass-fail-by-state.grid.preferences',
  ACCOUNT_DISTRIBUTIONS_VEC = 'account-distributions-vec.grid.preferences',
  PAYMENT_CODE_ANALYSIS = 'payment-code-analysis.grid.preferences',
  PAYMENT_CODE_ANALYSIS_YEAR_MONTH = 'payment-code-analysis-year-month.grid.preferences',
  CATALOG_COURSE_PROGRAM = 'catalog-course-program.grid.preferences',
  OPERATIONS_LOGS = 'outbound-api-event-view.grid.preferences',
  LMS_COURSES = 'lms-courses.grid.preferences',
  OPERATIONS_PROGRAM_TOOLS_ADD_COURSE_PREVIEW = 'operations-program-tools-add-course-preview',
  CALL_RECORDINGS = 'call-recordings.grid.preferences',
  CALL_LISTS = 'call-lists.grid.preferences',
  CALL_SCHEDULES = 'call-schedules.grid.preferences',
  CALL_REPORTS = 'call-reports.grid.preferences',
  CALL_REPORTS_BY_YEAR = 'call-reports-by-year.grid.preferences',
  CALL_REPORTS_BY_YEAR_AND_SCHEDULE = 'call-reports-by-year-and-schedule.grid.preferences',
  CALL_TYPES = 'call-types.grid.preferences',
  STUDENT_GRADE_IMPORT = 'student-grade-import.preferences',
  OPERATIONS_REPORTS_ACTIVE_STUDENTS_BY_COURSE = 'operations-reports-active-students-by-course.grid.preferences',
  OPERATIONS_REPORTS_ESTIMATED_COUNTS = 'operations-reports-estimated-counts.grid.preferences',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE = 'operations-reports-student-courses-by-state',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_PLC = 'operations-reports-student-courses-by-plc',
  PLC_COUNTS_BY_STATE = 'plc-counts-by-state.preferences',
  PAYMENT_MODULE_BATCH = 'payment-module-batch.preferences',
  PROGRAM_SECTION = 'program-section.grid.preferences',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE_EDUCERE = 'operations-reports-student-courses-by-state-educere',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE_FOUNDERS = 'operations-reports-student-courses-by-state-founders',
  OPERATIONS_REPORTS_VEC_ACTIVE_STUDENTS = 'operations-reports-vec-active-students',
  OPERATIONS_REPORTS_TOP_PROVIDERS = 'operations-reports-top-providers',
  OPERATIONS_REPORTS_REGISTRATIONS_BY_STATE = 'operations-reports-registrations-by-state',
  OPERATIONS_REPORTS_STATE_REGISTRATIONS_BY_SCHOOL = 'operations-reports-state-registration-by-school',
  OPERATIONS_REPORTS_REGISTRATIONS_BY_PROGRAM = 'operations-reports-registrations-by-program',
  OPERATIONS_REPORTS_ENROLLMENTS = 'operations-reports-enrollments',
  NCAA_ABBREVIATED_COURSE_COMPLIANCE_REPORT = 'ncaa-abbreviated-course-compliance-report.preferences',
  NCAA_FULL_COURSE_COURSE_COMPLIANCE_REPORT = 'ncaa-full-course-course-compliance-report.preferences',
  COMPLIANCE_REPORTS_FNC_LIST = 'compliance-reports-fnc-list',
  COMPLIANCE_REPORTS_COURSE_PROGRAM_COMPLIANCE = 'compliance-reports-course-program-compliance',
  COMPLIANCE_REPORTS_NCAA_COMPLIANCE = 'compliance-reports-ncaa-compliance',
  INVALID_COUPON_REPORT = 'invalid-coupon-report.grid.preferences',
  COMPLIANCE_REPORTS_DUPLICATE_COURSE_REGISTRATIONS = 'compliance-reports-duplicate-course-registrations',
  COMPLIANCE_REPORTS_DUPLICATE_PAYMENT_CODE_REPORT = 'compliance-reports-duplicate-payment-code-report',
  COMPLIANCE_REPORTS_NCAA_PROSPECT = 'compliance-reports-ncaa-prospective-athlete.preferences',
  COMPLIANCE_REPORTS_DUPLICATE_SUBJECT_REGISTRATIONS = 'compliance-reports-duplicate-subject-registrations',
  COMPLIANCE_REPORTS_BALANCE_EXCEPTION = 'compliance-reports-balance-exception',
  CUSTOMER_LICENSE_USAGE_REPORT = 'customer-license-usage-report',
  CUSTOMER_LICENSE_USAGE_REGISTRATIONS = 'customer-license-usage-registrations',
  NCES_URL_MANAGEMENT = 'nces-url-management.grid.preferences',
  PAYMENT_CODE_USAGE_REPORT = 'payment-code-usage.grid.preferences',
  PAYMENT_DISCREPANCY_REPORT = 'payment-discrepancy-report.grid.preferences'
}
