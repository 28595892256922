import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgGridColumn } from 'ag-grid-angular';
import { Column, ColumnApi, GridApi } from 'ag-grid-community';
import { cloneDeep, find, indexOf } from 'lodash';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ObjectService } from '../../../../edtell-admin/services/object.service';
import { DialogActionType } from '../../../../edtell-portal/enums/dialog-action-type.enum';
import { Attribute } from '../../../../edtell-portal/interfaces/attribute.interface';
import { LangUtils } from '../../../../edtell-portal/namespaces/lang-utils.namespace';
import { AttributeService } from '../../../../edtell-portal/services/attribute.service';
import { NotificationService } from '../../../../edtell-portal/services/notification.service';
import { SecurityService } from '../../../../edtell-portal/services/security.service';
import { EdtellDialogComponent } from '../../../../srs-forms/abstracts/edtell-dialog.component';
import { EdtellFormControl } from '../../../../srs-forms/classes/edtell-form-control';
import { EdtellFormArray, EdtellFormGroup } from '../../../../srs-forms/classes/edtell-form-group.class';
import { SrsCheckboxComponent } from '../../../../srs-forms/components/elements/srs-checkbox/srs-checkbox.component';
import { SrsFormFieldComponent } from '../../../../srs-forms/components/elements/srs-form-field/srs-form-field.component';
import { AttributeItemType } from '../../../../srs-forms/enumerations/attribute-item-type.enum';
import { SrsFormState } from '../../../../srs-forms/enumerations/form-state.enum';
import { EdtellDialogData } from '../../../../srs-forms/interfaces/abstracts/edtell-dialog-data.interface';
import { SrsCheckboxConfig } from '../../../../srs-forms/interfaces/elements/srs-checkbox-config.interface';
import { SrsFormFieldConfig } from '../../../../srs-forms/interfaces/elements/srs-form-field-config.interface';
import { SrsMultiElementConfig } from '../../../../srs-forms/interfaces/elements/srs-multi-element-config.interface';
import { SrsSelectBoxConfig, SrsSelectOption } from '../../../../srs-forms/interfaces/elements/srs-select-box-config.interface';
import { SrsMultiElementWidgetConfig } from '../../../../srs-forms/interfaces/widgets/srs-multi-element-widget-config';
import { EdtellAgGridConfig } from '../../../interfaces/edtell-ag-grid-config.interface';

@Component({
  selector: 'app-edtell-ag-grid-settings-dialog',
  templateUrl: './edtell-ag-grid-settings-dialog.component.html',
  styleUrls: ['./edtell-ag-grid-settings-dialog.component.scss']
})
export class EdtellAgGridSettingsDialogComponent extends EdtellDialogComponent {
  
  dialogData: EdtellDialogData<{ config: EdtellAgGridConfig, gridApi: GridApi, columnApi: ColumnApi, columnSettings: ColumnSetting[]}>;
  formGroup: EdtellFormGroup;
  columnsArray: EdtellFormArray;
  originalColumnsArray: EdtellFormArray;
  objectTitle: string = 'Grid Settings';
  config: EdtellAgGridConfig;

  gridApi: GridApi;
  columnApi: ColumnApi;

  filterCacheCheckboxConfig: SrsCheckboxConfig;

  gridAttribute: Attribute;
  attributeName = 'grid-settings';
  columnSettings: ColumnSetting[]

  dialogReady = false;

  reservedColumnIds = [
    // 'action'
  ];

  constructor(public dialogRef: MatDialogRef<EdtellAgGridSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EdtellDialogData,
    public notificationService: NotificationService,
    private objectService: ObjectService,
    private attributeService: AttributeService
  ) {

    super(data.action as DialogActionType, notificationService);
    this.dialogData = data;
    this.config = this.dialogData.objectData.config;
    this.columnSettings = this.dialogData.objectData.columnSettings;
    this.attributeName += `.${this.config.gridPreferenceName}`.replace('.preferences','');
  }

  async dialogOnInit() {

    // console.log('grid attribute:', this.gridAttribute)
    this.editFormControlConfig.deleteCallback = null;

    let onEditCallback = this.onEditClick.bind(this);

    this.onEditClick = () => {
      onEditCallback();

      for (let formGroup of this.columnsArray.controls) {
        if (formGroup instanceof EdtellFormGroup)
          formGroup.state = SrsFormState.WRITE;
      }
    }

    let onCancelCallback = this.onCancelClick.bind(this);

    this.onCancelClick = () => {

      if (onCancelCallback()) {
        return true;
      }

      for (let formGroup of this.columnsArray.controls) {
        if (formGroup instanceof EdtellFormGroup)
          formGroup.state = SrsFormState.READ;
      }

      this.columnsArray = cloneDeep(this.originalColumnsArray);
    }

    let saveCallback = this.editSaveButton.callback;

    this.editSaveButton.callback = () => {
      // get sortIndexes that aren't null
      let sortIndexes = this.columnsArray.controls.map((formGroup: EdtellFormGroup) => {
        return formGroup.get('sortIndex').value;
      }).filter((sortIndex) => {
        return sortIndex != null;
      });
      // make sure each sortIndex is unique
      let uniqueSortIndexes = [...new Set(sortIndexes)];
      if (sortIndexes.length != uniqueSortIndexes.length) {
        window.alert('Sort Indexes must be unique');
        return;
      }

      saveCallback();
    }

    this.formGroup.markAsOriginal();

    this.initFormConfigs();

    this.dialogReady = true
  }

  async getGridAttribute() {
    let itemId = await this.objectService.getObjectId(this.config.object).pipe(first()).toPromise();

    this.gridAttribute = await this.attributeService.getAttributeValueByItemIdAndAttributeName(itemId, this.attributeName).pipe(first()).toPromise();

    if (this.gridAttribute == null) {
      this.gridAttribute = {
        attributeName: this.attributeName,
        systemAttribute: true,
        blacklist: false,
        visibleOnView: false,
        attributeValue: null,
        itemId: itemId,
        itemType: AttributeItemType.OBJECT
      }
    }
  }

  async initForm() {
    await this.getGridAttribute();
    this.gridApi = this.dialogData.objectData.gridApi; 
    this.columnApi = this.dialogData.objectData.columnApi;
    let filterCache = true;
    if (this.gridAttribute.attributeValue) {
      filterCache = JSON.parse(this.gridAttribute.attributeValue).filterCache;
    }

    let formState = SrsFormState.READ;

    let gridColumns: Column[] = this.columnApi.getAllColumns();

    this.columnsArray = new EdtellFormArray(formState, gridColumns.map((column, i) => {
      let colDef = column.getColDef();
      return new EdtellFormGroup(formState, {
        colId: new EdtellFormControl(column.getColId()),
        width: new EdtellFormControl(colDef.width),
        hide: new EdtellFormControl(colDef.hide),
        index: new EdtellFormControl(i),
        sort: new EdtellFormControl(colDef.sort),
        sortIndex: new EdtellFormControl(colDef.sortIndex)
      })
    }))

    this.formGroup = new EdtellFormGroup(formState, {
      columnSettings: this.columnsArray,
      filterCache: new EdtellFormControl(filterCache)
    })

    
    if (this.columnSettings != null) {

      // make sure all columns are represented in the attribute value
      for (let i in this.config.columnDefs) {
        let columnDef = this.config.columnDefs[+i];
        let attValue = find(this.columnSettings, (colValue) => {
          return colValue.colId == columnDef.colId
        });

        if (attValue != undefined) {
          continue;
        }

        // column is not represented
        this.columnSettings.splice(+i, 0, {
          colId: columnDef.colId,
          width: null,
          hide: false,
          index: +i,
          sort: null,
          sortIndex: null
        })
      }

      // make sure all column properties are represented for the form
      for (let i in this.columnSettings) {
        let columnSetting = this.columnSettings[+i];
        columnSetting.index = columnSetting.index ?? +i;
        columnSetting.sortIndex = columnSetting.sortIndex ?? null;
        columnSetting.sort = columnSetting.sort ?? null;
      }

      this.formGroup.get('columnSettings').setValue(this.columnSettings);
    }
    
    this.originalColumnsArray = cloneDeep(this.columnsArray);
  }

  initFormConfigs() {
    this.filterCacheCheckboxConfig = {
      key: 'filterCache',
      group: this.formGroup,
      size: 12,
      title: 'Filter Cache'
    }
  }

  getColumnName(formGroup: EdtellFormGroup): string {
    let columnName = find(this.config.columnDefs, {colId: formGroup.get('colId').value})?.headerName;
    if (columnName == null) {
      if (formGroup.get('colId').value == 'action') {
        columnName = "Actions"
      }
    }
    return columnName
  }

  getWidthFormConfig(formGroup: EdtellFormGroup): SrsFormFieldConfig {
    let isReservedColumn = this.reservedColumnIds.includes(formGroup.get('colId').value);
    return {
      key: 'width',
      size: 12,
      title: 'Width',
      group: formGroup,
      disabled: () => {
        return isReservedColumn;
      },
      settings: {
        hideTitle: true,
        hiddenTitlePadding: true,
        numberInput: true
      }
    }
  }

  getHideFormConfig(formGroup: EdtellFormGroup): SrsCheckboxConfig {
    let isReservedColumn = this.reservedColumnIds.includes(formGroup.get('colId').value);
    return {
      key: 'hide',
      size: 2,
      title: 'Hide',
      group: formGroup,
      disabled: () => {
        return isReservedColumn;
      },
      settings: {
        hideTitle: true,
        padding: 0
      }
    }
  }

  getSortFormConfig(formGroup: EdtellFormGroup): SrsSelectBoxConfig<string> {
    return {
      key: 'sort',
      size: 3,
      title: 'Sort',
      group: formGroup,
      settings: {
        hideTitle: true,
      },
      options: [
        {
          value: null,
          text: 'None',
          allowNullValue: true
        },
        {
          value: 'asc',
          text: 'Ascending'
        },
        {
          value: 'desc',
          text: 'Descending'
        }
      ],
      onChange: (control) => {
        if (control.value == null) {
          formGroup.get('sortIndex').setValue(null);
        }

        this.updateSortIndexes();
      }
    }
  }

  getSortIndexFormConfig(formGroup: EdtellFormGroup): SrsSelectBoxConfig<number> {
    return {
      key: 'sortIndex',
      size: 2,
      title: 'Sort Index',
      group: formGroup,
      settings: {
        hideTitle: true,
      },
      options: this.sortIndexOptions,
      disabled: () => {
        return formGroup.get('sort').value == null;
      }
    }
  }

  updateSortIndexes() {
    // get all formGroups with sort
    let formGroups = this.columnsArray.controls.filter((formGroup) => {
      return formGroup.get('sort').value != null;
    })
    // sort by sortIndex value
    formGroups.sort((a, b) => {
      if (a.get('sortIndex').value == null && b.get('sortIndex').value == null) {
        return 0;
      }

      if (a.get('sortIndex').value == null) {
        return 1;
      }

      if (b.get('sortIndex').value == null) {
        return -1;
      }

      return a.get('sortIndex').value < b.get('sortIndex').value ? -1 : 1;
    })
    // set sortIndex to the correct value
    let sortCount = 1;
    for (let formGroup of formGroups) {
      formGroup.get('sortIndex').setValue(sortCount);
      sortCount++;
    }
  }

  sortUp(formGroup: EdtellFormGroup) {
    let formGroups = this.columnsArray.controls;
    let index = indexOf(formGroups, formGroup);
    if (index == 0) {
      return;
    }

    let tempGroup = formGroups[index - 1];
    formGroups[index - 1] = formGroup;
    formGroups[index] = tempGroup;

    tempGroup.get('index').setValue(index);
    formGroup.get('index').setValue(index - 1);
  }

  sortDown(formGroup: EdtellFormGroup) {
    let formGroups = this.columnsArray.controls;
    let index = indexOf(formGroups, formGroup);
    if (index == formGroups.length - 1) {
      return;
    }

    let tempGroup = formGroups[index + 1];
    formGroups[index + 1] = formGroup;
    formGroups[index] = tempGroup;

    tempGroup.get('index').setValue(index);
    formGroup.get('index').setValue(index + 1);
  }

  create(reqBody: any): Observable<any> {
    return null;
  }
  update(): Observable<any> {
    let formValue = this.formGroup.getRawValue();
    for (let column of formValue.columnSettings) {
      if (column.width == '') {
        column.width = null;
      }
    }

    let formValueRemoveIndex = formValue.columnSettings.map((col) => {
      return {
        colId: col.colId,
        width: col.width,
        hide: col.hide,
        sort: col.sort,
        sortIndex: col.sortIndex
      }
    })

    this.gridAttribute.attributeValue = JSON.stringify(formValue);

    return this.attributeService.upsertObjectAttribute(this.gridAttribute);
  }
  delete(id: number): Observable<any> {
    return null;
  }

  get sortIndexOptions(): SrsSelectOption<number>[] {
    let options: SrsSelectOption<number>[] = [];

    let sortCount = 0;
    for (let formGroup of this.columnsArray.controls) {
      if (formGroup.get('sort').value != null) {
        sortCount++;
        options.push({
          value: sortCount,
          text: sortCount.toString()
        })
      }
    }
    return options;
  }

}



interface ColumnSetting {
  colId: string;
  width: string;
  hide: boolean;
  index: number;
  sort: 'asc' | 'desc' | null;
  sortIndex: number;
}

interface ColumnSettings {
  filterCache: boolean;
  columnSettings: ColumnSetting[];
}