import * as moment from 'moment';

export function toDBDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function toJSDate(date: Date | string) {

  if (date == null || date instanceof Date) {
    return null;
  }

  let split = date.split('-');
  let retDate = new Date(+split[0], parseInt(split[1]) - 1, +split[2]);

  return retDate;
}
