import { ValidatorFn, AbstractControl } from "@angular/forms";
import * as moment from "moment";

export function maxDate(max: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if(moment(control.value).diff(max, 'days') > 0) {
      return { "maxDate": true };
    }
    return null;
  };
}