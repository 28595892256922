import { Component, Input, OnInit } from '@angular/core';
import { GoogleChatDistributionApi } from '../../../interfaces/chat-distribution-api.interface';
import { EmailDistributionApiData } from '../../../interfaces/email-distrubtion-api-data.interface';
import { ProcessedApiComponent } from '../../../interfaces/processed-api.interface';
import { DistributionMetaData } from '../../../interfaces/processed-job-status.interface';

@Component({
  selector: 'app-distribution-google-chat-view',
  templateUrl: './distribution-google-chat-view.component.html',
  styleUrls: ['./distribution-google-chat-view.component.scss']
})
export class DistributionGoogleChatViewComponent implements OnInit {

  @Input()
  config : ProcessedApiComponent

  @Input()
  distribution : DistributionMetaData

  data : GoogleChatDistributionApi
  loaded = false;

  constructor() { }

  async ngOnInit() {
    this.data = await this.config.api.googleChatDistribution(this.distribution.id);
    this.loaded = true;
  }

  async downloadAttachment(fileId : number){
    await this.config.api.downloadDistribution(fileId) 
  }
}
