import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { PreviousRouteService } from '../../../../services/previous-route.service';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})
export class FourOhFourComponent implements OnInit {

  public readonly VIDEO_COUNT = 8;
  ranNum;
  private src: string;

  constructor(
    private router : Router,
    private previousRouteService : PreviousRouteService
    ) {}

  ngOnInit() {
    this.ranNum = Math.floor((Math.random() * this.VIDEO_COUNT) - .001);
    this.src = ('../../assets/404/' + (this.ranNum + 1)) + '.mp4';
  }

  // This uses the location from the router to send the user back to the previous location
  async backClicked() {
    await this.router.navigate([this.previousRouteService.getPreviousRoute()]);
  }

  getSource() {
    return this.src;
  }

}
