<ng-container [ngSwitch]="config.formGroup.state">
    <div class="row" *ngSwitchCase="'WRITE'">
        <div class="col-12 mb-0 form-group d-flex justify-content-between">
            <label *ngIf="config.title">{{config.title}}
                <span class="required-asterisk">{{ required ? "*" : "" }}</span>
            </label>
            <app-srs-checkbox *ngIf="config.titleLineCheckboxConfig" [config]="config.titleLineCheckboxConfig"></app-srs-checkbox>
        </div>
        <div class="col-12">
            <app-srs-multi-element-widget [config]="writeTemplateConfig">
            </app-srs-multi-element-widget>
        </div>
    </div>

    <div class="row" *ngSwitchCase="'READ'">
        <div class="col-12 form-group">
            <app-srs-text-area [config]="readTextareaConfig"></app-srs-text-area>
        </div>
    </div>
</ng-container>
