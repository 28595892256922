<div class="row spacing entity-control-container">
  <div class="col-12 d-flex align-items-end">
    <app-entity-control-buttons class="mr-2" [options]="options"></app-entity-control-buttons>

    <div class="ml-auto d-flex align-items-end justify-content-end">
      <div class="entity-control-security-link-container">
        <app-security-link [securityObject]="securityObject"></app-security-link>
      </div>
    </div>

  </div>
</div>