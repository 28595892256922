import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../abstracts/component-can-deactivate';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: ComponentCanDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {
    // If there is a component and it has the canDeactivate function,
    // it will check for the return of that function or just allow nav away
    if ((!!component && !!component.canDeactivate) ? !component.canDeactivate() : false) {
      let prompt = !!component.canDeactivatePrompt ? component.canDeactivatePrompt : 'Do you want to cancel your changes?';
          return confirm(prompt);
    }
    return true;
  }
}
