import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';
import { OwnershipDialogComponent } from '../../../edtell-controls/components/ownership-dialog/ownership-dialog.component';
import { EdtellDialogData } from '../../../srs-forms/interfaces/abstracts/edtell-dialog-data.interface';
import { InfoLinkConfig } from '../../interfaces/edtell-info-link-config.interface';

@Component({
  selector: 'app-info-link',
  template: '<a class="info-link" (click)="viewInfo()" [secure]="securityConfig">Info</a>',
  styleUrls: ['./info-link.component.scss']
})
export class InfoLinkComponent {

  _object;
  _saveFunc;

  createUID;
  createUser;
  createDate;

  modifyUID;
  modifyUser;
  modifyDate;

  users;

  @Input()
  set object(input) {
    this._object = input;
  }

  @Input()
  set saveFunc(input) {
    this._saveFunc = input;
  }

  @Input()
  config: InfoLinkConfig

  
  securityConfig: SecurityConfig = {
    accessLevels: [
      {
        accessLevel: 1,
        objectName: ObjectName.INFO_DIALOG
      }
    ]
  }

  constructor(private router: Router, public dialog: MatDialog) {
  }

  viewInfo() {
    if (!this.config.owernshipDialogOverride) {
      this.viewOwnership();
      return;
    
    }

    const dialogRef = this.dialog.open(this.config.owernshipDialogOverride.dialogComponent, this.config.owernshipDialogOverride.edtellDialogConfig);

    dialogRef.afterClosed().subscribe((dialogData: EdtellDialogData) => {
      if (dialogData == null) {
        return;
      }

      dialogRef.componentInstance.save(dialogData).subscribe(data => {
        this.config.owernshipDialogOverride.afterClosedCallback(data);
      })
    });
  }

  viewOwnership() {
    this.dialog.open(OwnershipDialogComponent, {
      width: '400px',
      data: this.config != null ? { config: this.config } : { object: this._object, saveFunc: this._saveFunc }
    });
  }
}
