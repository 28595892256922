import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgButtonConfig } from '../../interfaces/ag-button-config.interface';

@Component({
  selector: 'app-ag-button',
  templateUrl: './ag-button.component.html',
  styleUrls: ['./ag-button.component.scss']
})
export class AgButtonComponent implements OnInit, ICellRendererAngularComp {

  config : AgButtonConfig
  params : ICellRendererParams

  constructor() { }

  ngOnInit() {
  }

  agInit(params : ICellRendererParams) {
    this.params = params;
    this.config = (<any>params).config;
  }

  click(){
    this.config.onClick(this.params.data);
  }

  refresh() { return true; }

}
