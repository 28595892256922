import { OnDestroy, OnInit, Directive } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SidebarConfig } from "../interfaces/sidebar-config.interface";
import { EmitterEvent } from "../../edtell-portal/enums/emitter-events.enum";
import { EventEmitterService } from "../../edtell-portal/services/event-emitter.service";

@Directive()
export abstract class SidebarWidgetComponent implements OnInit, OnDestroy{

    private sidebarConfig : SidebarConfig;
    private _router: Router;
    private subscription: Subscription;

    constructor(
        router: Router, 
        private _route : ActivatedRoute,
        private _eventEmitterService : EventEmitterService
        ) { this._router = router; }

    async ngOnInit() {

        this.subscription = this._eventEmitterService.subscribe(EmitterEvent.SIDEBAR_REFRESH, async () => {
            await this.refresh();
        })

        await this.onWidgetInit();
        await this.refresh();
    }

    async ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    abstract onWidgetInit();
    abstract refresh() : Promise<any>;

}