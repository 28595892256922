import {Component, Input, OnInit} from '@angular/core';
import {ResultIntervalPickerConfigInterface} from '../interfaces/edtell-result-interval-picker-config.interface';

@Component({
  selector: 'app-result-interval-picker',
  template: `
    <div class="col">
      <span class="day-selector-text">Show {{config.displayTitle}} where the Course End Date is any time in the future or </span>
      <select [(ngModel)]="interval">
        <option *ngFor="let o of intervalOptions; index as i"
                [value]="o.value">{{o.text}}</option>
      </select>
      <span class="day-selector-text"> days in the past. </span>
      <button class="btn btn-tertiary" (click)="onResultsFetchClick()">>></button>
    </div>
  `,
  styleUrls: []
})
export class EdtellResultIntervalPickerComponent implements OnInit {

  @Input()
  config: ResultIntervalPickerConfigInterface;

  interval = 30;
  // Note: if we are still in business in ~100 years (50,000 Days) then we can re-evaluate the last option
  intervalOptions = [
    {text: '30', value: 30},
    {text: '60', value: 60},
    {text: '90', value: 90},
    {text: '180', value: 180},
    {text: '365', value: 365},
    {text: 'All', value: 50000}
  ];


  constructor() {}

  async ngOnInit() {}

  async onResultsFetchClick() {
    await this.config.getResultsCallback(this.interval);
  }

}
