<div *ngIf="dataLoaded">
  <div class="dialog-header">
    <h1 mat-dialog-title mat-dialog-draggable-title>Linked Entities
      <!-- <app-info-link class="info" [secure]="info" [object]="this.asset" [saveFunc]="updateRef"></app-info-link> -->
    </h1>
    <span aria-hidden="true" (click)="onNoClick(false)">&times;</span>
  </div>
  <div class="row mt-4">
    <div class="col-12" *ngIf="customerObjectsUsed.length > 0">
      <div class="object-used-list-title">
        Customer(s) using this asset.
      </div>
      <ul>
        <li *ngFor="let object of customerObjectsUsed">
          <a [routerLink]="object.route" (click)="onNoClick(true)">{{object.name}}</a>
        </li>
      </ul>
    </div>

    <div class="col-12" *ngIf="providerObjectsUsed.length > 0">
      <div class="object-used-list-title">
        Provider(s) using this asset.
      </div>
      <ul>
        <li *ngFor="let object of providerObjectsUsed">
          <a [routerLink]="object.route" (click)="onNoClick(true)">{{object.name}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>