import { Component, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { min } from 'lodash';
import { merge, Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { SrsDropCompleteConfigInterface, SrsDropCompleteDropdownOption } from '../../../interfaces/elements/srs-drop-complete-config.interface';
import { SrsSelectOption } from '../../../interfaces/elements/srs-select-box-config.interface';

@Component({
  selector: 'app-srs-drop-complete',
  templateUrl: './srs-drop-complete.component.html',
  styleUrls: ['./srs-drop-complete.component.scss']
})
export class SrsDropCompleteComponent extends SrsFormElement<SrsDropCompleteConfigInterface<any>> {

  // @ViewChild('instance', {static: true}) instance: any;

  options: SrsDropCompleteDropdownOption<any>[] = [];

  optionsMap = new Map<string, any>()

  lastSearchString: string
  searchString: string
  nullSearchString: string

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor() {
    super();
  }

  onElementInit() {

    this.options = this.config.options;

    for(let o of this.options){
      let optionText = ((<string>(o.text != null ? o.text : this.getOptionValue(o))));
      if (optionText == null) {
        console.warn('Invalid Drop Complete option configuration. This option was skipped.')
        continue;
      }
      this.optionsMap.set(optionText.toLowerCase(), this.getOptionValue(o))
    }
  

    this.config.options.sort((a, b) => {
      // push null value to top
      if (a.value == null) {
        return -1;
      }
      if (b.value == null) {
        return 1;
      }
      return a.text > b.text ? 1 : a.text < b.text ? -1 : 0;
    });

    // This is used for initializing the field with data on load
    let options = _.cloneDeep(this.config.options);
    this.searchString = options.find(x => this.getOptionValue(x) === this.control.value)?.text;
    this.lastSearchString = this.searchString

    // Subscribing to value changes to keep field state up to date with control values
    this.control.valueChanges.subscribe((val) => {
      // This is entirely for the formGroup.resetValues()
      if (val != null && val == this.control['originalValue']) {
        let options = _.cloneDeep(this.config.options);
        this.searchString = options.find(x => this.getOptionValue(x) === this.control.value).text;
        this.lastSearchString = this.searchString
      }
    });
  }

  onChange() {
    // Checking to see that the value is not null and the value is different from the original
    // Only sending on change if value is different to prevent false change notifications
    let value = this.optionsMap.get(this.searchString?.toLowerCase());

    if (value != this.control['originalValue']) {
      this.control.setValue(this.searchString != null ? this.optionsMap.get(this.searchString?.toLowerCase()) : null);
      this.config.onChange != null ? this.config.onChange(this.control) : null;
      this.control.markAsDirty();
    }

    // if (this.searchString != null && value != null && this.control.dirty && value == this.control['originalValue']) {
    if (this.control.dirty && value == this.control['originalValue']) {
      this.control.setValue(this.optionsMap.get(this.searchString?.toLowerCase() ?? null));
      this.config.onChange != null ? this.config.onChange(this.control) : null;
      this.control.markAsPristine();
    }

  }

  
  getOptionValue(option: SrsSelectOption<any>) {
    if (option.allowNullValue) {
      this.nullSearchString = option.text;
      return option.value;
    }
    return option.value != null ? option.value : option.text;
  }

  onFocus() {
    if (this.control.value == null) {
      this.searchString = ""
    }
  }

  // Used for resetting field if focus is lost and user navigates away
  // To prevent the field from having improper data in the field if the user changes the field and doesnt commit to those changes
  unfocus(e) {
    if (this.optionsMap.get(this.searchString?.toLowerCase()) == null) {
      this.searchString = this.nullSearchString
    } else {
      this.lastSearchString = this.searchString
    }

    this.onChange()
  }

  get datalistId() {
    return `${this.elementId}-list`
  }

}
