export enum ObjectName {

  // --------- Shared -----------
  // #region
  API_MANAGEMENT = 'API_MANAGEMENT',
  ASSET = 'ASSET',
  AUTHORIZE_NET = 'AUTHORIZE_NET',
  COURSE_TYPE_MANAGEMENT = 'COURSE_TYPE_MANAGEMENT',
  CUSTOMER_ATTRIBUTES = 'CUSTOMER_ATTRIBUTES',
  CUSTOMER_CONTACT = 'CUSTOMER_CONTACT',
  CUSTOMER_EMAIL_TEMPLATE = 'CUSTOMER_EMAIL_TEMPLATE',
  CUSTOMER_HANDOUT = 'CUSTOMER_HANDOUT',
  CUSTOMER_LICENSE = 'CUSTOMER_LICENSE',
  CUSTOMER_LICENSE_PAYMENTS = 'CUSTOMER_LICENSE_PAYMENTS',
  CUSTOMER_LICENSE_SEATS = 'CUSTOMER_LICENSE_SEATS',
  CUSTOMER_LICENSE_TYPE = 'CUSTOMER_LICENSE_TYPE',
  CUSTOMER_PAYMENT_CODE = 'CUSTOMER_PAYMENT_CODE',
  CUSTOMER_PAYMENT_MODULE = 'CUSTOMER_PAYMENT_MODULE',
  CUSTOMER_LOGO = 'CUSTOMER_LOGO',
  CUSTOMER_PROFILE = 'CUSTOMER_PROFILE',
  CUSTOMER_PROGRAM = 'CUSTOMER_PROGRAM',
  CUSTOMER_PROGRAM_SECTION = 'CUSTOMER_PROGRAM_SECTION',
  DATABASE_EVENTS = 'DATABASE_EVENTS',
  EVENT_LOG = 'EVENT_LOG',
  EXTERNAL_UTILITIES = 'EXTERNAL_UTILITIES',
  GRID_SETTINGS = 'GRID_SETTINGS',
  LMS_API = 'LMS_API',
  LMS_MANAGEMENT = 'LMS_MANAGEMENT',
  MANDRILL = 'MANDRILL',
  NAVIGATION = 'NAVIGATION',
  COMPLIANCE_REPORTS_NCAA_PROSPECT = 'COMPLIANCE_REPORTS_NCAA_PROSPECT',
  ORGANIZATION_TYPE = 'ORGANIZATION_TYPE',
  PROVIDER_ATTRIBUTES = 'PROVIDER_ATTRIBUTES',
  PROVIDER_CONTACT = 'PROVIDER_CONTACT',
  PROVIDER_COURSE_TYPE = 'PROVIDER_COURSE_TYPE',
  PROVIDER_CREDIT_EQUIVALENT_MANAGEMENT = 'PROVIDER_CREDIT_EQUIVALENT_MANAGEMENT',
  PROVIDER_DELIVERY_METHOD = 'PROVIDER_DELIVERY_METHOD',
  PROVIDER_DEPARTMENT_MANAGEMENT = 'PROVIDER_DEPARTMENT_MANAGEMENT',
  PROVIDER_FORMAT_MANAGEMENT = 'PROVIDER_FORMAT_MANAGEMENT',
  PROVIDER_NOTES = 'PROVIDER_NOTES',
  PROVIDER_ORG_TYPE = 'PROVIDER_ORG_TYPE',
  PROVIDER_PRICING_DEFAULTS_ADD = 'PROVIDER_PRICING_DEFAULTS_ADD',
  PROVIDER_PRICING_DEFAULTS_EDIT = 'PROVIDER_PRICING_DEFAULTS_EDIT',
  PROVIDER_PRICING_MANAGEMENT = 'PROVIDER_PRICING_MANAGEMENT',
  PROVIDER_PRICING_OVERRIDES = 'PROVIDER_PRICING_OVERRIDES',
  PROVIDER_PROFILE = 'PROVIDER_PROFILE',
  PROVIDER_RIGOR_LEVEL_MANAGEMENT = 'PROVIDER_RIGOR_LEVEL_MANAGEMENT',
  PROVIDER_STRUCTURE_MANAGEMENT = 'PROVIDER_STRUCTURE_MANAGEMENT',
  PROVIDER_TERM_MANAGEMENT = 'PROVIDER_TERM_MANAGEMENT',
  PROVIDER_WELCOME_EMAILS = 'PROVIDER_WELCOME_EMAILS',
  PROVIDERS = 'PROVIDERS',
  SIS_EMPLOYEE = 'SIS_EMPLOYEE',
  SUBJECT_MANAGEMENT = 'SUBJECT_MANAGEMENT',
  SUBJECT_TAG_MANAGEMENT = 'SUBJECT_TAG_MANAGEMENT',
  SYSTEM = 'SYSTEM',
  SYSTEM_PROPERTIES = 'SYSTEM_PROPERTIES',
  TAXJAR = 'TAXJAR',
  TERRITORY_MANAGEMENT = 'TERRITORY_MANAGEMENT',
  USER = 'USER',

  //#endregion
  // --------- SRS -----------
  // #region
  ACCOUNT_DISTRIBUTIONS_PLC = 'ACCOUNT_DISTRIBUTIONS_PLC',
  ACCOUNT_DISTRIBUTIONS_PLC_COUNTS_BY_CUSTOMER = 'ACCOUNT_DISTRIBUTIONS_PLC_COUNTS_BY_CUSTOMER',
  ACCOUNT_DISTRIBUTIONS_PLC_COUNTS_BY_STATE = 'ACCOUNT_DISTRIBUTIONS_PLC_COUNTS_BY_STATE',
  ACCOUNT_DISTRIBUTIONS_PLC_MONTHLY_SUMMARY_BY_YEAR = 'ACCOUNT_DISTRIBUTIONS_PLC_MONTHLY_SUMMARY_BY_YEAR',
  ACCOUNT_DISTRIBUTIONS_VEC = 'ACCOUNT_DISTRIBUTIONS_VEC',
  ACTIVE_STUDENT_REPORT = 'ACTIVE_STUDENT_REPORT',
  ADMIN_EMAIL_TEMPLATES = 'ADMIN_EMAIL_TEMPLATES',
  ANALYTICS_REVENUE_BY_PLC = 'ANALYTICS_REVENUE_BY_PLC',
  ANALYTICS_REVENUE_BY_STATE = 'ANALYTICS_REVENUE_BY_STATE',
  ANALYTICS_REVENUE_BY_VEC = 'ANALYTICS_REVENUE_BY_VEC',
  API_PERMISSION_MISMATCHES = 'API_PERMISSION_MISMATCHES',
  ATTRIBUTES_NOT_LINKED_REPORT = 'ATTRIBUTES_NOT_LINKED_REPORT',
  BATCH_ENROLLMENTS = 'BATCH_ENROLLMENTS',
  BATCH_EXTENSIONS = 'BATCH_EXTENSIONS',
  BATCH_PROCESSES = 'BATCH_PROCESSES',
  BATCH_WELCOME_EMAILS = 'BATCH_WELCOME_EMAILS',
  BLACKOUT_DATES = 'BLACKOUT_DATES',
  CATALOG_COURSE = 'CATALOG_COURSE',
  CATALOG_COURSE_NOTES = 'CATALOG_COURSE_NOTES',
  CATALOG_COURSE_PROFILE = 'CATALOG_COURSE_PROFILE',
  CATALOG_COURSE_TYPE_AVAILABILITY = 'CATALOG_COURSE_TYPE_AVAILABILITY',
  COMMS_CALL_LISTS = 'COMMS_CALL_LISTS',
  COMMS_CALL_RECORDINGS = 'COMMS_CALL_RECORDINGS',
  COMMS_CALL_REPORTS = 'COMMS_CALL_REPORTS',
  COMMS_CALL_REPORTS_BY_YEAR = 'COMMS_CALL_REPORTS_BY_YEAR',
  COMMS_CALL_SCHEDULE = 'COMMS_CALL_SCHEDULE',
  COMMS_CALL_TYPES = 'COMMS_CALL_TYPES',
  COMMUNICATIONS = 'COMMUNICATIONS',
  COMMUNICATIONS_BOUNCED_EMAIL_CALLS = 'COMMUNICATIONS_BOUNCED_EMAIL_CALLS',
  COMMUNICATIONS_RECENT_EMAILS = 'COMMUNICATIONS_RECENT_EMAILS',
  COMMUNICATIONS_SEND_VOLUMES = 'COMMUNICATIONS_SEND_VOLUMES',
  COMMUNICATIONS_WELCOME_EMAIL = 'COMMUNICATIONS_WELCOME_EMAIL',
  COMMUNICATIONS_EMAIL_ERROR_INFORMATION = 'COMMUNICATIONS_EMAIL_ERROR_INFORMATION',
  COMMUNICATIONS_EMAIL_ERROR_ALL = 'COMMUNICATIONS_EMAIL_ERROR_ALL',
  COMMUNICATIONS_EMAIL_ERROR_INFORMATION_WELCOME = 'COMMUNICATIONS_EMAIL_ERROR_INFORMATION_WELCOME',
  COMMUNICATIONS_EMAIL_ERROR_INFORMATION_GRADE = 'COMMUNICATIONS_EMAIL_ERROR_INFORMATION_GRADE',
  COMMUNICATIONS_EMAIL_ERROR_INFORMATION_REG_CONFIRMATION = 'COMMUNICATIONS_EMAIL_ERROR_INFORMATION_REG_CONFIRMATION',
  COMMUNICATIONS_EMAIL_ERROR_INFORMATION_TRANSACTION = 'COMMUNICATIONS_EMAIL_ERROR_INFORMATION_TRANSACTION',
  COMMUNICATIONS_EMAIL_BOUNCE_ALL = 'COMMUNICATIONS_EMAIL_BOUNCE_ALL',
  COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_WELCOME = 'COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_WELCOME',
  COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_GRADE = 'COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_GRADE',
  COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_REG_CONFIRMATION = 'COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_REG_CONFIRMATION',
  COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_TRANSACTION = 'COMMUNICATIONS_EMAIL_BOUNCE_INFORMATION_TRANSACTION',
  COMPLIANCE_REPORTS_DUPLICATE_COURSE_REGISTRATIONS = 'COMPLIANCE_REPORTS_DUPLICATE_COURSE_REGISTRATIONS',
  COMPLIANCE_REPORTS_DUPLICATE_PAYMENT_CODE_REPORT = 'COMPLIANCE_REPORTS_DUPLICATE_PAYMENT_CODE_REPORT',
  COMPLIANCE_REPORTS_COURSE_PROGRAM_COMPLIANCE = 'COMPLIANCE_REPORTS_COURSE_PROGRAM_COMPLIANCE',
  COMPLIANCE_REPORTS_FNC_LIST = 'COMPLIANCE_REPORTS_FNC_LIST',
  COMPLIANCE_REPORTS_NCAA_COMPLIANCE = 'COMPLIANCE_REPORTS_NCAA_COMPLIANCE',
  COMPLIANCE_REPORTS_DUPLICATE_SUBJECT_REGISTRATIONS = 'COMPLIANCE_REPORTS_DUPLICATE_SUBJECT_REGISTRATIONS',
  COMPLIANCE_REPORTS_BALANCE_EXCEPTION = 'COMPLIANCE_REPORTS_BALANCE_EXCEPTION',
  CONTACTS_EXPORT = 'CONTACTS_EXPORT',
  CREDENTIAL_SUBJECT = 'CREDENTIAL_SUBJECT',
  CUSTOMER_CONTACT_SEARCH = 'CUSTOMER_CONTACT_SEARCH',
  CUSTOMER_LICENSE_USAGE = 'CUSTOMER_LICENSE_USAGE',
  CUSTOMER_MARKUPS = 'CUSTOMER_MARKUPS',
  CUSTOMER_NOTES = 'CUSTOMER_NOTES',
  CUSTOMER_PASS_FAIL_ANALYSIS = 'CUSTOMER_PASS_FAIL_ANALYSIS',
  CUSTOMER_REPORTS = 'CUSTOMER_REPORTS',
  CUSTOMER_REPORTS_REVENUE = 'CUSTOMER_REPORTS_REVENUE',
  CUSTOMER_REPORTS_STUDENT_REGISTRATIONS = 'CUSTOMER_REPORTS_STUDENT_REGISTRATIONS',
  DUPLICATE_ATTRIBUTES_REPORT = 'DUPLICATE_ATTRIBUTES_REPORT',
  EDTELL_USAGE = 'EDTELL_USAGE',
  EDUCATION_LEVEL = 'EDUCATION_LEVEL',
  ESTIMATED_REVENUE_THIS_MONTH = 'ESTIMATED_REVENUE_THIS_MONTH',
  EXTERNAL_APPLICATIONS = 'EXTERNAL_APPLICATIONS',
  FINAL_GRADE_PREVIEW = 'FINAL_GRADE_PREVIEW',
  FOUNDERS_NCAA_EMAIL = 'FOUNDERS_NCAA_EMAIL',
  FOUNDERS_COURSE_TRANSACTION = 'FOUNDERS_COURSE_TRANSACTION',
  FOUNDERS_PASS_FAIL_ANALYSIS = 'FOUNDERS_PASS_FAIL_ANALYSIS',
  FOUNDERS_REPORTS = 'FOUNDERS_REPORTS',
  GRADE_LEVEL = 'GRADE_LEVEL',
  INVALID_COUPON_REPORT = 'INVALID_COUPON_REPORT',
  INVOICE_AUDIT = 'INVOICE_AUDIT',
  INVOICE_AUDIT_DETAILS = 'INVOICE_AUDIT_DETAILS',
  INFO_DIALOG = 'INFO_DIALOG',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  LMS_COURSES_EXPORT = 'LMS_COURSES_EXPORT',
  NCAA_ABBREVIATED_COURSE_REPORT = 'NCAA_ABBREVIATED_COURSE_REPORT',
  NCAA_FULL_COURSE_COURSE_REPORT = 'NCAA_FULL_COURSE_COURSE_REPORT',
  NCAA_PROSPECT_COMPLIANCE_REPORT = 'NCAA_PROSPECT_COMPLIANCE_REPORT',
  NCES_URL_MANAGEMENT = 'NCES_URL_MANAGEMENT',
  OBJECT = 'OBJECT',
  OPERATIONS = 'OPERATIONS',
  OPERATIONS_EDCALLS = 'OPERATIONS_EDCALLS',
  OPERATIONS_FOUNDERS_GRADE = 'OPERATIONS_FOUNDERS_GRADE',
  OPERATIONS_LMS_COURSES = 'OPERATIONS_LMS_COURSES',
  OPERATIONS_LOGS = 'OPERATIONS_LOGS',
  OPERATIONS_PROGRAM_TOOLS = 'OPERATIONS_PROGRAM_TOOLS',
  OPERATIONS_PROGRAM_TOOLS_ADD_COURSE = 'OPERATIONS_PROGRAM_TOOLS_ADD_COURSE',
  OPERATIONS_PROGRAM_TOOLS_REMOVE_COURSE = 'OPERATIONS_PROGRAM_TOOLS_REMOVE_COURSE',
  OPERATIONS_PROGRAM_TOOLS_REPLACE_COURSE = 'OPERATIONS_PROGRAM_TOOLS_REPLACE_COURSE',
  OPERATIONS_REPORTS = 'OPERATIONS_REPORTS',
  OPERATIONS_REPORTS_ACTIVE_STUDENTS_BY_COURSE = 'OPERATIONS_REPORTS_ACTIVE_STUDENTS_BY_COURSE',
  OPERATIONS_REPORTS_ENROLLMENTS = 'OPERATIONS_REPORTS_ENROLLMENTS',
  OPERATIONS_REPORTS_ESTIMATED_COURSE_COUNT = 'OPERATIONS_REPORTS_ESTIMATED_COURSE_COUNT',
  OPERATIONS_REPORTS_STATE_REGISTRATIONS_BY_SCHOOL = 'OPERATIONS_REPORTS_STATE_REGISTRATIONS_BY_SCHOOL',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_PLC = 'OPERATIONS_REPORTS_STUDENT_COURSES_BY_PLC',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE = 'OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE_EDUCERE = 'OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE_EDUCERE',
  OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE_FOUNDERS = 'OPERATIONS_REPORTS_STUDENT_COURSES_BY_STATE_FOUNDERS',
  PA_TAX_DATA = 'PA_TAX_DATA',
  PASS_FAIL_BY_STATE = 'PASS_FAIL_BY_STATE',
  PASS_FAIL_REPORTS = 'PASS_FAIL_REPORTS',
  PAYMENT_CODE_ANALYSIS = 'PAYMENT_CODE_ANALYSIS',
  PAYMENT_CODE_ANALYSIS_YEAR_MONTH = 'PAYMENT_CODE_ANALYSIS_YEAR_MONTH',
  PAYMENT_CODE_USAGE = "PAYMENT_CODE_USAGE",
  PAYMENT_DISCREPANCY_REPORT = "PAYMENT_DISCREPANCY_REPORT",
  PO_NUMBER_ANALYSIS = 'PO_NUMBER_ANALYSIS',
  PROCESSED = 'PROCESSED',
  PROVIDER_COURSE_TRANSACTION = 'PROVIDER_COURSE_TRANSACTION',
  PROVIDER_REPORTS = 'PROVIDER_REPORTS',
  REACTIVATED_COURSES = 'REACTIVATED_COURSES',
  REGISTERED_COURSE_REPORT_AND_EXPORT = 'REGISTERED_COURSE_REPORT_AND_EXPORT',
  REGISTERED_PAYMENT_REPORT_AND_EXPORT = 'REGISTERED_PAYMENT_REPORT_AND_EXPORT',
  REGISTRATION_NOTES = 'REGISTRATION_NOTES',
  REGISTRATION_DETAIL_REMOVE = 'REGISTRATION_DETAIL_REMOVE',
  REGISTRATIONS = 'REGISTRATIONS',
  REGISTRATIONS_MORE_INFORMATION = 'REGISTRATIONS_MORE_INFORMATION',
  REGISTRATIONS_DELETED = 'REGISTRATIONS_DELETED',
  REGISTRATIONS_COMMUNICATOINS = 'REGISTRATIONS_COMMUNICATIONS',
  REGISTRATIONS_PAYMENTS = 'REGISTRATIONS_PAYMENTS',
  REGISTRATIONS_PAYMENTS_VIEW = 'REGISTRATIONS_PAYMENTS_VIEW',
  REGISTRATIONS_COURSE_VIEW = 'REGISTRATIONS_COURSE_VIEW',
  REGISTRATIONS_REFUNDS = 'REGISTRATIONS_REFUNDS',
  REPORTS = 'REPORTS',
  SALES_TERRITORY_REPORTS = 'SALES_TERRITORY_REPORTS',
  SALES_TERRITORY_REPORTS_COUNTS = 'SALES_TERRITORY_REPORTS_COUNTS',
  SALES_TERRITORY_REPORTS_REVENUE = 'SALES_TERRITORY_REPORTS_REVENUE',
  SCHOOL_REGISTRATIONS_FOR_INVOICING = 'SCHOOL_REGISTRATIONS_FOR_INVOICING',
  SCHOOL_TYPE = 'SCHOOL_TYPE',
  STUDENT_GRADE_IMPORT = 'STUDENT_GRADE_IMPORT',
  SYSTEM_CONTACT_TITLE = 'SYSTEM_CONTACT_TITLE',
  SYSTEM_HEALTH_REPORTS = 'SYSTEM_HEALTH_REPORTS',

  //#endregion
  // --------- SIS -----------
  // #region
  COURSE_CATALOG = 'COURSE_CATALOG',
  STUDENT_REGISTRATIONS_REPORT = 'STUDENT_REGISTRATIONS_REPORT',
  STUDENT_GRADES_REPORT = 'STUDENT_GRADES_REPORT',
  STUDENT_ACTIVITY_REPORT = 'STUDENT_ACTIVITY_REPORT',
  REGISTRATION_PAYMENTS_REPORT = 'REGISTRATION_PAYMENTS_REPORT',
  STUDENT_LRS_ACTIVITY_REPORT = 'STUDENT_LRS_ACTIVITY_REPORT',
  EMPLOYEE_SCHOOL_VIEW = 'EMPLOYEE_SCHOOL_VIEW'
  // #endregion
  ,
  TIMEZONE_MANAGEMENT = 'TIMEZONE_MANAGEMENT',
  CUSTOMER_REPORTS_ADMIN_ACCESS_LOGS = 'CUSTOMER_REPORTS_ADMIN_ACCESS_LOGS',
  CUSTOMER_REPORTS_STUDENT_PROGRESS_REPORT = 'CUSTOMER_REPORTS_STUDENT_PROGRESS_REPORT',
}
