<div [formGroup]="formGroup">
    <div class="row mb-3" *ngIf="config.disabled ? !config.disabled() : true">
        <div class="col-12">
            <div class="edtell-card p-3">
                <div class="row">
                    <div class="col-12 d-flex">
                        <h5>{{creditLanguage}} Information</h5>
                        <span [ngClass]="['credit-logo-container', getCreditClass('mastercard')]">
                            <img src="./assets/credit-card-logos/mastercard.png" alt="Mastercard">
                        </span>
                        <span [ngClass]="['credit-logo-container', getCreditClass('visa')]">
                            <img src="./assets/credit-card-logos/visa.png" alt="Visa">
                        </span>
                        <span [ngClass]="['credit-logo-container', getCreditClass('discover')]">
                            <img src="./assets/credit-card-logos/discover.png" alt="Discover">
                        </span>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-6">
                        <label for="cc-number">Credit Card Number <span class="required-asterisk">*</span></label>
                        <input id="cc-number" [ngClass]="['form-control', validationClass(config.ccNumberKey)]" [formControlName]="config.ccNumberKey" type="tel" autocomplete="cc-number" ccNumber>
                        <span *ngFor="let v of ccNumberValidators">
                            <div class="invalid-feedback d-block" *ngIf="hasError(config.ccNumberKey, v.key) == true">{{v.message}}</div>
                        </span>
                    </div>
                </div>
            
                <div class="row form-group">
                    <div class="col-3">
                        <label for="cc-cvc">CSC <span class="required-asterisk">*</span></label>
                        <span class="fake-link ml-2" (click)="cscWhatsThis()">What's This?</span>
                        <input id="cc-cvc" [ngClass]="['form-control', validationClass(config.cscKey)]" [disabled]="config.disabled" [formControlName]="config.cscKey" type="tel" autocomplete="off" ccCVC>
                        <span *ngFor="let v of cscValidators">
                            <div class="invalid-feedback d-block" *ngIf="hasError(config.cscKey, v.key) == true">{{v.message}}</div>
                        </span>
                    </div>
                    <div class="col-3">
                      <label for="cc-exp-date-month">Expiration Month <span class="required-asterisk">*</span></label>
                      <select id="cc-exp-date-month" [ngClass]="['form-control', validationClass(config.expirationMonthKey)]" [disabled]="config.disabled" [formControlName]="config.expirationMonthKey" type="tel" autocomplete="cc-exp">
                          <option *ngFor="let option of monthOptions" [value]="option.value">{{option.text}}</option>
                      </select>
                      <span *ngFor="let v of monthValidators">
                        <div class="invalid-feedback d-block" *ngIf="hasError(config.expirationMonthKey, v.key) == true">{{v.message}}</div>
                    </span>
                    </div>
                    <div class="col-3">
                        <label for="cc-exp-date-year">Expiration Year <span class="required-asterisk">*</span></label>
                        <select id="cc-exp-date-year" [ngClass]="['form-control', validationClass(config.expirationYearKey)]" [disabled]="config.disabled" [formControlName]="config.expirationYearKey" type="tel" autocomplete="cc-exp">
                            <option *ngFor="let option of yearOptions" [value]="option.value">{{option.text}}</option>
                        </select>
                        <span *ngFor="let v of yearValidators">
                            <div class="invalid-feedback d-block" *ngIf="hasError(config.expirationYearKey, v.key) == true">{{v.message}}</div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="edtell-card p-3">
                <div class="row">
                    <div class="col-12 bill-to">
                        <h5>Payor:</h5> <span>(Name and address as it appears on your credit card account)</span>
                    </div>
                    <div class="col-2">
                        <app-srs-form-field [config]="firstNameFormFieldConfig"></app-srs-form-field>
                    </div>
                    <div class="col-2">
                      <app-srs-form-field [config]="lastNameFormFieldConfig"></app-srs-form-field>
                  </div>
                    <div class="col-12">
                        <app-srs-full-address-widget [config]="fullAddressConfig"></app-srs-full-address-widget>
                    </div>
                    <div *ngIf="config.schoolAsPayerConfig" class="col-12">
                      <p class="text-danger">*IF SCHOOL IS PAYING WITH CREDIT CARD* Please check the box below.</p>
                      <app-srs-checkbox [config]="config.schoolAsPayerConfig"></app-srs-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>