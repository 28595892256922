<div class="container">
  <div class="d-flex row p-2 py-3 justify-content-center">
    <h1 style="text-align: center">Login</h1>
  </div>
  
  <div class="d-flex row p-2 justify-content-center">
    <img src="../../assets/edtell_logo_745_x_356_with_tagline.png" style="width: 30%; height: 30%" (click)="cleanLocalStorage()" title="Click to Clean Local Storage">
  </div>
  
  <div class="d-flex row p-2 justify-content-center">
    <div id="gsign-in-parent"></div>
  </div>
  
</div>