import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SecurityService } from "../services/security.service";
import { AccessLevelSecurity, AccessLevelSecurityConfig, SecurityConfig } from "../../edtell-admin/interfaces/route-security-config.interface";
import { falsey } from "../../edtell-utilities/functions/falsey.function";


@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {
  

  constructor(private securityService: SecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq: HttpRequest<any> = req;
    if(!falsey(this.securityService.routeSecurityTrail)) {
      const currentPageSecurityConfig: SecurityConfig = this.securityService.routeSecurityTrail[this.securityService.routeSecurityTrail.length - 1];

      // Security config won't be present for the home page
      if(!falsey(currentPageSecurityConfig)) {
        const accessLevels: AccessLevelSecurityConfig | AccessLevelSecurityConfig[] = currentPageSecurityConfig.accessLevels;
        if(accessLevels instanceof AccessLevelSecurity) {
          const accessLevelSecurityConfig: AccessLevelSecurityConfig = accessLevels.config;
          modifiedReq = req.clone({
            headers: req.headers.set("page-permissions", `${accessLevelSecurityConfig.objectName}`)
          })
        } else if (accessLevels instanceof Array) {
          const pagePermissionsString: string = accessLevels.map((curr: AccessLevelSecurityConfig) => `${curr.objectName}`).join(", ");
          modifiedReq = req.clone({
            headers: req.headers.set("page-permissions", pagePermissionsString)
          })
        }
      }
    }
    return next.handle(modifiedReq);
  }

}

export const PermissionsInterceptorProvider = {
  // This is the provider that gets used in a module. This statement allows for import of the provider insted of the component.
  provide: HTTP_INTERCEPTORS,
  useClass: PermissionsInterceptor,
  multi: true,
};