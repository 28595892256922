import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { LangUtils } from "../../../edtell-portal/namespaces/lang-utils.namespace";
import { EdtellFormControl } from "../../../srs-forms/classes/edtell-form-control";
import { EdtellFormGroup } from "../../../srs-forms/classes/edtell-form-group.class";
import { SrsFormFieldComponent } from "../../../srs-forms/components/elements/srs-form-field/srs-form-field.component";
import { SrsSelectBoxComponent } from "../../../srs-forms/components/elements/srs-select-box/srs-select-box.component";
import { SrsFormState } from "../../../srs-forms/enumerations/form-state.enum";
import { SrsFormFieldConfig } from "../../../srs-forms/interfaces/elements/srs-form-field-config.interface";
import { SrsSelectBoxConfig } from "../../../srs-forms/interfaces/elements/srs-select-box-config.interface";
import { SrsMultiElementWidgetConfig } from "../../../srs-forms/interfaces/widgets/srs-multi-element-widget-config";
import { ApiKeyService } from "../../services/api-key.service";

@Component({
  selector: "app-api-key-management-form",
  templateUrl: "./api-key-management-form.component.html",
  styleUrls: ["./api-key-management-form.component.scss"]
})
export class ApiKeyManagementFormComponent implements OnInit {

  jwt : string

  formGroup: EdtellFormGroup = new EdtellFormGroup(SrsFormState.WRITE, {
    name: new EdtellFormControl(),
    userId: new EdtellFormControl(),
    expiration: new EdtellFormControl()
  });

  widget: SrsMultiElementWidgetConfig<any> = {
    formGroup: this.formGroup,
    elements: [
      [
        {
          component: SrsFormFieldComponent,
          config: LangUtils.type<SrsFormFieldConfig>({
            key: "name",
            size: 6,
            title: "Name"
          })
        }
      ],
      [
        {
          component: SrsFormFieldComponent,
          config: LangUtils.type<SrsFormFieldConfig>({
            key: "userId",
            size: 6,
            settings: {
              numberInput: true
            },
            title: "User Id"
          })
        }
      ],
      [
        {
          component: SrsSelectBoxComponent,
          config: LangUtils.type<SrsSelectBoxConfig<number>>({
            key: "expiration",
            size: 6,
            title: "Expiration",
            options: [
              {
                text: "1 Day",
                value: 86400000
              },
              {
                text: "1 Month",
                value: 2592000000
              },
              {
                text: "1 Year",
                value: 946080000000
              },
              {
                text: "Infinite",
                value: null,
                allowNullValue: true
              }
            ]
          })
        }
      ]
    ]
  };

  constructor(private apiKeyService : ApiKeyService) {}

  ngOnInit() {}

  async generateKey(){

    this.formGroup.submitted = true;
    if(this.formGroup.invalid){
      return;
    }

    this.jwt = (await this.apiKeyService.createApiKey(this.formGroup.value).pipe(first()).toPromise()).jwt;
  }
}
