import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssetDocumentPickerDialogComponent } from './components/asset-document-picker-dialog/asset-document-picker-dialog.component';
import { AssetPickerDialogComponent } from './components/asset-picker-dialog/asset-picker-dialog.component';
import { MoreButtonDialogComponent } from './components/more-button-dialog/more-button-dialog.component';
import { EdtellMaterialModule } from '../material/material.module';
import { SrsFormsModule } from '../srs-forms/srs-forms.module';
import { EdtellAgGridModule } from '../edtell-ag-grid/edtell-ag-grid.module';
import { AgGridModule } from 'ag-grid-angular';
import { GridResetButtonComponent } from '../edtell-ag-grid/components/grid-reset-button/grid-reset-button.component';
import { StatusBarPlusComponent } from '../edtell-ag-grid/components/status-bar-plus/status-bar-plus.component';
import { EntitySelectComponent } from '../edtell-ag-grid/components/entity-select/entity-select.component';
import { GridClearButtonComponent } from '../edtell-ag-grid/components/grid-clear-button/grid-clear-button.component';
import { EntityControlLinkComponent } from '../edtell-ag-grid/components/entity-control-link/entity-control-link.component';
import { LinkedEntitiesDialogComponent } from './components/linked-entities-dialog/linked-entities-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { EdtellContainersModule } from '../edtell-containers/edtell-containers.module';

@NgModule({
  declarations: [
    AssetPickerDialogComponent,
    AssetDocumentPickerDialogComponent,
    MoreButtonDialogComponent,
    LinkedEntitiesDialogComponent
  ],
  imports: [
    CommonModule,
    EdtellMaterialModule,
    SrsFormsModule,
    EdtellAgGridModule,
    RouterModule,
    EdtellContainersModule
  ],
  exports: [
    AssetPickerDialogComponent,
    AssetDocumentPickerDialogComponent,
    MoreButtonDialogComponent,
    LinkedEntitiesDialogComponent
  ]
})
export class EdtellAssetManagementModule { }
