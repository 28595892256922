import { CdkDragEnter, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Attribute } from '../../../edtell-portal/interfaces/attribute.interface';
import { PinnedEntity, PinnedEntityAttribute, PinningGridConfig } from '../../../edtell-portal/interfaces/pinning-grid-config.interface';
import { AttributeService } from '../../../edtell-portal/services/attribute.service';

@Component({
  selector: 'app-pinning-grid',
  templateUrl: './pinning-grid.component.html',
  styleUrls: ['./pinning-grid.component.scss']
})
export class PinningGridComponent implements OnInit, AfterViewInit {

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  pinnedEntitiesAttribute: Attribute;

  @Input()
  public config: PinningGridConfig;

  pinnedEntities: PinnedEntity<any>[];
  minimizeTab: boolean = false;
  title: string;


  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public activeContainer;

  constructor(
    private viewportRuler: ViewportRuler,
    private attributeService: AttributeService,
    private sanitizer: DomSanitizer
  ) {
    this.target = null;
    this.source = null;
  }

  async ngOnInit(){
    if (this.config == null) {
      throw new Error('Pinning Grid Config not being passed into pinning grid template');
    }

    this.title = this.config.title;

    let data = this.config.loadDataCallback();

    if (data instanceof Observable) {
      data = data.toPromise();
    }
    if (data instanceof Promise) {
      data = await data;
    }

    // Handles the case where a user doesn't have the attribute for pins
    // Attribute will be created as the user pins entities and then no longer be null after that
    if (data === null) {
      return;
    }

    this.pinnedEntitiesAttribute = data.attribute;

    // Sort by order index
    data.entities = data.entities.sort((a, b) => {
      return a.orderIndex - b.orderIndex;
    });

    for (let e of data.entities) {
      if (e.asset != null) {
        let unsanitizedUrl = 'data:image/' + e.asset.assetType + ';base64,' + e.asset.assetBase64;
        e.sanatizedAsset = this.sanitizer.bypassSecurityTrustUrl(unsanitizedUrl); // TODO Sanatize
      }
    }

    this.pinnedEntities = data.entities;
    this.unpinDeletedCustomers();
  }

  public itemTrackBy(item) {
    return item.id;
  }

  ngAfterViewInit() {
    const phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  dropListDropped() {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    this.target = null;
    this.source = null;
    this.activeContainer = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.pinnedEntities, this.sourceIndex, this.targetIndex);
    }

    this.updateAttributeOrder();

  }

  cdkDropListEntered(e: CdkDragEnter) {
    const drag = e.item;
    const drop = e.container;

    if (drop === this.placeholder) {
      return true;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(
      dropElement.parentElement.children,
      this.source ? phElement : sourceElement
    );
    const dropIndex = __indexOf(
      dropElement.parentElement.children,
      dropElement
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = dropElement.clientWidth + 'px';
      phElement.style.height = dropElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    requestAnimationFrame(() => {
      this.placeholder._dropListRef.enter(
        drag._dragRef,
        drag.element.nativeElement.offsetLeft,
        drag.element.nativeElement.offsetTop
      );
    });
  }

  unpinDeletedCustomers() {

    let deleting = false;

    // Removes pins with missing entities
    this.pinnedEntities = _.remove(this.pinnedEntities, (e) => {

      if (e.entity != null) {
        return true;
      }

      deleting = true;
      return false;
    });

    // If nothing needs to be done, exit function
    if (!deleting) {
      return;
    }

    this.updateAttributeOrder();

  }
  getEntityTitle(e: PinnedEntity<any>): string {
    return !!this.config.titleRenderFunction ? this.config.titleRenderFunction(e) : e.entity[this.config.entityTitleKey];
  }

  truncateString(string) {
    return _.truncate(string, {'length': 30});
  }
  togglePinnedEntitiesTab() {
    this.minimizeTab = !this.minimizeTab;
  }
  onClick(resp: PinnedEntity<any>) {
    this.config.onItemClick(resp.entity);
  }

  updateAttributeOrder(){
    let attribute: PinnedEntityAttribute[] = [];

    // reorder, no need to check order because it is done in constructor
    // create an attribute data for each
    for (let i = 0; i < this.pinnedEntities.length; ++i) {
      let e = this.pinnedEntities[i];
      this.pinnedEntities[i].orderIndex = i;
      attribute.push({
        entityId: e.entity.id,
        orderIndex: e.orderIndex
      });
    }
    // update attribute
    this.pinnedEntitiesAttribute.attributeValue = JSON.stringify(attribute);
    this.attributeService.upsertUserAttribute(this.pinnedEntitiesAttribute).subscribe((resp) => {
    });
  }

}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}
