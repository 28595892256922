import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { EdtellCommentsModule } from '../edtell-comments/edtell-comments.module';
import { EdtellContainersModule } from '../edtell-containers/edtell-containers.module';
import { EdtellMaterialModule } from '../material/material.module';
import { SrsFormsModule } from '../srs-forms/srs-forms.module';
import { SrsSearchBarModule } from '../srs-search-bar/srs-search-bar.module';
import { NavigationNotAuthorizedComponent } from './components/403/components/navigation-not-authorized/navigation-not-authorized.component';
import { FourOhFourComponent } from './components/404/components/four-oh-four/404.component';
import { BreadcrumbNavigationComponent } from './components/breadcrumb-navigation/breadcrumb-navigation.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MultiActionSnackbarComponent } from './components/multi-action-snackbar/multi-action-snackbar.component';
import { NavigationLinkComponent } from './components/navigation-link/navigation-link.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { RoleChoiceComponent } from './components/role-choice/role-choice.component';
import { RouterWrapperComponent } from './components/router-wrapper/router-wrapper.component';
import { EnrollmentFooterComponent } from '../enrollment-footer/enrollment-footer.component';

@NgModule({
  declarations: [
    NavigationLinkComponent,
    LoginComponent,
    FourOhFourComponent,
    MultiActionSnackbarComponent,
    ErrorDialogComponent,
    NavigationNotAuthorizedComponent,
    FooterComponent,
    HomeComponent,
    BreadcrumbNavigationComponent,
    RouterWrapperComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    RoleChoiceComponent,
    EnrollmentFooterComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatBadgeModule,
    SrsSearchBarModule,
    SrsFormsModule,
    RouterModule,
    EdtellMaterialModule,
    EdtellCommentsModule,
    EdtellContainersModule
  ],
  exports: [
    NavigationLinkComponent,
    LoginComponent,
    FourOhFourComponent,
    MultiActionSnackbarComponent,
    ErrorDialogComponent,
    NavigationNotAuthorizedComponent,
    FooterComponent,
    EnrollmentFooterComponent,
    HomeComponent,
    BreadcrumbNavigationComponent,
    RouterWrapperComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    RoleChoiceComponent,
    EdtellMaterialModule
  ]
})
export class EdtellPortalModule { }
