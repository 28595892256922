import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Asset } from '../../../../../edtell-asset-management/interfaces/asset.interface';

@Component({
  selector: 'app-asset-download-link',
  templateUrl: './asset-download-link.component.html',
  styleUrls: ['./asset-download-link.component.scss']
})
export class AssetDownloadLinkComponent implements OnInit {

  @Input()
  assetDownloadLinkData: Asset;

  assetDownloadURL;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  setLink() {
    this.assetDownloadURL = this.createDownloadLink(this.assetDownloadLinkData.assetBase64, this.assetDownloadLinkData.assetType);
  }

  createDownloadLink(base64Data, contentType?) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.convertBase64ToBlobData(base64Data, contentType)));
  }

  convertBase64ToBlobData(base64Data: string, contentType: string = 'image/jpeg', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


}
