import {Component, ElementRef, ViewChild} from '@angular/core';
import {cloneDeep, find, findIndex, remove} from 'lodash';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import {EdtellListBuilderConfig, EdtellListBuilderOption} from '../../../interfaces/elements/edtell-list-builder-config.interface';
import { SrsSelectOption } from '../../../interfaces/elements/srs-select-box-config.interface';

@Component({
  selector: 'app-edtell-list-builder',
  templateUrl: './edtell-list-builder.component.html',
  styleUrls: ['./edtell-list-builder.component.scss']
})
export class EdtellListBuilderComponent extends SrsFormElement<EdtellListBuilderConfig<any>> {

  @ViewChild('leftList')
  leftList: ElementRef;

  @ViewChild('rightList')
  rightList: ElementRef;

  leftOptions: EdtellListBuilderOption<any>[];
  rightOptions: EdtellListBuilderOption<any>[];

  constructor() {
    super();
  }

  onElementInit() {
    this.configureOptionLocations();
    this.control.valueChanges.subscribe(() => {
      this.configureOptionLocations();
    });
  }

  configureOptionLocations() {
    this.leftOptions = cloneDeep(this.config.options);
    this.rightOptions = [];

    for (let value of this.control.value) {
      let option = find(this.leftOptions, (option) => {
        return this.getOptionValue(option) == value;
      });
      if (option) {
        this.moveOptionToRightList(option);
      }
    }

    for (let i in this.leftOptions) {
      let option = this.leftOptions[i];
      option.originalIndex = +i;
      option.homeBase = 'left';
    }

    for (let i in this.rightOptions) {
      let option = this.rightOptions[i];
      option.originalIndex = +i;
      option.homeBase = 'right';
    }
  }

  moveOptionToRightList(option?: SrsSelectOption<any>, value?: any) {

    if (option == null) {
      option = find(this.leftOptions, (option) => {
        return this.getOptionValue(option) == value;
      });
    }

    let removedOptions = remove(this.leftOptions, option);
    let homeBaseRemoved = remove(removedOptions, (option) => {
      return option.homeBase == 'right';
    });

    // console.log(homeBaseRemoved, this.leftOptions);

    for (let homeOption of homeBaseRemoved) {
      let optionAdded = false;
      for (let i in this.rightOptions) {
        let rightOption = this.rightOptions[+i];

        if (rightOption.homeBase != 'right') {
          continue;
        }

        if (rightOption.originalIndex > homeOption.originalIndex) {
          this.rightOptions.splice(+i, 0, homeOption);
          optionAdded = true;
          break;
        }
      }

      if (!optionAdded) {
        this.rightOptions.push(homeOption);
      }
    }

    this.rightOptions = [
      ...this.rightOptions,
      ...removedOptions
    ];
  }

  moveOptionToLeftList(option?: SrsSelectOption<any>, value?: any) {

    if (option == null) {
      option = find(this.rightOptions, (option) => {
        return this.getOptionValue(option) == value;
      });
    }

    let removedOptions = remove(this.rightOptions, option);
    let homeBaseRemoved = remove(removedOptions, (option) => {
      return option.homeBase == 'left';
    });

    // console.log(homeBaseRemoved, this.leftOptions);

    for (let homeOption of homeBaseRemoved) {
      let optionAdded = false;
      for (let i in this.leftOptions) {
        let leftOption = this.leftOptions[+i];

        if (leftOption.homeBase != 'left') {
          continue;
        }

        if (leftOption.originalIndex > homeOption.originalIndex) {
          this.leftOptions.splice(+i, 0, homeOption);
          optionAdded = true;
          break;
        }
      }

      if (!optionAdded) {
        this.leftOptions.push(homeOption);
      }
    }

    this.leftOptions = [
      ...this.leftOptions,
      ...removedOptions
    ];
  }

  leftFocus() {
    // unselect options
    for (let option of this.rightList.nativeElement.options) {
      option.selected = false;
    }
  }

  rightFocus() {
    // unselect options
    for (let option of this.leftList.nativeElement.options) {
      option.selected = false;
    }
  }

  onLeftArrowClick() {
    let rightOptions = Array.apply(null, this.rightList.nativeElement.options);
    let selectedOptions = rightOptions.filter(option => {
      return option.selected;
    });

    let controlValue = this.control.value;
    for (let option of selectedOptions) {
      remove(controlValue, (item) => {
        return item == option.value;
      });
      this.moveOptionToLeftList(null, option.value);
    }

    this.control.setValue(controlValue, {emitEvent: false});
  }

  onRightArrowClick() {
    let leftOptions = Array.apply(null, this.leftList.nativeElement.options);
    let selectedOptions = leftOptions.filter(option => {
      return option.selected;
    });

    let controlValue = this.control.value;
    for (let option of selectedOptions) {
      controlValue.push(this.getOptionValue(option));
      this.moveOptionToRightList(null, option.value);
    }

    this.control.setValue(controlValue, {emitEvent: false});
  }

  getOptionValue(option: SrsSelectOption<any>) {
    if (option.allowNullValue) {
      return option.value;
    }
    return option.value != null ? option.value : option.text;
  }

  onUpArrowClick() {
    let rightOptions = Array.apply(null, this.rightList.nativeElement.options);
    let selectedOptions = rightOptions.filter(option => {
      return option.selected;
    });

    if (selectedOptions.length == 0) {
      return;
    }

    for (let option of selectedOptions) {
      let index = findIndex(this.rightOptions, (opt) => {
        return this.getOptionValue(opt) == option.value;
      });

      if (index == 0) {
        continue;
      }

      let controlValue = this.control.value;

      swapArrayElements(this.rightOptions, index, index - 1);
      swapArrayElements(controlValue, index, index - 1);
    }
  }

  onDownArrowClick() {
    let rightOptions = Array.apply(null, this.rightList.nativeElement.options);
    let selectedOptions = rightOptions.filter(option => {
      return option.selected;
    });


    if (selectedOptions.length == 0) {
      return;
    }

    for (let i = selectedOptions.length; i > 0; i--) {
      let option = selectedOptions[i - 1];
      let index = findIndex(this.rightOptions, (opt) => {
        return this.getOptionValue(opt) == option.value;
      });

      if (index == this.rightOptions.length - 1) {
        continue;
      }

      let controlValue = this.control.value;

      swapArrayElements(this.rightOptions, index, index + 1);
      swapArrayElements(controlValue, index, index + 1);
    }
  }

}

let swapArrayElements = function (arr, indexA, indexB) {
  // console.log('swap',arr,indexA,indexB)
  var temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
};
