import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccessSecureStructuralDirective } from './directives/access-secure-structural.directive';
import { AccessSecureDirective } from './directives/access-secure.directive';

@NgModule({
  declarations: [
    AccessSecureDirective,
    AccessSecureStructuralDirective
  ],
  exports: [
    AccessSecureDirective,
    AccessSecureStructuralDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SrsSecurityModule { }