import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { EdtellLocationService } from "../services/edtell-location.service";

@Injectable({
  providedIn: "root",
})
export class EdtellLocationGuard implements CanActivate, CanActivateChild {

  constructor(private edtellLocationService : EdtellLocationService){}

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.updateRoutes(childRoute);
    return true;
  }


  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.updateRoutes(childRoute);
    return true;
  }

  private updateRoutes(route: ActivatedRouteSnapshot) {

    route.queryParamMap

    if(this.edtellLocationService.root == null){
      this.edtellLocationService.root = {
        path: window.location.pathname,
        queryParams: route.queryParamMap
      };
    }

    this.edtellLocationService.previous = this.edtellLocationService.current;
    this.edtellLocationService.current = {
      path: window.location.pathname,
      queryParams: route.queryParamMap
    };
  }

}
