import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Provider, Type } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { SrsSearch } from "./classes/srs-search.abstract";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { SearchTypeSearch } from "./searches/system/search-type.search";

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatOptionModule,
  ],
  providers: [SearchTypeSearch],
  declarations: [SearchBarComponent],
  exports: [SearchBarComponent],
})
export class SrsSearchBarModule {
  static withSearches(searches: Type<SrsSearch>[]): ModuleWithProviders<SrsSearchBarModule> {
    return {
      ngModule: SrsSearchBarModule,
      providers: [
        ...searches
      ],
    };
  }
}
