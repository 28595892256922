import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { PreviousRouteService } from '../../../../services/previous-route.service';

@Component({
  selector: 'app-navigation-not-authorized',
  templateUrl: './navigation-not-authorized.component.html',
  styleUrls: ['./navigation-not-authorized.component.scss']
})
export class NavigationNotAuthorizedComponent implements OnInit {

  constructor(private router: Router, private previousRouteService : PreviousRouteService) {
  }

  ngOnInit() {
  }

  async goHome() {
    // Don't understand it, but the two router navigates are needed here
    // I think this has something to do with how the router is being passed to its immediate children
    // in the app.module.
    await this.router.navigate(['']);
    await this.router.navigate(['']);
  }

}
