import { Input, OnInit, Directive } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { SrsElementConfig } from "../interfaces/elements/srs-element-config.interface";
import { SrsWidgetConfig } from "../interfaces/widgets/srs-widget-config.interface";

@Directive()
export abstract class SrsFormWidget<E extends SrsWidgetConfig<SrsElementConfig>> implements OnInit {

    @Input()
    group: FormGroup;

    @Input()
    config: E

    @Input()
    data: Observable<any> | Promise<any>

    async ngOnInit() {

        // Sets the default form group ref for each element
        if (this.config?.elements != null) {
            for (let elm of this.config.elements) {
                elm.group = elm.group == null ? this.config.formGroup : elm.group
            }
        }

        await this.onWidgetInit();
    }

    getSize(elm: SrsElementConfig) {
        return "col-md-" + elm.size
    }


    abstract onWidgetInit();

}