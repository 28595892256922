import {
  SrsSearchBarModule,
  AuthGuard,
  ErrorInterceptorProvider,
  NavigationChangeService,
  LoginService,
  EdtellMaterialModule,
  EdtellPortalModule,
  ApiDocumentationModule,
  PermissionsInterceptorProvider,
  ShoppingCartInterceptorProvider,
  JwtInterceptorProvider
} from "@edtell-common"
// Angular Imports
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Routing
import { Routing } from './app.routing';
// Component Imports
import { AppComponent } from './components/app.component';
// 3rd Party Imports
import { ProviderChildrenGuard } from './utility/guards/provider-children.guard';
import { CustomerSearch } from './modules/customer/services/customer.search';
import { UserSearch } from './modules/admin/services/users.search';
import { ObjectSearch } from './modules/admin/services/object.search';
import { CatalogSearch } from './modules/catalog/services/catalog.search';
import { ProviderSearch } from './modules/providers/services/provider.search';
import {RegistrationSearch} from "./modules/registrations/searches/registration.search";
import {KnowledgeBaseService} from './modules/knowledge-base/services/knowledge-base.service';
import { EdtellRouterInterceptorManagerRegistration } from "./modules/edtell-common/edtell-portal/services/edtell-router-interceptor.manager";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    Routing,
    HttpClientModule,
    SrsSearchBarModule.withSearches([
      CustomerSearch,
      UserSearch,
      ObjectSearch,
      CatalogSearch,
      ProviderSearch,
      RegistrationSearch,
    ]),
    EdtellMaterialModule,
    EdtellPortalModule,
    ApiDocumentationModule
  ],
  providers: [
    NavigationChangeService,
    ErrorInterceptorProvider,
    PermissionsInterceptorProvider,
    JwtInterceptorProvider,
    LoginService,
    AuthGuard,
    ProviderChildrenGuard,
    KnowledgeBaseService,
    ShoppingCartInterceptorProvider,
    EdtellRouterInterceptorManagerRegistration
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
