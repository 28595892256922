export enum EmitterEvent {
  OBJECT_PERMISSION_CHANGE_EVENT,
  USER_PERMISSION_CHANGE_EVENT,
  MANAGE_USER_SYSTEM_ATTRIBUTE_EVENT,
  CUSTOMER_SIDEBAR_EVENT,
  FORM_SWITCH_TO_EDIT_EVENT,
  PAYMENT_MODULES_EVENT,
  RERENDER_BREADCRUMB_TRAIL,
  LICENSE_SEAT_UPDATE_EVENT,
  SIDEBAR_REFRESH,
  SIDEBAR_BUTTON_REFRESH,
  SIDEBAR_NOTE_EVENT,
  CATALOG_SIDEBAR_DISABLE_EVENT,
  DISABLE_RELATED_COURSES_EVENT,
  DELIVERY_METHOD_SIDEBAR_EVENT,
  CATALOG_PROFILE_PROVIDER_CHANGE_EVENT,
  ATTRIBUTE_WIDGET_CHANGE_EVENT,
  PROVIDER_CHANGE_DELIVERY_METHOD_OPTIONS_EVENT,
  ROLE_PERMISSION_EVENT_CHANGE,
  CREATED_CUSTOMER_NOTE_EVENT,
  CREATED_PROVIDER_NOTE_EVENT,
  PROVIDER_SIDEBAR_EVENT,
  DELIVERY_METHOD_EVENT,
  KNOWLEDGE_BASE_CATEGORY_UPDATE_EVENT,
  KNOWLEDGE_BASE_USER_PINS_UPDATE_EVENT,
  SIGN_OUT,
  PROCESSED_REFRESH_JOB_BAR
}
