<div id="commentInlineContainer">

  <h2>{{_title}} - Comments and Discussions</h2>

  <br/>

  <div class="create-comment-container">
    <textarea [id]="'share-comment-text-box'" class="form-control" [(ngModel)]="newCommentText" (ngModelChange)="onChange($event)"></textarea>
    <div class="create-comment-share-button d-flex align-items-end justify-content-end">
      <button [id]="'share-comment-button-' + _itemId + '-' + _objectId" class="btn btn-tertiary srs-save-btn" (click)="addComment()" [disabled]="disableSubmit()">Share Comment</button>
    </div>
  </div>


  <div class="comment-tree-container" *ngFor="let node of commentResponseMap; index as i">
    <div class="flex" *ngIf="!hidden[i]">
      <div [id]="'hide-note-tree-toggle-' + i + '-' + _itemId + '-' + _objectId" class="side-bar inline" (click)="toggleHideCommentTreeNode(i)"></div>
      <div class="inline">
        <app-comments-tree-node (commentChangedEmitter)="commentChangedHandler($event)" [node]="node" [objectId]="_objectId" [itemId]="_itemId" [index]="i" (endMe)="deleteRootNode($event)"></app-comments-tree-node>
      </div>
    </div>
    <span *ngIf="hidden[i]">
      <span [id]="'expand-note-tree-toggle - ' + i + '-' + _itemId + '-' + _objectId" class="expand" (click)="toggleHideCommentTreeNode(i)"><i class="far fa-plus-square"></i>
        {{commentResponseMap[i].firstName}} {{commentResponseMap[i].lastName}} Commented On {{formatDate(commentResponseMap[i].comment.dateCreated)}} - Expand
      </span>
    </span>
  </div>

</div>
