import { Component } from '@angular/core';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { EdtellFormControl } from '../../../classes/edtell-form-control';
import { SrsFormFieldConfig } from '../../../interfaces/elements/srs-form-field-config.interface';
import { SrsNumberRangeWidgetConfig } from '../../../interfaces/elements/srs-number-range-config.interface';

@Component({
  selector: 'app-srs-number-range-widget',
  templateUrl: './srs-number-range-widget.component.html',
  styleUrls: ['./srs-number-range-widget.component.scss']
})
export class SrsNumberRangeWidgetComponent extends SrsFormWidget<SrsNumberRangeWidgetConfig> {

  minControl: EdtellFormControl
  maxControl: EdtellFormControl

  minConfig: SrsFormFieldConfig
  maxConfig: SrsFormFieldConfig

  constructor() {
    super();
  }

  onWidgetInit() {

    this.minControl = <EdtellFormControl>this.config.formGroup.get(this.config.minConfig.key);
    this.maxControl = <EdtellFormControl>this.config.formGroup.get(this.config.maxConfig.key)

    let minValidators = this.minControl.getValidators()
    let maxValidators = this.maxControl.getValidators();

    minValidators.push(this.rangeValidator());
    maxValidators.push(this.rangeValidator())

    this.minControl.setValidators(minValidators);
    this.maxControl.setValidators(maxValidators);

    this.config.minConfig.validators.push({
      key: "valueError",
      message: "Min value must be less than or equal to max."
    })

    this.config.maxConfig.validators.push({
      key: "valueError",
      message: "Max value must be greater than or equal to min."
    })

    this.minConfig = {
      key: this.config.minConfig.key,
      title: this.config.minConfig.title,
      group: this.config.minConfig.group == null ? this.config.formGroup : this.config.minConfig.group,
      size: 6,
      validators: this.config.minConfig.validators,
      onInput: () => {
        this.config.formGroup.get(this.maxConfig.key).updateValueAndValidity();
      },
      settings: {
        placeholder: this.config.minConfig.settings != null ? this.config.minConfig.settings.placeholder : null,
        showRequired: this.config.minConfig.settings != null ? this.config.minConfig.settings.showRequired : false,
        numberInput: true
      }
    }

    this.maxConfig = {
      key: this.config.maxConfig.key,
      title: this.config.maxConfig.title,
      size: 6,
      group: this.config.maxConfig.group == null ? this.config.formGroup : this.config.maxConfig.group,
      validators: this.config.maxConfig.validators,
      onInput: () => {
        this.config.formGroup.get(this.minConfig.key).updateValueAndValidity();
      },
      settings: {
        placeholder: this.config.maxConfig.settings != null ? this.config.maxConfig.settings.placeholder : null,
        showRequired: this.config.maxConfig.settings != null ? this.config.maxConfig.settings.showRequired : false,
        numberInput: true
      }
    }
  }

  rangeValidator() {
    return () => {
      if (
        this.minControl.value == null || this.maxControl.value == null ||
        this.minControl.value == '' || this.maxControl.value == '' ||
        +this.minControl.value == NaN || +this.maxControl.value == NaN
      ) {
        return null;
      }

      if (+this.minControl.value > +this.maxControl.value) {
        return {
          valueError: "valueErr"
        }
      }
    }
  }

  get colWidthClass(): string {
    let widthClass = "col-md-6";
    if (this.config.settings && this.config.settings.alignVertical) {
      widthClass = "col-12";
    }
    return widthClass;
  }

}
