import { EventEmitter, Injectable } from '@angular/core';
import { SnackbarActionConfig } from '../components/multi-action-snackbar/snackbar-action-config';

@Injectable({
  providedIn: "root"
})
export class NotificationService {

  constructor() {
  }

  notificationEmitter = new EventEmitter<SnackbarActionConfig>();

  sendNotification(event: SnackbarActionConfig) {
    this.notificationEmitter.emit(event);
  }

}


