<div class="front-facing dialog-wrapper">
    <div class="row">
        <div class="col-12 d-flex justify-content-between">
            <div class="spacer-div" *ngIf="!hideDialogCloseButton"></div>
            <h1 class="text-center w-100">{{header}}</h1>
            <div class="button-div text-right" *ngIf="!hideDialogCloseButton">
                <button class="btn btn-primary" (click)="onXButtonClick()"><i class="fas fa-times"></i></button>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <ng-content></ng-content>
        </div>
    </div>
    
    <div class="row" *ngIf="config.submitButtonConfig">
        <div class="col-12 submit-button-container">
            <button class="btn btn-primary" [disabled]="submitDisabled" (click)="config.submitButtonConfig.submitCallback()">{{config.submitButtonConfig.text}}</button>
        </div>
    </div>
</div>