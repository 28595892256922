<ng-template [ngIf]="loaded">
  <div class="row pt-2 px-0" *ngIf="getCurrentViewType() != null">
    <div class="col-12">
      <a [routerLink]="" (click)="goBack()">Back</a>
    </div>
  </div>

  <div class="row pt-2 px-0">
    <div class="col-12">
      <container-element [ngSwitch]="getCurrentViewType()">
        <app-distribution-email-view
          *ngSwitchCase="'email'"
          [distribution]="selectedDistribution"
          [config]="config"
        ></app-distribution-email-view>
        <app-distribution-google-chat-view
          *ngSwitchCase="'google-chat'"
          [distribution]="selectedDistribution"
          [config]="config"
        ></app-distribution-google-chat-view>
        <app-distribution-data-view
          *ngSwitchDefault
          [config]="config"
          (distributionSelected)="switchView($event)"
        ></app-distribution-data-view>
      </container-element>
    </div>
  </div>
</ng-template>
