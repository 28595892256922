import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SidebarButtonConfig, SidebarConfig } from '../interfaces/sidebar-config.interface';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sidebarState: Observable<SidebarConfig> = new Observable((o) => { this.observer = o })
  observer: Observer<SidebarConfig>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => {
        let child = this.route.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          }else {

            if (child.snapshot.data.sidebar != null) {
              let sidebar: SidebarConfig = child.snapshot.data.sidebar

              sidebar._route = child.snapshot
              sidebar._router = router

              for (let b of sidebar.buttons) {
                this.assignParent(b)
              }
            }

            return child.snapshot.data.sidebar;
          }
        }
        return null;
      })).subscribe((data: any) => {
        this.observer != null ? this.observer.next(data) : null
      });

  }

  private assignParent(parent: SidebarButtonConfig) {
    if (Array.isArray(parent.route)) {
      for (let child of parent.route) {
        child._parent = parent;
        this.assignParent(child);
      }
    }
  }

}
