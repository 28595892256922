import { OnInit, AfterViewInit, Input, Directive } from "@angular/core"
import { SidebarIndicatorConfig } from "../interfaces/sidebar-indicator-data"
import { NavigationEnd } from "@angular/router";

@Directive()
export abstract class SidebarIndicatorComponent implements OnInit {

    @Input()
    data: SidebarIndicatorConfig

    async ngOnInit() {

        this.data._sidebar._router.events.subscribe(async (e) => {
            if (e instanceof NavigationEnd) {
                await this.refresh();
            }
        })

        await this.onIndicatorInit();
        await this.refresh();
    }

    abstract onIndicatorInit();
    abstract refresh();

}