import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EdtellCalendarWidgetConfig } from '../../interfaces/edtell-calendar-widget-config.interface';

@Component({
  selector: 'app-edtell-calendar-widget',
  templateUrl: './edtell-calendar-widget.component.html',
  styleUrls: ['./edtell-calendar-widget.component.scss']
})
export class EdtellCalendarWidgetComponent implements OnInit {

  @Input()
  config: EdtellCalendarWidgetConfig;

  today: Date;
  startDate: Date;
  endDate: Date;
  calendarSizeClass: string;

  dayCount = 1;

  activeCount = 0;
  inactiveCount = 0;
  future = 0;


  doneLoading = false;

  constructor() {

  }

async ngOnInit() {

  this.today = new Date();

  this.endDate = moment(this.config.endMonth).toDate(); // not really the end date per se since it will hold the 1st as the day value
  this.startDate = moment(this.config.startMonth).toDate();
  this.calendarSizeClass = this.config.calendarSizeClass ?? 'col-12 col-md-4 col-xl-3';

  this.doneLoading = true;
}



/* ---------- Function Functions --------- */
// TODO - Make generic
/**
 * Returns a number to display for a given day based on months after start date, rowIndex and columnIndex
 * @param date - Start Date for a given student
 * @param rIndex - Row index for a given month
 * @param cIndex - Column Index for a given row
 * @param mIndex - Number of months after the startDate
 */
getDayDisplayNumber(date, rIndex, cIndex, mIndex) {
  let monthStartDay = this.getMonthStartDay(date, mIndex);
  let daysInMonth = this.getDaysInMonth(date, mIndex);

  // Top left of new month
  if (rIndex === 0 && cIndex === 0) {
    this.dayCount = 1;
  }

  // Top row and col index is at least the start day index
  if (rIndex === 0 && cIndex >= monthStartDay) {
    return this.dayCount++;
  }

  // Returns null if the dayCount is greater than the number of days in the given month
  if (this.dayCount > daysInMonth) {
    return null;
  }

  // Returns the day count and then increments it if it is just a normal week
  if (rIndex > 0) {
    return this.dayCount++;
  }
}

/**
 * Determines the number of rows to draw for a given month
 * @param date - Start Date for given student
 * @param mIndex - Number of months after the startDate
 */
getRowCount(date, mIndex) {
  let daysInMonth = this.getDaysInMonth(date, mIndex);
  let startDate = this.getMonthStartDay(date, mIndex);

  if (startDate > 4 && daysInMonth === 31) {
    return 6;
  }

  if (startDate > 5 && daysInMonth === 30) {
    return 6;
  }

  if (startDate === 0 && daysInMonth === 28) {
    return 4;
  }

  return 5;
}

/**
 * Generates an ID value for a given cell for styling
 * Returns blank if the cell doesn't contain a day number
 * @param date - Start Date for a given student
 * @param mIndex - Number of months after the startDate
 * @param rIndex - Row index for given month
 * @param cIndex - Column index for given row
 */
getIDValue(date, mIndex, rIndex, cIndex) {
  let dayNumber = this.getDayNumberForIDs(date, rIndex, cIndex, mIndex);

  if (dayNumber == null) {
    return 'blank';
  } else {
    return 'cal-day-' + this.getMonthName(date, mIndex) + '-' + dayNumber;
  }
}

getFieldStyling(date, rIndex, cIndex, mIndex) {
  let dayNumber = this.getDayNumberForIDs(date, rIndex, cIndex, mIndex);
  let month = moment(date).add(mIndex, 'month').month();
  let year = this.getYear(date, mIndex);

  let dateKey = moment(new Date(+year, month, dayNumber)).format('YYYY-MM-DD');
  
  let classArr = [];
  
  if (this.config.onDayClick) {
    classArr.push('clickable')
  }

  let styleMap = this.config.styleMap;

  if (styleMap == undefined) {
    return classArr;
  }

  let classType = styleMap.get(dateKey);

  if (classType == 'disabled') {
    classArr.shift(); // remove clickable
  }

  classType != undefined ? classArr.push(classType) : null;

  return classArr;
}

getCellValue(date, rIndex, cIndex, mIndex) {
  let dayNumber = this.getDayNumberForIDs(date, rIndex, cIndex, mIndex);
  let month = moment(date).add(mIndex, 'month').month();
  let year = this.getYear(date, mIndex);

  if (dayNumber == null) {
    return;
  }

  return moment(new Date(+year, month, dayNumber)).format('YYYY-MM-DD');
}

onDayClick(event) {
  if (this.config.onDayClick == undefined) {
    return;
  }

  let cell = event.srcElement;

  if (cell.classList.contains('disabled')) {
    return;
  }

  let date = cell.dataset.date;
  if (date == undefined) {
    return;
  }

  this.config.onDayClick(date);
}

/* ---------- Helper Functions ---------- */
/**
 * Gets the month name as a string for a given month *index* number of months after the startDate
 * @param date - Start Date for a given student
 * @param index - number of months after the startDate
 */
getMonthName(date, index): string {
  return moment(date).add(index, 'month').format('MMMM');
}

/**
 * Returns the year as a string for an *index* number of months after the startDate
 * @param date - Start Date for Given Student
 * @param index - Number of months after the startDate
 */
getYear(date, index): string {
  return moment(date).add(index, 'month').format('YYYY');
}

/**
 * Returns the number of days in a given mongh
 * @param date - Start Date for the student
 * @param index - Index of how many months after startDate
 */
getDaysInMonth(date: Date, index): number {
  return moment(date).add(index, 'month').daysInMonth();
}

/**
 *  Returns a 0 indexed day of the week for the start of a month
 * @param date - startDate for this student's registrations
 * @param mIndex - The index of what month this is
 */
getMonthStartDay(date, mIndex): number {
  return moment(date).add(mIndex, 'month').startOf('month').weekday();
}

/**
 * Returns the day number for a specified cell at rIndex, cIndex.
 * Functionally the same as getDayDisplayNumber() but without the day incrementing.
 * @param date - Start Date for this student
 * @param rIndex - Row Index for the (startDate + mIndex)'th month
 * @param cIndex - Column Index for the rowIndex'th week
 * @param mIndex - Month count after startDate
 */
getDayNumberForIDs(date, rIndex, cIndex, mIndex, log=false) {
  let monthStartDay = this.getMonthStartDay(date, mIndex);
  let daysInMonth = this.getDaysInMonth(date, mIndex);

  if (log)
    console.log(monthStartDay, daysInMonth, rIndex, cIndex, mIndex)

  if (rIndex === 0 && cIndex === 0) {
    this.dayCount = 1;
  }

  if (rIndex === 0 && cIndex >= monthStartDay) {
    return this.dayCount;
  }


  if (this.dayCount > daysInMonth) {
    return null;
  }

  if (rIndex > 0) {
    return this.dayCount;
  }
}

getDayNumber(rIndex, cIndex, mIndex) {

}

/**
 * Generates an array with i number of items for the ngFor to work
 * @param i - number of items in the array
 */
getCounterArray(i: number) {
  return new Array(i);
}

/**
 * Calculates the ceiling of the number of months in between the start and end dates
 */
get numberOfMonths() {
  return Math.ceil(moment(this.endDate).diff(moment(this.startDate), 'months', true)) + 1;
}
}
