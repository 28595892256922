import {Component} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {isEqual} from 'lodash';
import {SrsFormWidget} from '../../../abstracts/srs-form-widget.abstract';
import {EdtellFormControl} from '../../../classes/edtell-form-control';
import {EdtellFormGroup} from '../../../classes/edtell-form-group.class';
import {SrsFormState} from '../../../enumerations/form-state.enum';
import {SrsTableWidgetColumnConfig, SrsTableWidgetConfig} from '../../../interfaces/widgets/srs-table-widget-config.interface';
import { OwnershipDialogComponent } from '../../../../edtell-controls/components/ownership-dialog/ownership-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AttributeService } from '../../../../edtell-portal/services/attribute.service';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { ObjectName } from '../../../../edtell-admin/enums/object-name.enum';
import { SecurityConfig } from '../../../../edtell-admin/interfaces/route-security-config.interface';

@Component({
  selector: 'app-srs-table-widget',
  templateUrl: './srs-table-widget.component.html',
  styleUrls: ['./srs-table-widget.component.scss']
})
export class SrsTableWidgetComponent extends SrsFormWidget<SrsTableWidgetConfig<any>> {

  elementConfigs: RowInfo[] = [];
  danglingRowKey: string;
  originalData: any[];

  originalFormValue: any;

  own: OwnershipDialogComponent

  constructor(
    public dialog: MatDialog,
    public attributeService : AttributeService
  ) {
    super();
  }

  get state() {
    return this.config.state;
  }

  onWidgetInit() {
    this.elementConfigs = [];
    this.originalData = _.cloneDeep(this.config.data);
    this.initTable();
  }

  initTable() {
    for (let key in this.config.data) {
      let data = this.config.data[key];
      let rowInfo = this.addRow(key, data);
      rowInfo.key = key;
      this.elementConfigs.push(rowInfo);
    }

    if (this.state === SrsFormState.WRITE) {
      this.addDanglingRow();
    }

    this.originalFormValue = this.config.formGroup.getRawValue();

  }

  resetTable() {
    this.danglingRowKey = undefined;
    for (let row of this.elementConfigs) {
      this.removeRow(row);
    }
    this.config.data = _.cloneDeep(this.originalData);
    this.elementConfigs = [];
    this.initTable();
  }

  toggleReadWrite(state: SrsFormState) {
    state = this.state;
    if (state === SrsFormState.READ) {
      this.removeRow(this.elementConfigs[this.elementConfigs.length - 1]);
    } else {
      this.addDanglingRow();
    }
    // change all rowGroups to the right form state
    for (let i in this.elementConfigs) {
      let row = this.elementConfigs[i];
      row.rowGroup.state = state;
    }
  }

  private addRow(rowKey: any, data?: any): RowInfo {

    let row: SrsTableWidgetColumnConfig[] = [];
    let elementInfo: RowElementInfo[] = [];
    let rowGroup = new EdtellFormGroup(this.state, {});

    for (let def of this.config.colDef) {

      let elmKey = rowKey + '-' + def.config.key;
      let colDefClone = _.cloneDeep(def);

      colDefClone.config.group = rowGroup;
      colDefClone.config.key = elmKey;

      // On change function wrap to modify reference values
      let onChangeFunc = colDefClone.config.onChange;
      colDefClone.config.onChange = (control) => {

        if (data != null) {
          data[def.config.key] = control.value;
        }

        if (onChangeFunc != null) {
          onChangeFunc(control);
        }

        this.config.formGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
      };

      let value = data != null ? data[def.config.key] : null;
      let control = new EdtellFormControl(value != null ? value : '', colDefClone.validators);

      rowGroup.addControl(elmKey, control);
      elementInfo.push({
        columnConfig: colDefClone,
        control: control
      });
      row.push(colDefClone);
    }


    this.config.formGroup.removeControl('row-' + rowKey); // not pointless
    this.config.formGroup.addControl('row-' + rowKey, rowGroup);

    return {
      col: row,
      elementInfo: elementInfo,
      rowGroup: rowGroup,
      key: rowKey,
      data: data
    };
  }

  removeRow(row: RowInfo) {

    // Remove row from attributes
    let dataIndex = this.config.data.indexOf(row.data);
    this.config.data.splice(dataIndex, 1);

    // Remove Row Form Group
    this.config.formGroup.removeControl('row-' + row.key);

    // Remove from elements config
    let rowIndex = this.elementConfigs.indexOf(row);
    this.elementConfigs.splice(rowIndex, 1);

    this.config.formGroup.markAsDirty();

  }

  openOwnershipDialog(data) {
    let config = {
      object: {
        id: data.id,
        dateCreated: data.dateCreated,
        dateModified: data.dateModified,
        createdBy: data.createdBy,
        modifiedBy: data.modifiedBy,
        owner: data.owner
      },
      saveCallback: async (foo) => {
        data.owner = foo.owner
        data.modifiedBy = foo.modifiedBy
        data.dateModified = moment().utc().format('MM/DD/YYYY HH:mm:ss');        
        await this.attributeService.upsertCustomerAttribute(data).pipe(first()).toPromise();
      }
    }
    const dialogRef = this.dialog.open(OwnershipDialogComponent, {
      width: '400px',
      data: config != null ? { config: config } : { object: config.object, saveFunc: config.saveCallback }
    });    
    return config
  }

  getSecurityConfig(data) : SecurityConfig {    
    if(data.itemType == 'provider'){
      return {
        accessLevels: {
          accessLevel: 3,
          objectName: ObjectName.PROVIDER_ATTRIBUTES
        },
        ownershipData: data,
        localOwnership:true, 
        roleSecurity: {
          systemAdmin: true,
        }
      }
    }
    if(data.itemType == 'customer'){
      return {
        accessLevels: {
          accessLevel: 3,
          objectName: ObjectName.CUSTOMER_ATTRIBUTES
        },
        ownershipData: data,
        localOwnership:true, 
        roleSecurity: {
          systemAdmin: true,
        }
      }
    }
  }

  isColumnRequired(col: SrsTableWidgetColumnConfig) {
    if (this.config.formGroup.state == SrsFormState.READ) {
      return false;
    }
    return col.validators != null ? col.validators.includes(Validators.required) : false;
  }

  isDanglingCell(row: RowInfo): boolean {
    return row.key == this.danglingRowKey;
  }

  private addDanglingRow() {

    let data = {};
    let row = this.addRow(this.elementConfigs.length, data); // Extra row
    row.data = data;

    // Remove Validators from row elements
    for (let info of row.elementInfo) {
      info.control.clearValidators();
    }

    this.danglingRowKey = row.key;

    // Remove row Group
    this.config.formGroup.removeControl('row-' + row.key);

    let added = false;
    for (let key in row.elementInfo) {

      let info = row.elementInfo[key];
      let control = info.control;

      // Create a call back that will define a new row of data
      let originalOnChangeFunction = info.columnConfig.config.onChange;
      info.columnConfig.config.onChange = () => {
        if (added) {
          originalOnChangeFunction(control);
          return;
        }

        // Add refrence to the form data value to the list of attributes,        this.config.data.push(data)
        this.config.data.push(data);

        // Add refrence to the form data value to the list of attributes,        this.config.data.push(data)

        // Add to table form group
        this.config.formGroup.addControl('row-' + row.key, row.rowGroup);

        // Create another dangling row
        this.addDanglingRow();

        // Set validators for all controls in group
        for (let elm of row.elementInfo) {
          elm.control.setValidators(elm.columnConfig.validators);
          elm.control.updateValueAndValidity();
        }

        // Execute original callback
        if (originalOnChangeFunction != null) {
          originalOnChangeFunction(control);
        }

        // Ensure this does not repeat
        info.columnConfig.config.onChange = originalOnChangeFunction;
        added = true;

      };
    }

    this.elementConfigs.push(row);
  }

  get dataChanged(): boolean {
    return !isEqual(this.originalFormValue, this.config.formGroup.getRawValue());
  }

  getColToolTip(col: SrsTableWidgetColumnConfig) {
    return col?.tooltip;
  }

}

interface RowInfo {
  elementInfo: RowElementInfo[]
  col: SrsTableWidgetColumnConfig[]
  rowGroup: EdtellFormGroup
  key?: string,
  data?: any
}

interface RowElementInfo {
  control: FormControl,
  columnConfig: SrsTableWidgetColumnConfig
}
