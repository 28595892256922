import { Component, Inject } from '@angular/core';
import { Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../edtell-portal/services/notification.service";
import { uniqueValidator } from '../../validators/unique.validator';
import { EdtellDialogComponent } from '../../../srs-forms/abstracts/edtell-dialog.component';
import { EdtellFormControl } from '../../../srs-forms/classes/edtell-form-control';
import { EdtellFormGroup } from '../../../srs-forms/classes/edtell-form-group.class';
import { SrsCheckboxComponent } from '../../../srs-forms/components/elements/srs-checkbox/srs-checkbox.component';
import { SrsFormFieldComponent } from '../../../srs-forms/components/elements/srs-form-field/srs-form-field.component';
import { SrsFormState } from '../../../srs-forms/enumerations/form-state.enum';
import { SrsCheckboxConfig } from '../../../srs-forms/interfaces/elements/srs-checkbox-config.interface';
import { SrsFormFieldConfig } from '../../../srs-forms/interfaces/elements/srs-form-field-config.interface';
import { SrsMultiElementWidgetConfig } from '../../../srs-forms/interfaces/widgets/srs-multi-element-widget-config';
import { LangUtils } from "../../../edtell-portal/namespaces/lang-utils.namespace";
import { DialogActionType } from '../../../edtell-portal/enums/dialog-action-type.enum';
import { Attribute } from '../../../edtell-portal/interfaces/attribute.interface';
import { EdtellDialogData } from '../../../srs-forms/interfaces/abstracts/edtell-dialog-data.interface';

@Component({
  selector: 'app-attribute-dialog',
  templateUrl: './attribute-dialog.component.html',
  styleUrls: ['./attribute-dialog.component.scss']
})
export class AttributeDialogComponent extends EdtellDialogComponent {

  dialogData: EdtellDialogData;
  formGroup: EdtellFormGroup;
  objectTitle = "Attribute";
  dataLoaded = false;
  systemAttributeOnOpen: boolean;

  isAdd: boolean;
  isEdit: boolean;

  attributeConfig: SrsMultiElementWidgetConfig<any>;

  constructor(public dialogRef: MatDialogRef<AttributeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EdtellDialogData<Attribute>,
              public notificationService: NotificationService,) {

    super(data.action as DialogActionType, notificationService);
    this.dialogData = data;
    if (data.action == DialogActionType.ADD) {
      this.objectTitle = "Add " + this.objectTitle;
      this.isAdd = true;
    }
    if (data.action == DialogActionType.EDIT) {
      this.objectTitle = "Edit " + this.objectTitle;
      this.isEdit = true;
    }
  }

  dialogOnInit() {
    this.editFormControlConfig.deleteSecurityConfig = {
      accessLevels: [
        {
          accessLevel: 3,
          objectName: this.dialogData.securityObject
        }],
      ownershipData: this.dialogData.objectData,
      localOwnership: true
    };

    this.editFormControlConfig.entityControl.controlButtons[1].disabledValidator = () => {
      return this.disableSave();
    };

    this.addFormControlConfig.entityControl.controlButtons[1].disabledValidator = () => {
      return false;
    };
    this.dataLoaded = true

  }

  async initForm() {
    let otherAttributeNames = []
    if (this.dialogData.objectData.otherAttributes) {
      otherAttributeNames = this.dialogData.objectData.otherAttributes.map((att) => {
        return att.attributeName;
      })
    }

    this.formGroup = new EdtellFormGroup(
      SrsFormState.WRITE,
      {
      'attributeName': new EdtellFormControl(this.data.objectData.attributeName, [Validators.required, uniqueValidator(otherAttributeNames)]),
      'attributeValue': new EdtellFormControl(this.data.objectData.attributeValue, [Validators.required]),
      'attributeDescription': new EdtellFormControl(this.data.objectData.attributeDescription),
      'visibleOnView': new EdtellFormControl(this.data.objectData.visibleOnView||false),
      'systemAttribute': new EdtellFormControl(this.data.objectData.systemAttribute||false),
      'encrypted' : new EdtellFormControl(this.data.objectData.encrypted || false)
    });

    this.systemAttributeOnOpen = this.data.objectData.systemAttribute||false;

    this.attributeConfig = {
      formGroup: this.formGroup,
      elements: [
        [
          {
            component: SrsFormFieldComponent,
            config: LangUtils.type<SrsFormFieldConfig>({
              size: 12,
              key: 'attributeName',
              title: 'Attribute Name',
              disabled: () => {
                return this.isSystemAttributeOnOpen()
              },
              validators: [
                {
                  key: 'required',
                  message: 'Attribute Name is required.'
                },
                {
                  key: 'nonunique',
                  message: 'Attribute Name must be unique from one another.'
                }
              ]
            })
          },
          {
            component: SrsFormFieldComponent,
            config: LangUtils.type<SrsFormFieldConfig>({
              size: 12,
              key: 'attributeValue',
              title: 'Attribute Value',
              disabled: () => {
                return this.isSystemAttributeOnOpen()
              },
              validators: [
                {
                  key: 'required',
                  message: 'Attribute Value is required.'
                }
              ]
            })
          },
          {
            component: SrsFormFieldComponent,
            config: LangUtils.type<SrsFormFieldConfig>({
              size: 12,
              key: 'attributeDescription',
              title: 'Attribute Description',
              disabled: () => {
                return this.isSystemAttributeOnOpen()
              },
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              key: 'visibleOnView',
              size: 4,
              title: 'Visible On View',
              disabled: () => {
                return this.isSystemAttributeOnOpen()
              },
              settings: {
                padding: 0
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              key: 'systemAttribute',
              size: 4,
              title: 'System Attribute',
              disabled: () => {
                return this.isSystemAttributeOnOpen()
              },
              settings: {
                padding: 0
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              key: 'encrypted',
              size: 4,
              title: 'Encrypt Attribute',
              disabled: () => {
                return this.isSystemAttributeOnOpen()
              },
              settings: {
                padding: 0
              }
            })
          }
        ]
      ]
    }
  }

  create(reqBody) {
    return reqBody;
  }

  update(reqBody) {
    return null;
  }

  delete(id) {
    return null;
  }

  get attributeName() {
    return this.formGroup.get('attributeName');
  }

  get attributeValue() {
    return this.formGroup.get('attributeValue');
  }

  get attributeDescription() {
    return this.formGroup.get('attributeDescription');
  }

  get visibleOnView() {
    return this.formGroup.get('visibleOnView');
  }

  get systemAttribute() {
    return this.formGroup.get('systemAttribute');
  }

  isSystemAttributeOnOpen() {
    return this.systemAttributeOnOpen;
  }

  disableSave() {
    return (this.systemAttributeOnOpen && !this.isAdd) || (this.formGroup.isOriginal || this.formGroup.invalid);
  }

  // onSave() {
  //   this.customerService.isUniqueEmail(this.email.value, this.data).subscribe(resp => {
  //     if (resp == true) {
  //       this.dialogRef.close(this.email.value);
  //     } else {
  //       this.notificationService.notificationEmitter.emit(
  //         {message: 'Email is currently in use outside of this organization hierarchy. Please use a different email', duration: 5000});
  //     }
  //   });
  // }

}
