import * as moment from "moment";

// Standard format for date when rendering outside of form generation
// component or outside of a grid.
export function srsFormatDate(date: string) {
    return moment(date).format('MM/DD/YYYY');
}

export function srsFormatDateData(date: string) {
    return moment(date).format('YYYY-MM-DD');
}

export function srsFormatDateTimeToDate(date: string) {
    return moment.utc(date).local().format('MM/DD/YYYY');
} 

export function srsFormatDateFromDateTime(date: string) {
    return moment.utc(date).local().format('YYYY-MM-DD');
}

export function srsFormatDateTime(date: string) {
    return moment.utc(date).local().format('MM/DD/YYYY, h:mm:ss A')
}