import { Component, Input, OnInit } from '@angular/core';
import { EmitterEvent } from '../../../edtell-portal/enums/emitter-events.enum';
import { EventEmitterService } from '../../../edtell-portal/services/event-emitter.service';
import { EntityControlButtonOption } from '../../interfaces/entity-control-config';

@Component({
  selector: 'app-entity-control-buttons',
  templateUrl: './entity-control-buttons.component.html',
  styleUrls: ['./entity-control-buttons.component.scss']
})
export class EntityControlButtonsComponent implements OnInit {
  
  @Input() options: EntityControlButtonOption[];


  constructor(private eventEmitterService: EventEmitterService) { }

  ngOnInit() {
    if (this.options != null) {

      for(let o of this.options){
        if (!o.class) {
          o.class = "srs-btn-" + o.text.replace(/[^a-zA-Z ]/g, "").toLowerCase().replace(' ', '-');
        }
      }
    }
  }
  
  focus() {
    this.eventEmitterService.emit(EmitterEvent.FORM_SWITCH_TO_EDIT_EVENT, null);
  }
  
  async doCallBack(button: EntityControlButtonOption) {
    if (button.focusOnClick !== undefined && button.focusOnClick !== false) {
      this.focus();
    }
    if (button.submit) {
      button.formGroup.submitted = true;
    }
    if (button.callback != null) {
      button.callback();
    }
  }

}
