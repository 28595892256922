import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sidebarDropdownPosition]'
})
export class SidebarDropdownPositionDirective {
  
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    this.reposition()
  }

  ngOnChanges() {
    this.reposition()
  }

  private reposition() {
    let elm: HTMLElement = this.elementRef.nativeElement
    let parent: HTMLElement = <any>elm.parentElement
    this.renderer.setStyle(elm, "top", parent.offsetTop + "px");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.reposition();
  }

}
