import { Type } from "@angular/core";
import { SrsSearch } from "../classes/srs-search.abstract";
import { SrsSearchConfig } from "../interfaces/srs-search-config.interface";

export function SrsSearchInfo(config : SrsSearchConfig){
    return (Target : Type<SrsSearch>) => {
        Target.prototype.label = config.label
        Target.prototype.security = config.security
    }
}

