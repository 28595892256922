import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { update } from 'lodash';
import { Subscription } from 'rxjs';
import { EmitterEvent } from '../../../edtell-portal/enums/emitter-events.enum';
import { EdtellStreamApi } from '../../../edtell-portal/interfaces/edtell-stream-api.interface';
import { LangUtils } from '../../../edtell-portal/namespaces/lang-utils.namespace';
import { EventEmitterService } from '../../../edtell-portal/services/event-emitter.service';
import { JobHistoryViewComponentConfig } from '../../interfaces/job-history-component-config.interface';
import { JobProgressUpdate } from '../../interfaces/job-progress-update.interface';
import { DistributionDialogConfig } from '../../interfaces/processed-distribution-dialog-config.interface';
import { ProcessedJobStatusBarConfig } from '../../interfaces/processed-job-status-bar-config';
import { ProcessedJobStatus } from '../../interfaces/processed-job-status.interface';
import { DistributionDialogComponent } from '../distribution-views/distribution-dialog/distribution-dialog.component';
import { JobHistoryViewComponent } from '../job-history-view/job-history-view.component';

type JobDisplayData = (ProcessedJobStatus & {progressValue? : number});

@Component({
  selector: 'app-processed-job-status-bar',
  templateUrl: './processed-job-status-bar.component.html',
  styleUrls: ['./processed-job-status-bar.component.scss'],
})
export class ProcessedJobStatusBarComponent implements OnInit, OnDestroy {
  @Input()
  config: ProcessedJobStatusBarConfig;

  loaded: boolean = false;
  jobs: JobDisplayData[];
  displayedJobs: ProcessedJobStatus[];

  intrvalKey: any;

  showLogData : boolean = false;
  progressSubscription : EdtellStreamApi<JobProgressUpdate[]>
  inProgressJobs = new Map<number, JobDisplayData>()
  progressQueueIds = new Set<number>()

  private eventSub : Subscription

  constructor(private matDialog : MatDialog, private router : Router, private eventEmitterService : EventEmitterService) {}

  async ngOnInit() {
    await this.refreshJobs();
    this.loaded = true;

    if (this.config.refreshInterval != null) {
      this.intrvalKey = setInterval(async () => {
        await this.refreshJobs();
      }, this.config.refreshInterval);
    }

    this.showLogData = await this.config.api.showProcessedLogData();
    this.eventSub = this.eventEmitterService.subscribe(EmitterEvent.PROCESSED_REFRESH_JOB_BAR, () => {
      console.log("Got the refresh event")
      this.refreshJobs().then()
    })
  }

  viewDistributions(jobStatus : ProcessedJobStatus){
    this.matDialog.open(DistributionDialogComponent, {
      width: "1000px",
      data: LangUtils.type<DistributionDialogConfig>({
        jobStatus: jobStatus,
        api: this.config.api
      })
    });
  }

  async ngOnDestroy() {
    clearInterval(this.intrvalKey);
    if(this.progressSubscription != null){
      this.progressSubscription.cancel()
    }
    
    if(this.eventSub != null){
      this.eventSub.unsubscribe();
    }

  }

  private async refreshJobs() {

    let jobIds = this.config.jobId
    if(!Array.isArray(jobIds)){
      jobIds = [jobIds]
    }
    
    this.jobs = (await this.config.api.getJobHistoryList({jobIds: jobIds, age: new Date(Date.now() - 8.64e+7),jobDisplayLimit: this.config.jobDisplayLimit, includeLogs: true, includeDismissed: false})).map(j => {
      let jobData : JobDisplayData = { ...j };
      let creationTime = new Date(j.dateCreated);
      // TODO: We may need a utc to etc conversion
      // TODO: This can probably be done much better using moment

      jobData.dateCreated = `${creationTime.getHours() % 12}:${creationTime.getMinutes()} ${
        creationTime.getHours() < 12 ? 'AM' : 'PM'
      } ${creationTime.getMonth() + 1}/${creationTime.getDate()}/${creationTime.getFullYear()}`;

      if(this.config.reportProgress === true){
        if(j.progress === true){
          let oldJobData = this.inProgressJobs.get(j.id)
          if(oldJobData?.progressValue != null){
            jobData.progressValue = oldJobData.progressValue
          }
          this.inProgressJobs.set(j.id, jobData)
        }else if(this.inProgressJobs.has(j.id)){
          this.inProgressJobs.delete(j.id)
          this.progressQueueIds.delete(j.id)

          if(this.progressQueueIds.size == 0){
            this.progressSubscription.cancel();
            this.progressSubscription = null;
          }
        }
      }
      return jobData;
    });

    // Progress Updates Streaming
    if(this.inProgressJobs.size > 0 && this.reloadProgressStream([...this.inProgressJobs.keys()])){

      if(this.progressSubscription != null){
        this.progressSubscription.cancel()
      }

      // See notes for reloadProgressStream. Hopefully this can be gutted in the future.
      this.progressQueueIds.clear()
      for(let j of this.inProgressJobs.keys()){
        this.progressQueueIds.add(j)
      }

      this.progressSubscription = this.config.api.getJobProgress([...this.inProgressJobs.keys()])
      this.progressSubscription.data.subscribe((updates) => {
        for(let u of updates){
          let j = this.inProgressJobs.get(u.id)
          if(u.complete == true){
            this.inProgressJobs.delete(u.id)
          }else{
            j.progressValue = u.value
          }
        }
      })
    }

    this.displayedJobs = this.config.jobDisplayLimit != null ? this.jobs.slice(0, this.config.jobDisplayLimit) : this.jobs;
      // this.displayedJobs = this.displayedJobs.concat(this.displayedJobs)
      // this.displayedJobs = []
  }

  onDismissClick(jobQueueId: number) {}

  openLogsDialog(j : ProcessedJobStatus){
    this.matDialog.open(JobHistoryViewComponent, {
      width: "800px",
      data: LangUtils.type<JobHistoryViewComponentConfig>({
        jobArchiveId: j.id,
        api: this.config.api
      })
    });
  }

  isJobComplete(j : ProcessedJobStatus){
    return j.executionStatus != "waiting" && j.executionStatus != "processing"
  }

  isJobSuccessful(j : ProcessedJobStatus){
    return j.executionStatus == "complete"
  }

  navigateToJobHistory(){
    return this.config.api.navigateToJobHistoryPage(this.config.jobId, this.router)
  }

  // This update for the progress stream when a progress update is addded should probably automatically happen on the server and be included in the exsting stream.
  // In the future we should just pass the job ids that the status bar is loading to the logs controller
  // This would report progress for the user/origin/jobId they are requesting and include newly queued jobs in the stream.
  // This is kinda an edge case but it should be optimized eventually. In the mean time the frontend will just kill the current
  // stream and reload the queue.
  private reloadProgressStream(loadedIds : number[]) : boolean{
    if(this.progressSubscription == null){
      return true;
    }

    for(let l of loadedIds){
      if(!this.progressQueueIds.has(l)){
        return true
      }
    }

    return false;
  }

  async dismiss(j : ProcessedJobStatus){
    await this.config.api.dismissArchive(j.id)
    this.jobs = this.jobs.filter((elm) => {
      return elm != j
    })
    this.displayedJobs = this.config.jobDisplayLimit != null ? this.jobs.slice(0, this.config.jobDisplayLimit) : this.jobs;
  }

  async requeue(j : ProcessedJobStatus){
    await this.config.api.requeueJob(j.id)
    await this.refreshJobs();
  }

  get allowLogView(){
    return this.showLogData;
  } 

}
