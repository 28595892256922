import { ANALYZE_FOR_ENTRY_COMPONENTS, ModuleWithProviders, NgModule } from "@angular/core";
import { EdtellHeaderComponent } from "./components/edtell-header/edtell-header.component";
import { EdtellHeaderHelperDirective } from "./directives/edtell-header-helper.directive";


@NgModule({
  declarations: [
    EdtellHeaderComponent,
    EdtellHeaderHelperDirective
  ],
  exports: [
    EdtellHeaderComponent
  ]
})
export class EdtellHeaderModule {
  static withComponents(components?: any): ModuleWithProviders<any> {
    return {
      ngModule: EdtellHeaderModule,
      providers: [
          {provide: ANALYZE_FOR_ENTRY_COMPONENTS, useValue: components, multi: true}
      ]
    };
  }
}
