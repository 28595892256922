<!--Title -->
<div class="row" *ngIf="config.title != null">
    <div class="col-md-12">
        <div class="title-container">{{config.title}}</div>
    </div>
</div>

<!-- Image -->
<div class="row">
    <div class="col-md-12">
        <!-- Image Goes Here -->
        <img *ngIf="imageUrl != null" [src]="imageUrl"/>
    </div>
</div>

<input style="display: none" type="file" accept="image/*" (change)="onFileChanged($event)" #fileInput>

<!-- Edit Buttons EDIT MODE ONLY -->
<div class="row" *ngIf='state != null && state == "WRITE"'>
    <div class="col-md-12">
        <button class="btn btn-tertiary" (click)="fileInput.click()" [secure]="config.updateAssetSecurity">Upload</button>
        <button class="btn btn-tertiary" (click)="onChooseClick()" [secure]="config.updateAssetSecurity">Choose</button>
        <button class="btn btn-tertiary" [disabled]="asset === null" (click)="onRemoveClick()" [secure]="config.updateAssetSecurity">Remove</button>
    </div>
</div>