<div *ngIf="dialogReady">
    <div class="dialog-header">
        <h1 mat-dialog-title mat-dialog-draggable-title>{{actionTypeState | titlecase}} {{objectTitle}}</h1>
        <span aria-hidden="true" (click)="onNoClick()">&times;</span>
    </div>
    <mat-dialog-content>
        <div class="row">
            <div class="col-12" [ngSwitch]="actionTypeState">
              <app-entity-form-control *ngSwitchCase="'view'" [config]="viewFormControlConfig"></app-entity-form-control>
              <app-entity-form-control *ngSwitchCase="'edit'" [config]="editFormControlConfig"></app-entity-form-control>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <app-srs-checkbox [config]="filterCacheCheckboxConfig"></app-srs-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Column</th>
                            <th class="width-col">Width</th>
                            <th>Hide</th>
                            <th>Sort</th>
                            <th>Sort Index</th>
                            <th>Order</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let formGroup of columnsArray.controls; index as i">
                            <td>{{getColumnName(formGroup)}}</td>
                            <td><app-srs-form-field [config]="getWidthFormConfig(formGroup)"></app-srs-form-field></td>
                            <td><app-srs-checkbox [config]="getHideFormConfig(formGroup)"></app-srs-checkbox></td>
                            <td><app-srs-select-box [config]="getSortFormConfig(formGroup)"></app-srs-select-box></td>
                            <td><app-srs-select-box [config]="getSortIndexFormConfig(formGroup)"></app-srs-select-box></td>
                            <td *ngIf="formGroup.state == 'WRITE'">
                                <div class="d-flex flex-column align-items-center sort-cell">
                                    <span>
                                        <span (click)="sortUp(formGroup)"
                                            class="clickable"
                                            *ngIf="i != 0">
                                                <i class="fas fa-sort-up"></i>
                                            </span>
                                    </span>
                                    <span>
                                        <span (click)="sortDown(formGroup)"
                                            class="clickable"
                                            *ngIf="i != columnsArray.controls.length - 1">
                                            <i class="fas fa-sort-down"></i>
                                        </span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </mat-dialog-content>    
</div>
