import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { GridPreferenceService } from '../../../../edtell-portal/services/grid-preference.service';
import { EdtellAgGridConfig } from '../../../interfaces/edtell-ag-grid-config.interface';
import { globalGridOptions } from '../../../interfaces/global-grid-config';

@Component({
  selector: 'app-edtell-ag-grid-full-screen-dialog',
  templateUrl: './edtell-ag-grid-full-screen-dialog.component.html',
  styleUrls: ['./edtell-ag-grid-full-screen-dialog.component.scss']
})
export class EdtellAgGridFullScreenDialogComponent implements OnInit {

  gridOptions: GridOptions;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public config: EdtellAgGridConfig,
    private gridPreferenceService: GridPreferenceService
  ) { }

  ngOnInit(): void {
    this.gridOptions = {
      ...globalGridOptions(this.config.gridPreferenceName, this.gridPreferenceService, this.config.onGridReady, this.config.onFirstDataRendered, null, {
        fullScreen: true
      }),
      ...this.config.gridOptions
    };
    console.log(this.config)
  }

  closeDialog() {
    this.dialogRef.close();
  }
  
  get data() {
    if (typeof this.config.data == 'function') {
      return this.config.data();
    }

    return this.config.data;
  }

}
