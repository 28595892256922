import { FormControl, ValidatorFn } from "@angular/forms";

export function includesValidator(otherElements: any[], wildcard?: string): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      if(wildcard){
        if(wildcard == control.value){
          return null;
        }
      }
      if (control.value) {
        if (otherElements.includes(control.value)) {
            return null;
        }

        return {
            includes: true
        }
      }
      
      return null;
    }
  }

  export function ignoreCaseIncludesValidator(otherElements: any[], wildcard?: string): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      if(wildcard){
        if(wildcard == control.value){
          return null;
        }
      }
      if (control.value) {
        if (otherElements.some(x => x ? x.toLowerCase() == control.value.toLowerCase() : false)) {
            return null;
        }

        return {
            includes: true
        }
      }
      
      return null;
    }
  }
  