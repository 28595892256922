<div *secureStructural="security">
    <div class="row front-facing">
        <div class="col-12 d-flex">
            <div class="tabset-header">
                <div *ngFor="let tab of tabComponentArray" (click)="setTabActive(tab)" class="d-inline-block">
                    <div [ngClass]="['edtell-tab', tab.tabOpen ? 'active' : 'inactive']">
                        {{ tab.title }}
                        <span class="info-button color-primary" (click)="showTabInfo(tab)" *ngIf="tab.config?.tabInfoText != null">
                            <i class="far fa-info-circle"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="ml-auto" *ngIf="entityControlConfig">
                <app-entity-control [config]="entityControlConfig"></app-entity-control>
            </div>
        </div>
    </div>
    <ng-content></ng-content>
</div>