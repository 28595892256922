<ng-template [ngIf]="loaded">
  <div class="row mb-1">
    <div class="col-12">
      <app-entity-form-control [config]="entityFormControlConfig"></app-entity-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-srs-multi-element-widget [config]="formConfig"></app-srs-multi-element-widget>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-edtell-ag-grid [config]="agGridConfig"></app-edtell-ag-grid>
    </div>
  </div>
  
</ng-template>
