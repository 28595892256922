<ng-template [ngIf]="loaded == true">
    <div class="row">
        <div class="col-12">
            Sent to Webhook: {{ data.name }}
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span>Date Created: {{ data.dateCreated }}</span>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-12">
        <span>{{ data.message }}</span>
      </div>
    </div>
  </ng-template>
  