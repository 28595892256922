import { merge } from "lodash";
import { LangUtils } from "../../edtell-portal/namespaces/lang-utils.namespace";
import { EdtellCacheConfig } from "../interfaces/edtell-cache-config.interface";
import { EdtellInternalCacheUtil } from "../namespaces/edtell-internal-cache-utils.namespace";

export class EdtellMethodCache<E = any> {

  previousRawValue : any

  private data = new Map<string, any>();
  private queue: string[] = [];

  constructor(
    public readonly methodName: string,
    public readonly config: EdtellCacheConfig
  ) {}

  get(key: string) {
    let elm = this.data.get(key)();
    return elm;
  }

  add(key: string, value: E) {
    if (this.data.has(key)) {
      this.replace(key, value);
      return;
    }

    let cacheFnWrapper = EdtellInternalCacheUtil.formatForCache(
      this.data,
      this.config,
      this.queue,
      key,
      value,
      this
    );

    this.data.set(key, cacheFnWrapper);
    this.queue.push(key);

    if (this.queue.length > this.config.size) {
      let staleKey = this.queue.shift();
      this.data.delete(staleKey);
    }
  }

  remove(key: string) {
    this.data.delete(key);
    this.queue = this.queue.filter((elm) => {
      return elm == key;
    });
  }

  async removeByValue(value: E, compareFn?: (a, b) => boolean) {
    for (let k of this.data.keys()) {
      let v = await LangUtils.toPromise(this.data.get(k));

      // Determine if element should be removed
      let remove = false;
      if (compareFn != null) {
        remove = compareFn(value, v);
      } else {
        remove = value == v;
      }

      if (remove == true) {
        this.remove(k);
      }
    }
  }

  has(key: string) {
    return this.data.has(key);
  }

  replace(key: string, value: E) {

    let cacheFnWrapper = EdtellInternalCacheUtil.formatForCache(
      this.data,
      this.config,
      this.queue,
      key,
      value,
      this
    );

    // Remove key from queue because we are still modifying this elm
    // We dont need to remove the element because it will be replced below
    this.queue.splice(this.queue.indexOf(key), 1);
    this.data.set(key, cacheFnWrapper);
    this.queue.push(key);
  }

  clear() {
    this.data.clear();
    this.queue = [];
    this.previousRawValue = null;
  }

  size() { 
    return this.data.size;
  }

  keys() {
    return this.data.keys();
  }

  copy() {
    if(this.config.scope == "INSTANCE"){
      let c = new EdtellMethodCache(this.methodName, this.config);
      c.data = new Map(this.data);
      c.queue = [...this.queue];
      return c;
    }
    return this;
  }

}
