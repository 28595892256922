import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { EdtellFormControl } from '../classes/edtell-form-control';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl(condition: boolean) {
    setTimeout(() => {
      let control = this.ngControl.control;
      if (control instanceof EdtellFormControl) {
        if (condition) {
          control.directiveDisable()
        } else {
          control.directiveEnable();
        }
      } else if(control != null) {
        if (condition) {
          control.disable();
        } else {
          control.enable();
        }
      }
    })
  }

  constructor(private ngControl: NgControl) {
  }

}