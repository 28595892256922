import { CommonRegExpPattern } from "../../../edtell-portal/enums/common-regexp-pattern.enum";
import { SrsElementCallbackFunc, SrsElementConfig, SrsElementSettings } from "./srs-element-config.interface";


export interface SrsFormFieldConfig extends SrsElementConfig {
    title: string
    key: string
    attributes?: { key: string, value: string | number | boolean }[]
    hint?: string // Move to settings
    maxCharacters?: number // Move to settings
    autoCompleteName?: string;
    settings?: SrsFormFieldSettings
    onClick?: SrsElementCallbackFunc
    onFocusOut?: SrsElementCallbackFunc;
    onDoneTyping?: SrsElementCallbackFunc;
}

export interface SrsFormFieldSettings extends SrsElementSettings {
    placeholder?: string
    showRequired?: boolean
    password?: boolean
    hyperlinkType?: HyperlinkType
    /**
     * If set to true, the input will only accept keystrokes that pass the CommonRegExpPattern.INTEGER regular expression.
     * Any value defined for the regExpPattern setting will overwrite this setting.
     */
    numberInput?: boolean
    /**
     * Input will only accept key strokes that pass this regular expression pattern.
     */
    regExpPattern?: RegExp | CommonRegExpPattern,
    /**
     * type attribute on the html element. Default is 'text' if not defined.
     */
    type?: () => string | string

    searchIcon?: boolean;
    
    inputGroupPrependText?: string;

    hideMaxCharacterHint?: boolean;
    infoConfig?: InfoConfig
}

export enum HyperlinkType {
    WEBSITE = "WEBSITE",
    EMAIL = "EMAIL"
}

export interface InfoConfig {
    message: string;
}