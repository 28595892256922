import { Component, Input, OnInit } from '@angular/core';
import { SecurityConfig } from '../../../edtell-admin/interfaces/route-security-config.interface';
import { EdtellDeleteLinkConfig } from '../../interfaces/edtell-delete-link-config.interface';
import { EntityFormControlConfig } from '../../interfaces/entity-form-control-config';

@Component({
  selector: 'app-entity-form-control',
  templateUrl: './entity-form-control.component.html',
  styleUrls: ['./entity-form-control.component.scss']
})
export class EntityFormControlComponent implements OnInit {



  deleteLinkConfig: EdtellDeleteLinkConfig

  public showInfoLink: Boolean = false;
  public showDeleteLink: Boolean = false;

  private _config: EntityFormControlConfig


  constructor() {
  }

  async ngOnInit() {

    this.showInfoLink = this.config?.infoConfig != null;

    this.showDeleteLink = this.config.deleteCallback != null && this.config.deleteSecurityConfig != null;
    this.deleteLinkConfig =  this.showDeleteLink ? {
      deleteLinkCallback: () => { this.deleteLinkCallback() },
      isDisabledCallback: this.config.isDeleteDisabledCallback
    } : null;

    if (this.config.entityControl) {
      for (let button of this.config.entityControl.controlButtons) {
        button.formGroup = this.config.formGroup;
      }
    }

  }

  async deleteLinkCallback() {
    let message = this._config.deleteMessageOverride == null ? 'Are you sure you want to delete this?' : this._config.deleteMessageOverride;
    if (confirm(message)) {
      this.config.deleteCallback();
    }
  }

  noDeleteDisabledFunction() {
    return false;
  }

  @Input()
  set config(config: EntityFormControlConfig) {
    this._config = config;
    this.ngOnInit();
  };

  get config(){
    return this._config;
  }

  get hasRightSide() {
    return this.config.entityControl.controlButtons.length != 0 || this.config.entityControl.securityObject != null || this.showDeleteLink
  }

}
