import { Component, Input, OnInit } from '@angular/core';
import { EmitterEvent } from '../../../../edtell-portal/enums/emitter-events.enum';
import { EventEmitterService } from '../../../../edtell-portal/services/event-emitter.service';
import { EntityControlButtonOption } from '../../../interfaces/entity-control-config';

@Component({
  selector: 'app-edtell-button',
  templateUrl: './edtell-button.component.html',
  styleUrls: ['./edtell-button.component.scss']
})
export class EdtellButtonComponent implements OnInit {

  @Input()
  config: EntityControlButtonOption;

  asyncLoading = false;
  showSpinner = false;

  typeClass: string;
 
  constructor(private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    let type = this.config?.typeClass ?? 'tertiary';

    if (this.config?.typeClass == 'cancel') {
      type = 'danger';
    }

    if (this.config?.typeClass == 'save') {
      type = 'success';
    }

    this.typeClass = `btn-${type}`;
  }

  focus() {
    this.eventEmitterService.emit(EmitterEvent.FORM_SWITCH_TO_EDIT_EVENT, null);
  } 

  async doCallBack() {
    if (this.config == null) {
      return;
    }

    if (this.config.focusOnClick !== undefined && this.config.focusOnClick !== false) {
      this.focus();
    }
    if (this.config.submit) {
      this.config.formGroup.submitted = true;
    }
    if (this.config.callback != null) {
      this.asyncLoading = true;
      setTimeout(() => {
        if (this.asyncLoading) {
          this.showSpinner = true;
        }
      }, 250);
      try {
        await this.config.callback();
      } catch (err) {
        throw err;
      } finally {
        this.asyncLoading = false;
        this.showSpinner = false;
      }
    }
  }

  get buttonText() {
    let text = '';

    if (this.config?.icon) {
      text = ' ';
    }
    if (this.config) {
      text += this.config.text;
    }

    return text;
  }

  get buttonDisabled() {
    return this.asyncLoading || (this.config?.disabledValidator == null ? false : this.config.disabledValidator());
  }
}
