import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogActionType } from '../../../../edtell-portal/enums/dialog-action-type.enum';
import { NotificationService } from '../../../../edtell-portal/services/notification.service';
import { EdtellDialogComponent } from '../../../../srs-forms/abstracts/edtell-dialog.component';
import { EdtellFormGroup } from '../../../../srs-forms/classes/edtell-form-group.class';
import { SrsFormState } from '../../../../srs-forms/enumerations/form-state.enum';
import { EdtellDialogData } from '../../../../srs-forms/interfaces/abstracts/edtell-dialog-data.interface';
import { EdtellFrontFacingDialogConfig } from '../../../interface/edtell-front-facing-dialog-config.interface';


@Component({
  selector: 'app-front-facing-tab-info-dialog',
  templateUrl: './front-facing-tab-info-dialog.component.html',
  styleUrls: ['./front-facing-tab-info-dialog.component.scss']
})
export class FrontFacingTabInfoDialogComponent extends EdtellDialogComponent {
  dialogData: EdtellDialogData;
  formGroup = new EdtellFormGroup(SrsFormState.READ, {});
  objectTitle: string;

  frontFacingDialogConfig: EdtellFrontFacingDialogConfig = {
    edtellDialogRef: this,
    header: ''
  }

  constructor(public dialogRef: MatDialogRef<FrontFacingTabInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EdtellDialogData,
    public notificationService: NotificationService) {

    super(data.action as DialogActionType, notificationService);
    this.dialogData = data;
  }

  initForm() {
  }

  dialogOnInit() {
  }
  create(reqBody: any): Observable<any> {
    return null;
  }
  update(reqBody: any): Observable<any> {
    return null;
  }
  delete(id: number): Observable<any> {
    return null;
  }

}