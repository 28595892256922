import { 
  ObjectName, 
  SrsSearch, 
  SrsSearchInfo, 
  AutocompleteInterface,
  EventEmitterService,
  EmitterEvent
} from "@edtell-common"
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CourseSearchAutocompleteResultDataInterface } from '../interfaces/course-search-autocomplete-result-data.interface';
import { CatalogService } from './catalog.service';
import { CourseSearchResultInterface } from "../interfaces/course-search-result.interface";
import { DeliveryMethodAction } from "../enumerations/delivery-method-action.enum";

@Injectable()
@SrsSearchInfo({
  label: 'Catalog',
  security: {
    accessLevels: {
      accessLevel: 1,
      objectName: ObjectName.CATALOG_COURSE_PROFILE
    }
  }
})
export class CatalogSearch extends SrsSearch {

  constructor(private catalogService: CatalogService) {
    super();
  }

  async autocomplete(text) {
    let results = await this.catalogService.getAutocompleteResults(text).toPromise();
    let options: AutocompleteInterface<CourseSearchAutocompleteResultDataInterface>[] = [];

    for (let i of results) {
      options.push({
        text: i.courseName,
        object: i
      });
    }

    return options;
  }

  async search(router: Router, text: string, autoCompleteObject: any) {
    let results : CourseSearchResultInterface[]
    if(autoCompleteObject){
      results = await this.catalogService.getCourseSearchResults(autoCompleteObject.courseName, null, null, null, null, null, null, null, null).toPromise();
    }else{
      results = await this.catalogService.getCourseSearchResults(text, null, null, null, null, null, null, null, null).toPromise();
    }

    if (results.length == 1) {
      router.navigate([`./catalog/${results[0].id}`])
    } else {
      router.navigate([`./catalog`], { state: { 'searchString': text } });
    }

  }


}