import { Injectable, Injector, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import { first, take } from "rxjs/operators";
import { UserModel } from "../../edtell-admin/services/user.model";
import { AuthenticationStateConfig } from "../interfaces/authentication-state-config.interface";
import { AuthenticationState } from "../enums/authentication-state.enum";
import { apiURL } from "app-environment";
import { EventEmitterService } from "./event-emitter.service";
import { EmitterEvent } from "../enums/emitter-events.enum";
import { AttributeService } from "./attribute.service";
import { NavigationEnd, Router } from "@angular/router";

type JwtResponse = {success : boolean, token : string, user : UserModel};

@Injectable({
  providedIn: "root",
})
export class LoginService {

  // private info: JwtInfo;
  private signedIn: boolean = false;
  private loginCheck : Promise<boolean>
  private currentUser: UserModel;

  private jwtRefreshPromise : Promise<any>
  private jwtRefreshQueued : boolean = false;
  private jwtTokenRefreshTimeout : any

  public httpOptions: {
    headers?: HttpHeaders;
    withCredentials?: boolean;
  } = {
    headers: new HttpHeaders({ "Content-Type": "application/json", "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone}),
    withCredentials: true,
  };

  public textHttpOptions: {
    headers?: HttpHeaders;
    withCredentials?: boolean;
    responseType?: string;
  } = {
    headers: new HttpHeaders({ "Content-Type": "text/plain", "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone}),
    withCredentials: true,
    responseType: 'text'
  };

  loginState = new Subject<AuthenticationStateConfig>();
  loadingAuth: Promise<any>;

  constructor(
    private http: HttpClient,
    private eventService : EventEmitterService,
    private injector : Injector,
    private router : Router
  ) {
    this.loadStoredJwtToken();
    this.router.events.subscribe((e) => {
      if(e instanceof NavigationEnd){
        let url = window.location.href
        this.httpOptions.headers = this.copyAndAddHeaders(this.httpOptions.headers, {"APP-URL": url})
        this.textHttpOptions.headers = this.copyAndAddHeaders(this.textHttpOptions.headers, {"APP-URL": url})
      }
    })
  }

  async googleSignIn(googleToken : string, role? : string) : Promise<boolean>{

    let headers = new HttpHeaders({
      Authorization: "Bearer " + googleToken,
      "Content-Type": "application/json",
    });

    let jwtResponse : JwtResponse = await this.http.get<JwtResponse>(`${apiURL}/auth-validation-app/auth/google${role != null ? "?role=" + role : ""}`, { headers: headers, withCredentials: true}).pipe(first()).toPromise();
    if(jwtResponse.success == true){
      await this.setupJwtToken(jwtResponse.token, jwtResponse.user)
      return true;
    }

    return false;
  }
  

  async checkForExistingLogin() : Promise<boolean>{
    let result = false;
    try{

      if(this.loginCheck != null){
        return await this.loginCheck;
      }

      this.loginCheck = this.runLoginCheck();
      result = await this.loginCheck;
    }catch(err){
      this.loginCheck = null;
      throw err;
    }finally{
      this.loginCheck = null;
    }
    return result 
  }

  private async runLoginCheck(){

    if(this.signedIn == true){
      return true;
    }

    let jwt = localStorage.getItem("JWT")
    if(jwt == null){
      return false;
    }

    // apply to auth readers
    this.httpOptions.headers = this.copyAndAddHeaders(this.httpOptions.headers, {"JWT": jwt})

    try{
      let result = await this.getRefreshedJwtToken().pipe(first()).toPromise()
      if(result.success){
        await this.setupJwtToken(result.token, result.user)
      }
    }catch(err){
      return false;
    }

    return true;
  }

  async refreshJwtSession(){
    let e : Error
    try{
      if(this.jwtRefreshPromise != null){
        await this.jwtRefreshPromise
      }

      this.jwtRefreshPromise = this.internalRefreshJwtSession();
      await this.jwtRefreshPromise;
    }catch(err){
      e = err
    }finally{
      this.jwtRefreshQueued = false;
      this.jwtRefreshPromise = null;
    }

    if(e != null){
      throw e;
    }
    
  }

  private async internalRefreshJwtSession(){
    let result = await this.getRefreshedJwtToken().pipe(first()).toPromise()
    if(result.success){
      console.log("Setting up JWT token")
      await this.setupJwtToken(result.token, result.user)
    }else{
      throw new Error("User session is invalid")
    }
  }


  async signOut(redirect : boolean = true) {
    this.signedIn = false;
    this.loginState.next({
      redirect: redirect,
      state: AuthenticationState.UNAUTHENTICATED,
    });

    clearTimeout(this.jwtTokenRefreshTimeout)

    try {
      await this.http.get(`${apiURL}/auth-validation-app/auth/logout`, this.httpOptions).pipe(take(1)).toPromise();
    } catch (err) {}

    this.httpOptions.headers = new HttpHeaders({ "Content-Type": "application/json", "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone})
    this.textHttpOptions.headers = new HttpHeaders({ "Content-Type": "text/plain", "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone})

    this.currentUser = null;

    this.cleanLocalStorage();
    this.signedIn = false;

    this.eventService.emit(EmitterEvent.SIGN_OUT, null)
  }

  private setupJwtTokenRefresh(jwtData : any) {

    if (this.jwtTokenRefreshTimeout != null) {
      clearTimeout(this.jwtTokenRefreshTimeout);
    }

    this.jwtTokenRefreshTimeout = setTimeout(async () => {
      console.log("Queueing Refresh")
      this.jwtRefreshQueued = true;
    }, (jwtData.exp - jwtData.iat) * 500);
  }

  private getRefreshedJwtToken() {
    return this.http.get<JwtResponse>(`${apiURL}/auth-validation-app/auth/jwt/refresh`, {
      withCredentials: true,
      headers: this.httpOptions.headers,
    });
  }

  private async setupJwtToken(token : string, user : UserModel){

    this.jwtRefreshQueued = false;

    localStorage.setItem("JWT", token)
    let jwtData = this.decodeJwtToken(token)

    this.httpOptions.headers = this.copyAndAddHeaders(this.httpOptions.headers, {"JWT": token})
    this.textHttpOptions.headers = this.copyAndAddHeaders(this.textHttpOptions.headers, {"JWT": token})

    if(this.currentUser == null){
      this.currentUser = user;
    }

    if(localStorage.getItem("picture") == null){

      // This create a circular ts dependency, luckily the ts compiled is able to figure that out.
      // We should refactor this when the SIS Edtell Http client is merged in
      let attributeService = this.injector.get(AttributeService)       
      let picture = await attributeService.getAttributeByUserIdAndAttributeName(user.id, "profile.picture").pipe(take(1)).toPromise()

      if(picture != null){
        localStorage.setItem("picture", picture.attributeValue) 
      }
    }

    if(this.jwtTokenRefreshTimeout != null){
      clearTimeout(this.jwtTokenRefreshTimeout)
      this.jwtTokenRefreshTimeout = null;
    }

    this.signedIn = true;
    this.setupJwtTokenRefresh(jwtData)
  }

  private loadStoredJwtToken(){

    let jwt = localStorage.getItem("JWT")
    if(jwt == null){
      return
    }

    // apply to auth readers
    this.httpOptions.headers = this.copyAndAddHeaders(this.httpOptions.headers, {"JWT": jwt})

  }

  public decodeJwtToken(token : string) : JwtResponse{
    let jwtStr = atob(token.split(".")[1])
    return JSON.parse(jwtStr)
  }

  
  getCurrentUser(): UserModel {
    return this.currentUser;
  }


  isLoggedIn() {
    return this.signedIn
  }

  cleanLocalStorage() {
    localStorage.removeItem("nav");
    localStorage.removeItem("name");
    localStorage.removeItem("picture");
    localStorage.removeItem("JWT");
    localStorage.removeItem("CARTID");
  }

  // =============================== Need to rewrite for SIS ============================

  async loginAsUser(email: string, role: string) {
    return null;
    // return new Promise<boolean>(async (resolve) => {
    //   let result = await this.http
    //     .post<JwtInfo>(
    //       `${apiURL}/auth-validation-app/auth/services/become/user`,
    //       { email: email, role: role },
    //       {
    //         headers: this.httpOptions.headers,
    //         withCredentials: this.httpOptions.withCredentials,
    //         observe: "response",
    //       }
    //     )
    //     .pipe(take(1))
    //     .toPromise();

    //   if (result != null) {
    //     await this.setupJwtToken(result, true);
    //     resolve(true);
    //   }
    //   resolve(false);
    // });
  }

  async revertLoggedInUser() {
    return null;
    // return new Promise<any>(async (resolve) => {
    //   let result = await this.http
    //     .get<JwtInfo>(`${apiURL}/auth-validation-app/auth/services/become/revert`, {
    //       headers: this.httpOptions.headers,
    //       withCredentials: true,
    //       observe: "response",
    //     })
    //     .pipe(take(1))
    //     .toPromise();
    //   if (result != null) {
    //     await this.setupJwtToken(result, false);
    //     resolve(true);
    //   }
    //   resolve(false);
    // });
  }

  getCredentialAuthenticationToken(username: string, password: string, role?: string) {
    return null;
    // return this.http.get<JwtInfo>(
    //   `${apiURL}/auth-validation-app/auth/credentials${role != null ? "?role=" + role : ""}`,
    //   {
    //     headers: {
    //       Authorization: "Basic " + btoa(`${username}:${password}`),
    //       "Content-Type": "application/json",
    //     },
    //     withCredentials: true,
    //     observe: "response",
    //   }
    // );
  }

  private getJwtRoleChange(role: string) {
    return null;
    // return this.http.get<JwtInfo>(`${apiURL}/auth-validation-app/auth/jwt/role?role=${role}`, {
    //   withCredentials: true,
    //   headers: this.httpOptions.headers,
    //   observe: "response",
    // });
  }

  getPasswordResetLinkStatus(resetId: string) {
    return null;
    // return this.http.get<{ hasExpired: boolean }>(
    //   `${apiURL}/auth-validation-app/auth/credentials/reset/status?resetId=${resetId}`
    // );
  }

  changeRole(roles: string[]) {
    return null;
    // this.loginState.next({
    //   redirect: true,
    //   state: AuthenticationState.ROLE_SPECIFICATION_REQUIRED,
    //   data: LangUtils.type<MultipleAccountTypeLoginConfig>({
    //     choices: roles,
    //     callback: async (choice) => {
    //       let result = await this.getJwtRoleChange(choice).pipe(take(1)).toPromise();
    //       if (result != null) {
    //         await this.setupJwtToken(result.body, true);
    //       }
    //     },
    //   }),
    // });
  }

  requestPasswordReset(email: string) {
    return this.http.post(`${apiURL}/auth-validation-app/auth/credentials/reset-request`, { email: email }, {});
  }

  resetPassword(resetId: string, resetToken: string, password: string) {
    return this.http.post(
      `${apiURL}/auth-validation-app/auth/credentials/reset`,
      { resetId: resetId, resetToken: resetToken, password: password },
      {}
    );
  }

  isJwtRefreshQueued(){
    return this.jwtRefreshQueued;
  }

  copyAndAddHeaders(headers : HttpHeaders, additionalHeaders :{[key: string]: string}){

    let newHeaderNames = new Set();
    for(let e in additionalHeaders){
      newHeaderNames.add(e)
    }

    let oldHeaders = {}
    for(let k of headers.keys()){
      if(!newHeaderNames.has(k)){
        oldHeaders[k] = headers.get(k)
      }
    }

    return new HttpHeaders({
      ...oldHeaders,
      ...additionalHeaders
    })

  }

  private removeHeaders(headers : HttpHeaders, deleteList : string[]){
    let items = new Set(deleteList)
    let newHeaders = {}
    for(let k in headers){
      if(!items.has(k)){
        newHeaders[k] = headers.get(k)
      }
    }
    return new HttpHeaders(newHeaders)
  }

}
