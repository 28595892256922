import { 
  RouterDataInfo, 
  EventEmitterService, 
  RouterProcessor, 
  EntityResolver, 
  ResolverHelpers, 
  EmitterEvent
} from "@edtell-common"
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProviderInterface } from '../../modules/providers/interfaces/provider.interface';
import { ProviderService } from '../../modules/providers/services/provider.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderResolver extends EntityResolver implements RouterProcessor {

  private previousObject: ProviderInterface;
  private resolver : Promise<ProviderInterface>

  constructor(
    private providerService: ProviderService,
    private eventEmitterService : EventEmitterService
    ) {
    super();
  }

  async resolve(route: ActivatedRouteSnapshot) {

    let data = route.data;
    let id = route.paramMap.get('id');

    if (data.routerData.paramId != null) {
      id = data[data.routerData.paramId];
    }

    if (this.previousObject == null || this.previousObject.id != Number.parseInt(id) || ResolverHelpers.isEndRoute(route)) {
      this.previousObject = await this.providerService.getProvider(id).toPromise();
    }

    return this.previousObject;
  }

  async process(route: ActivatedRouteSnapshot, routerData: RouterDataInfo) {
    this.resolver = this.resolve(route)
    await this.resolver
    routerData.title = this.previousObject.providerName;
  }

  async getProvider(){
    await this.resolver
    return this.previousObject;
}

  async updateProviderName(providerName: string) {
    if(this.previousObject != null && providerName){
      this.previousObject.providerName = providerName;
      this.eventEmitterService.emit(EmitterEvent.RERENDER_BREADCRUMB_TRAIL, null)
    }
  }

}