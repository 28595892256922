<div class="row">
  <div class="col-12">
    <div class="dialog-header">
      <h3 mat-dialog-title mat-dialog-draggable-title>
        {{ jobStatus.jobName }} ({{ jobDate() }}) - Distribution Data
      </h3>
      <span aria-hidden="true" (click)="onNoClick()">&times;</span>
    </div>
  </div>
</div>

<mat-dialog-content>
  <div class="row" *ngIf="distributionContainerConfig != null">
    <div class="col-12">
      <app-distribution-container [config]="distributionContainerConfig"></app-distribution-container>
    </div>
  </div>
</mat-dialog-content>
