import {Asset, CacheableMethod, LoginService, PinnedEntityResponse} from '@edtell-common';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {apiURL} from '../../../../environments/environment';
import {ProviderPricingOverrideCustomerInterface} from '../../providers/interfaces/provider-pricing-override-customer.interface';
import {CopyContact} from '../interfaces/copy-contact-interface';
import {CustomerContactAssociationInterface} from '../interfaces/customer-contact-association.interface';
import {CustomerContactInterface} from '../interfaces/customer-contact.interface';
import {CustomerDataInterface} from '../interfaces/customer-data.interface';
import {CustomerProfileData} from '../interfaces/customer-profile-data.interface';
import {CustomerSearchAutocompleteResultDataInterface} from '../interfaces/customer-search-autocomplete-result-data.interface';
import {CustomerSearchResultDataInterface} from '../interfaces/customer-search-result-data.interface';
import {CustomerSiblingOrChildResponse} from '../interfaces/customer-sibling-response.inteface';
import {CustomerSidebarInfo} from '../interfaces/customer-sidebar-info.interface';
import {Timezone} from '../interfaces/timezone.interface';
import {CustomerContactRelationsReportDataInterface} from '../interfaces/customer-contact-relations-report-data.interface';
import {CustomerContactAutocompleteResult} from '../interfaces/customer-contact-autocomplete-result.interface';
import {CustomerContactSearchResult} from '../interfaces/customer-contact-search-result.interface';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private httpOptions: {};
  private textHttpOptions: {};

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
    this.textHttpOptions = loginService.textHttpOptions;
  }

  getCustomerProfileData(customerId?: number) {
    if (customerId !== undefined && customerId !== null) {
      return this.http.get<CustomerProfileData>(`${apiURL}/customer-app/customer/profile?customerId=${customerId}`, this.httpOptions);
    } else {
      return this.http.get<CustomerProfileData>(`${apiURL}/customer-app/customer/profile/add`, this.httpOptions);
    }
  }

  getCustomer(customerId: number): Observable<CustomerDataInterface> {
    return this.http.get<CustomerDataInterface>(`${apiURL}/customer-app/customer/info?id=${customerId}`, this.httpOptions);
  }

  updateCustomer(customer: CustomerDataInterface): Observable<CustomerDataInterface> {
    return this.http.put<CustomerDataInterface>(`${apiURL}/customer-app/customer`, customer, this.httpOptions);
  }

  createCustomer(customer: CustomerDataInterface): Observable<CustomerDataInterface> {
    return this.http.post<CustomerDataInterface>(`${apiURL}/customer-app/customer`, customer, this.httpOptions);
  }

  getSalesRep() {
    return this.http.get(`${apiURL}/administration-app/user/all/servicerep`, this.httpOptions);
  }

  getAutocompleteResults(searchString: string): Observable<CustomerSearchAutocompleteResultDataInterface[]> {
    return this.http.get<CustomerSearchAutocompleteResultDataInterface[]>(`${apiURL}/customer-app/customer/autocomplete?searchString=${searchString}`, this.httpOptions);
  }

  getCustomerSearchResults(searchString, stateCode?, recordType?, plcId?, vecId?, orgTypeId?, customerStatus?, schoolTypes?, maxResults?) {

    let searchParamString: string = '';

    if (searchString != null) {
      searchParamString = searchParamString.concat('searchString=', searchString, '&');
    }
    if (stateCode != null && stateCode != 'null') {
      searchParamString = searchParamString.concat('stateCode=', stateCode, '&');
    }
    if (recordType != null && recordType != 'null') {
      searchParamString = searchParamString.concat('recordType=', recordType, '&');
    }
    if (plcId != null && plcId != 'null') {
      searchParamString = searchParamString.concat('plcId=', plcId, '&');
    }
    if (vecId != null && vecId != 'null') {
      searchParamString = searchParamString.concat('vecId=', vecId, '&');
    }
    if (orgTypeId != null && orgTypeId != 'null') {
      searchParamString = searchParamString.concat('orgTypeId=', orgTypeId, '&');
    }
    if (customerStatus != null && customerStatus != 'null') {
      searchParamString = searchParamString.concat('customerStatus=', customerStatus, '&');
    }
    if (schoolTypes != null && schoolTypes != 'null') {
      searchParamString = searchParamString.concat('schoolType=', schoolTypes, '&');
    }

    if (maxResults != null) {
      searchParamString = searchParamString.concat('maxResults=', maxResults);
    } else {
      searchParamString = searchParamString.concat('maxResults=100');
    }

    return this.http.get<CustomerSearchResultDataInterface[]>(`${apiURL}/customer-app/customer/search?${searchParamString}`, this.httpOptions);
  }

  getCustomerByCustomerName(customerName: string) {
    return this.http.get<CustomerDataInterface[]>(`${apiURL}/customer-app/customer/search/name?customerName=${customerName}`, this.httpOptions);
  }

  getCustomersByState(state) {
    return this.http.get<CustomerDataInterface[]>(`${apiURL}/customer-app/customer/state?stateCode=${state}`, this.httpOptions);

  }

  getVECNameForCustomer(customerId) {
    return this.http.get<String>(`${apiURL}/customer-app/customer/vec?customerId=${customerId}`, this.textHttpOptions);
  }

  getCustomerParentList(state, orgTypeId) {
    return this.http.get<CustomerDataInterface[]>(`${apiURL}/customer-app/customer/parents?state=${state}&orgTypeId=${orgTypeId}`, this.httpOptions);
  }

  getChildren(parentId) {
    return this.http.get<CustomerDataInterface[]>(`${apiURL}/customer-app/customer/children?parentId=${parentId}`, this.httpOptions);
  }

  getAllTimezones() {
    return this.http.get<Timezone[]>(`${apiURL}/customer-app/customer/timezone`, this.httpOptions);
  }

  getCustomersBySchoolType(id: number) {
    return this.http.get<CustomerDataInterface[]>(`${apiURL}/customer-app/customer/school-type?id=${id}`, this.httpOptions);
  }

  getCustomerBySchoolCode(schoolCode: string) {
    return this.http.get<CustomerDataInterface>(`${apiURL}/customer-app/customer/school-code?schoolCode=${schoolCode}`, this.httpOptions);
  }

  // ============================================= Contacts ====================================
  // TODO: Move this into its own service

  getContactList(customerId) {
    return this.http.get<CustomerContactInterface[]>(`${apiURL}/customer-app/customer/contact/list?customerId=${customerId}`, this.httpOptions);
  }

  getContactById(contactId) {
    return this.http.get<CustomerContactInterface>(`${apiURL}/customer-app/customer/contact/?id=${contactId}`, this.httpOptions);
  }

  getRelationshipById(relationshipId) {
    return this.http.get(`${apiURL}/customer-app/customer/contact/relationship?relationshipId=${relationshipId}`, this.httpOptions);
  }

  createRelationship(relationship) {
    return this.http.post(`${apiURL}/customer-app/customer/contact/relationship/`, relationship, this.httpOptions);
  }

  updateRelationship(relationship) {
    return this.http.put(`${apiURL}/customer-app/customer/contact/relationship`, relationship, this.httpOptions);
  }

  getCombinedData(contactId, relationshipId) {
    return this.http.get(`${apiURL}/customer-app/customer/contact/combined?contactId=${contactId}&relationshipId=${relationshipId}`, this.httpOptions);
  }

  @CacheableMethod()
  getContactsWhoShowOnApplication(customerId: number) {
    return this.http.get<CustomerContactInterface[]>(`${apiURL}/customer-app/customer/contact/show-on-application?customerId=${customerId}`, this.httpOptions);
  }


  getContact(contactId, customerId) {
    return this.http.get(`${apiURL}/customer-app/customer/contact/info?contactId=${contactId}&customerId=${customerId}`, this.httpOptions);
  }

  getContactCopyList(customerId, contactId) {
    return this.http.get<CopyContact[]>(`${apiURL}/customer-app/customer/contact/copy/list?contactId=${contactId}&customerId=${customerId}`, this.httpOptions);
  }

  updateCustomerContact(contact) {
    return this.http.put<CustomerContactInterface>(`${apiURL}/customer-app/customer/contact`, contact, this.httpOptions);
  }

  createContact(contact) {
    return this.http.post<CustomerContactInterface>(`${apiURL}/customer-app/customer/contact`, contact, this.httpOptions);
  }

  updateContact(contact) {
    return this.http.put<CustomerContactInterface>(`${apiURL}/customer-app/customer/contact/info`, contact, this.httpOptions);
  }

  updateOnlyContact(contact) {
    return this.http.put(`${apiURL}/customer-app/customer/contact`, contact, this.httpOptions);
  }

  deleteContact(contactId, customerId) {
    return this.http.delete(`${apiURL}/customer-app/customer/contact?contactId=${contactId}&customerId=${customerId}`, this.httpOptions);
  }

  isUniqueEmail(email, customerId) {
    return this.http.get(`${apiURL}/customer-app/customer/contact/email/is-unique?email=${email}&customerId=${customerId}`, this.httpOptions);
  }

  generateContactEmail(customerId: number): Observable<string> {
    return this.http.get<string>(`${apiURL}/customer-app/customer/contact/email/generate?customerId=${customerId}`, this.textHttpOptions);
  }

  getContactByEmail(email) {
    return this.http.get(`${apiURL}/customer-app/customer/contact/email?email=${email}`, this.httpOptions);
  }

  canDelete(id) {
    return this.http.get<boolean>(`${apiURL}/customer-app/customer/can-delete?customerId=${id}`, this.httpOptions);
  }

  deleteCustomer(id) {
    return this.http.delete<boolean>(`${apiURL}/customer-app/customer?id=${id}`, this.httpOptions);
  }

  deleteAttribute(id: number) {
    return this.http.delete<boolean>(`${apiURL}/customer-app/customer/attribute?id=${id}`, this.httpOptions);
  }

  getAssociatedSchools(id: number) {
    return this.http.get<CustomerContactAssociationInterface[]>(`${apiURL}/customer-app/customer/contact/schools?contactId=${id}`, this.httpOptions);
  }

  getCustomerSiblings(id: number) {
    return this.http.get<CustomerSiblingOrChildResponse[]>(`${apiURL}/customer-app/customer/siblings?id=${id}`, this.httpOptions);
  }

  getInactiveCustomerCount(customerId: number) {
    return this.http.get<number>(`${apiURL}/customer-app/customer/contact/inactive/count?customerId=${customerId}`, this.httpOptions);
  }

  getActiveContactList(customerId: number) {
    return this.http.get<CustomerContactInterface[]>(`${apiURL}/customer-app/customer/contact/list/active?customerId=${customerId}`, this.httpOptions);
  }

  getRecentlyModified = () => {
    return this.http.get(`${apiURL}/customer-app/customer/modified/recently`, this.httpOptions);
  };

  getModifiedByUser = () => {
    return this.http.get(`${apiURL}/customer-app/customer/modified/user`, this.httpOptions);
  };

  getPinnedCustomers() {
    return this.http.get<PinnedEntityResponse<CustomerDataInterface>>(`${apiURL}/customer-app/customer/pins`, this.httpOptions);
  }

  getCustomerSidebar(customerId: number) {
    return this.http.get<CustomerSidebarInfo>(`${apiURL}/customer-app/customer/sidebar?id=${customerId}`, this.httpOptions);
  }

  getContactTitles() {
    return this.http.get(`${apiURL}/customer-app/customer/contact/title/display-list`, this.httpOptions);
  }

  getCustomerSchoolCodeList(stateCode, schoolType) {

    let requestObject = {
      states: (stateCode == null || stateCode[0] == '*') ? [] : stateCode,
      schoolTypes: (schoolType == null || schoolType[0] == '*') ? [] : schoolType
    };

    // State will only ever be a string if there is a single item (ie from select box)
    // This is to parse it into an array so the call accepts it
    if (typeof requestObject.states === 'string') {
      let states = [];
      states.push(requestObject.states);
      requestObject.states = states;
    }

    // ditto
    if (typeof requestObject.schoolTypes === 'string') {
      let schoolTypes = [];
      schoolTypes.push(requestObject.schoolTypes);
      requestObject.schoolTypes = schoolTypes;
    }

    return this.http.post<ProviderPricingOverrideCustomerInterface[]>(`${apiURL}/customer-app/customer/school-code`, requestObject, this.httpOptions);
  }

  getCustomerLogo(id: number) {
    return this.http.get<Asset>(`${apiURL}/customer-app/customer/logo?id=${id}`, this.httpOptions);
  }


  getAllSchoolTypes() {
    return this.http.get(`${apiURL}/customer-app/school-type/all`, this.httpOptions);
  }

  getContactRelationsData(customerId, contactId) {
    return this.http.get<CustomerContactRelationsReportDataInterface>(`${apiURL}/customer-app/customer/contact/relations-report?customerId=${customerId}&contactId=${contactId}`, this.httpOptions);
  }

  @CacheableMethod()
  getContactAutocompleteResults(searchString: string) {
    return this.http.get<CustomerContactAutocompleteResult[]>(`${apiURL}/customer-app/customer/contact/autocomplete?searchString=${searchString}`, this.httpOptions);
  }

  getCustomerContactSearchResults(searchString?: string, state?: string, firstNameBeginsWith?: string, lastNameBeginsWith?: string, plcId?: number, vecId?: number, maxResults?: number) {
    let urlParams: string[] = [];
    if (searchString != null) {
      urlParams.push(`searchString=${searchString}`);
    }
    if (state != null) {
      urlParams.push(`stateCode=${state}`);
    }
    if (firstNameBeginsWith != null) {
      urlParams.push(`firstNameBeginsWith=${firstNameBeginsWith}`);
    }
    if (lastNameBeginsWith != null) {
      urlParams.push(`lastNameBeginsWith=${lastNameBeginsWith}`);
    }
    if (plcId != null) {
      urlParams.push(`plcId=${plcId}`);
    }
    if (vecId != null) {
      urlParams.push(`vecId=${vecId}`);
    }
    if (maxResults != null) {
      urlParams.push(`maxResults=${maxResults}`);
    }

    let urlParamsString = '';
    if (urlParams.length > 0) {
      urlParamsString += '?';
      for (let i in urlParams) {
        let index = +i;
        if (index != 0) {
          urlParamsString += '&';
        }
        urlParamsString += urlParams[index];
      }
    }

    return this.http.get<CustomerContactSearchResult[]>(`${apiURL}/customer-app/customer/contact/search${urlParamsString}`, this.httpOptions);
  }


  isContactEmailUnique(email: string, contactId: number) {
    let endpointURL = `${apiURL}/customer-app/customer/contact/unique/email?requestEmail=${email}`;
    if (!!contactId) {
      endpointURL += `&contactId=${contactId}`;
    }
    return this.http.get(endpointURL, this.httpOptions);
  }

  @CacheableMethod()
  getCustomersByActivePaymentCode(paymentCode: string) {
    return this.http.get<CustomerDataInterface[]>(`${apiURL}/customer-app/customer/payment-code/active?paymentCode=${paymentCode}`, this.httpOptions);
  }

}
