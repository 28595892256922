import {Component, ElementRef, EventEmitter, Output, QueryList, ViewChildren} from '@angular/core';
import {CommonRegExpPattern} from '../../../../edtell-portal/enums/common-regexp-pattern.enum';
import {SrsFormElement} from '../../../abstracts/srs-form-element.abstract';
import {EdtellFormControl} from '../../../classes/edtell-form-control';
import {EdtellFormGroup} from '../../../classes/edtell-form-group.class';
import {SrsFormFieldConfig} from '../../../interfaces/elements/srs-form-field-config.interface';
import {AlertConfig} from '../../../interfaces/elements/srs-element-config.interface';

@Component({
  selector: 'app-srs-form-field-always-view',
  templateUrl: './srs-form-field-always-view.component.html',
  styleUrls: ['./srs-form-field-always-view.component.scss']
})
export class SrsFormFieldAlwaysViewComponent extends SrsFormElement<SrsFormFieldConfig> {

  @ViewChildren('inputField')
  inputFieldRef: QueryList<ElementRef>;

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  isMoney: boolean = false;
  isHyperlink: boolean = false;

  // Value changes subscription calls on initialization. Flag first time so that we can mark the original control
  // as Dirty only after initialization.
  moneyFirstValueChangesSub: boolean = true;

  formGroup: EdtellFormGroup;

  constructor() {
    super();
  }

  onElementInit() {
    if (this.config.settings &&
      (this.config.settings.regExpPattern === CommonRegExpPattern.MONEY
        || this.config.settings.regExpPattern === CommonRegExpPattern.MONEY_ALLOW_NEGATIVES
        || this.config.settings.regExpPattern === CommonRegExpPattern.MONEY_ALLOW_ONLY_NEGATIVES)
    ) {
      this.isMoney = true;

      this.formGroup = new EdtellFormGroup(this.config.group.state, {});
      this.formGroup.addControl(this.config.key, new EdtellFormControl(this.controlValue));

      // change the formgroup reset function to also reset the money input formgroup
      let resetFunction = this.config.group.resetValues;
      this.config.group.resetValues = () => {
        this.formGroup.get(this.config.key).reset();
        this.moneyFirstValueChangesSub = true;
        resetFunction.bind(this.config.group)();
      };

    } else {
      this.formGroup = this.config.group;
    }

    if (this.config.settings && this.config.settings.hyperlinkType) {
      this.isHyperlink = true;
    }

    if (this.isMoney) {

      this.control.valueChanges.subscribe((v) => {
        this.changeDisplayValue('$' + v, false);
      });

      // remove the dollar sign and set the value for the real form control
      this.formGroup.get(this.config.key).valueChanges.subscribe(async (value: string) => {
        this.changeDisplayValue(value, true);
      });
    }

  }

  private changeDisplayValue(_value: string, propegate: boolean) {
    if (!this.moneyFirstValueChangesSub) {
      this.control.markAsDirty();
    } else {
      this.moneyFirstValueChangesSub = false;
    }

    // Starting with a null check. If _value is NOT null then it checks for the String "null".
    // The string check has to be done because concatenating a string, "$" in this case, to null turns the null value into a string itself
    // See: Line 68
    let value = _value == null ? null : _value.includes('null') ? null : _value;

    if (value != null && value.includes('$')) {
      if (propegate == true) {
        this.control.setValue(value.substring(1));
      } else {
        this.formGroup.get(this.config.key).setValue(value, {
          onlySelf: true,
          emitEvent: false
        });
      }
    } else if (value != null && !value.includes('$')) {
      // This is stopping the input from eating the first element when typing in a value
      if (propegate) {
        this.control.setValue(value);
      } else {
        this.formGroup.get(this.config.key).setValue(value, {
          onlySelf: true,
          emitEvent: false
        });
      }
    }
  }

  get controlValue() {
    if (this.isMoney && this.control.value !== '' && this.control.value !== null) {
      // format decimals
      let value = this.control.value.toString();
      let decimalPlaces = value.split('.')[1] === undefined ? 0 : value.split('.')[1].length;
      let decimalString = decimalPlaces == 0 ? '.' : '';
      decimalString += '0'.repeat(Math.abs(2 - decimalPlaces));
      return '$' + this.control.value + decimalString;
    }

    if (!this.control) {
      return '';
    }
    return this.control.value;
  }

  // overriding to prevent error
  get validationClass() {
    if (!this.control) {
      return '';
    }
    return this.control.errors && this.config.group.submitted ? 'is-invalid' : '';
  }

  // --------- Hyperlink stuff --------------

  get formattedWebsite(): string {
    let website = this.control.value;

    if (website == null) {
      return '';
    } else if (website.substr(0, 4) === 'http') {
      return website;
    }
    return '//' + website;
  }

  get type() {

    if (this.config.settings && this.config.settings.type) {
      if (typeof this.config.settings.type == 'function') {
        return this.config.settings.type();
      } else {
        return this.config.settings.type;
      }
    }
  }

  get displayAlertClass(): string {
    if (!this.control) {
      return '';
    }

    if (this.config.alerts == null) {
      return '';
    }


    if (typeof this.config.alerts[Symbol.iterator] !== 'function') {
      return '';
    }

    for (let alert of this.config.alerts) {
      if (alert.showAlertMessage(this.control)) {
        return 'alert-field';
      }
    }
    return '';
  }

  alertMessageExists(config: AlertConfig) {
    return !!config.message;
  }

}
