<ng-template [ngIf]="loaded">
  <!-- I would like to remove this top row because it seems uneeded, 
        however the overflowdoes not seem to work properly without it-->
  <div class="row">
    <div class="col-12 roles-container">
      <div class="row pb-2">
        <div class="col-12">
          <span>{{config.title}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-srs-form-field [config]="searchFieldConfig"></app-srs-form-field>
        </div>
      </div>

      <!-- Selected Roles -->
      <div class="row row-list-container">
        <div class="col-12">
          <ng-template [ngIf]="selected.length > 0">
            <ng-container *ngFor="let i of selected">
              <div class="row role-row" *ngIf="searchSet.has(i)">
                <div class="col-11">
                  <app-srs-checkbox [config]="optionCheckboxConfigMap.get(i)"></app-srs-checkbox>
                </div>
              </div>
            </ng-container>
          </ng-template>

          <div class="row pb-3" *ngIf="selected.length > 0 && unselected.length > 0">
            <div class="col-12 divider"></div>
          </div>

          <!-- Unselected Roles -->
          <ng-template [ngIf]="unselected.length > 0">
            <ng-container *ngFor="let i of unselected">
              <div class="row role-row" *ngIf="searchSet.has(i)">
                <div class="col-11">
                  <app-srs-checkbox [config]="optionCheckboxConfigMap.get(i)"></app-srs-checkbox>
                </div>
              </div>
            </ng-container>
          </ng-template>
          
        </div>
      </div>
    </div>
  </div>
</ng-template>
