<ng-container [ngSwitch]="hasChildren()">
  <!-- Button -->
  <div *ngSwitchCase="true" (mouseenter)="buttonConfig._previewDropdown = true"
       (mouseleave)="buttonConfig._previewDropdown = false">

      <button (click)="buttonConfig._showDropdown = true;" sidebarDropdown
          [buttonConfig]="buttonConfig" [id]="buttonConfig.label + '-sidebar-btn'" [secure]="buttonConfig.security" type="button"
          [ngClass]="['btn', 'text-left', buttonConfig == activeButton ? 'button-active' : '']"
          *ngIf="!hiddenMap.get()" [disabled]="disabledMap.get(buttonConfig)">
          <i [class]="buttonConfig.icon + ' mr-lg-1 mr-xl-2 mr-sm-1'"></i>{{buttonConfig.label}}

      <!-- Indicator Render -->
      <ng-template sidebarIndicator></ng-template>
    </button>

    <div sidebarDropdownPosition
         *ngIf="buttonConfig._showDropdown || buttonConfig._previewDropdown || childrenShowing()"
         class="flyMenu btn-group">
      <app-sidebar-button-group [config]="sidebarConfig" [buttons]="buttonConfig.route"></app-sidebar-button-group>
    </div>

  </div>

  <ng-container *ngSwitchDefault>
        <button [id]="buttonConfig.label + '-sidebar-btn'" (click)="onButtonClick()" [secure]="buttonConfig.security" type="button"
                [ngClass]="['btn', 'text-left', buttonConfig == activeButton ? 'button-active' : '', buttonConfig.conditionals != null ? disabledMap.get(buttonConfig) ? 'button-disabled' : '' : '']"
                *ngIf="!hiddenMap.get(buttonConfig)" [disabled]="disabledMap.get(buttonConfig)">
          <i [class]="buttonConfig.icon + ' mr-lg-1 mr-xl-2 mr-sm-1'"></i>{{buttonConfig.label}}
          <!-- Indicator Render -->
          <ng-template sidebarIndicator></ng-template>
      </button>
  </ng-container>
</ng-container>


