import {
  RouterRefreshGuard,
  NavigationNotAuthorizedComponent,
  FourOhFourComponent,
  LoginComponent,
} from "@edtell-common";
import { RouterModule, Routes } from "@angular/router";
import { EdtellLocationGuard } from "./modules/edtell-common/edtell-portal/guards/edtell-location.guard";

const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./components/app-container/app-container.module").then(
        (m) => m.AppContainerModule
      ),
    canActivateChild: [RouterRefreshGuard, EdtellLocationGuard],
  },

  {
    path: "login",
    component: LoginComponent,
    canActivate: [EdtellLocationGuard],
  },

  {
    path: "apidocumentation",
    loadChildren: () =>
      import(
        "./modules/edtell-common/edtell-documentation/api-documentation.module"
      ).then((m) => m.ApiDocumentationRoutingModule),
  },

  {
    path: "403",
    component: NavigationNotAuthorizedComponent,
    canActivate: [EdtellLocationGuard],
  },

  { path: "**", component: FourOhFourComponent,  canActivate: [EdtellLocationGuard] },
];

export const Routing = RouterModule.forRoot(appRoutes, {
  paramsInheritanceStrategy: "always",
  relativeLinkResolution: "legacy",
  scrollPositionRestoration: "top",
});
