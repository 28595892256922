import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { EdtellHeaderHelperDirective } from '../../directives/edtell-header-helper.directive';
import { EdtellHeaderService } from '../../services/edtell-header.service';

@Component({
  selector: 'app-edtell-header',
  templateUrl: './edtell-header.component.html',
  styleUrls: ['./edtell-header.component.scss']
})
export class EdtellHeaderComponent implements OnInit {

  @ViewChild(EdtellHeaderHelperDirective, {static: false}) injectionPoint: EdtellHeaderHelperDirective;

  constructor(
    private edtellHeaderService: EdtellHeaderService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    this.edtellHeaderService.headerState.subscribe(async (navComponent) => {
      const routeParams: any = await this.edtellHeaderService.getRouteParams();
      if (navComponent == undefined) {
        return;
      }
      const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(navComponent);

      const viewRef: ViewContainerRef = this.injectionPoint.viewContainerRef;

      viewRef.clear();
      const c: ComponentRef<any> = viewRef.createComponent(componentFactory);

      c.instance.routeParams = routeParams;
    })
  }
}
