import { Component, Input, OnInit } from '@angular/core';
import { DistributionContainerConfig } from '../../../interfaces/distribution-container-config.interface';
import { DistributionMetaData, ProcessedJobStatus } from '../../../interfaces/processed-job-status.interface';

@Component({
  selector: 'app-distribution-container',
  templateUrl: './distribution-container.component.html',
  styleUrls: ['./distribution-container.component.scss']
})
export class DistributionContainerComponent implements OnInit {

  @Input()
  public config: DistributionContainerConfig

  loaded : boolean = false;

  jobStatus: ProcessedJobStatus;
  distributions: DistributionMetaData[];

  selectedDistribution : DistributionMetaData

  constructor() {}

  async ngOnInit() {

    this.jobStatus = this.config.jobStatus
    this.distributions = this.config.jobStatus.distributions

    this.loaded = true;
  }
  
  switchView(distributionMetaData : DistributionMetaData){
    this.selectedDistribution = distributionMetaData;
  }

  goBack(){
    this.selectedDistribution = null;
  }

  getCurrentViewType(){
    return this.selectedDistribution?.type
  }

}
