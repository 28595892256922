import { 
  PinnedEntityResponse, 
  MoreButtonDataInterface, 
  State, 
  LoginService
} from "@edtell-common"
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiURL } from '../../../../environments/environment';
import { ProviderAutocompleteInterface } from '../interfaces/provider-autocomplete.interface';
import { ProviderModifiedResultInterface } from '../interfaces/provider-modified-result.interface';
import { ProviderParentInterface } from '../interfaces/provider-parent.interface';
import { ProviderProfileData } from '../interfaces/provider-profile-data.interface';
import { ProviderSearchInterface } from '../interfaces/provider-search.interface';
import { ProviderSidebarDataInterface } from '../interfaces/provider-sidebar-data.interface';
import { ProviderInterface } from '../interfaces/provider.interface';
import { ProviderEntities } from "../interfaces/provider-entities.interface";
import { CopyProviderRequest } from "../interfaces/copy-provider-request.interface";


@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  private httpOptions: {};
  private textHttpOptions: {};
  private providerId;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
    this.textHttpOptions = loginService.textHttpOptions;
  }

  getProvider(id) {
    this.providerId = id;
    return this.http.get<ProviderInterface>(`${apiURL}/providers-app/providers/info?id=${id}`, this.httpOptions);
  }

  getFoundersProvider(){
    return this.http.get<ProviderInterface>(`${apiURL}/providers-app/providers/founders-info`, this.httpOptions);
  }

  getAllProviders() {
    return this.http.get<ProviderInterface[]>(`${apiURL}/providers-app/providers/all`, this.httpOptions);
  }

  getProviderId() {
    return this.providerId;
  }

  updateProvider(provider) {
    return this.http.put<ProviderInterface>(`${apiURL}/providers-app/providers`, provider, this.httpOptions);
  }

  createProvider(provider) {
    return this.http.post<ProviderInterface>(`${apiURL}/providers-app/providers`, provider, this.httpOptions);
  }

  copyProvider(request: CopyProviderRequest) {
    return this.http.post<ProviderInterface>(`${apiURL}/providers-app/providers/copy`, request, this.httpOptions);
  }

  deleteProvider(providerId) {
    return this.http.delete<boolean>(`${apiURL}/providers-app/providers?id=${providerId}`, this.httpOptions);
  }

  getAutocompleteResults(searchString) {
    return this.http.get<ProviderAutocompleteInterface[]>(`${apiURL}/providers-app/providers/autocomplete?searchString=${searchString}`, this.httpOptions);
  }

  getProviderSearchResults(searchString, stateCode?, city?, edtellLMS?, instantTeacher?, usesFoundersTeachers?, providerStatus?, maxResults?) {

    let searchParamString: string = '';

    if (searchString != null) {
      searchParamString = searchParamString.concat('searchString=', searchString, '&');
    }
    if (stateCode != null && stateCode != 'null') {
      searchParamString = searchParamString.concat('stateCode=', stateCode, '&');
    }
    if (edtellLMS != null && edtellLMS != 'null') {
      searchParamString = searchParamString.concat('edtellLMS=', edtellLMS, '&');
    }
    if (city != null && city != 'null') {
      searchParamString = searchParamString.concat('city=', city, '&');
    }
    if (instantTeacher != null && instantTeacher != 'null') {
      searchParamString = searchParamString.concat('instantTeacher=', instantTeacher, '&');
    }
    if (usesFoundersTeachers != null && usesFoundersTeachers != 'null') {
      searchParamString = searchParamString.concat('usesFoundersTeachers=', usesFoundersTeachers, '&');
    }
    if (providerStatus != null && providerStatus != 'null') {
      searchParamString = searchParamString.concat('providerStatus=', providerStatus, '&');
    }

    if (maxResults != null) {
      searchParamString = searchParamString.concat('maxResults=', maxResults);
    } else {
      searchParamString = searchParamString.concat('maxResults=100');
    }

    return this.http.get<ProviderSearchInterface[]>(`${apiURL}/providers-app/providers/search?${searchParamString}`, this.httpOptions);
  }

  getStateList() {
    return this.http.get<State[]>(`${apiURL}/customer-app/customer/states`, this.httpOptions);
  }

  getCityList(stateCode) {
    return this.http.get<string[]>(`${apiURL}/providers-app/providers/cities?stateCode=${stateCode}`, this.httpOptions);
  }

  getRecentlyModified = () => {
    return this.http.get<ProviderModifiedResultInterface[]>(`${apiURL}/providers-app/providers/modified/recently`, this.httpOptions);
  };

  getModifiedByUser = () => {
    return this.http.get<ProviderModifiedResultInterface[]>(`${apiURL}/providers-app/providers/modified/user`, this.httpOptions);
  };

  getProviderDocumentAssets() {
    return this.http.get<MoreButtonDataInterface[]>(`${apiURL}/providers-app/providers/assets/documents`, this.httpOptions);
  }

  getPinnedProviders() {
    return this.http.get<PinnedEntityResponse<ProviderInterface>>(`${apiURL}/providers-app/providers/pins`, this.httpOptions);
  }

  getProviderParents(orgTypeId) {
    return this.http.get<ProviderParentInterface[]>(`${apiURL}/providers-app/providers/parents?orgTypeId=${orgTypeId}`, this.httpOptions);
  }

  getProviderSidebar(providerId) {
    return this.http.get<ProviderSidebarDataInterface>(`${apiURL}/providers-app/providers/sidebar?providerId=${providerId}`, this.httpOptions);
  }
  
  getProfileData(id) {
    if (id !== undefined && id !== null) {
      return this.http.get<ProviderProfileData>(`${apiURL}/providers-app/providers/profile?id=${id}`, this.httpOptions);
    } else {
      return this.http.get<ProviderProfileData>(`${apiURL}/providers-app/providers/profile`, this.httpOptions);
    }
  }

  getAllProviderEntities(providerId: number) {
    return this.http.get<ProviderEntities>(`${apiURL}/providers-app/providers/entities?providerId=${providerId}`, this.httpOptions);
  }

}