import { Injectable, Injector, Type } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, PRIMARY_OUTLET, Router } from "@angular/router";
import { cloneDeep } from "lodash";
import { Observable, Observer } from "rxjs";
import { SrsSearch } from "../classes/srs-search.abstract";
import { AutocompleteInterface } from "../interfaces/autocomplete-result.interface";
import { SearchStateInfo } from "../interfaces/search-state-info";
import { SrsSearchConfig } from "../interfaces/srs-search-config.interface";
import { HideSearch } from "../searches/system/hide.search";
import { filter, map } from 'rxjs/operators';


const searchSet: Set<Type<SrsSearch>> = new Set();
const searchInfoMap: Map<Type<SrsSearch>, SrsSearchConfig> = new Map();

@Injectable({ providedIn: "root" })
export class SrsSearchService {
  public searchState: Observable<SearchStateInfo>;
  private searchObserver: Observer<SearchStateInfo>;

  private searchSet: Set<Type<SrsSearch>>;
  private searchInfoMap: Map<Type<SrsSearch>, SrsSearchConfig> = new Map();

  activeSearch: SrsSearch;
  activeSearchType: Type<SrsSearch>;
  lastActiveSearchType: Type<SrsSearch>;

  label: string;

  // This is so that if we choose to implement a return to the original search on a page, its already setup
  firstSearchTypeFound: Type<SrsSearch>;

  constructor(private router: Router, private injector: Injector, private route: ActivatedRoute) {
    this.searchState = new Observable((o) => {
      this.searchObserver = o;
      this.searchObserver.next({
        label: this.label,
        type: this.activeSearchType
      })
    });
    this.searchSet = cloneDeep(searchSet);
    this.searchInfoMap = cloneDeep(searchInfoMap);

    // Tracks changes in the router to get search function
    // let searchTypeFound: boolean = false;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map((route) => {
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(async (route) => {
        this.processSearch(route)
      });

  }

  processSearch(route: ActivatedRoute) {
    let routeEnd;
    while (!!route) {
      routeEnd = route;
      route = route.firstChild;
    }

    let search: Type<SrsSearch> = routeEnd.snapshot.data.search;

    if (search != null) {
      this.setActiveSearch(search);
      this.firstSearchTypeFound = search;
    }

  }

  search(text: string, autoCompleteObj?: AutocompleteInterface<any>) {
    this.activeSearch.search(
      this.router,
      text,
      autoCompleteObj != null ? autoCompleteObj.object : null
    );
  }

  async getAutoComplete(searchString: string) {
    return await this.activeSearch.autocomplete(searchString);
  }

  reset() {
    this.setActiveSearch(this.lastActiveSearchType);
  }

  getActiveSearch(): SrsSearch {
    return this.activeSearch;
  }

  setActiveSearch(Search: Type<SrsSearch>) {
    if (Search == HideSearch) {
      // Type was NONE or DNE
      this.activeSearch = null;
    } else {
      this.activeSearch = this.injector.get(Search);
      this.lastActiveSearchType =
        this.activeSearchType != null ? this.activeSearchType : Search;
      this.activeSearchType = Search;
    }

    this.label = this.activeSearch != null ? this.activeSearch.label : null;
    if (this.searchObserver != null) {
      this.searchObserver.next({
        label: this.label,
        type: Search,
      });
    }
  }

  // Used to gain access to child router outlets when used at a higher level
  registerRouter(router: Router) {
    this.router = router;
  }

  getAllSearchTypes(): Type<SrsSearch>[] {
    return Array.from(this.searchSet);
  }

  getSearchInfo(search: Type<SrsSearch>) {
    return this.searchInfoMap.get(search);
  }

  static withSearches(Searchs: Type<SrsSearch>[]): Type<SrsSearchService> {
    for (let Search of Searchs) {
      let s = new Search();
      searchSet.add(Search);
      searchInfoMap.set(Search, {
        label: s.label,
        security: s.security,
      });
    }

    return SrsSearchService;
  }
}
