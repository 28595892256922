import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentHostDirective } from '../../../directives/component-host.directive';
import { SrsElementConfig } from '../../../interfaces/elements/srs-element-config.interface';
import { SrsMultiElementConfig } from '../../../interfaces/elements/srs-multi-element-config.interface';
import { DynamicComponentConfig } from '../../../interfaces/utils/dynamic-component-config.interface';
import { SrsWidgetConfig } from '../../../interfaces/widgets/srs-widget-config.interface';

@Component({
  selector: 'app-dynamic-container',
  templateUrl: './dynamic-container.component.html',
  styleUrls: ['./dynamic-container.component.scss']
})
export class DynamicContainerComponent implements OnInit {

  @ViewChild(ComponentHostDirective, { static: true }) host: ComponentHostDirective;

  @Input()
  config: DynamicComponentConfig

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(<any>this.config.component)
    const viewContainerRef = this.host.viewContainerRef
    viewContainerRef.clear();

    const componentRef: any = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.config = this.config?.config
  }

}
