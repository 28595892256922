import { ObjectName } from "../../edtell-admin/enums/object-name.enum";
import { SecurityConfig, RoleSecurityConfig } from "../../edtell-admin/interfaces/route-security-config.interface";


export namespace RouteSecurityGenerator {

    export function generateAccessLevelSecurity(object: ObjectName, accessLevel?: 0 | 1 | 2 | 3 | 4): SecurityConfig {

        if (accessLevel == null) {
            accessLevel = 1
        }

        return {
            accessLevels: {
                objectName: object,
                accessLevel: accessLevel
            }
        }

    }

    /* User role based route secruity. (e.g. regAdmin, systemAdmin, etc) */
    export function generateRoleSecurity(config: RoleSecurityConfig): SecurityConfig {
        return {
            roleSecurity: config
        }
    }

    /**
     * Used to allow access to SecurityConfigInterface type definitions on route file
     * and formate single route definitions into arrays
     * @param config 
     */
    export function generateSecurity(config: SecurityConfig | SecurityConfig[]): SecurityConfig | SecurityConfig[] {
        return config;
    }

}
