import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { AssetViewObjectLink } from '../../../edtell-admin/interfaces/asset-view-object-link.interface';
import { Asset } from '../../interfaces/asset.interface';
import { LinkedToAssetEntity } from '../../interfaces/linked-to-asset-entity.interface';
import { AssetService } from '../../services/asset.service';

@Component({
  selector: 'app-linked-entities-dialog',
  templateUrl: './linked-entities-dialog.component.html',
  styleUrls: ['./linked-entities-dialog.component.scss']
})
export class LinkedEntitiesDialogComponent implements OnInit {

  asset: Asset;
  customerObjectsUsed: AssetViewObjectLink[] = [];
  providerObjectsUsed: AssetViewObjectLink[] = [];
  objectTypeName: string;
  dataLoaded = false;
  entities: LinkedToAssetEntity[];

  constructor(
    private assetService: AssetService,
    public dialogRef: MatDialogRef<LinkedEntitiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  async ngOnInit() {

    console.log('this.data', this.data);

    this.asset = _.cloneDeep(this.data);

    this.entities = await this.assetService.getEntitiesLinkedToLogo(this.asset.id).pipe(first()).toPromise();

    this.customerObjectsUsed = this.entities.filter((entity) => {
      return entity.entityName == 'Customer';
    }).map(entity => {
      return {
        name: entity.name,
        route: `/customer/${entity.id}`
      }
    })

    this.providerObjectsUsed = this.entities.filter((entity) => {
      return entity.entityName == 'Provider';
    }).map(entity => {
      return {
        name: entity.name,
        route: `/providers/${entity.id}`
      }
    })
    
    this.dataLoaded = true;
  }

  onNoClick(navigateToEntity): void {
    this.dialogRef.close({ navigate: navigateToEntity });
  }
}
