<div *ngIf="dialogReady">
  <div class="dialog-header">
    <h1 mat-dialog-title mat-dialog-draggable-title>Ownership</h1>
    <span aria-hidden="true" (click)="onNoClick()">&times;</span>
  </div>
  <mat-dialog-content>
    <div class="modal-body">

      <div class="row">
        <div class="col-12 px-0" [ngSwitch]="formGroup.state">
          <app-entity-form-control *ngSwitchCase="'READ'" [config]="viewFormControlConfig" [secure]="editSecureConfig"></app-entity-form-control>
          <app-entity-form-control *ngSwitchCase="'WRITE'" [config]="editFormControlConfig"></app-entity-form-control>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-12 px-0">
          <app-srs-select-box [config]="selectBoxConfig"></app-srs-select-box>
        </div>
      </div>
      <div class="row">
        <label for="createInfo">Created By: </label>
        <div id="createInfo">
          &nbsp; {{formatName(createUser)}}
          &nbsp;||&nbsp; {{formatDate(createDate)}}
        </div>
      </div>
      <div class="row">
        <label for="modifyInfo">Modified By: </label>
        <div id="modifyInfo">
          &nbsp; {{formatName(modifyUser)}}
          &nbsp;||&nbsp; {{formatDate(modifyDate)}}
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
