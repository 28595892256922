import { FormArray, FormControl, FormGroup } from '@angular/forms';

// Src: https://loiane.com/2017/08/angular-reactive-forms-trigger-validation-on-submit/
export function validateAllFormFields(formGroup: FormGroup | FormArray) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({onlySelf: true});
    } else if (control instanceof FormGroup || control instanceof FormArray) {
      validateAllFormFields(control);
    }
  });
}
