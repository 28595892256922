import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarAction, SnackbarActionConfig } from './snackbar-action-config';

@Component({
  selector: 'app-multi-action-snackbar',
  templateUrl: './multi-action-snackbar.component.html',
  styleUrls: ['./multi-action-snackbar.component.scss']
})
export class MultiActionSnackbarComponent implements OnInit {

  public actions: SnackBarAction[]

  constructor(
    public snackBarRef: MatSnackBarRef<MultiActionSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public config: SnackbarActionConfig
  ) {

    this.actions = []

    if (config.actions != null) {
      for (let a of config.actions) {
        this.actions.push(a)
      }
    }

    this.actions.push({
      title: "Dismiss",
      action: () => {
        this.dismiss()
      }
    })

  }

  ngOnInit() { }

  act(action: SnackBarAction) {
    action.action();
    this.dismiss();
  }

  dismiss() { this.snackBarRef.dismiss() }

}
