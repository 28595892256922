import { CdkFixedSizeVirtualScroll } from "@angular/cdk/scrolling";
import { ChangeDetectorRef, EventEmitter } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormArray, FormGroup, ValidatorFn } from "@angular/forms";
import { LangUtils } from "../../edtell-portal/namespaces/lang-utils.namespace";
import { SrsFormState } from "../enumerations/form-state.enum";
import { EdtellAbstractControlConfig } from "../interfaces/abstracts/edtell-abstract-control-config.interface";
import { EdtellFormControl } from "./edtell-form-control";
import { EdtellFormChangeTracker } from "./edtell-form-difference-tracker.class";

export class EdtellFormGroup extends FormGroup {
  private _submitted: boolean = false;
  private _state: SrsFormState;

  readonly changeTracker = new EdtellFormChangeTracker(this);

  stateChangeObservable: EventEmitter<SrsFormState>;
  changeDetectionRef;

  constructor(
    state: SrsFormState,
    controls?: { [key: string]: AbstractControl },
    validatorOps?: ValidatorFn | ValidatorFn[],
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
  ) {
    super(controls, validatorOps, asyncValidator);
    this._state = state;
    this.stateChangeObservable = new EventEmitter();
  }

  onStateChange() {
    return this.stateChangeObservable.asObservable();
  }

  addControl(name: string, control: AbstractControl, options?: EdtellAbstractControlConfig) {
    super.addControl(name, control, options);
    if (options == null || options.modifyOriginalStructure != true) {
      this.changeTracker.addStructureDifference(control);
    }
  }

  removeControl(name: string, options?: EdtellAbstractControlConfig) {
    let c = this.controls[name];
    super.removeControl(name, options);
    if (options == null || options.modifyOriginalStructure != true) {
      this.changeTracker.removeStructureDifference(c);
    }
  }

  setChangeDetectionDelay(cd: ChangeDetectorRef, delay: number) {
    let queuedChangeTimeout = null;
    this.valueChanges.subscribe(() => {
      if (queuedChangeTimeout != null) {
        clearTimeout(queuedChangeTimeout);
      }

      queuedChangeTimeout = setTimeout(() => {
        queuedChangeTimeout = null;
        if (!cd["destroyed"]) {
          cd.detectChanges();
        }
      }, delay);
    });
  }

  reset(
    value?: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    super.reset(value, options);
    this.submitted = false;
    this.changeTracker.reset();
  }

  /**
   * Resets Form Values to what they were before submission,
   * This also resets the submitted value, meaning validation messages
   * will be hidden.
   */
  resetValues() {
    for (let c in this.controls) {
      this.controls[c].reset();
    }
    this.submitted = false;
  }

  get state() {
    return this._state;
  }

  set state(formState: SrsFormState) {
    this._state = formState;
    if (this.stateChangeObservable != null) {
      this.stateChangeObservable.emit(this._state);
    }
  }

  markAsOriginal() {
    for (let key in this.controls) {
      let control = this.controls[key];
      if (control instanceof EdtellFormControl) {
        let edtellControl = control as EdtellFormControl;
        edtellControl.setOriginalValueToCurrentValue();
      } else if (control instanceof EdtellFormGroup || control instanceof EdtellFormArray) {
        control.markAsOriginal();
      }
    }
    this.markAsPristine();
    this.changeTracker.clearDifferences();
  }

  get getValues() {
    for (let key in this.controls) {
      let control = this.controls[key];
      if (control instanceof EdtellFormControl) {
        if (typeof control.value == "string" && control.value.endsWith(" ")) {
          control.setValue(control.value.slice(0, -1));
        }
      }
    }
    return this.value;
  }

  get isOriginal() {
    return this.changeTracker.isOriginal;
  }

  set submitted(value: boolean) {
    this._submitted = value
    for(let key in this.controls) {
      let control = this.controls[key];
      if(control instanceof EdtellFormGroup || control instanceof EdtellFormArray) {
        control.submitted = value
      }
    }
  }

  get submitted() {
    return this._submitted
  }
}


// After Angular 12 update, move this to a different file
// To handle circular depedency issue use " import type {} from './path/to/file' "
export class EdtellFormArray extends FormArray {
  _submitted: boolean
  _state: SrsFormState;
  readonly changeTracker = new EdtellFormChangeTracker(this);

  constructor(
    state: SrsFormState,
    controls?: AbstractControl[],
    validatorOps?: ValidatorFn | ValidatorFn[],
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
  ) {
    super(controls, validatorOps, asyncValidator);
    this._state = state;
  }

  push(control: AbstractControl, options?: EdtellAbstractControlConfig) {
    super.push(control, options);
    if (options == null || options.modifyOriginalStructure != true) {
      this.changeTracker.addStructureDifference(control);
    }
  }

  insert(index: number, control: AbstractControl, options?: EdtellAbstractControlConfig) {
    super.insert(index, control, options);
    if (options == null || options.modifyOriginalStructure != true) {
      this.changeTracker.addStructureDifference(control);
    }
  }

  removeAt(index: number, options?: EdtellAbstractControlConfig) {
    let c = this.controls[index];
    super.removeAt(index, options);
    if (options == null || options.modifyOriginalStructure != true) {
      this.changeTracker.removeStructureDifference(c);
    }
  }

  clear(options?: EdtellAbstractControlConfig) {
    if (options == null || options.modifyOriginalStructure != true) {
      for (let c of Object.values(this.controls)) {
        this.changeTracker.removeStructureDifference(c);
      }
    }
    super.clear();
  }

  markAsOriginal(){
    for(let c of Object.values(this.controls)){
      if(c instanceof EdtellFormControl){
        c.setIsOriginal(true);
      }else{
        if(c instanceof EdtellFormArray || c instanceof EdtellFormGroup){
          c.markAsOriginal()
        }
      }
    }
    this.changeTracker.clearDifferences();
  }

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.reset(value, options);
    this.changeTracker.reset();
  }

  markChildrenSubmitted(formArray?: EdtellFormArray) {
    formArray = formArray ?? this;
    
    for(let c of formArray.controls){
      if (c instanceof EdtellFormGroup) {
        c.submitted = true;
      } else if (c instanceof EdtellFormArray) {
        this.markChildrenSubmitted(c);
      }
      // else EdtellFormControl, don't need to do anything.
    }
  }
  
  get isOriginal() {
    return this.changeTracker.isOriginal;
  }

  set submitted(value: boolean) {
    this._submitted = value
    for(let key in this.controls) {
      let control = this.controls[key];
      if(control instanceof EdtellFormGroup || control instanceof EdtellFormArray) {
        control.submitted = value
      }
    }
  }

  get submitted() {
    return this._submitted
  }
}
