import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DistributionType } from '../../../enum/distribution-type.enum';
import { DistributionDialogConfig } from '../../../interfaces/processed-distribution-dialog-config.interface';
import { DistributionMetaData, ProcessedJobStatus } from '../../../interfaces/processed-job-status.interface';

@Component({
  selector: 'app-distribution-data-view',
  templateUrl: './distribution-data-view.component.html',
  styleUrls: ['./distribution-data-view.component.scss']
})
export class DistributionDataViewComponent implements OnInit {

  loaded : boolean = false;
  jobStatus: ProcessedJobStatus;
  distributions: DistributionMetaData[];

  @Output() 
  distributionSelected = new EventEmitter<DistributionMetaData>();

  @Input()
  config : DistributionDialogConfig

  constructor() {}

  async ngOnInit() {
    this.jobStatus = await this.config.api.getArchivedJobData(this.config.jobStatus.id)
    this.distributions = this.jobStatus.distributions
    this.loaded = true;
  }


  async downloadFile(d : DistributionMetaData){
    await this.config.api.downloadDistribution(d.id)
  }

  switchView(d : DistributionMetaData){
    this.distributionSelected.emit(d)
  }

}
