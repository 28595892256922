<div [formGroup]="config.group" [ngSwitch]="config.group.state">
  
  <ng-container *ngSwitchCase='"READ"'>
    <div class="form-group">
      <label>{{config.title}}</label>
      <textarea [edtellTooltip]="tooltip" class="form-control" [id]="elementId" [formControlName]="config.key"
                [rows]="config.startingRows" readonly></textarea>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase='"WRITE"'>
    <div class="form-group">
      <label *ngIf="!hideTitle && config.title">{{config.title}} <span class="required-asterisk">{{ required ? "*" : "" }}</span></label>
      <textarea patternInput [edtellTooltip]="tooltip" [id]="elementId" class="form-control" [formControlName]="config.key" [style.height]="config.height"
                [placeholder]="config.placeholder === undefined ? '' : config.placeholder" [rows]="config.startingRows"
                [maxlength]="config.maxCharacters" [autoFocus]="autoFocus" [disableControl]="disabled"
                (click)="onClick($event)" #textArea></textarea>
      <span *ngIf="config.maxCharacters; else noMaxChars">
                <small class="d-inline-block form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
                <small
                  [ngClass]="['char-hint', 'form-text', 'text-muted', 'text-right', maxCharWarningClass, noCharsLeftClass]">{{ currCharacterCount + '/' + config.maxCharacters}}</small>
            </span>
      <ng-template #noMaxChars>
        <small class="form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
      </ng-template>
      <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
        <div class="invalid-feedback d-block" *ngIf="hasError(v.key) == true">{{v.message}}</div>
      </ng-template>
    </div>
  </ng-container>

</div>
