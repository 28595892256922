import { FormControl, ValidatorFn, Validators } from "@angular/forms";

export function emailFormatValidator(): ValidatorFn {
  // NOTE: Email validation is very complicated and has many edge cases. Validators need to be
  // NOTE: more on the permissive to account for all these cases. If there is any further issues with email validation
  // NOTE: do not burn any more time on it and just use the angular validator. Uncommment Below...
  // return Validators.email;

  return (control: FormControl): { [key: string]: boolean } | null => {
    let email: string = control.value;
    if (email == null || email == "") {
      return null;
    }

    let parts = email.split("@");
    if (parts.length < 2 || parts.length > 2) {
      return {
        email: true,
      };
    }

    let addrParts = parts[1].split("."); // looking at section after the @
    if (addrParts.length <= 1) {
      return {
        email: true,
      };
    }

    for (let p of addrParts) {
      if (p == "") {
        return {
          email: true,
        };
      }
    }

    return Validators.email(control);
  };

  //   return Validators.email;
}
