import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LoginService } from '../../edtell-portal/services/login.service';
import { apiURL } from 'app-environment';
import { Asset } from '../interfaces/asset.interface';
import { AssetTypeConfig } from '../interfaces/asset-type-config.interface';
import { MoreButtonDataInterface } from '../../edtell-controls/interfaces/more-button-data.interface';
import { LinkedToAssetEntity } from '../interfaces/linked-to-asset-entity.interface';

@Injectable(
  {providedIn: 'root'}
)
export class AssetService {

  private httpOptions: {};
  private textHttpOptions: {};

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
    this.textHttpOptions = loginService.textHttpOptions;
  }

  createAsset(asset) {
    return this.http.post<number>(`${apiURL}/assets-app/asset`, asset, this.httpOptions);
  }

  getAsset(id) {
    return this.http.get<Asset>(`${apiURL}/assets-app/asset?id=${id}`, this.httpOptions);
  }

  getAssetsByAssetType(assetTypeId: number) {
    return this.http.get<MoreButtonDataInterface[]>(`${apiURL}/assets-app/asset/type?assetTypeId=${assetTypeId}`, this.httpOptions);
  }

  getImage(id) {
    return this.http.get(`${apiURL}/assets-app/asset/image?assetId=${id}`);
  }

  updateAsset(asset) {
    return this.http.post(`${apiURL}/assets-app/asset/update`, asset, this.httpOptions);
  }

  // TODO: We may want to change this call to determine which endpoint to hit in the case of a type mismatch
  deleteAssetById(id) {
    return this.http.delete(`${apiURL}/customer-app/customer/asset?id=${id}`, this.httpOptions);
  }

  updateName(assetNameUpdateRequest) {
    return this.http.put(`${apiURL}/assets-app/asset/name`, assetNameUpdateRequest, this.httpOptions);
  }

  getAssetSearchCount(searchString: string, used: number, assetTypeId: number | number[]) {

    let queryString = `searchString=${searchString}&used=${used}`;

    let assetTypeIdParam = "";
    if (assetTypeId != null && assetTypeId != 0) {
      if (typeof assetTypeId == 'object') {
        for (let id of assetTypeId) {
          assetTypeIdParam += `${id},`
        }
        assetTypeIdParam = assetTypeIdParam.slice(0, -1);
      } else {
        assetTypeIdParam = `${assetTypeId}`;
      }
      queryString = queryString.concat(`&assetTypeId=${assetTypeIdParam}`);
    }
    return this.http.get<number>(`${apiURL}/assets-app/asset/search/count?${queryString}`, this.httpOptions);
  }

  getAssetsBySearch(limit: number, pageSize: number, searchString: string, used: number, assetTypeId: number | number[]) {

    let queryString = `limit=${limit}&pageSize=${pageSize}&searchString=${searchString}&used=${used}`;

    let assetTypeIdParam = "";
    if (assetTypeId != null && assetTypeId != 0) {
      if (typeof assetTypeId == 'object') {
        for (let id of assetTypeId) {
          assetTypeIdParam += `${id},`
        }
        assetTypeIdParam = assetTypeIdParam.slice(0, -1);
      } else {
        assetTypeIdParam = `${assetTypeId}`;
      }

      queryString = queryString.concat(`&assetTypeId=${assetTypeIdParam}`);
    }

    return this.http.get<Asset[]>(`${apiURL}/assets-app/asset/search?${queryString}`, this.httpOptions);
  }

  getRecentlyModified = () => {
    return this.http.get(`${apiURL}/assets-app/asset/modified/recently`, this.httpOptions);
  };

  getModifiedByUser = () => {
    return this.http.get(`${apiURL}/assets-app/asset/modified/user`, this.httpOptions);
  };

  getCustomersLinkedToAsset<E=any>(assetId: number) {
    return this.http.get<E[]>(`${apiURL}/customer-app/customer/asset/used-by?assetId=${assetId}`, this.httpOptions);
  }

  getProvidersLinkedToAsset<E=any>(assetId: number){
    return this.http.get<E[]>(`${apiURL}/providers-app/provider/asset/used-by?assetId=${assetId}`, this.httpOptions);
  }

  getEntitiesLinkedToLogo(id: number) {
    return this.http.get<LinkedToAssetEntity[]>(`${apiURL}/assets-app/asset/logo/used-by?id=${id}`, this.httpOptions);
  }

  getAllAssetTypes() {
    return this.http.get<AssetTypeConfig[]>(`${apiURL}/assets-app/asset/types/all`, this.httpOptions);
  }

  assetCheck(asset: Asset) {
    return new Promise((resolve, reject) => {
      if (asset != null) {
        if (asset.id) {
          resolve(asset.id);
        } else {
          this.createAsset(asset).subscribe(resp => {
            resolve(resp);
          });
        }
      } else {
        resolve(null);
      }
    });
  }


}
