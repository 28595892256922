<div class="sidebar-container">
  <ul>
    <li>
      <a [routerLink]="['/apidocumentation/console']"><span>API Console</span></a>
    </li>
    <li>
      <span routerLink="/apidocumentation/index">Command Index</span>
    </li>
    <li *ngFor="let cg of entriesObj; index as i">
      <app-command-group [entriesObj]="entriesObj[i]" [index]="i" (changePageIndex)="changePageIndex($event)"></app-command-group>
    </li>
  </ul>
</div>