<div class="row" [ngStyle]="{'height': config.listHeight ?? 'auto'}">
    <div class="col-12 form-group">
        <div class="list-container">
            <label [for]="'left-' + config.key">{{config.leftTitle}}</label>
            <select [id]="'left-' + config.key" multiple class="form-control" #leftList (focus)="leftFocus()">
                <option *ngFor="let option of leftOptions" [value]="getOptionValue(option)">{{option.text}}</option>
            </select>
        </div>
        <div class="arrow-container">
            <div (click)="onRightArrowClick()"><i class="fas fa-arrow-right"></i></div>
            <div (click)="onLeftArrowClick()"><i class="fas fa-arrow-left"></i></div>
        </div>
        <div class="list-container">
            <label [for]="'right-' + config.key">{{config.rightTitle}}</label>
            <select [id]="'right-' + config.key" multiple class="form-control" #rightList (focus)="rightFocus()">
                <option *ngFor="let option of rightOptions" [value]="getOptionValue(option)">{{option.text}}</option>
            </select>
        </div>
        <div class="arrow-container">
            <div (click)="onUpArrowClick()"><i class="fas fa-arrow-up"></i></div>
            <div (click)="onDownArrowClick()"><i class="fas fa-arrow-down"></i></div>
        </div>
    </div>
</div>