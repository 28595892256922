import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { DocEntry } from '../../../../interfaces/doc-entry.interface';
import { DocEntryGroup } from '../../../../interfaces/doc-entry-group.interface';
import { DocumentationUIService } from '../../../../services/documentationUI.service';

@Component({
  selector: 'app-commands-page',
  templateUrl: './commands-page.component.html',
  styleUrls: ['./commands-page.component.scss']
})
export class CommandsPageComponent implements OnInit, OnDestroy {
  module: {command, title}
  entriesObj: DocEntryGroup[];
  entryGroupObj: DocEntryGroup;
  groupTitle = "";
  commands: DocEntry[];
  scrollPositions = [0];
  init = true;
  scrollBuffer = 0;
  subscription: Subscription;
  sidebarSub: Subscription;

  constructor(private route: ActivatedRoute, private http: HttpClient, private documentationUIService: DocumentationUIService, private router: Router) {
    this.subscription = documentationUIService.indexChange.subscribe((group) => {
      this.entryGroupObj = group;
      this.groupTitle = group.title;
      this.commands = group.entries;
      for (let c of this.commands) {
        if (!(c.response === "Boolean" || c.response === "String")) {
          try {
            c.response = JSON.stringify(JSON.parse(c.response), null, '\t');
          }
          catch (error) {
            console.error("INVALID JSON - RESPONSE BODY " + c.title)
          }
        }
        if (!(c.example === "Boolean" || c.example === "String")) {
          if (c.example == null) {
            c.example = '';
          } else {
            try {
              c.example = JSON.stringify(JSON.parse(c.example), null, '\t');
            }
            catch (error) {
              console.error("INVALID JSON - REQUEST " + c.title)
            }
          }
        }
        if (c.requestBody != null) {
          try {
            c.requestBody = JSON.parse(c.requestBody);
          } catch (error) {
            // console.error(error)
          }
        }
      }
      // make sure page loads
      setTimeout(() => {
        this.setScrollPositions();
      }, 600)
    })
  }

  async ngOnInit() {
    let routerCommand: string = this.route.snapshot.params['command'];
    let routerTitle: string = this.route.snapshot.params['title'];

    if (routerTitle !== undefined) {
      this.sidebarSub = this.documentationUIService.gotSidebarData.subscribe(async (sidebarData) => {
        
        let groupId = _.find(sidebarData, {title: routerTitle}).entries[0].groupId;
        
        await this.documentationUIService.getDocEntryGroup(groupId);
        
        // timeout 0 prevents bug where the document cannot find the element by id.
        // it somehow delays it just enough for the template to render perhaps?
        setTimeout(() => {
          this.setScrollPositions();
          if (routerCommand !== undefined) {
            try {
              var myElement = document.getElementById(routerCommand);
              var topPos = myElement.offsetTop - 64;
              document.getElementById('commands-page-container').scrollTop = topPos;
            } catch (err) {
              // console.log(err);
            }
          }
        }, 0);
      });
    }
  }
  
  setScrollPositions() {
    this.scrollPositions = [0];
    this.commands.forEach((e, i) => {
      this.scrollPositions.push(document.getElementById(e.title).offsetTop - 64);
    });
  }

  compare(a,b) {
    if (a.title.toLowerCase() < b.title.toLowerCase())
      return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase())
      return 1;
    return 0;
  }
  onScroll() {
    if (!this.init) {
      var elem = document.getElementById('commands-page-container');
      if (elem.scrollTop + 2 >= (elem.scrollHeight - elem.offsetHeight)) { // at bottom
        window.history.replaceState({}, '', `/apidocumentation/${this.groupTitle}/${this.commands[this.commands.length - 1].title}`);
      } else if (elem.scrollTop == 0){
        window.history.replaceState({}, '', `/apidocumentation/${this.groupTitle}`); // at top
      } else if (this.scrollBuffer < 1) {
        var scrollPos = elem.scrollTop;
        for (var i = 1; i < this.scrollPositions.length + 1; i++) {
          if (scrollPos <= this.scrollPositions[i + 1] - 200) {
            window.history.replaceState({}, '', `/apidocumentation/${this.groupTitle}/${this.commands[i - 1].title}`);
            break;
          }
        };
      }
      this.scrollBuffer = (this.scrollBuffer + 1) % 10;
    } else {
      this.init = false;
    }
  }
  
  openCallInConsole(command) {
    this.documentationUIService.navigatedCommand = command;
    this.router.navigate(["/apidocumentation/console"])
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.sidebarSub.unsubscribe();
  }
}
