import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTreeModule } from "@angular/material/tree";
import { CommentDialogueComponent } from "./components/comments-dialogue/comments-dialogue.component";
import { CommentInlineComponent } from "./components/comments-inline/comments-inline.component";
import { CommentsTreeNodeComponent } from "./components/comments-tree-node/comments-tree-node.component";
import { CommentsComponent } from "./components/comments/comments.component";
import { FreshnessIndicatorComponent } from "./components/freshness-indicator/freshness-indicator.component";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatBadgeModule,
    MatInputModule,
    MatTreeModule,
    MatIconModule,
    FormsModule,
  ],
  declarations: [
    CommentsComponent,
    CommentDialogueComponent,
    CommentInlineComponent,
    CommentsTreeNodeComponent,
    FreshnessIndicatorComponent,
  ],
  exports: [
    CommentsComponent,
    CommentDialogueComponent,
    CommentInlineComponent,
    CommentsTreeNodeComponent,
    FreshnessIndicatorComponent,
  ]
})
export class EdtellCommentsModule {}
