<div [formGroup]="formGroup">

  <ng-container [ngSwitch]="isHyperlink">
    <ng-container *ngSwitchCase="false">
      <div [ngClass]="[formGroupClass]">
        <label *ngIf="!hideTitle && config.title">{{config.title}}</label>
        <input [ngClass]="['form-control', validationClass, displayAlertClass]"
               [edtellTooltip]="tooltip"
               [id]="elementId"
               [formControlName]="config.key"
               [disableControl]="disabled"
               readonly>
        <ng-template [ngIf]="showValidators()" *ngFor="let v of config.validators">
          <div class="invalid-feedback d-block" *ngIf="hasError(v.key) == true">{{v.message}}</div>
        </ng-template>

        <div *ngFor="let alert of config.alerts">
          <div class="row mt-2" *ngIf="alert.showAlertMessage() && alertMessageExists(alert)">
            <div class="col-12 alert-message">
              <i class="fas fa-exclamation-triangle"></i>
              {{alert.message}}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="true">
      <div [ngClass]="[formGroupClass]">
        <label *ngIf="!hideTitle && config.title">{{config.title}}</label>
        <div class="form-control-link" [ngSwitch]="config.settings.hyperlinkType" readonly>
          <a *ngSwitchCase="'WEBSITE'" [href]="formattedWebsite" target="_blank">{{ control.value }}</a>
          <a *ngSwitchCase="'EMAIL'" [href]="'mailto:' + controlValue">{{ control.value }}</a>
        </div>
      </div>
    </ng-container>
  </ng-container>


</div>
