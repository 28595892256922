import {Injectable} from '@angular/core';
import {AgGridEvent, ColumnState} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {GridCallbackFunction} from '../../edtell-ag-grid/interfaces/grid-callback-function';
import {LoginService} from './login.service';
import {GridPreferenceName} from '../enums/grid-preference-name.enum';
import {Attribute} from '../interfaces/attribute.interface';
import {AttributeService} from './attribute.service';
import {find} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GridPreferenceService {

  resizeTimer: any;
  gotFirstGridProps = false;
  fullScreenSuffix = '.full-screen';

  resetGridUpserting = false;

  constructor(
    private attributeService: AttributeService,
    private loginService: LoginService
  ) {
  }

  getGridPreferences(name: GridPreferenceName, fullScreen = false): Observable<any> {
    return this.attributeService.getAttributeByUserIdAndAttributeName(this.loginService.getCurrentUser().id, name.toString() + (fullScreen ? this.fullScreenSuffix : ''));
  }

  upsertGridAttribute(event: AgGridEvent, prefrence: any): Observable<any> {
    prefrence.attributeValue = JSON.stringify(event.columnApi.getColumnState());
    // Have to do this check here since the grid loading causes some events to be fired which will reset the col state
    return this.gotFirstGridProps ? this.attributeService.upsertUserAttribute(prefrence) : new Observable();
  }

  /**
   * This should be tied to the on grid ready event
   * @param params
   */
  createGridReadyFunction(name: GridPreferenceName, callback?: GridCallbackFunction, fullScreen = false, columnStates?: ColumnState[]) {

    return (params: AgGridEvent) => {

      if (callback != null) {
        callback(params);
      }

      // let gridAPI = params.api;
      // let columnAPI = params.columnApi;
      // let columnState = columnAPI.getColumnState();

      let request: Attribute = {
        'itemId': this.loginService.getCurrentUser().id,
        'itemType': 'user',
        'attributeName': name + (fullScreen ? this.fullScreenSuffix : ''),
        'attributeValue': null,
        'visibleOnView': false,
        'systemAttribute': true,
        'blacklist': false
      };

      // console.log(request, fullScreen)

      params.api.addEventListener('resetGrid', async (event) => {
        if (!this.resetGridUpserting) {
          this.resetGridUpserting = true;
          await this.upsertGridAttribute(event, request).toPromise();
          this.resetGridUpserting = false;
        }
      });
      params.api.addEventListener('columnVisible', async (event) => {
        await this.upsertGridAttribute(event, request).toPromise();
        params.api.resetRowHeights();
      });
      params.api.addEventListener('columnPinned', async (event) => {
        await this.upsertGridAttribute(event, request).toPromise();
      });
      params.api.addEventListener('columnMoved', async (event) => {
        await this.upsertGridAttribute(event, request).toPromise();
      });
      params.api.addEventListener('sortChanged', async (event) => {
        await this.upsertGridAttribute(event, request).toPromise();
      });
      // handles resize, and reorder
      params.api.addEventListener('dragStopped', (event) => {
        // using a timeout function delay here so that the endpoint is not
        // spammed with column resize requests as the column changes sizes

        if (this.resizeTimer != null) {
          clearTimeout(this.resizeTimer);
        }

        this.resizeTimer = setTimeout(async () => {
          await this.upsertGridAttribute(event, request).toPromise();
          this.resizeTimer = null;
        }, 1000);

        params.api.resetRowHeights();
      });

      let gridData = null;
      this.getGridPreferences(name, fullScreen).subscribe(async (resp: Attribute) => {
        if (resp != null) {
          gridData = JSON.parse(resp.attributeValue);
          request.visibleOnView = resp.visibleOnView;
          this.setGridPreferences(name, params, gridData, resp, fullScreen, columnStates);
        }

        this.gotFirstGridProps = true;
      });

    };

  }

  createFirstDataRendered(name: GridPreferenceName, callback?: GridCallbackFunction, fullScreen = false, columnState?: ColumnState[]) {
    let gridData = null;
    return (params: AgGridEvent) => {

      if (callback != null) {
        callback(params);
      }

      this.setGridPreferences(name, params, gridData, null, fullScreen, columnState); // Ensure that preferences are set
    };
  }

  private setGridPreferences(name: GridPreferenceName, gridEvent: AgGridEvent, gridPreferenceData: any, attr: Attribute, fullScreen = false, columnStates?: ColumnState[]) {

    // merge gridPreferenceData with columnStates
    if (columnStates != null && gridPreferenceData != null) {
      for (let col of columnStates) {
        let gridPreference = find(gridPreferenceData, {colId: col.colId});
        for (let [key, value] of Object.entries(col)) {
          gridPreference[key] = value;
        }
      }
    }

    if (gridEvent == null) {
      return;
    }

    this.validateStoredGridColumnState(name, gridEvent, gridPreferenceData, attr, fullScreen);

    gridEvent.columnApi.applyColumnState({state: gridPreferenceData, applyOrder: true});
    gridEvent.api.resetRowHeights();
  }

  private validateStoredGridColumnState(name: GridPreferenceName, event: AgGridEvent, gridPreferenceData: any[], attr: Attribute, fullScreen = false) {

    if (gridPreferenceData == null) {
      return;
    } // No data to process

    let currentColumnState = event.columnApi.getColumnState();
    let modified = false;
    let keys: Set<string> = new Set<string>();

    for (let col of currentColumnState) {
      keys.add(col.colId);
    }

    // Checking to see if a key was removed from the grid, but still present in the grid preferences
    for (let i = 0; i < gridPreferenceData.length; ++i) {

      let col = gridPreferenceData[i];
      if (keys.has(col.colId)) {
        continue;
      }

      // Removes the element from the grid
      gridPreferenceData.splice(i, 1);
      i -= 1;

      modified = true;
    }

    keys.clear(); // Clears keys to be reused

    for (let col of gridPreferenceData) {
      keys.add(col.colId);
    }

    for (let i = 0; i < currentColumnState.length; ++i) {

      let col = currentColumnState[i];
      if (keys.has(col.colId)) {
        continue;
      }

      // If the key from the local grid is not present, add the new grid col config to the saved grid prefrence data
      gridPreferenceData.push(col);
      modified = true;

    }

    // If preferences were modified, upload the new grid preferences string
    if (modified) {

      let request = {
        'itemId': this.loginService.getCurrentUser().id,
        'itemType': 'user',
        'attributeName': name + (fullScreen ? this.fullScreenSuffix : ''),
        'attributeValue': JSON.stringify(gridPreferenceData),
        'visibleOnView': attr.visibleOnView,
        'systemAttribute': attr.systemAttribute,
        'blacklist': attr.blacklist
      };

      this.attributeService.upsertUserAttribute(request);
    }

  }

}
