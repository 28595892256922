import { 
  ObjectName, 
  ObjectInterface, 
  ObjectService, 
  SrsSearch, 
  SrsSearchInfo, 
  AutocompleteInterface
} from "@edtell-common"
import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
@SrsSearchInfo({
    label: "Objects",
    security: {
        accessLevels: {
            accessLevel: 2,
            objectName: ObjectName.OBJECT
        }
    }
})
export class ObjectSearch extends SrsSearch implements OnInit {

    constructor(private objectService: ObjectService) { super(); }

    ngOnInit() {

    }

    search(router: Router, text: string, obj: ObjectInterface) {
        if (obj != null) {
            router.navigate([`/admin/object/${obj.id}`]);
        } else {
            router.navigate([`/admin/object`], { queryParams: { objectName: text } });
        }
    }

    async autocomplete(text: string) {

        let results = await this.objectService.getAllObjects().toPromise()
        let options: AutocompleteInterface<ObjectInterface>[] = []

        for (let i of results) {
            options.push({
                text: i.objectName,
                object: i
            })
        }

        return options;
    }

}