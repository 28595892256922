import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { apiURL } from 'app-environment';
import { SecurityConfig, AccessLevelSecurityConfig, RoleSecurityConfig } from '../../edtell-admin/interfaces/route-security-config.interface';
import { ObjectService } from '../../edtell-admin/services/object.service';
import { UserService } from '../../edtell-admin/services/user.service';
import { AccessEventRequest } from '../interfaces/access-event-request.interface';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private httpOptions: any;
  public routeSecurityTrail: SecurityConfig[]

  constructor(
    private http: HttpClient,
    private objectService: ObjectService,
    private loginService: LoginService,
    private userService: UserService,
    private injector: Injector
  ) {
    this.httpOptions = loginService.httpOptions;
  }

  createAccessEvent(req: AccessEventRequest) {
    return this.http.post<boolean>(`${apiURL}/event-log-app/logging/access`, req, this.httpOptions);
  }

  async hasPermission(route: ActivatedRouteSnapshot, securityConfigs: SecurityConfig | SecurityConfig[]): Promise<boolean> {

    if(securityConfigs == null){
      return true;
    }else if(!Array.isArray(securityConfigs)){
      securityConfigs = [securityConfigs]
    }else if(securityConfigs.length == 0){
      return true;
    }

    let pass = false;

    for (let security of securityConfigs) {

      // Local Ownership Check
      if (security.localOwnership && security.ownershipData && !pass) {
        pass = security.ownershipData.owner == this.loginService.getCurrentUser().id
      }

      // Access Level Check
      if (security.accessLevels != null && !pass) {

        if (!Array.isArray(security.accessLevels)) {
          security.accessLevels = [security.accessLevels]
        }

        for (let access of security.accessLevels) {
          pass = this.objectService.hasAccessLevel(access.objectName, access.accessLevel);
          if (pass) {
            break;
          }
        }

      }

      // Role Check
      if (security.roleSecurity != null && !pass) {
        pass = this.userService.hasRoles(security.roleSecurity);
      }

      // Route Ownership Check
      if (security.routeOwnershipResolver != null && !pass) {

        // Get the resolver
        let resolver = this.injector.get(security.routeOwnershipResolver)

        // Check if the user is the owner
        let result = await resolver.resolve(route)
        pass = result.owner == this.loginService.getCurrentUser().id
      }

      if (!pass) {
        return false;
      }

    }

    return pass;
  }

  hasAccessLevel(config: AccessLevelSecurityConfig) {
    return this.objectService.hasAccessLevel(config.objectName, config.accessLevel);
  }

  hasRoles(config: RoleSecurityConfig) {
    return this.userService.hasRoles(config);
  }
  
  getRouteSecurityConfigs(route: ActivatedRouteSnapshot): SecurityConfig[] {

    let configs: Set<SecurityConfig> = new Set(); // For quicker contains lookup

    while (route != null) {

      let security: SecurityConfig | SecurityConfig[] = route.data.security;

      if (security != null) {
        if (Array.isArray(security)) {
          for (let securityConfig of security) {
            configs.add(securityConfig);
          }
        } else {
          configs.add(security);
        }
      }

      route = route.parent;
    }

    this.routeSecurityTrail = <SecurityConfig[]>Array.from(configs);
    return <SecurityConfig[]>Array.from(configs);
  }

}
