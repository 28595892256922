<div class="front-facing">
    <div class="row mb-3">
        <div class="col-12">
            <h1 class="color-primary">Card Security Code Validation</h1>
            <div class="border-bottom-sm"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h2>About CSC</h2>
            <p class="pre">
                The Card Security Code (CSC) is a 3 or 4 digit number that appears on your credit card.
                <br>
                <br>
                Beacuse the CSC is not part of the actual Credit Card Number, appears only on the card, and not on receipts or statements, the CSC provides some assurance
                that the physical card is in the possession of the buyer.
                <br>
                <br>
                The CSC appears on the back of most cards. The example below may help you locate the number on your card.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
            <h2 class="mb-3">Visa, Mastercard, and Discover</h2>
            <img src="./assets/credit-card-logos/visa_mastercard_discover.jpg" alt="CSC Location">
        </div>
    </div>
</div>