export function falsey(arg: any) {
    if (arg === false ||
        arg === 0 ||
        arg === -0 ||
        arg === '' ||
        arg === null ||
        arg === undefined ||
        arg === NaN){
            return true;
        }

    return false;
}