import { Routes } from "@angular/router";
import { AppContainerComponent } from "./components/app-container/app-container.component";
import { ApiConsoleComponent } from "./components/app-container/body/api-console/api-console.component";
import { CommandsIndexComponent } from "./components/app-container/body/commands-index/commands-index.component";
import { CommandsPageComponent } from "./components/app-container/body/commands-page/commands-page.component";

export const appRoutes: Routes = [
  {
    path: "", component: AppContainerComponent,
    children: [
      { path: "index", component: CommandsIndexComponent },
      { path: "console", component: ApiConsoleComponent },
      { path: ":title", component: CommandsPageComponent },
      { path: ":title/:command", component: CommandsPageComponent },
    ]
  },
];