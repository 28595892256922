import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { take } from 'rxjs/operators';
import { EntityControlLinkComponent } from '../../../edtell-ag-grid/components/entity-control-link/entity-control-link.component';
import { globalGridOptions } from '../../../edtell-ag-grid/interfaces/global-grid-config';
import { EntityControlLinkConfig } from '../../../edtell-controls/interfaces/entity-control-link-config.interface';
import { JobHistoryComponentConfig, JobHistoryViewComponentConfig } from '../../interfaces/job-history-component-config.interface';
import { ProcessedJobStatus } from '../../interfaces/processed-job-status.interface';
import { JobHistoryViewComponent } from '../job-history-view/job-history-view.component';
import { MatDialog } from '@angular/material/dialog';
import { LangUtils } from '../../../edtell-portal/namespaces/lang-utils.namespace';


@Component({
  selector: 'app-job-history-grid',
  templateUrl: './job-history-grid.component.html',
  styleUrls: ['./job-history-grid.component.scss'],
})
export class JobHistoryGridComponent implements OnInit {

  @Input()
  config : JobHistoryComponentConfig

  selectedViewConfig : JobHistoryViewComponentConfig
  userMap = new Map<number, string>();

  loaded: boolean = false;
  logsLink : EntityControlLinkConfig = {
    disabled: (elm : ProcessedJobStatus) => {
      return !elm.archive
    },
    disabledTooltip: () => {
      return "Job has not finished processing.";
    },
    callback: (rowData: ProcessedJobStatus) => {
      
      if(!rowData.archive){
        return;
      }

      this.matDialog.open(JobHistoryViewComponent, {
        width: "800px",
        data: LangUtils.type<JobHistoryViewComponentConfig>({
          jobArchiveId: rowData.id,
          api: this.config.api
        })
      });

    },
  };

  columnDefs: ColDef[] = [
    {
      headerName: 'Job Name',
      field: 'jobName',
      width: 20,
      cellRendererFramework: EntityControlLinkComponent,
      cellRendererParams: this.logsLink,
    },
    {
      headerName: 'Execution Status',
      field: 'executionStatus',
      width: 10,
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      width: 10,
      valueGetter: (params) => {
        return this.userMap.has(params.data.createdBy) ? this.userMap.get(params.data.createdBy) : params.data.createdBy
      }
    },
    {
      headerName: 'Date Created',
      field: 'dateCreated',
      width: 10,
    },
    {
      headerName: 'Date Completed',
      field: 'dateCompleted',
      width: 10,
    },
    {
      headerName: 'Origin',
      field: 'origin',
      width: 10,
    },
  ];

  onFirstDataRendered = (event) => {
    let api : GridApi = event.api
    api.sizeColumnsToFit();
    console.log(this.rowData)
  }

  gridOptions: GridOptions = {
    defaultColDef: {
      floatingFilter: true,
      minWidth: 30,
      resizable: true,
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    onFirstDataRendered: this.onFirstDataRendered
  };

  rowData: ProcessedJobStatus[];

  constructor(private router: Router, private route: ActivatedRoute, private matDialog : MatDialog) {}

  async ngOnInit() {
    this.rowData = await this.config.api.getJobHistoryList({jobIds: this.config.jobId, age: new Date(Date.now() - 6.048e+8)})
    console.log(this.rowData)
    if(this.config.userDisplayInformation != null){
      for(let u of this.config.userDisplayInformation){
        this.userMap.set(u.id, u.name)
      }
    }

    this.loaded = true;
  }

  resetView(){
    this.selectedViewConfig = null;
  }

}
