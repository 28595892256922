<div class="row mb-1">
  <div class="col text-left">
    <h1 class="d-inline mr-2">{{config.title}}</h1>
    <app-ownership-link class="info" *ngIf="showInfoLink" [object]="config.infoData"
      [saveFunc]="config.infoSaveCallback" [config]="config.infoConfig"></app-ownership-link>
  </div>
  <div class="col-6 d-flex justify-content-end align-items-end" *ngIf="hasRightSide">
      <a class="delete-link mr-2" *ngIf="showDeleteLink" [secure]="config.deleteSecurityConfig"
        [deleteLink]="deleteLinkConfig">Delete</a>
      <app-entity-control-buttons [options]="config.entityControl.controlButtons"></app-entity-control-buttons>
  </div>
</div>