import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { apiURL } from 'app-environment';
import { EmitterEvent } from '../../edtell-portal/enums/emitter-events.enum';
import { EventEmitterService } from '../../edtell-portal/services/event-emitter.service';
import { LoginService } from '../../edtell-portal/services/login.service';
import { ObjectName } from '../enums/object-name.enum';
import { ObjectInfoInterface } from '../interfaces/object-info.interface';
import { ObjectInterface } from './object-interface';


@Injectable({
  providedIn: "root"
})
export class ObjectService {

  private httpOptions: {};
  private objectIdMap: Map<ObjectName, ObjectInfoInterface>;
  private objectIdNameMap: Map<number, CompleteObjectInfo>;

  private objectIdMapState: BehaviorSubject<number>

  private objectMapLoadRequest: Observable<any>

  constructor(private http: HttpClient, private loginService: LoginService, private eventService : EventEmitterService) {
    this.httpOptions = loginService.httpOptions;
    this.objectIdMapState = new BehaviorSubject<number>(null);
    this.objectIdNameMap = new Map();
    this.eventService.subscribe(EmitterEvent.SIGN_OUT, () => {
      console.log("Wiping Object Mappings")
      this.objectIdMap = null;
      this.objectMapLoadRequest = null;
    })
  }

  getAllObjects() {
    return this.http.get<ObjectInterface[]>(`${apiURL}/administration-app/object/all`, this.httpOptions);
  }

  getObject(id) {
    return this.http.get<ObjectInterface>(`${apiURL}/administration-app/object?id=${id}`, this.httpOptions);
  }

  createObject(object) {
    return this.http.post<ObjectInfoInterface>(`${apiURL}/administration-app/object`, object, this.httpOptions);
  }

  // This call does not exist on backend
  copyObject(object) {
    return this.http.post(`${apiURL}/administration-app/object/copyObject`, object, this.httpOptions);
  }

  updateObject(object) {
    return this.http.put<ObjectInterface>(`${apiURL}/administration-app/object`, object, this.httpOptions);
  }

  isUniqueObjectName(name): Observable<boolean> {
    return this.http.get<boolean>(`${apiURL}/administration-app/object/exists?name=${name}`, this.httpOptions);
  }

  deleteObject(id: number) {
    return this.http.delete(`${apiURL}/administration-app/object?id=${id}`, this.httpOptions);
  }

  getObjectId(objectName: ObjectName): Observable<number> {

    if (this.objectIdMap == null) {

      // If the obj id's are not loaded, load them and return the requested object
      return new Observable<number>((o) => {
        this.getObjectInfoMap().subscribe(resp => {
          this.objectIdMap = resp
          let result = this.objectIdMap.get(objectName) != null ? this.objectIdMap.get(objectName).id : null
          o.next(result)
        })
      })

    }

    return new Observable<number>((o) => {
      let result = this.objectIdMap.get(objectName) != null ? this.objectIdMap.get(objectName).id : null
      o.next(result)
    })

  }

  hasAccessLevel(objectName: ObjectName, accessLevel: number) {

    let objInfo = this.objectIdMap.get(objectName)

    // If it is null it means the user does not have access to the object
    // The server only shares object info if the user has access
    if (objInfo == null) {
      return false;
    }

    return objInfo.accessLevel >= accessLevel
  }

  getObjectMap(): Observable<any> {

    if (this.objectIdMap != null) {
      return new Observable<any>((finished) => { finished.next() });
    } else if (this.objectMapLoadRequest != null) {
      return this.objectMapLoadRequest
    }

    // Load the object map
    let request = this.getObjectInfoMap()
    this.objectMapLoadRequest = new Observable<any>((finished) => {

      request.subscribe((resp) => {

        this.objectIdMap = new Map<ObjectName, ObjectInfoInterface>();

        for (let k of Object.keys(resp)) {
          resp[k].objectName = k;
          this.objectIdNameMap.set(resp[k].id, resp[k])
          this.objectIdMap.set(<ObjectName>k, resp[k])
        }

        finished.next()
      })

    })

    return this.objectMapLoadRequest
  }

  getObjectInfoById(id: number): Observable<CompleteObjectInfo> {
    // return this.objectIdNameMap.get(id)
    return new Observable<CompleteObjectInfo>((o) => {
      this.getObjectMap().subscribe(() => {
        o.next(this.objectIdNameMap.get(id));
      })
    })
  }

  private getObjectInfoMap() {
    // Receives a key value pair of (object name) -> id
    // I cant interface this because it is dynamic
    return this.http.get<any>(`${apiURL}/administration-app/object/info-map`, this.httpOptions)
  }

}

interface CompleteObjectInfo {
  id: number,
  objectName: ObjectName,
  accessLevel: number
}
