import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { DocEntryGroup } from '../interfaces/doc-entry-group.interface';
import { DocEntry } from '../interfaces/doc-entry.interface';
import { SidebarEntriesObject } from '../interfaces/sidebar-entries-object.interface';
import { ApiDocService } from './apidoc.service';

@Injectable({
  providedIn: "root"
})
export class DocumentationUIService {

  entriesObj: DocEntry[] = undefined;
  selectedEntryGroupIndex = 0;
  indexChange: Subject<DocEntryGroup> = new Subject<DocEntryGroup>();
  gotSidebarData: Subject<SidebarEntriesObject[]> = new Subject<SidebarEntriesObject[]>(); // needed for commands-page to get group data on hard load
  baseUrl: { value: string } = { value: "" };
  navigatedCommand: DocEntry;
  docEntryGroups: DocEntryGroup[] = [];
  currGroupId: number;

  constructor(private http: HttpClient, private apiDocService: ApiDocService) {
  }

  setSelectedEntryGroupIndex(index) {
    this.selectedEntryGroupIndex = index;
  }

  getDocEntries() {
    if (this.entriesObj === undefined) {
      return this.apiDocService.getAllActiveDocEntries();
    } else {
      return new Observable((observer) => {
        observer.next(this.entriesObj);
      })
    }
  }
  
  async getDocEntryGroup(groupId: number) {
    let group = _.find(this.docEntryGroups, { groupId: groupId });
    if (group === undefined) {
      group = await this.apiDocService.getDocEntryGroupById(groupId).toPromise();
      group.entries.sort((a, b)=> { return a.title > b.title ? 1 : a.title < b.title ? -1 : 0 })
      this.docEntryGroups.push(group);
    }
    this.currGroupId = groupId;
    this.indexChange.next(group as DocEntryGroup);
  }
  
  getSidebarData() {
    return this.apiDocService.getSidebarData();
  }

  setSelectedEntryGroupIndexByTitle(entryGroupTitle) {
    this.entriesObj.forEach((element, index) => {
      if (element.title == entryGroupTitle) {
        this.selectedEntryGroupIndex = index;
      }
    });
  }
  
  getBaseUrl(docEntries: DocEntryGroup[]) {
    let fullUrl = docEntries[0].entries[0].url;
    let baseUrl = fullUrl.substr(0, fullUrl.indexOf('/') + 1);
    this.baseUrl.value = "https://" +  baseUrl;
    return baseUrl;
  }
  
}
