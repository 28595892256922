<div class="row">
  <div class="col-12" *ngIf="loaded && jobs?.length > 0">
    <div class="job-status-bar-empty-space">
      <div class="d-flex flex-row border-top border-bottom">
        <!-- Job Displayed -->
        <div
          class="d-flex flex-row overflow-auto px-0 mx-0 flex-grow-1"
          *ngIf="displayedJobs != null && displayedJobs.length > 0; else noJobs"
        >
          <div class="p-3 border-left border-right job-status-item" *ngFor="let j of displayedJobs">
            <div class="row">
              <div class="col-12">
                <span>{{ j.jobName }}</span>
              </div>
            </div>
            <div class="row" *ngIf="isJobComplete(j)">
              <div class="col">
                <div class="dropdown">
                  <i class="fa fa-cog clickable-icon" aria-hidden="true" data-toggle="dropdown"></i>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" *ngIf="allowLogView" [routerLink]="" (click)="openLogsDialog(j)">Logs</a>
                    <a class="dropdown-item" [routerLink]="" (click)="dismiss(j)">Dismiss</a>
                    <a class="dropdown-item" [routerLink]="" (click)="requeue(j)">Requeue</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">{{ j.dateCreated | date: 'short' }}</div>
            </div>
            <div class="row">
              <div class="col" [title]="j.errors != null ? j.errors : ''">
                {{ j.executionStatus }}
                <div *ngIf="j.progressValue != null">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      [style]="'width: ' + j.progressValue * 100 + '%'"
                      [aria-valuenow]="j.progressValue"
                      aria-valuemin="0"
                      aria-valuemax="1"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <a *ngIf="j.executionStatus == 'Complete' && j.hasDistributions == true" (click)="viewDistributions(j)" [routerLink]="">Distributions</a>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noJobs>
          <div
            class="d-flex flex-column border-left justify-content-center no-jobs-message-container align-items-center flex-grow-1"
          >
            No Queued Jobs to Display
          </div>
        </ng-template>

        <!-- History Button -->
        <div class="px-2 ml-auto job-status-item border-left border-right d-flex flex-column justify-content-center">
          <a class="span mx-auto" [routerLink]="" (click)="navigateToJobHistory()">Job History</a>
        </div>
      </div>
    </div>
  </div>
</div>
