import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CommonRegExpPattern } from '../../../../edtell-portal/enums/common-regexp-pattern.enum';
import { formatWebsite } from '../../../../edtell-utilities/functions/format-website.function';
import { SrsFormElement } from '../../../abstracts/srs-form-element.abstract';
import { EdtellFormControl } from '../../../classes/edtell-form-control';
import { EdtellFormGroup } from '../../../classes/edtell-form-group.class';
import { SrsFormFieldConfig } from '../../../interfaces/elements/srs-form-field-config.interface';

@Component({
  selector: 'app-srs-form-field',
  templateUrl: './srs-form-field.component.html',
  styleUrls: ['./srs-form-field.component.scss']
})
export class SrsFormFieldComponent extends SrsFormElement<SrsFormFieldConfig> implements AfterViewInit {

  @ViewChildren('inputField')
  inputFieldRef: QueryList<ElementRef>;

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  isMoney: boolean = false;
  isHyperlink: boolean = false;
  // Value changes subscription calls on initialization. Flag first time so that we can mark the original control
  // as Dirty only after initialization.
  moneyFirstValueChangesSub: boolean = true;

  formGroup: EdtellFormGroup;

  showInfoText: boolean = false;

  constructor() {
    super();
  }

  onElementInit() {
    // money configuration
    // If money, need to create new form control as the value in the input will be different than the value that
    // will be saved on the form object (no dollar sign, no formatted decimals).
    // Value will be updated on the original FormControl during the onChange method.
    // need to create a new formGroup to prevent ExpressionChangedAfterChecked errors
    if (this.config.settings &&
      (this.config.settings.regExpPattern === CommonRegExpPattern.MONEY 
        || this.config.settings.regExpPattern === CommonRegExpPattern.MONEY_ALLOW_NEGATIVES
        || this.config.settings.regExpPattern === CommonRegExpPattern.MONEY_ALLOW_ONLY_NEGATIVES)
    ) {
      this.isMoney = true;

      this.formGroup = new EdtellFormGroup(this.config.group.state, {});
      this.formGroup.addControl(this.config.key, new EdtellFormControl(this.controlValue));

      // change the formgroup reset function to also reset the money input formgroup
      let resetFunction = this.config.group.resetValues;
      this.config.group.resetValues = () => {
        this.formGroup.get(this.config.key).reset();
        this.moneyFirstValueChangesSub = true;
        resetFunction.bind(this.config.group)();
      };

    } else {
      this.formGroup = this.config.group;
    }

    if (this.config.settings && this.config.settings.hyperlinkType) {
      this.isHyperlink = true;
    }

    if (this.isMoney) {

      this.control.valueChanges.subscribe((v) => {
        this.changeDisplayValue('$' + v, false);
      });

      // remove the dollar sign and set the value for the real form control
      this.formGroup.get(this.config.key).valueChanges.subscribe(async (value: string) => {
        this.changeDisplayValue(value, true);
      });
    }
  }

  private changeDisplayValue(_value: string, propegate: boolean) {
    if (!this.moneyFirstValueChangesSub) {
      this.control.markAsDirty();
    } else {
      this.moneyFirstValueChangesSub = false;
    }

    // Starting with a null check. If _value is NOT null then it checks for the String "null".
    // The string check has to be done because concatenating a string, "$" in this case, to null turns the null value into a string itself
    // See: Line 68
    let value = _value == null ? null : _value.includes('null') ? null : _value;

    if (value != null && value.includes('$')) {
      if (propegate == true) {
        this.control.setValue(value.substring(1));
      } else {
        this.formGroup.get(this.config.key).setValue(value, {
          onlySelf: true,
          emitEvent: false
        });
      }
    } else if (value != null && !value.includes('$')) {
      // This is stopping the input from eating the first element when typing in a value
      if (propegate) {
        this.control.setValue(value);
      } else {
        this.formGroup.get(this.config.key).setValue(value, {
          onlySelf: true,
          emitEvent: false
        });
      }
    }
    this.onChange(null);
  }

  ngAfterViewInit() {
    let inputField = this.inputFieldRef.first;
    if (this.config.attributes != null) {
      let i: HTMLInputElement = inputField.nativeElement;
      for (let c of this.config.attributes) {
        i.setAttribute(c.key, c.value + '');
      }
    }

    // if Sort Order field, add sort order validator to catch single dash 'required' edge case
    if (this.config.settings && this.config.settings.regExpPattern === CommonRegExpPattern.SORT_ORDER) {
      let validators: ValidatorFn[] = this.control.getValidators();
      validators.push(this.sortOrderJustDashValidator());
      this.control.setValidators(validators);
    }

    if (this.config.onDoneTyping) {
      this.setDoneTypingEvents();
    }
  }

  callAgain = false;
  typingTimer;
  doneTypingInterval = 250;
  doingDoneTypingCallback = false;

  setDoneTypingEvents() {
    this.inputFieldRef.first.nativeElement.addEventListener('input', (e) => {
      // clear timeouts where interval is not completed
      clearTimeout(this.typingTimer);
      
      this.typingTimer = setTimeout(async () => {
        this.callAgain = true;

        if (!this.doingDoneTypingCallback) {
          this.doingDoneTypingCallback = true;
          this.doDoneTypingCallback();
        }
      }, this.doneTypingInterval);
    });
  }

  async doDoneTypingCallback() {

    this.callAgain = false;
    await this.doneTyping();

    // this can be set to true by the method above
    if (this.callAgain) {
      await this.doDoneTypingCallback();
    }

    this.doingDoneTypingCallback = false;
  }

  async doneTyping() {
      this.config.onDoneTyping ? await this.config.onDoneTyping(this.control) : null;
  }

  async onChange(event: any) {
    this.change.emit(event);
    this.config.onChange != null ? this.config.onChange(this.control) : null;
  }

  async onInput(event: any) {
    // remove the dollar sign and set the value for the real form control
    if (this.isMoney) {
      this.control.patchValue(this.formGroup.get(this.config.key).value.substring(1));
    }

    this.config.onInput != null ? this.config.onInput(this.control) : null;
  }

  async onClick(event: any) {
    this.config.onClick != null ? this.config.onClick(this.control) : null;
  }

  async onFocusOut(event: any) {
    this.config.onFocusOut != null ? this.config.onFocusOut(this.control) : null;
  }

  toggleInfoText() {
    this.showInfoText = !this.showInfoText;
  }

  get currCharacterCount(): number {
    return this.control.value ? (this.control.value + '').length : 0;
  }

  get maxCharWarningClass(): string {
    return this.config.maxCharacters - this.currCharacterCount < 21 ? 'max-char-warning' : '';
  }

  get noCharsLeftClass(): string {
    return this.config.maxCharacters < this.currCharacterCount ? 'no-chars-left' : '';
  }

  get placeHolder() {
    return this.config.settings != null &&
    this.config.settings.placeholder != null ?
      this.config.settings.placeholder : '';
  }

  get required() {
    return super.required && (this.config.settings != null && this.config.settings.showRequired != null ? this.config.settings.showRequired : true);
  }

  get isNumberInput() {
    return this.config.settings != null && this.config.settings.numberInput != null;
  }

  get regExpPattern() {
    // if we have settings
    if (this.config.settings != null) {

      // return regExpPattern setting first
      if (this.config.settings.regExpPattern !== undefined) {
        return this.config.settings.regExpPattern;
      }

      // if no regExpPatten, but numberInput is defined true,
      // INTEGER regExp is default.
      if (this.config.settings.numberInput) {
        return CommonRegExpPattern.INTEGER;
      }
    }

    return null;
  }

  get controlValue() {
    if (this.isMoney && this.control.value !== '' && this.control.value !== null) {
      // format decimals
      let value = this.control.value.toString();
      let decimalPlaces = value.split('.')[1] === undefined ? 0 : value.split('.')[1].length;
      let decimalString = decimalPlaces == 0 ? '.' : '';
      decimalString += '0'.repeat(Math.abs(2 - decimalPlaces));
      return '$' + this.control.value + decimalString;
    }
    return this.control.value;
  }

  get name() {
    return this.config.autoCompleteName ? this.config.autoCompleteName : this.config.title;
  }

  get showMaxCharacters() {
    return this.config.maxCharacters != null && !this.config.settings?.hideMaxCharacterHint
  }
  // --------- Hyperlink stuff --------------

  get formattedWebsite(): string {
    return formatWebsite(this.control.value);
  }


  private sortOrderJustDashValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value == '-' ? {'required': true} : null;
    };
  }

  get type() {

    if (this.config.settings && this.config.settings.type) {
      if (typeof this.config.settings.type == 'function') {
        return this.config.settings.type();
      } else {
        return this.config.settings.type;
      }
    }
  }

}
