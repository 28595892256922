import {ColDef, ColumnState, FilterChangedEvent, FirstDataRenderedEvent, GridOptions, GridReadyEvent, ValueFormatterParams, ValueGetterParams} from 'ag-grid-community';
import {param} from 'jquery';
import * as moment from 'moment';
import {GridPreferenceName} from '../../edtell-portal/enums/grid-preference-name.enum';
import {GridPreferenceService} from '../../edtell-portal/services/grid-preference.service';
import {GridClearButtonComponent} from '../components/grid-clear-button/grid-clear-button.component';
import {GridResetButtonComponent} from '../components/grid-reset-button/grid-reset-button.component';
import { ViewFullScreenGridComponent } from '../components/view-full-screen-grid/view-full-screen-grid.component';
import { ViewGridSettingsComponent } from '../components/view-grid-settings/view-grid-settings.component';
import {gridWrappingHeader} from './grid-wrapping-header.config';

type ColumnTypes = { [key: string]: ColDef };

export function globalGridOptions(
  gridPrefName?: GridPreferenceName,
  gridPreferenceService?: GridPreferenceService,
  gridReadyCallback?: (event?: GridReadyEvent) => void,
  firstDataRenderedCallback?: (event?: FirstDataRenderedEvent) => void,
  onFilterChangedCallback?: (event?: FilterChangedEvent) => void,
  opts: {
    alternateRowColor?: boolean
    columnTypes? : ColumnTypes
    fullScreen? : boolean
    chartSelection?: boolean
    columnStates?: ColumnState[]
  } = {
    alternateRowColor: true,
    columnTypes: {},
    fullScreen: false,
    chartSelection: false
  })
{

  let onGridReady: (event?: GridReadyEvent) => void;
  let onFirstDataRendered: (event?: FirstDataRenderedEvent) => void;

  if (gridPreferenceService && gridPrefName) {
    onGridReady = gridPreferenceService.createGridReadyFunction(gridPrefName, gridReadyCallback, opts.fullScreen, opts.columnStates);
    onFirstDataRendered = gridPreferenceService.createFirstDataRendered(gridPrefName, firstDataRenderedCallback, opts.fullScreen, opts.columnStates);
  } else {
    onGridReady = gridReadyCallback;
    onFirstDataRendered = firstDataRenderedCallback;
  }

  function onCellKeyDown(e) {
    if (e.event.which == 38) {
      options.api.forEachNode(node => node.setSelected(false));
      options.api.forEachNode(node => node.rowIndex == e.rowIndex - 1 ? node.setSelected(true) : null);
    }
    if (e.event.which == 40) {
      options.api.forEachNode(node => node.setSelected(false));
      options.api.forEachNode(node => node.rowIndex == e.rowIndex + 1 ? node.setSelected(true) : null);
    }
  }

  let options: GridOptions = {
    animateRows: true,
    enableCellChangeFlash: true,
    // enableCellTextSelection: true,

    colResizeDefault: 'shift',
    multiSortKey: 'ctrl',

    rowSelection: 'multiple',
    suppressRowDeselection: true,
    suppressCopyRowsToClipboard: true,
    enableRangeSelection: true,

    frameworkComponents: {
      gridResetButton: GridResetButtonComponent,
      gridClearButton: GridClearButtonComponent,
      viewGridSettings: ViewGridSettingsComponent,
      viewFullscreen: ViewFullScreenGridComponent
    },
    onCellKeyDown: onCellKeyDown,
    // suppressContextMenu: true,

    getContextMenuItems: () => {
      let contextItems = ['copy', 'copyWithHeaders', 'paste'];
      if (opts.chartSelection) {
        contextItems.push('chartRange');
      }
      return contextItems;
    },

    onFilterChanged: (foo) => {
      if (onFilterChangedCallback) {
        onFilterChangedCallback();
      }
    },
    
    statusBar: {
      statusPanels: [
        {statusPanel: 'agFilteredRowCountComponent', align: 'left'},
        {statusPanel: 'agTotalRowCountComponent', align: 'left'},
        {statusPanel: 'agSelectedRowCountComponent', align: 'left'},
        {statusPanel: 'gridClearButton', align: 'left'},
        // {statusPanel: 'viewFullscreen'},
        {statusPanel: 'gridResetButton'},
        {statusPanel: 'viewGridSettings'}
      ]
    },

    defaultColDef: {
      minWidth: 30,
      resizable: true,
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        textFormatter: (from: string) => {
          return from?.toString().trim().toLowerCase();
        },
      },
      wrapText: true,
      autoHeight: true,
      comparator: (a, b) => {
        if (a == null || b == null) {
          return a == b ? 0 : a == null ? -1 : 1;
        }
        return a.toString().toLowerCase().localeCompare(b.toString().toLowerCase());
      }
      // cellStyle: {'white-space': 'normal'}
    },

    columnTypes: {
      checkbox: {
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,

        checkboxSelection: true,
        sortable: true,

        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressSizeToFit: true,

      },
      date: {
        valueGetter: (params: ValueGetterParams) => {
          let field = params.colDef.field;
          let cellValue = params.data[field];
          let date = moment.utc(cellValue).local().format('YYYY-MM-DD, h:mm:ss A');
          return date == 'Invalid date' ? null : date;
        }
      },
      money: {
        cellStyle: {textAlign: 'right'},
        filter: 'agNumberColumnFilter',
        valueGetter: (params: ValueGetterParams) => {
          let field = params.colDef.field;
          return params.data[field];
        },
        valueFormatter: (params: ValueFormatterParams) => {
          let field = params.colDef.field;
          let cellValue = params.data[field];
          if (cellValue != null) {
            return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(cellValue);
          } else {
            return null;
          }
        },
        comparator: numberComparator
      },
      number: {
        cellStyle: {
          textAlign: 'right'
        },
        valueGetter: (params: ValueGetterParams) => {
          let field = params.colDef.field;
          let cellValue = params.data[field];
          if (cellValue == null || cellValue == "") {
            return cellValue;
          }
          return isNaN(+cellValue) ? cellValue : +cellValue;
        },
        filter: 'agNumberColumnFilter',
        comparator: numberComparator
      },
      id: {
        cellStyle: {
          textAlign: 'right',
        },
        filter: 'agTextColumnFilter',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (isNaN(+valueA) && isNaN(+valueB)) {
            return 0;
          }
          if (isNaN(+valueA)) {
            return -1;
          }
          if (isNaN(+valueB)) {
            return 1;
          }
          return +valueA > +valueB ? 1 : +valueA < +valueB ? -1 : 0;
        }
      },
      float: {
        cellStyle: {
          textAlign: 'right'
        },
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => {
          let field = params.colDef.field;
          let fp = 2;
          return parseFloat(params.data[field].toFixed(fp));
        },
        valueFormatter: (params) => {
          let field = params.colDef.field;
          let fp = 2;
          return params.data[field].toFixed(fp);
        },
        comparator: numberComparator
      },
      boolean: {
        valueGetter: (params) => {
          let field = params.colDef.field;
          return params.data[field] ? 'True' : 'False';
        }
      },
      ...opts.columnTypes
    },
    ...gridWrappingHeader(onGridReady, onFirstDataRendered),
  };

  if (opts.chartSelection) {
    options = {
      ...options,
      enableCharts: true
    }
  }

  return options;
}

export function actionColumn(cellRF, cellRP?, widthOverride?) {
  let width = widthOverride == undefined ? 130 : widthOverride;

  return {
    headerName: 'Actions',
    field: 'id',
    colId: 'action',
    width: width,
    resizable: false,
    sortable: false,
    filter: false,
    suppressSizeToFit: true,
    cellRendererFramework: cellRF,
    cellRendererParams: cellRP,
  };
}

function numberComparator(a, b) {
  let aNum = +a;
  let bNum = +b;
  if (isNaN(aNum) && isNaN(bNum)) {
    return 0;
  }
  if (isNaN(aNum)) {
    return -1;
  }
  if (isNaN(bNum)) {
    return 1;
  }
  return aNum > bNum ? 1 : aNum < bNum ? -1 : 0;
}