import {AttributeService, AuthenticationState, LoginService} from '@edtell-common';
import {Component, NgZone} from '@angular/core';
import {Params, Router} from '@angular/router';
import {LicenseManager} from 'ag-grid-enterprise';
import {agGridKey} from '../../environments/environment';
import {EdtellLocationService} from '../modules/edtell-common/edtell-portal/services/edtell-location.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private loginService: LoginService,
    private attributeService: AttributeService,
    private edtellLocationService: EdtellLocationService,
  ) {
    this.setAgGridLicenseKey();

    this.loginService.loginState.subscribe((e) => {

      if (e.redirect == null || e.redirect == false) {
        return;
      }

      if (e.state != AuthenticationState.AUTHENTICATED) {
        this.login();
      } else {
        this.home();
      }
    });
  }

  login() {
    this.ngZone.run(() => {
      this.router.navigate(['/login']);
    });
  }

  home() {
    this.ngZone.run(() => {
      let loc = this.edtellLocationService.previous;
      loc = loc?.path == null || loc.path == '/login' ? {path: '/'} : this.edtellLocationService.previous;

      let queryParams: Params = {};
      if (loc.queryParams != null) {
        for (let key of loc.queryParams.keys) {
          queryParams[key] = loc.queryParams.get(key);
        }
      }

      this.router.navigate([loc.path], {queryParams: queryParams});
    });
  }

  private async setAgGridLicenseKey() {
    let agGridLicenseKeyAttribute = await this.attributeService.getAgGridLicenseKey().toPromise();
    LicenseManager.setLicenseKey(!!agGridLicenseKeyAttribute ? agGridLicenseKeyAttribute.attributeValue : agGridKey);
  }
}
