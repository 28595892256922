import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { EmitterEvent } from "../../../edtell-portal/enums/emitter-events.enum";
import { EventEmitterService } from "../../../edtell-portal/services/event-emitter.service";
import { NotificationService } from "../../../edtell-portal/services/notification.service";
import { CommentsService } from "../../services/comments.service";
import { CommentResponse } from "../comments/comments.component";

@Component({
    selector: 'app-comments-tree-node',
    templateUrl: './comments-tree-node.component.html',
    styleUrls: ['./comments-tree-node.component.scss']
})
export class CommentsTreeNodeComponent implements OnInit {

  // This is the comment response to be displayed and it may or may not contain its children
  @Input()
  public node: CommentResponse | null;

  @Input()
  public objectId: number | null;

  @Input()
  public itemId: number | null;

  @Input()
  public index: number;

  @Output()
  commentChangedEmitter = new EventEmitter<any>();

  @Input()
  childChanged: boolean;

  freshness;

  // Lets the parent know if the delete button has been pressed on the child
  @Output()
  endMe = new EventEmitter();

  // Used for adding a reply to a comment
  showText = false;
  commentText;

  hidden = false;
  submitClicked = false;

  elemIdBase;
  elemId;

  constructor(
    private commentService: CommentsService, 
    private notificationService: NotificationService,
     private emitterService: EventEmitterService
  ) {}

  ngOnInit() {
    this.setFreshnessIndicator();
    this.commentChangedEmitter.emit(true);
    this.elemId = this.findUniqueElementId();
    this.elemIdBase = this.elemId.substring(14); // stripping off 'reply-button' for the unique string
  }

  setFreshnessIndicator() {
    let dateCreated = new Date(this.node.comment.dateCreated);
    let date = new Date();
    let isToday = date.getDate() == dateCreated.getDate() && date.getMonth() == dateCreated.getMonth() && date.getFullYear() == dateCreated.getFullYear();
    let daysAgo = (Date.now() - +dateCreated + ((date.getTimezoneOffset() - date.getUTCDate()) * 60000)) / 86400000; // 86,400,000ms in a day
    let freshness = '';
    if (isToday) {
      freshness = 'Today';
    } else if (daysAgo < 7) {
      freshness = 'Past week';
    } else if (daysAgo < 30) {
      freshness = 'Past month';
    }
    this.node.freshness = freshness;
  }

  onChange($event) {
    if ($event != '') {
      this.commentChangedEmitter.emit(false);
    } else {
      this.commentChangedEmitter.emit(true);
    }
  }

  childChangedHandler($event) {
    this.commentChangedEmitter.emit($event);
  }

  addNew() {

    this.submitClicked = true;

    // Creating a new comment object for use in the backend
    let newCommentObject = {
      id: null,
      createdBy: null,
      dateCreated: null,
      owner: null,
      objectId: this.objectId,
      itemId: this.itemId,
      parentId: this.node.comment.id,
      comment: this.commentText,
      children: []
    };

    this.commentService.addComment(newCommentObject).subscribe(resp => {
      // Closing the comment box and setting the text to a empty string
      this.showText = false;
      this.commentText = '';

      // Pushing the child to the start of the array to preserve the reverse chronological order of the comments
      this.node.children.push(resp as unknown as CommentResponse);

      // Letting the user know that the creation has been successful
      this.notificationService.notificationEmitter.emit({message: 'New Comment Has Been Added.', duration: 5000});

      this.commentText = undefined;
    });

  }

  deleteChild(event) {

    if (confirm('Are you sure you want to delete this comment / thread?')) {
      this.commentService.deleteComment(this.node.children[event].comment.id).subscribe(resp => {
        if (resp) {
          _.pullAt(this.node.children, event);

          this.notificationService.notificationEmitter.emit({
            message: 'The Comment / Comment thread has been successfully deleted',
            duration: 5000
          });
        }


        this.emitterService.emit(EmitterEvent.SIDEBAR_NOTE_EVENT, null);
      });
    } else {
      console.log('xd');
    }
  }

  deleteComment() {
    this.endMe.emit(this.index);
  }

  formatDate(date) {
    return moment.utc(date).utcOffset(moment().utcOffset()).format('MMMM Do YYYY, h:mm:ss a');
  }

  dateMagic(date) {
    return moment.tz(date, 'UTC').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MMMM Do YYYY, h:mm:ss a');
  }

  toggleHideCommentTreeNode() {
    this.hidden = !this.hidden;
  }

  toggleReply() {
    if (this.commentText != undefined && confirm('Your reply may be lost. Are you sure you want to close?')) {
      this.showText = !this.showText;
      this.commentText = undefined;
    } else {
      this.showText = !this.showText;
    }
  }

  private findUniqueElementId(increment?: number) {
    let i = increment != null ? '-' + increment : ''; // increment value to append to element id
    // using reply-button since its a common element in all sub-comments and if this id is unique, it can be guaranteed that it will be unique for all of the other elements
    let possibleId = 'reply-button-' + this.index + '-' + this.itemId + '-' + this.index + i;

    let elm = document.getElementById(possibleId);
    if (elm != null) {
      return this.findUniqueElementId(increment != null ? increment + 1 : 1);
    }

    return possibleId;
  }

}
