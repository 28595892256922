import { Component, Input, OnInit } from '@angular/core';
import { EdtellFrontFacingDialogConfig } from '../../interface/edtell-front-facing-dialog-config.interface';

@Component({
  selector: 'app-edtell-front-facing-dialog',
  templateUrl: './edtell-front-facing-dialog.component.html',
  styleUrls: ['./edtell-front-facing-dialog.component.scss']
})
export class EdtellFrontFacingDialogComponent implements OnInit {

  @Input()
  config: EdtellFrontFacingDialogConfig;

  hideDialogCloseButton: boolean;

  constructor() {

  }

  ngOnInit(): void {
    this.hideDialogCloseButton = this.config.hideCloseDialogButton ?? false;
  }

  onXButtonClick() {
    this.config.edtellDialogRef.onNoClick();
  }

  get submitDisabled() {
    return this.config.submitButtonConfig.disabled != null ? this.config.submitButtonConfig.disabled() : false;
  }

  get header() {
    let header = "";
    if (typeof this.config.header == "string") {
      header = this.config.header;
    }
    else if (typeof this.config.header == "function") {
      header = this.config.header();
    }

    return header;
  }

}
