<div class="row" *ngIf="config?.entityFormControl">
  <div class="col-12">
    <app-entity-form-control [config]="config.entityFormControl"></app-entity-form-control>
  </div>
</div>

<div class="row mb-1" *ngIf="config?.entityControl">
  <div class="col-12">
    <app-entity-control [config]="config.entityControl"></app-entity-control>
  </div>
</div>

<div class="row" *ngIf="config?.data && loaded">
  <div class="col-12 ag-grid-container status-bar-on-top" [ngStyle]="getStyle()">
    <ag-grid-angular
      class="ag-theme-balham"
      [rowData]="data"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [ngStyle]="getStyle()"
    >
    </ag-grid-angular>
  </div>
</div>
