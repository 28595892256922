import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessedTestPageComponent } from './components/processed-test-page/processed-test-page.component';
import { RouterModule, Routes } from '@angular/router';
import { ProcessedJobStatusBarComponent } from './components/processed-job-status-bar/processed-job-status-bar.component';
import { EdtellMaterialModule } from '../material/material.module';
import { DistributionDataViewComponent } from './components/distribution-views/distribution-data-view/distribution-data-view.component';
import { DistributionEmailViewComponent } from './components/distribution-views/distribution-email-view/distribution-email-view.component';
import { DistributionContainerComponent } from './components/distribution-views/distribution-container/distribution-container.component';
import { JobHistoryGridComponent } from './components/job-history-grid/job-history-grid.component';
import { JobHistoryViewComponent } from './components/job-history-view/job-history-view.component';
import { AgGridModule } from 'ag-grid-angular';
import { EntityControlLinkComponent } from '../edtell-ag-grid/components/entity-control-link/entity-control-link.component';
import { DistributionDialogComponent } from './components/distribution-views/distribution-dialog/distribution-dialog.component';
import { ProcessedWebhooksComponent } from './components/webhooks/processed-webhooks/processed-webhooks.component';
import { ProcessedWebhookFormComponent } from './components/webhooks/processed-webhook-form/processed-webhook-form.component';
import { SrsFormsModule } from '../srs-forms/srs-forms.module';
import { ProcessedWebhookLogsComponent } from './components/webhooks/processed-webhook-logs/processed-webhook-logs.component';
import { DistributionGoogleChatViewComponent } from './components/distribution-views/distribution-google-chat-view/distribution-google-chat-view.component';

const routes: Routes = [
  {
    path: 'test',
    component: ProcessedTestPageComponent,
  },
];

@NgModule({
  declarations: [
    ProcessedTestPageComponent,
    ProcessedJobStatusBarComponent,
    DistributionDialogComponent,
    DistributionDataViewComponent,
    DistributionEmailViewComponent,
    DistributionContainerComponent,
    JobHistoryGridComponent,
    JobHistoryViewComponent,
    ProcessedWebhooksComponent,
    ProcessedWebhookFormComponent,
    ProcessedWebhookLogsComponent,
    DistributionGoogleChatViewComponent
  ],
  imports: [
    CommonModule,
    EdtellMaterialModule,
    SrsFormsModule,
    RouterModule,
    AgGridModule.withComponents([EntityControlLinkComponent]),
  ],
  exports: [
    ProcessedTestPageComponent,
    ProcessedJobStatusBarComponent,
    DistributionDialogComponent,
    DistributionDataViewComponent,
    DistributionEmailViewComponent,
    DistributionContainerComponent,
    JobHistoryGridComponent,
    JobHistoryViewComponent,
    ProcessedWebhooksComponent,
    ProcessedWebhookFormComponent,
    ProcessedWebhookLogsComponent
  ],
})
export class ProcessedModule {}

@NgModule({
  imports: [CommonModule, ProcessedModule, RouterModule.forChild(routes)],
  exports: [ProcessedModule],
})
export class ProcessedRoutingModule {}
