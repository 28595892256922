import { 
  ObjectName, 
  UserInterface, 
  UserService, 
  SrsSearch, 
  SrsSearchInfo, 
  AutocompleteInterface
} from "@edtell-common"
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
@SrsSearchInfo({
    label: "Users",
    security: {
        accessLevels: {
            accessLevel: 1,
            objectName: ObjectName.USER
        }
    }
})
export class UserSearch extends SrsSearch {

    private users: AutocompleteInterface<UserInterface>[] = [];

    constructor(private userService: UserService) {

        super();
        if (userService == null) { return; }

        userService.getAllUsers().subscribe((resp) => {
            for (let user of resp) {
                this.users.push({
                    text: user.lastName + " " + user.firstName,
                    object: user
                })
            }
        });

    }

    async search(router: Router, text: string, obj: UserInterface) {
        if (obj != null) {
            router.navigate([`/admin/users/${obj.id}`]);
        } else {
            let name = text.split(" ");
            router.navigate(["/admin/users"], {
                queryParams:{
                    lastName: name[0],
                    firstName: name[1]
                }
            })
        }

    }

    async autocomplete(text: string) {
        return this.users.filter((user) => {
            if (user.text.includes(text)) {
                return true;
            }
            return false;
        })
    }

}