import { Component, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { find } from 'lodash';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { EdtellFormControl } from '../../../classes/edtell-form-control';
import { EdtellFormGroup } from '../../../classes/edtell-form-group.class';
import { SrsFormState } from '../../../enumerations/form-state.enum';
import { SrsSelectOption } from '../../../interfaces/elements/srs-select-box-config.interface';
import { SrsTextAreaConfig } from '../../../interfaces/elements/srs-textarea-config.interface';
import { SrsFullAddressWidgetConfig } from '../../../interfaces/widgets/srs-full-address-widget-config.interface';
import { SrsMultiElementWidgetConfig } from '../../../interfaces/widgets/srs-multi-element-widget-config';
import { SrsFormFieldComponent } from '../../elements/srs-form-field/srs-form-field.component';
import { SrsSelectBoxComponent } from '../../elements/srs-select-box/srs-select-box.component';
import { SrsTwoLineFormFieldComponent } from '../../elements/srs-two-line-form-field/srs-two-line-form-field.component';

@Component({
  selector: 'app-srs-full-address-widget',
  templateUrl: './srs-full-address-widget.component.html',
  styleUrls: ['./srs-full-address-widget.component.scss']
})
export class SrsFullAddressWidgetComponent extends SrsFormWidget<SrsFullAddressWidgetConfig> implements OnDestroy {

  writeTemplateConfig: SrsMultiElementWidgetConfig<any>;
  readTextareaConfig: SrsTextAreaConfig;
  readValue: string;

  readGroup: EdtellFormGroup;

  formStateSubscription : Subscription;

  onWidgetInit() {
    this.writeTemplateConfig = {
      formGroup: this.config.formGroup,
      elements: [
        [
          {
            component: SrsTwoLineFormFieldComponent,
            config: this.config.addressConfig
          },
          {
            component: SrsFormFieldComponent,
            config: this.config.cityConfig
          },
          {
            component: SrsSelectBoxComponent,
            config: this.config.stateConfig
          },
          {
            component: SrsFormFieldComponent,
            config: this.config.zipConfig
          },
        ]
      ]
    };

    if (this.config.countyConfig) {
      this.writeTemplateConfig.elements[0].push({
        component: SrsSelectBoxComponent,
        config: this.config.countyConfig
      })
    }

    if (this.config.countryConfig) {
      this.writeTemplateConfig.elements[0].push({
        component: SrsSelectBoxComponent,
        config: this.config.countryConfig
      })
    }

    if (this.config.titleLineCheckboxConfig) {
      this.config.titleLineCheckboxConfig.settings = {
        formGroupMargin: false,
        padding: 0,
        class: 'title-checkbox',
        ...this.config.titleLineCheckboxConfig.settings
      }
    }

    this.makeReadValue();

    this.readGroup = new EdtellFormGroup(SrsFormState.READ, {});
    this.readGroup.addControl('addressReadControl', new EdtellFormControl(this.readValue));

    this.readTextareaConfig = {
      group: this.readGroup,
      key: 'addressReadControl',
      size: this.config.size,
      title: this.config.title,
      startingRows: 3
    };

    this.formStateSubscription = this.config.formGroup.onStateChange().subscribe((state) => {
      if (state == SrsFormState.READ) {
        this.makeReadValue();
        this.readGroup.get('addressReadControl').setValue(this.readValue);
      }
    })
  }

  get required() {
    return this.config.formGroup.get(this.config.addressConfig.key).hasValidator(Validators.required);
  }

  makeReadValue() {
    let group = this.config.formGroup;
    let address1: string = group.get(this.config.addressConfig.key).value; 
    let address2: string = group.get(this.config.addressConfig.key2).value;
    let city: string = group.get(this.config.cityConfig.key).value;
    let state: string = group.get(this.config.stateConfig.key).value;
    let zip: string = group.get(this.config.zipConfig.key).value;
    let county: string;
    let country: string;
    if (this.config.countyConfig) {
      let selectedCountyOption = find(this.config.countyConfig.options, {value: +group.get(this.config.countyConfig.key).value});
      if (selectedCountyOption) {
        county = selectedCountyOption.text;
      } else {
        county = ""
      }
    }
    if (this.config.countryConfig) {
      country: group.get(this.config.countryConfig.key).value;
    }

    let fullAddress = address1;
    address2 ? fullAddress += `, ${address2}` : null;
    city ? fullAddress += `\n${city}` : null;
    state ? fullAddress += `, ${state}` : null;
    zip ? fullAddress += ` ${zip}` : null;
    county ? fullAddress += `\n${county} County` : null;
    country ? fullAddress += `\n${country} Country` : null;

    this.readValue = fullAddress;
  }

  ngOnDestroy() {
    this.formStateSubscription.unsubscribe();
  }
}
