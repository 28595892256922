import { Component, OnInit } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'app-view-full-screen-grid',
  templateUrl: './view-full-screen-grid.component.html',
  styleUrls: ['./view-full-screen-grid.component.scss']
})
export class ViewFullScreenGridComponent {

  private gridApi;
  private columnApi;

  agInit(params: IStatusPanelParams): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  viewFullscreen() {
    this.gridApi.dispatchEvent({type: "viewFullScreen", columnApi: this.columnApi});
  }
}
