import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoginService} from '@edtell-common';
import {apiURL} from 'app-environment';
import {KnowledgeBaseCategoryInterface} from '../interfaces/knowledge-base-category.interface';
import {KnowledgeBasePostInterface} from '../interfaces/knowledge-base-post.interface';
import {KnowledgeBaseHomeDataResponseInterface} from '../interfaces/knowledge-base-home-data-response.interface';
import {KnowledgeBaseCategoryDataResponseInterface} from '../interfaces/knowledge-base-category-data-response.interface';
import {KnowledgeBaseSearchResultInterface} from '../interfaces/knowledge-base-search-result.interface';
import {KnowledgeBaseTagsInterface} from '../interfaces/knowledge-base-tags.interface';
import {KnowledgeBaseSimpleCategoryInterface} from '../interfaces/knowledge-base-simple-category.interface';
import {KnowledgeBaseSimpleHomeDataInterface} from '../interfaces/knowledge-base-simple-home-data.interface';
import {KnowledgeBaseSimpleCategoryDataInterface} from '../interfaces/knowledge-base-simple-category-data.interface';
import {KnowledgeBaseSimpleTagInterface} from '../interfaces/knowledge-base-simple-tag.interface';

@Injectable()
export class KnowledgeBaseService {

  private httpOptions: {};

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {

    this.httpOptions = loginService.httpOptions;
  }

  // TODO - Group Calls Better

  getCategories() {
    return this.http.get<KnowledgeBaseSimpleCategoryInterface[]>(`${apiURL}/knowledge-base-app/knowledge-base/categories/list`, this.httpOptions);
  }

  getAllCategories() {
    return this.http.get<KnowledgeBaseSimpleCategoryInterface[]>(`${apiURL}/knowledge-base-app/knowledge-base/categories/all`, this.httpOptions);
  }

  getHomeData() {
    return this.http.get<KnowledgeBaseSimpleHomeDataInterface>(`${apiURL}/knowledge-base-app/knowledge-base/home/data`, this.httpOptions);
  }

  getCategoryData(categoryId) {
    return this.http.get<KnowledgeBaseSimpleCategoryDataInterface>(`${apiURL}/knowledge-base-app/knowledge-base/category/data?categoryId=${categoryId}`, this.httpOptions);
  }


  getCategory(categoryId) {
    return this.http.get<KnowledgeBaseCategoryInterface>(`${apiURL}/knowledge-base-app/knowledge-base/category/get?categoryId=${categoryId}`, this.httpOptions);
  }

  createCategory(category: KnowledgeBaseCategoryInterface) {
    return this.http.post<KnowledgeBaseCategoryInterface>(`${apiURL}/knowledge-base-app/knowledge-base/category/create`, category, this.httpOptions);
  }

  updateCategory(category: KnowledgeBaseCategoryInterface) {
    return this.http.post<KnowledgeBaseCategoryInterface>(`${apiURL}/knowledge-base-app/knowledge-base/category/update`, category, this.httpOptions);
  }

  deleteCategory(categoryId) {
    return this.http.delete<boolean>(`${apiURL}/knowledge-base-app/knowledge-base/category/delete\?categoryId=${categoryId}`, this.httpOptions);
  }


  getPost(postId) {
    return this.http.get<KnowledgeBasePostInterface>(`${apiURL}/knowledge-base-app/knowledge-base/post/get?postId=${postId}`, this.httpOptions);
  }

  createPost(post: KnowledgeBasePostInterface) {
    return this.http.post<KnowledgeBasePostInterface>(`${apiURL}/knowledge-base-app/knowledge-base/post/create`, post, this.httpOptions);
  }

  updatePost(post: KnowledgeBasePostInterface) {
    return this.http.post<KnowledgeBasePostInterface>(`${apiURL}/knowledge-base-app/knowledge-base/post/update`, post, this.httpOptions);
  }

  deletePost(postId) {
    return this.http.delete<boolean>(`${apiURL}/knowledge-base-app/knowledge-base/post/delete?postId=${postId}`, this.httpOptions);
  }


  unpinHome(postId) {
    return this.http.get<boolean>(`${apiURL}/knowledge-base-app/knowledge-base/post/update/unpin-home?postId=${postId}`, this.httpOptions);
  }

  getSearchResults(searchString, categoryId, maxResults) {
    let endpointString = `${apiURL}/knowledge-base-app/knowledge-base/search?searchString=${searchString}`;
    if (!!categoryId) {
      endpointString += `&categoryId=${categoryId}`;
    }
    if (!!maxResults) {
      endpointString += `&maxResults=${maxResults}`;
    }
    return this.http.get<KnowledgeBaseSearchResultInterface[]>(endpointString, this.httpOptions);
  }

  getUserPins() {
    return this.http.get(`${apiURL}/knowledge-base-app/knowledge-base/user/pins`, this.httpOptions);
  }

  getTags() {
    return this.http.get<KnowledgeBaseSimpleTagInterface[]>(`${apiURL}/knowledge-base-app/knowledge-base/tag/all`, this.httpOptions);
  }

  createTag(tag) {
    return this.http.post<KnowledgeBaseTagsInterface>(`${apiURL}/knowledge-base-app/knowledge-base/tag/create`, tag, this.httpOptions);
  }

  updateTag(tag) {
    return this.http.post<KnowledgeBaseTagsInterface>(`${apiURL}/knowledge-base-app/knowledge-base/tag/update`, tag, this.httpOptions);
  }

  deleteTag(tagId) {
    return this.http.delete<boolean>(`${apiURL}/knowledge-base-app/knowledge-base/tag/delete?tagId=${tagId}`, this.httpOptions);
  }

  pinPostToCategory(postId, categoryId) {
    return this.http.get<boolean>(`${apiURL}/knowledge-base-app/knowledge-base/post/update/pin-category?postId=${postId}&categoryId=${categoryId}`, this.httpOptions);
  }

  unpinPostFromCategory(postId, categoryId) {
    return this.http.get<boolean>(`${apiURL}/knowledge-base-app/knowledge-base/post/update/unpin-category?postId=${postId}&categoryId=${categoryId}`, this.httpOptions);
  }


}
