import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleSecurityConfig, SecurityConfig } from '../../edtell-admin/interfaces/route-security-config.interface';
import { SecurityService } from '../../edtell-portal/services/security.service';
import { SecurityFailureBehavior } from '../enums/access-secure-behavior.enum';
import { AccessSecureCallback } from '../interfaces/access-secure-failure-callback';

@Directive({
  selector: '[secureStructural]'
})
export class AccessSecureStructuralDirective implements OnInit {

  @Input()
  behavior: SecurityFailureBehavior;

  @Input('success')
  successCallback: AccessSecureCallback;

  @Input('failure')
  failureCallback: AccessSecureCallback;

  @Input('data')
  data: any;

  private element: HTMLElement;
  private securityConfig: SecurityConfig[] = []; // Set in the secure setter

  private storedOwnershipIds: OwnerSecurityObject[] = [];

  constructor(
    private securityService: SecurityService,
    private route: ActivatedRoute,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit(): void {

  }

  @Input()
  set secureStructural(security: SecurityConfig | SecurityConfig[]) {

    if (security == null) {
      this.viewContainer.createEmbeddedView(this.templateRef)
      return;
    } else if (Array.isArray(security)) {
      this.securityConfig = security;
    } else {
      this.securityConfig = [security];
    }

    this.checkAccess();
  }

  async checkAccess() {

    // True if have access, False if don't
    let pass = await this.securityService.hasPermission(this.route.snapshot, this.securityConfig);

    switch (this.behavior) {

      case SecurityFailureBehavior.DISABLE: {
        if(!pass) {
          if(!pass) {
            this.element.setAttribute('disabled','');
            this.element.outerHTML = `<div class="secure-disabled-wrapper">${this.element.outerHTML}</div>`
          }
        }
        break;
      }
      default: {
        if (pass) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }

    }

    if (this.failureCallback != null && !pass) { // if there is a call back and they dont have access
      this.failureCallback(this.element, this.data);
    } else if (this.successCallback != null && pass) { // if there is a call back and they do have access
      this.successCallback(this.element, this.data);
    }

    this.storedOwnershipIds = [];

    for (let securityConf of this.securityConfig) {
      this.storedOwnershipIds.push({
        owner: securityConf.ownershipData == null ? null : securityConf.ownershipData.owner,
        securityConfig: securityConf
      });
    }
  }

  checkRoles(config: RoleSecurityConfig) {

    try {
      return this.securityService.hasRoles(config);
    } catch (err) {
      console.error(err);
    }

    return false;
  }

}

interface OwnerSecurityObject {
  securityConfig: SecurityConfig;
  owner: number;
}