import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginService } from "../services/login.service";

export class JwtInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    try {
      if (req.headers.has("JWT")) {
        req = req.clone({
          headers: this.loginService.copyAndAddHeaders(req.headers, { JWT: localStorage.getItem("JWT") })
        });
      }
    } catch (err) { }
    return next.handle(req);
  }
}

export const JwtInterceptorProvider = {
  // This is the provider that gets used in a module. This statement allows for import of the provider insted of the component.
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true,
  deps: [LoginService]
};
