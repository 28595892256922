import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { NotificationService } from "../../../edtell-portal/services/notification.service";
import { CommentsService } from "../../services/comments.service";
import { CommentResponse, DialogData } from "../comments/comments.component";

@Component({
    selector: 'app-comment-dialogue',
    templateUrl: './comments-dialogue.component.html',
    styleUrls: ['./comments-dialogue.component.scss'],
    providers: [CommentsService]
  })
  export class CommentDialogueComponent {
  
    newCommentText;
    submitClicked = false;
  
  
    constructor(public dialogueRef: MatDialogRef<CommentDialogueComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private commentService: CommentsService,
                private notificationService: NotificationService) {
  
    }
  
    // This is used for deleting comments at the root of the comment tree.
    deleteRootNode(event) {
      console.log('deleteRootNode Event', event);
      if (confirm('Are you sure you want to delete this comment / thread?')) {
        this.commentService.deleteComment(this.data.commentData[event].comment.id).subscribe(resp => {
  
          // The backend returns true if the comment / comment tree has been deleted
          if (resp) {
            // Using lodash to remove the comment / comment tree from the frontend dataset so the change is reflected to the user
            _.pullAt(this.data.commentData, event);
  
            // Alerting the user to the successful delete call
            this.notificationService.notificationEmitter.emit({
              message: 'The Comment / Comment Chain has been successfully deleted',
              duration: 5000
            });
          }
        });
      } else {
        console.log('lol jk');
      }
    }
  
  
    addComment() {
      this.submitClicked = true;
      // Creating a new comment object that gets used by the backend
      let newCommentObject = {
        id: null,
        createdBy: null,
        dateCreated: null,
        owner: null,
        objectId: this.data.objectId,
        itemId: this.data.itemId,
        parentId: 0,
        comment: this.newCommentText,
        children: []
      };
  
      this.commentService.addComment(newCommentObject).subscribe(resp => {
        // Resetting the text box to an empty string
        this.newCommentText = '';
  
        // this adds the resposne to the front of the comment data array to preserve the reverse chronological order
        this.data.commentData.unshift(resp as unknown as CommentResponse);
  
        // Sending a notificaiton to alert the user to the successful response
        this.notificationService.notificationEmitter.emit({message: 'New Comment Has Been Added.', duration: 5000});
      });
    }
  
    // This is the 'No Thanks' button. It closes the modal without saving/passing on the data.
    onNoClick() {
      this.dialogueRef.close();
    }
  
  }