<div class="body-container" id="commands-page-container" (scroll)="onScroll()">
  <div class="header">
      <h1>{{ groupTitle }}</h1>
    </div>
    <div class="command-container" *ngFor="let c of commands; index as i" id="{{ c.title }}">
      <div class="row">
        <div class="col-10">
          <h2>
            <span [ngClass]="c.requestType" class="type">{{ c.requestType }}</span>
            <span>{{ c.title }}</span>
          </h2>
        </div>
        <div class="col-2 d-flex justify-content-end align-items-end api-link">
          <span class="span-link" (click)="openCallInConsole(c)">Try In Console</span>
        </div>
      </div>
      <p class="command-desc">{{ c.description }}</p>
      <div class="url-container">{{ c.url }}</div>
      <span [hidden]="c.headers.length == 0">
        <h5>HEADERS</h5>
        <table class="header-table">
          <span class="head-row" *ngFor="let head of c.headers">
            <tr>
              <td class="head-first-cell">{{ head.title }}</td>
              <td class="head-second-cell">{{ head.description }}</td>
            </tr>
          </span>
        </table>
      </span>
      <span [hidden]="c.parameters.length == 0">
        <h5 class="params-h5">PARAMETERS</h5>
        <table class="table">
          <thead>
            <th class="first-cell" scope="col">Field</th>
            <th class="second-cell" scope="col">Description</th>
            <th scope="col" class="required-cell">Required</th>
          </thead>
          <tbody>
            <tr *ngFor="let p of c.parameters">
              <td class="first-cell">{{ p.fieldName }}</td>
              <td class="second-cell">{{ p.description }}</td>
              <td class="required-cell">{{ p.required == true ? "Yes" : "No" }}</td>
            </tr>
          </tbody>
        </table>
      </span>
      <span [hidden]="c.requestBody === null">
        <h5 class="body-h5">BODY</h5>
        <table class="table">
          <thead>
            <th class="first-cell" scope="col">Field</th>
            <th class="second-cell" scope="col">Value</th>
            <th scope="col" class="required-cell">Required</th>
          </thead>
          <tbody>
            <tr *ngFor="let f of c.requestBody">
              <td class="first-cell">{{ f.field }}</td>
              <td class="second-cell">{{ f.value }}</td>
              <td class="required-cell">{{ f.required == true ? "Yes" : "No" }}</td>
            </tr>
          </tbody>
        </table>
      </span>
      <h5 class="example-h5">REQUEST</h5>
      <pre class="example-container">
<code>{{ c.example }}</code>
</pre>
      <span [hidden]="c.response === null">
        <h5 class="example-h5">RESPONSE</h5>
        <pre class="example-container">
<code>{{ c.response }}</code>
</pre>
      </span>
    </div>
</div>
