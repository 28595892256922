import { AbstractControl } from "@angular/forms";
import { LangUtils } from "../../edtell-portal/namespaces/lang-utils.namespace";
import { EdtellFormControl } from "./edtell-form-control";
import { EdtellFormArray, EdtellFormGroup } from "./edtell-form-group.class";

export class EdtellFormChangeTracker {
    private differences = {
      stucture: {
        additions: new Set<AbstractControl>(),
        reductions: new Set<AbstractControl>(),
      },
      values: new Set<EdtellFormControl>(),
    };
  
    constructor(private host: EdtellFormGroup | EdtellFormArray) {}
  
    valueChanged(control: EdtellFormControl) {
      if (!control.isOriginal) {
        this.addValueDifference(control);
      } else {
        this.removeValueDifference(control);
      }
    }
  
    addValueDifference(control: EdtellFormControl) {
      this.differences.values.add(control);
      let parent = this.getParent();
      if (parent != null) {
        parent.changeTracker.addValueDifference(control);
      }
    }
  
    removeValueDifference(control: EdtellFormControl) {
      this.differences.values.delete(control);
      let parent = this.getParent();
      if (parent != null) {
        parent.changeTracker.removeValueDifference(control);
      }
    }
  
    addStructureDifference(control: AbstractControl) {
      if (this.differences.stucture.reductions.has(control)) {
        this.differences.stucture.reductions.delete(control);
      } else {
        this.differences.stucture.additions.add(control);
      }
  
      let parent = this.getParent();
      if (parent != null) {
        parent.changeTracker.addStructureDifference(control);
      }
  
      if (LangUtils.isInstance(control, [EdtellFormArray, EdtellFormGroup])) {
        let c = <EdtellFormArray | EdtellFormGroup>control;
        let childDifferenceTracker = c.changeTracker;
  
        // Merge Value differences
        for (let vc of childDifferenceTracker.differences.values) {
          this.differences.values.add(vc);
        }
  
        // Merge Structure differences
        let childStructureDifferences = childDifferenceTracker.differences.stucture;
        for (let vc of childStructureDifferences.additions) {
          this.differences.stucture.additions.add(vc);
        }
  
        for (let vc of childStructureDifferences.reductions) {
          this.differences.stucture.reductions.delete(vc);
        }
      }
    }
  
    removeStructureDifference(control: AbstractControl) {
      if (this.differences.stucture.additions.has(control)) {
        this.differences.stucture.additions.delete(control);
      } else {
        this.differences.stucture.reductions.add(control);
      }
  
      let parent = this.getParent();
      if (parent != null) {
        parent.changeTracker.removeStructureDifference(control);
      }
  
      // Remove Differences if item being removed is a ForumContainer
      if (LangUtils.isInstance(control, [EdtellFormArray, EdtellFormGroup])) {
        let c = <EdtellFormArray | EdtellFormGroup>control;
        let childDifferenceTracker = c.changeTracker;
  
        // Merge Value differences
        for (let vc of childDifferenceTracker.differences.values) {
          this.differences.values.delete(vc);
        }
  
        let childStructureDifferences = childDifferenceTracker.differences.stucture;
        for (let vc of childStructureDifferences.additions) {
          this.differences.stucture.additions.delete(vc);
        }
  
        for (let vc of childStructureDifferences.reductions) {
          this.differences.stucture.reductions.delete(vc);
        }
      }
    }
  
    private hasParent() {
      return this.hasParent != null && LangUtils.isInstance(this.host.parent, [EdtellFormArray, EdtellFormGroup]);
    }
  
    private getParent() {
      return this.hasParent() ? <EdtellFormArray | EdtellFormGroup>this.host.parent : null;
    }

    clearDifferences(){
      
      for(let a of this.differences.stucture.additions){
        this.removeStructureDifference(a)
      }

      for(let r of this.differences.stucture.reductions.keys()){
        this.addStructureDifference(r)
      }

      for(let v of this.differences.values){
        this.removeValueDifference(v)
      }

    }
  
    reset() {
      let controlToKeyMap = new Map<AbstractControl, string>();
      if (this.host instanceof EdtellFormGroup) {
        for (let k in this.host.controls) {
          controlToKeyMap.set(this.host.controls[k], k);
        }
      } else {
        for (let k in this.host.controls) {
          controlToKeyMap.set(this.host.controls[k], k);
        }
      }
  
      // Reset Structure Additions
      let struct = this.differences.stucture;
      let offset: number = 0; // Used when iterating through controls to determine offsets caused by removals in array
      for (let a of struct.additions) {
        let i = controlToKeyMap.get(a);
        if (this.host instanceof EdtellFormGroup) {
          this.host.removeControl(controlToKeyMap.get(a));
        } else {
          this.host.removeAt(+i - offset);
          ++offset;
        }
      }
  
      // Reset Structure Reductions
      for (let a in struct.reductions) {
        if (this.host instanceof EdtellFormGroup) {
          this.host.addControl(a, struct.reductions[a]);
        } else if (this.host instanceof EdtellFormArray) {
          // ORDER DOES NOT MATTER FOR DIFFERENCES, DEAL WITH ORDER ON THE RENDER RATHER THAN ON THE RESET
          // DO NOT IMPLEMENT ORDER ON RESET HERE - For more information ask Sam
          this.host.push(struct.reductions[a]);
        }
      }
  
      // Reset Values
      for (let v of this.differences.values) {
        v.reset();
      }
    }
  
    get isOriginal() {
      let structure = this.differences.stucture;
      return structure.additions.size == 0 && structure.reductions.size == 0 && this.differences.values.size == 0;
    }
  }
  