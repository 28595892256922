import { EdtellMethodCache } from "../classes/edtell-method-cache.class";
import { EdtellCacheConfig } from "../interfaces/edtell-cache-config.interface";
import { EdtellMethodCacheInfo } from "../interfaces/edtell-cache-method-info.interface";
import { EdtellCacheUtil } from "../namespaces/edtell-cache-utils.namespace";
import { EdtellInternalCacheUtil } from "../namespaces/edtell-internal-cache-utils.namespace";

export function CacheableMethod(config?: EdtellCacheConfig) {
  config = validateConfig(config);

  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const fn: Function = descriptor.value;

    let cache: EdtellMethodCache = new EdtellMethodCache(propertyKey, config);

    let classApi = EdtellInternalCacheUtil.getClassCacheApi(target);
    if (classApi == undefined) {
      classApi = EdtellInternalCacheUtil.initClassCacheApi(target);
    }
    classApi._addCache(cache);

    descriptor.value = function (...args: any[]) {
      let api = EdtellCacheUtil.getCacheApi(this);
      if (api == undefined) {
        api = EdtellInternalCacheUtil.initCacheApi(this);
        api._copyClassApi(classApi);
      }

      let info = api.getCacheByMethodName(propertyKey);

      // Config Check
      config.size = config.size >= 0 ? config.size : 0;

      // Queue cleanup in case something has been deleted from the cache, but the queue has not updated

      // Check to see if the result is already in the cache
      let cacheKey = config.hashFn(args);
      if (cache.has(cacheKey)) {
        return cache.get(cacheKey);
      }

      // Execute the wrapped method and store the pending results
      let response = fn.bind(this)(...args);
      cache.add(cacheKey, response);

      // Register Cache With Caching Service
      EdtellInternalCacheUtil.ACTIVE_EDTELL_CACHES.add(info);

      return cache.get(cacheKey);
    };
  };
}

function getCacheKey(args: any[]) {
  return JSON.stringify(args); // TODO: Have a definable hash creation
}

function validateConfig(config: EdtellCacheConfig) {

  config = config != null ? config : ({} as EdtellCacheConfig);
  config.size = config.size != null ? config.size : 1;
  config.copy = config.copy != null ? config.copy : false;

  config.hashFn =
    config.hashFn != null
      ? config.hashFn
      : (args: any[]) => {
          // This no args check may be silly
          //we would have to manually clear it if we need it to fire again before navigation
          if (args == null || args.length == 0) {
            args = [];
          }
          return getCacheKey(args);
        };

  config.onNavigation =
    config.onNavigation != null
      ? config.onNavigation
      : () => {
          return true;
        };

  return config;
}
