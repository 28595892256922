<div [formGroup]="config.group" [ngSwitch]="config.group.state">

  <ng-container *ngSwitchCase="'READ'">
      <div [ngClass]="[getLabelOrientation() ? 'inline-radio-read' : '']">
          <div class="form-group">
              <label *ngIf="config.title">{{config.title}}</label>
              <input [id]="elementId" [edtellTooltip]="tooltip" class="form-control" [value]="getReadText()"
                  readonly>
          </div>
      </div>
  </ng-container>

  <ng-container *ngSwitchCase='"WRITE"'>
      <div [ngClass]="[getLabelOrientation() ? 'inline-radio-write' : '', config.settings?.radioGroupClass != undefined ? config.settings?.radioGroupClass : '']">
          <label *ngIf="!hideTitle" [ngClass]="['label-bold', getLabelOrientation() ? 'inline-label' : '']">{{config.title}} <span class="required-asterisk">{{ required ? "*" : "" }}</span></label>
          <div [ngClass]="[formGroupClass, getLabelOrientation() ? 'd-inline' : '', hasAlert() ? 'has-alert' : '']">
            <div [ngClass]="['custom-control', 'custom-radio', areOptionsVertical() ? '' : 'custom-control-inline']" *ngFor="let o of config.options; index as i"
                  [ngStyle]="config.optionWidth !== undefined ? {'width.px': config.optionWidth} : {}">
                  <input [edtellTooltip]="tooltip" type="radio" [id]="elementId + '-option-' + i" (ngModelChange)="onChange()" #radioInput
                      [ngClass]="['custom-control-input', validationClass]" [formControlName]="config.key"
                      [value]="getValue(o)" [autoFocus]="i === 0 && autoFocus"
                      [disableControl]="disabled">
                  <label class="custom-control-label" [for]="elementId + '-option-' + i" [ngClass]="disabled ? 'disabled-option-thing' : 'enabled-option-thing'">{{o.text}}</label>
              </div>
              <small class="form-text text-muted" *ngIf="config.hint != null">{{hint}}</small>
              <div class="invalid-feedback d-block" *ngIf="control.hasError('required') && submitted && !hasRequiredError">
                  {{config.title}} is required.
              </div>
            <div class="invalid-feedback d-block" *ngIf="control.hasError('required') && submitted && hasRequiredError">
              {{requiredError.message}}
            </div>
              <ng-template [ngIf]="config.validators != null" *ngFor="let v of config.validators">
                  <div class="invalid-feedback" *ngIf="control.hasError(v.key) && submitted">{{v.message}}</div>
              </ng-template>
          </div>

      </div>
      <div *ngFor="let alert of config.alerts">
        <div class="row mt-2" *ngIf="alert.showAlertMessage()">
            <div class="col-12 alert-message">
                <i class="fas fa-exclamation-triangle"></i> 
                {{alert.message}}
            </div>
        </div>
    </div>
  </ng-container>

</div>
