import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import { EventEmitterService } from '../../../edtell-portal/services/event-emitter.service';

@Component({
  selector: 'app-security-management',
  template: `<span>
    <input type="range" min="0" max="4" [ngModel]="accessLevel" (ngModelChange)="onChange($event)" id="range{{params.data.id}}"> {{accessLevelText}}
  </span>`,
  styles: []
})
export class RolePermissionSliderComponent implements ICellRendererAngularComp {

  params;
  accessLevel;
  accessLevelText = '';

  constructor(private eventEmitterService: EventEmitterService) {
  }

  agInit(params: any) {
    this.params = params;
    this.accessLevel = params.value;
    this.setAccessLevelText();
  }

  onChange(e) {
    this.accessLevel = e;
    this.params.data.accessLevel = e;
    this.setAccessLevelText();
  }

  // This is for displaying text next to the slider to clarify what permission level is being given
  setAccessLevelText() {
    if (this.accessLevel == 0) {
      this.accessLevelText = 'No Access';
    } else if (this.accessLevel == 1) {
      this.accessLevelText = 'Read';
    } else if (this.accessLevel == 2) {
      this.accessLevelText = 'Create';
    } else if (this.accessLevel == 3) {
      this.accessLevelText = 'Modify';
    } else if (this.accessLevel == 4) {
      this.accessLevelText = 'Delete';
    }
  }

  // idk what this does. I was just following the example from the ag-grid documentation.
  refresh(): boolean {
    return false;
  }

}
