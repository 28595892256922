<div class="console-container px-0" *ngIf="dataLoaded">
  <div class="mt-3">
    <div class="header">
      <h1>API Console</h1>
    </div>
  </div>
  <div class="url-container">
    <div class="url-button" (click)="toggleDropdown()">
      <span class="button-title">{{ requestType }}</span>
      <span class="down-arrow">&#x2c5;</span>
    </div>
    <ul class="url-dropdown" [hidden]="!dropdownOpen">
      <li class="url-option not-last first" (click)="setRequestType('GET')">GET</li>
      <li class="url-option not-last" (click)="setRequestType('POST')">POST</li>
      <li class="url-option not-last" (click)="setRequestType('PUT')">PUT</li>
      <li class="url-option last" (click)="setRequestType('DEL')">DEL</li>
    </ul>
    <input class="url-box" [(ngModel)]="url.value">
  </div>
  <div class="headers-container">
    <h3>HEADERS</h3>
    <table cellspacing="0" cellpadding="0">
      <tr>
        <td>Key</td>
        <td>Value</td>
      </tr>
      <tr *ngFor="let h of headers; index as i">
        <td><input class="key" [(ngModel)]="h.key" (change)="checkAddRow(i)" placeholder="New Key" [disabled]="i === 0 || i === 1"></td>
        <td><input class="value" [(ngModel)]="h.value" (change)="checkAddRow(i)" placeholder="New Value" [disabled]="i === 0"></td>
        <span class="remove-row-button" (click)="removeRow(i)">&#215;</span>
      </tr>
    </table>
  </div>
  <div class="body-container">
    <h3>BODY</h3>
    <table cellspacing="0" cellpadding="0">
      <tr>
        <td>Key</td>
        <td>Value</td>
      </tr>
      <tr *ngFor="let b of body; index as i">
        <td>
          <input class="key" [(ngModel)]="b.key" (change)="checkAddBodyRow(i)" placeholder="New Key">
        </td>
        <td>
          <input class="value" [(ngModel)]="b.value" (change)="checkAddBodyRow(i)" placeholder="New Value">
        </td>
        <span class="remove-row-button" (click)="removeBodyRow(i)">&#215;</span>
      </tr>
    </table>
  </div>
  <div class="padding-20">
    <button (click)="sendRequest()">Send Request</button>
  </div>
  <div class="response-container">
    <h3>RESPONSE</h3>
    <app-code-text-format [codeTextFormatConfig]="codeTextFormatConfig"></app-code-text-format>
  </div>
</div>