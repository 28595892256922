import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { EventLogService } from '../../../edtell-admin/services/event-log.service';
import { NotificationService } from '../../services/notification.service';
import { SrsTextAreaConfig } from '../../../srs-forms/interfaces/elements/srs-textarea-config.interface';
import { EdtellFormGroup } from '../../../srs-forms/classes/edtell-form-group.class';
import { SrsFormState } from '../../../srs-forms/enumerations/form-state.enum';
import { EdtellFormControl } from '../../../srs-forms/classes/edtell-form-control';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  providers: [EventLogService]
})
export class ErrorDialogComponent implements OnInit {

  private formGroup = new EdtellFormGroup(SrsFormState.WRITE, {
    "message": new EdtellFormControl()
  })

  reportSent = false;
  textAreaConfig : SrsTextAreaConfig = {
    key: "message",
    size: 12,
    title: "Notes",
    group: this.formGroup,
    startingRows: 4,
    settings: {
      autoFocus: true
    }
  }

  constructor(
    private eventLogService: EventLogService,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public err: HttpErrorResponse) { }

  ngOnInit() {}

  close(){
    this.dialogRef.close()
  }

  sendReport(){

    let errorId = +this.err.headers.get("ERROR_ID");
    this.eventLogService.sendBugReport({
      errorId: errorId,
      message: this.formGroup.get("message").value
    }).subscribe((data) => {
      this.reportSent = true;
      this.notificationService.sendNotification({message: "Bug report sent"});
      this.close();
    });
  }

}
