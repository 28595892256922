<div class="row">
    <div class="col-12">
        <div class="month-block-container" #monthBlockContainer>
            <div [ngClass]="['month-block', 'btn-secondary', control.value == month ? 'selected-block' : '']" #monthBlocks
                *ngFor="let month of visibleMonths; index as i"
                (click)="selectMonthBox(month)"
                >
                    {{i == 0 && !config.startLastMonth ? 'This Month' : month}}
            </div>
            <select [ngClass]="['month-block', 'more', 'btn-secondary', control.value == dropDownMonth ? 'selected' : '']"
                *ngIf="moreDropDown"
                placeholder="more..."
                [(ngModel)]="dropDownMonth"
                (ngModelChange)="onSelectChange()">
                <option [value]="null" disabled>more...</option>
                <option *ngFor="let month of hiddenMonths" [value]="month">{{month}}</option>
            </select>
            <div class="month-block arrow-month-block btn-secondary" *ngIf="moreDropDown" (click)="onArrowClick()">>></div>
        </div>
    </div>
</div>