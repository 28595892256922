import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
// import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import {EdtellAgGridModule} from '../edtell-ag-grid/edtell-ag-grid.module';
import {EdtellMaterialModule} from '../material/material.module';
import {SrsSecurityModule} from '../srs-security/srs-security.module';
import {SrsCheckboxComponent} from './components/elements/srs-checkbox/srs-checkbox.component';
import {SrsDatetimePickerComponent} from './components/elements/srs-datetime-picker/srs-datetime-picker.component';
import {SrsDropCompleteComponent} from './components/elements/srs-drop-complete/srs-drop-complete.component';
import {SrsFormFieldAlwaysViewComponent} from './components/elements/srs-form-field-always-view/srs-form-field-always-view.component';
import {SrsFormFieldComponent} from './components/elements/srs-form-field/srs-form-field.component';
import {SrsMultiSelectDropdownComponent} from './components/elements/srs-multi-select-dropdown/srs-multi-select-dropdown.component';
import {SrsRadioButtonComponent} from './components/elements/srs-radio-button/srs-radio-button.component';
import {SrsRangeSelectorComponent} from './components/elements/srs-range-selector/srs-range-selector.component';
import {SrsSelectBoxComponent} from './components/elements/srs-select-box/srs-select-box.component';
import {SrsSpacerComponent} from './components/elements/srs-spacer/srs-spacer.component';
import {SrsTextAreaComponent} from './components/elements/srs-text-area/srs-text-area.component';
import {SrsTwoLineFormFieldComponent} from './components/elements/srs-two-line-form-field/srs-two-line-form-field.component';
import {DynamicContainerComponent} from './components/utils/dynamic-container/dynamic-container.component';
import {AssetDownloadLinkComponent} from './components/widgets/asset-picker-widget/asset-download-link/asset-download-link.component';
import {AssetPickerWidgetComponent} from './components/widgets/asset-picker-widget/asset-picker-widget.component';
import {ImageAssetPickerWidgetComponent} from './components/widgets/image-asset-picker-widget/image-asset-picker-widget.component';
import {SrsAttributeWidgetComponent} from './components/widgets/srs-attribute-widget/srs-attribute-widget.component';
import {SrsDateRangeWidgetComponent} from './components/widgets/srs-date-range-widget/srs-date-range.component';
import {SrsFormFieldWidgetComponent} from './components/widgets/srs-form-field-widget/srs-form-field-widget.component';
import {SrsFullAddressWidgetComponent} from './components/widgets/srs-full-address-widget/srs-full-address-widget.component';
import {SrsMultiElementWidgetComponent} from './components/widgets/srs-multi-element-widget/srs-multi-element-widget.component';
import {SrsNumberRangeWidgetComponent} from './components/widgets/srs-number-range-widget/srs-number-range-widget.component';
import {SrsRadioGroupWidgetComponent} from './components/widgets/srs-radio-group-widget/srs-radio-group-widget.component';
import {SrsTableWidgetComponent} from './components/widgets/srs-table-widget/srs-table-widget.component';
import {TextareaWithCheckboxWidgetComponent} from './components/widgets/textbox-with-checkbox-widget/textarea-with-checkbox-widget.component';
import {AutofocusDirective} from './directives/auto-focus.directive';
import {ComponentHostDirective} from './directives/component-host.directive';
import {DateInputDirective} from './directives/date-input.directive';
import {DisableControlDirective} from './directives/disable-control.directive';
import {DialogDraggableTitleDirective} from './directives/draggable-dialog.directive';
import {PatternInputDirective} from './directives/pattern-input.directive';
import {PhoneNumberInputDirective} from './directives/phone-number-input.directive';
import {TooltipDirective} from './directives/tooltip.directive';
import {SrsHideToggleComponent} from './components/elements/srs-hide-toggle/srs-hide-toggle.component';
import {SrsFormArrayWidgetComponent} from './components/widgets/srs-form-array-widget/srs-form-array-widget.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {AngularCCLibraryModule} from '../angular-cc-library/angular-cc-library.module';
import {EdtellCreditCardPaymentWidgetComponent} from './components/widgets/edtell-credit-card-payment-widget/edtell-credit-card-payment-widget.component';
import {WhatIsCscDialogComponent} from './components/widgets/edtell-credit-card-payment-widget/what-is-csc-dialog/what-is-csc-dialog.component';
import {
  EdtellCheckOrMoneyOrderPaymentWidgetComponent
} from './components/widgets/edtell-check-or-money-order-payment-widget/edtell-check-or-money-order-payment-widget.component';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import {EdtellListBuilderComponent} from './components/elements/edtell-list-builder/edtell-list-builder.component';
import {EdtellMonthBlockPickerComponent} from './components/elements/edtell-month-block-picker/edtell-month-block-picker.component';
import {EdtellDatetimePickerComponent} from './components/elements/edtell-datetime-picker/edtell-datetime-picker.component';
import {EdtellFileUploadComponent} from './components/elements/edtell-file-upload/edtell-file-upload.component';
import {CallScheduleWidgetComponent} from './components/widgets/call-schedule-widget/call-schedule-widget.component';
import {EdtellControlsModule} from '../edtell-controls/edtell-controls.module';
import {PhoneNumberPipe} from './pipes/phone-number.pipe';
import { EdtellColorPickerComponent } from './components/elements/edtell-color-picker/edtell-color-picker.component';

@NgModule({
  declarations: [
    SrsFormFieldComponent,
    SrsRadioButtonComponent,
    SrsRadioGroupWidgetComponent,
    ImageAssetPickerWidgetComponent,
    SrsMultiElementWidgetComponent,
    ComponentHostDirective,
    DynamicContainerComponent,
    SrsSelectBoxComponent,
    SrsDatetimePickerComponent,
    SrsRangeSelectorComponent,
    SrsTextAreaComponent,
    SrsCheckboxComponent,
    SrsFormFieldAlwaysViewComponent,
    TextareaWithCheckboxWidgetComponent,
    SrsTwoLineFormFieldComponent,
    SrsAttributeWidgetComponent,
    SrsTableWidgetComponent,
    SrsMultiSelectDropdownComponent,
    SrsDateRangeWidgetComponent,
    SrsNumberRangeWidgetComponent,
    TooltipDirective,
    DialogDraggableTitleDirective,
    AutofocusDirective,
    PhoneNumberInputDirective,
    DateInputDirective,
    DisableControlDirective,
    PatternInputDirective,
    AssetDownloadLinkComponent,
    SrsSpacerComponent,
    AssetPickerWidgetComponent,
    SrsFullAddressWidgetComponent,
    SrsFormFieldWidgetComponent,
    SrsDropCompleteComponent,
    SrsHideToggleComponent,
    SrsFormArrayWidgetComponent,
    EdtellCreditCardPaymentWidgetComponent,
    WhatIsCscDialogComponent,
    EdtellCheckOrMoneyOrderPaymentWidgetComponent,
    EdtellListBuilderComponent,
    EdtellMonthBlockPickerComponent,
    EdtellDatetimePickerComponent,
    EdtellFileUploadComponent,
    CallScheduleWidgetComponent,
    PhoneNumberPipe,
    EdtellColorPickerComponent
  ],
  exports: [
    SrsFormFieldComponent,
    SrsRadioGroupWidgetComponent,
    SrsRadioButtonComponent,
    ImageAssetPickerWidgetComponent,
    SrsMultiElementWidgetComponent,
    SrsSelectBoxComponent,
    SrsDatetimePickerComponent,
    SrsRangeSelectorComponent,
    SrsTextAreaComponent,
    SrsCheckboxComponent,
    SrsFormFieldAlwaysViewComponent,
    TextareaWithCheckboxWidgetComponent,
    SrsTwoLineFormFieldComponent,
    SrsAttributeWidgetComponent,
    SrsTableWidgetComponent,
    DynamicContainerComponent,
    SrsMultiSelectDropdownComponent,
    SrsDateRangeWidgetComponent,
    SrsNumberRangeWidgetComponent,
    DialogDraggableTitleDirective,
    AutofocusDirective,
    PhoneNumberInputDirective,
    DateInputDirective,
    DisableControlDirective,
    AssetDownloadLinkComponent,
    PatternInputDirective,
    SrsSpacerComponent,
    AssetPickerWidgetComponent,
    SrsFullAddressWidgetComponent,
    SrsFormFieldWidgetComponent,
    SrsDropCompleteComponent,
    SrsHideToggleComponent,
    SrsFormArrayWidgetComponent,
    FormsModule,
    EdtellCreditCardPaymentWidgetComponent,
    EdtellCheckOrMoneyOrderPaymentWidgetComponent,
    EdtellListBuilderComponent,
    EdtellMonthBlockPickerComponent,
    EdtellDatetimePickerComponent,
    EdtellFileUploadComponent,
    CallScheduleWidgetComponent,
    TooltipDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    RouterModule,
    SrsSecurityModule,
    ReactiveFormsModule,
    EdtellMaterialModule,
    AngularCCLibraryModule,
    CdkScrollableModule,
    // EdtellControlsModule
    // NgbTypeaheadModule
  ]
})
export class SrsFormsModule {
}
