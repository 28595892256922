import { Component } from '@angular/core';
import { CheckOrMoneyOrderContactInformation, EdtellCheckOrMoneyOrderPaymentWidgetConfig } from '../../../interfaces/widgets/edtell-check-or-money-order-payment-widget-config.interface';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { SrsFormFieldConfig } from '../../../interfaces/elements/srs-form-field-config.interface';
import { EdtellFormGroup } from '../../../classes/edtell-form-group.class';
import { SrsFullAddressWidgetConfig } from '../../../interfaces/widgets/srs-full-address-widget-config.interface';

@Component({
  selector: 'app-edtell-check-or-money-order-payment-widget',
  templateUrl: './edtell-check-or-money-order-payment-widget.component.html',
  styleUrls: ['./edtell-check-or-money-order-payment-widget.component.scss']
})
export class EdtellCheckOrMoneyOrderPaymentWidgetComponent extends SrsFormWidget<EdtellCheckOrMoneyOrderPaymentWidgetConfig> {

  firstNameFormFieldConfig: SrsFormFieldConfig;
  lastNameFormFieldConfig: SrsFormFieldConfig;
  fullAddressConfig: SrsFullAddressWidgetConfig;
  formGroup: EdtellFormGroup;
  hasAttn = false;
  loaded = false;

  paymentModule: CheckOrMoneyOrderContactInformation;

  onWidgetInit() {

    if (this.config.titleLineCheckboxConfig) {

      let onChange = this.config.titleLineCheckboxConfig.onChange ? this.config.titleLineCheckboxConfig.onChange : null;
      this.config.titleLineCheckboxConfig = {
        ...this.config.titleLineCheckboxConfig,
        onChange: (control) => {
          
          if (onChange) {
            onChange(control);
          }

          if (control.value) {
            this.formGroup.get(this.config.address1Key).disable();
            this.formGroup.get(this.config.address2Key).disable();
            this.formGroup.get(this.config.cityKey).disable();
            this.formGroup.get(this.config.stateKey).disable();
            this.formGroup.get(this.config.zipKey).disable();
            this.formGroup.get(this.config.countryKey).disable();
            return;
          }

          this.formGroup.get(this.config.address1Key).enable();
          this.formGroup.get(this.config.address2Key).enable();
          this.formGroup.get(this.config.cityKey).enable();
          this.formGroup.get(this.config.stateKey).enable();
          this.formGroup.get(this.config.zipKey).enable();
          this.formGroup.get(this.config.countryKey).enable();
        }
      }
    }

    this.formGroup = this.config.formGroup;
    this.paymentModule = this.config.paymentModule ? this.config.paymentModule : {
      checkLanguage: "Sender",
      checkPayableTo: "Educere",
      checkAddress1: "Ambler Plaza",
      checkAddress2: "12 East Butler Avenue, Suite 100",
      checkCity: "Ambler",
      checkState: "PA",
      checkZip: "19002"
    }

    this.firstNameFormFieldConfig = {
      key: this.config.firstNameKey,
      size: 12,
      title: 'First Name',
      group: this.formGroup,
      disabled: this.config.disabled,
      validators: [
        {
          key: 'required',
          message: 'First name is required.'
        }
      ]
    }

    this.lastNameFormFieldConfig = {
      key: this.config.lastNameKey,
      size: 12,
      title: 'Last Name',
      group: this.formGroup,
      disabled: this.config.disabled,
      validators: [
        {
          key: 'required',
          message: 'Last name is required.'
        }
      ]
    }

    this.fullAddressConfig = {
      formGroup: this.formGroup,
      title: this.config.title ?? 'Address',
      addressConfig: {
        group: this.formGroup,
        key: this.config.address1Key,
        key2: this.config.address2Key,
        placeholder1: 'Address Line 1',
        placeholder2: 'Address Line 2',
        size: 12,
        disabled: this.config.disabled,
        validators: [
          {
            key: 'required',
            message: 'Address is required.'
          }
        ]
      },
      cityConfig: {
        group: this.formGroup,
        key: this.config.cityKey,
        size: 5,
        title: 'City',
        disabled: this.config.disabled,
        validators: [
          {
            key: 'required',
            message: 'City is required.'
          }
        ]
      },
      stateConfig: {
        key: this.config.stateKey,
        options: this.config.stateOptions,
        size: 2,
        title: 'State',
        group: this.formGroup,
        disabled: this.config.disabled,
        validators: [
          {
            key: 'required',
            message: 'State is required.'
          }
        ],
        onChange: (control) => {
          this.config.onStateControlChange ? this.config.onStateControlChange(control) : null
        }
      },
      zipConfig: {
        key: this.config.zipKey,
        size: 2,
        title: 'Zip-Code',
        group: this.formGroup,
        disabled: this.config.disabled,
        settings: {
          numberInput: true
        },
        validators: [
          {
            key: 'required',
            message: 'Zip-Code is required.'
          }
        ]
      },
      countryConfig: {
        key: this.config.countryKey,
        options: this.config.countryOptions,
        size: 3,
        group: this.formGroup,
        title: 'Country',
        disabled: this.config.disabled,
        validators: [
          {
            key: 'required',
            message: 'Country is required.'
          }
        ]
      },
      titleLineCheckboxConfig: this.config.titleLineCheckboxConfig
    }

    
    this.hasAttn = this.config.paymentModule.checkAttn != null && this.config.paymentModule.checkAttn != "";
  }

}
