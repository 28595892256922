import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogContainer, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import * as _ from 'lodash';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';
import { EntityControlLinkComponent } from '../../../edtell-ag-grid/components/entity-control-link/entity-control-link.component';
import { EdtellAgGridConfig } from '../../../edtell-ag-grid/interfaces/edtell-ag-grid-config.interface';
import { globalGridOptions } from '../../../edtell-ag-grid/interfaces/global-grid-config';
import { EntityControlLinkConfig } from '../../../edtell-controls/interfaces/entity-control-link-config.interface';
import { MoreButtonConfigInterface } from '../../../edtell-controls/interfaces/more-button-config.interface';
import { MoreButtonDataInterface } from '../../../edtell-controls/interfaces/more-button-data.interface';
import { GridPreferenceService } from '../../../edtell-portal/services/grid-preference.service';

@Component({
  selector: 'app-more-button-dialog',
  templateUrl: './more-button-dialog.component.html',
  styleUrls: ['./more-button-dialog.component.scss']
})
export class MoreButtonDialogComponent implements OnInit, OnDestroy {

  lastModified: MoreButtonDataInterface[];
  lastModifiedLoaded = false;

  lastModifiedByUser: MoreButtonDataInterface[];
  userModifiedLoaded = false;

  itemLinkConfig: EntityControlLinkConfig = {
    callback: (rowData) => {
      this.data.callback(rowData);
      this.onNoClick();
    }
  };

  columnDefsRecent: ColDef[] = [
    {
      headerName: 'Item Name',
      cellRendererFramework: EntityControlLinkComponent,
      cellRendererParams: this.itemLinkConfig,
      field: 'itemName',
      colId: 'params',
    },
    {
      headerName: 'Modified By',
      field: 'userName',
      width: 125,
      suppressSizeToFit: true,
      type: 'text',
      
    },
    {
      headerName: 'Date Modified',
      field: 'dateModified',
      width: 125,
      suppressSizeToFit: true,
      type: 'date',
    },
  ];

  columnDefsUser: ColDef[] = [
    {
      headerName: 'Item Name',
      cellRendererFramework: EntityControlLinkComponent,
      cellRendererParams: this.itemLinkConfig,
      field: 'itemName',
      colId: 'params',
    },
    {
      headerName: 'Modified By',
      field: 'userName',
      width: 125,
      suppressSizeToFit: true,
      type: 'text',
      
    },
    {
      headerName: 'Date Modified',
      field: 'dateModified',
      width: 125,
      suppressSizeToFit: true,
      type: 'date',
    },
  ];

  agGridConfigRecent: EdtellAgGridConfig;
  agGridConfigUser: EdtellAgGridConfig;

  constructor(public matDialogRef: MatDialogRef<any>,
              private container: MatDialogContainer,
              @Inject(MAT_DIALOG_DATA) public data: MoreButtonConfigInterface) {
  }

  ngOnInit() {

    this.data.getModifiedRecent.subscribe(resp => {
      this.lastModified = resp;
      this.lastModifiedLoaded = true;
    });

    this.data.getModifiedByUser.subscribe(resp => {
      this.lastModifiedByUser = resp;
      this.userModifiedLoaded = true;
    });

    this.initGridConfig();
  }

  initGridConfig() {
    this.agGridConfigRecent = {
      columnDefs: this.columnDefsRecent,
      data: () => { return this.lastModified },
      gridPreferenceName: this.data.gridPreferenceForRecent,
      maxHeight: 500,
      object: ObjectName.OBJECT
    }
    this.agGridConfigUser = {
      columnDefs: this.columnDefsUser,
      data: () => { return this.lastModifiedByUser },
      gridPreferenceName: this.data.gridPreferenceForUser,
      maxHeight: 500,
      object: ObjectName.OBJECT
    }
  }



  onNoClick(): void {
    this.matDialogRef.close();
  }

  ngOnDestroy() {

  }

}
