<ng-template [ngIf]="loaded == true">
  <div class="row" *ngIf="isDialog == false; else dialogTitle">
    <div class="col-10">
      <h4>{{ data.jobName }} Execution</h4>
    </div>

    <div class="col-2">
      <button class="btn btn-primary float-right" (click)="goBack()">Back</button>
    </div>
  </div>

  <ng-template #dialogTitle>
    <div class="dialog-header">
      <h4 mat-dialog-title mat-dialog-draggable-title>{{ data.jobName }} Execution</h4>
      <span aria-hidden="true" (click)="onNoClick()">&times;</span>
    </div>
  </ng-template>

  <mat-dialog-content>
    <div class="row pt-2">
      <div class="col-12">
        <span>Executed On {{ data.dateCreated }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span>Completed On {{ data.dateCompleted }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span>Execution Status: {{ data.executionStatus }}</span>
      </div>
    </div>

    <div class="row" *ngIf="data.url != null">
      <div class="col-12">
        <span
          >Execution Url:
          <a [href]="data.url" target="_blank">
            {{ data.url }}
          </a></span
        >
      </div>
    </div>

    <ng-template [ngIf]="data.success == false || data.errors != null">
      <div class="row pt-4">
        <div class="col-10">
          <h5>Execution Errors</h5>
        </div>
        <div class="col-2">
          <button class="btn btn-primary float-right" (click)="expandTextArea('errorsTextArea')">Expand</button>
        </div>
      </div>

      <div class="row pt-1">
        <div class="col-12">
          <textarea id="errorsTextArea" readonly rows="15">{{ data.errors }}</textarea>
        </div>
      </div>
    </ng-template>

    <div class="row pt-4">
      <div class="col-10">
        <h5>Execution Logs</h5>
      </div>
      <div class="col-2">
        <button class="btn btn-primary float-right" (click)="expandTextArea('logTextArea')">Expand</button>
      </div>
    </div>

    <div class="row pt-1 pb-4">
      <div class="col-12">
        <textarea id="logTextArea" readonly rows="15">{{ data.logs }}</textarea>
      </div>
    </div>

    <div class="row pt-4">
      <div class="col-10">
        <h5>Distribution Logs</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-distribution-container [config]="distributionContainerConfig"></app-distribution-container>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
