import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CommonRegExpPattern } from '../../edtell-portal/enums/common-regexp-pattern.enum';


@Directive({
  selector: '[patternInput]',
})
export class PatternInputDirective implements OnInit {

  @Input()
  regExpPattern?: CommonRegExpPattern | RegExp;

  storedControlValue: string;
  storedElemValue: string;

  inputElement: HTMLInputElement;

  isMoney: boolean = false;
  isDate: boolean = false;
  isHashtag: boolean = false;
  isPhoneNumber: boolean = false;
  isOverrideCourseId: boolean = false;
  isAbbreviation: boolean = false;

  constructor(public el: ElementRef, private ngControl: NgControl) {
    this.inputElement = el.nativeElement;
  }

  ngOnInit() {
    // No pattern, do nothing.
    if (!this.regExpPattern) {
      this.addEventListener(null);
      return;
    }

    setTimeout(() => {
      this.storedControlValue = this.ngControl.control.value;
      this.storedElemValue = this.el.nativeElement.value;
    });

    // if typeof is 'object', it is of type RegExp
    if (typeof this.regExpPattern == 'object') {
      this.addEventListener(this.regExpPattern);
      return;
    }

    // if typeof is string, it is of type CommonRegExpPattern
    if (typeof this.regExpPattern == 'string') {
      if (this.regExpPattern == CommonRegExpPattern.MONEY) {
        this.isMoney = true;
      } else if (this.regExpPattern == CommonRegExpPattern.DATE) {
        this.isDate = true;
      } else if (this.regExpPattern == CommonRegExpPattern.SUBJECT_TAG) {
        this.isHashtag = true;
      } else if (this.regExpPattern == CommonRegExpPattern.OVERRIDE_COURSE_ID) {
        this.isOverrideCourseId = true;
      } else if (this.regExpPattern == CommonRegExpPattern.ABBREVIATION) {
        this.isAbbreviation = true;
      }
      this.addEventListener(new RegExp(this.regExpPattern));
      return;
    }
      this.addEventListener(new RegExp(this.regExpPattern));
      return;
  }

  addEventListener(regExp: RegExp) {
    this.el.nativeElement.oninput = (event) => {
      let controlValue = this.ngControl.control.value;
      let elemValue = this.el.nativeElement.value;

      if(this.inputElement.type != "textarea"){
        this.checkForWhiteSpace(elemValue);
      }

      if (regExp != null){
      // only accept digits if date
      if (this.isDate && (isNaN(+event.data) || event.data == ' ') && event.inputType !== 'deleteContentBackward') {
        this.el.nativeElement.value = this.storedElemValue;
        return;
      }

      // Check for edge case where there is a missing slash from backspace and user is inputing a number
      if (this.isDate && event.inputType !== 'deleteContentBackward') {
        this.checkForMissingSlash(elemValue);
      }

      if (this.isPhoneNumber && event.inputType !== 'deleteContentBackward') {
        this.checkForMissingDash(elemValue);
        this.checkForDashes(elemValue);
      }

      if (this.isOverrideCourseId && event.inputType !== 'deleteContentBackward') {
        this.checkForCourseId(elemValue);
      }

      // If fails regExp, reset the value to previous value.
      // If value is blank, that is OK
      if (!regExp.test(elemValue) && elemValue) {
        // Date is a special case
        if (this.isDate) {
          this.el.nativeElement.value = this.storedElemValue;
        }
        this.ngControl.control.setValue(this.storedControlValue);

      } else {
        this.storedControlValue = controlValue;
        this.storedElemValue = elemValue;
      }

      if (this.isOverrideCourseId && event.inputType !== 'deleteContentBackward') {
        this.checkForNumbers(elemValue);
      }

      // Add dollar sign if money value, add commas later perhaps?
      if (this.isMoney && elemValue.charAt(0) != '$' && !isNaN(+elemValue.charAt(0))) {
        this.ngControl.control.setValue('$' + elemValue);
        return;
      }

      // Add octothorpe if hashtag
      if (this.isHashtag && elemValue.charAt(0) != '#') {
        this.ngControl.control.setValue('#' + elemValue);
        return;
      }

      // Unfortunately the date control value is in a DateTime string format if using mat datepicker.
      // Cannot retrieve input text or set value via form control
      // ..
      // If backspace is pressed no need to check if we should add slash
      if (this.isDate && event.inputType !== 'deleteContentBackward') {
        if (elemValue.slice(-1) == '/' || elemValue.length > 5) {
          return;
        }

        if (elemValue.length === 2) {
          this.el.nativeElement.value = elemValue + '/';
          this.storedElemValue = this.el.nativeElement.value;
          return;
        }
        if (elemValue.length > 3) {
          let dayVal = elemValue.split('/')[1];
          if (dayVal.length === 2 && +dayVal) {
            this.el.nativeElement.value = elemValue + '/';
            this.storedElemValue = this.el.nativeElement.value;
          }
        }
      }
      if (this.isAbbreviation) {
        this.ngControl.control.setValue(this.ngControl.value.toUpperCase());
      }
    }

    }
  }

  // Can happen if backspaced trailing slash
  checkForMissingSlash(elemValue) {

    // case: previous input value is 00
    if (this.isDate && elemValue.length == 3 && +elemValue) {
      this.el.nativeElement.value = elemValue.slice(0, 2) + '/' + elemValue.slice(2, 3);
      return;
    }

    // case: previous input value is 0/00
    if (this.isDate && elemValue.length == 5 && +elemValue.slice(0, 1) && +elemValue.slice(2, 4)) {
      this.el.nativeElement.value = elemValue.slice(0, 4) + '/' + elemValue.slice(4, 5);
      return;
    }

    // case: previous input value is 00/00
    if (this.isDate && elemValue.length == 6 && +elemValue.slice(0, 2) && +elemValue.slice(3, 5)) {
      this.el.nativeElement.value = elemValue.slice(0, 5) + '/' + elemValue.slice(5, 6);
      return;
    }
  }

  checkForMissingDash(elemValue) {
    let phoneNumberRegEx = /^\d{10}$/;
    let noCharactersRegEx = /^[0-9]*$/;
    let valueBeforeKeyPress = elemValue.slice(0, -1);
    let keyPress = elemValue.slice(-1);

    if (phoneNumberRegEx.test(elemValue) || noCharactersRegEx.test(elemValue)) {
      this.ngControl.control.setValue(elemValue);
    }
    if (elemValue.length > 10){
      this.ngControl.control.setValue(valueBeforeKeyPress)
    }

    // Stripping out all of the dashes to make sure that the value in the field is actually 10 numbers long.
    // let pastedValue = elemValue.replace(/-/g, '');

    // If the value in the field is 10 long, then it goes through and parses it out and adds dashes
    // Won't have an impact if the user types in a phone number because it will parse the field into the same value
    // Only really comes in clutch when a user pastes in a phone number (with or without dashes)
  //   if (pastedValue.length == 10) {
  //     let formatted = pastedValue.slice(0, 3) + '-' + pastedValue.slice(3, 6) + '-' + pastedValue.slice(6, 10);
  //     this.ngControl.control.setValue(formatted);
  //   }
     }

  checkForNumbers(elemValue) {
    let courseIdRegex = /^[0-9]{1,5}$/;
    let asteriskRegex = /^[\\*]+$/;
    let asteriskAfterNumber = /^\d+\*$/;
    let asteriskBeforeNumber = /^\*\d+$/;

    // Case: Just a single asterisk
    if (elemValue.length >= 1 && asteriskRegex.test(elemValue)) {
      this.ngControl.control.setValue('*');
      this.storedControlValue = '*';
      return;
    }
    // Case: An Asterisk before some numbers. ie *123
    else if (!asteriskRegex.test(elemValue) && asteriskBeforeNumber.test(elemValue)) {
      // setting to just a single asterisk
      this.ngControl.control.setValue('*');
      return;
    }

  }

  checkForCourseId(elemValue) {
    let courseIdRegex = /^[0-9]{1,5}$/;
    let asteriskRegex = /^[\\*]+$/;
    let asteriskAfterNumber = /^\d+\*$/;
    let asteriskBeforeNumber = /^\*\d+$/;

    // Case: Just some numbers
    if (courseIdRegex.test(elemValue)) { // testing for just numbers
      this.storedControlValue = elemValue;
      return;
    }
    // Case: An Asterisk after some numbers. ie 123*
    else if (!courseIdRegex.test(elemValue) && asteriskAfterNumber.test(elemValue)) {
      // Stripping out the asterisk
      this.ngControl.control.setValue(elemValue.replace('*', ''));
      return;
    }
    // Case: Letters after some numbers. ie 123a
    else {
      // Stripping off last character since we know its a number but the following characters arent numbers
      let foo = elemValue.slice(0, -1);
      this.ngControl.control.setValue(foo); // setting control to the value to prevent bad values being set in regex.test function
      this.storedControlValue = foo;
    }

  }

  checkForWhiteSpace(elemValue){
    let whiteSpaceCharacterRegex = /^.*\s{2,}\w*/;
    let beforeWhiteSpace = /^\s.*/;

    if (elemValue.length >= 0 && beforeWhiteSpace.test(elemValue)) {
        let test = elemValue.replace(/\s/g, '')
        this.ngControl.control.setValue(elemValue.replace(/^\s/g, ''));
        return;
    }

    if(whiteSpaceCharacterRegex.test(elemValue)) {
      let test = elemValue.replace(/\s/g, '')
        this.ngControl.control.setValue(elemValue.replace(/\s\s/g, ' '));
        return;
    }
  }

  checkForDashes(elemValue){
    let noDashesRegEx = /^-*$/;
    let noDashesRegEx2 = /^\d*(-)*$/;

    if (noDashesRegEx.test(elemValue) || noDashesRegEx2.test(elemValue)) {
        let test = elemValue.replace(/\-/g, '')
        this.ngControl.control.setValue(elemValue.replace(/\-/g, ''));
    }
  }

}
