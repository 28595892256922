import { Type } from '@angular/core';
import { Routes } from '@angular/router';
import { data } from 'jquery';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { RouterWrapperComponent } from '../components/router-wrapper/router-wrapper.component';

export namespace LangUtils {
  export function type<E>(data: E) {
    return data;
  }
  export interface SimpleCallback<T = any, E = any> {
    (arg: E): T | Promise<T>;
  }

  export function wrapRoutes(routes: Routes): Routes {
    return [
      {
        path: '',
        component: RouterWrapperComponent,
        children: routes,
      },
    ];
  }
  export function isConstructor(f) {
    try {
      new f();
    } catch (err) {
      if (err.message.indexOf('is not a constructor') >= 0) {
        return false;
      }
    }
    return true;
  }

  export function isInstance<E>(obj: E, type: Type<any> | Type<any>[]) {
    if (!Array.isArray(type)) {
      return obj instanceof type;
    }

    for (let t of type) {
      if (obj instanceof t) {
        return true;
      }
    }

    return false;
  }

  export function setHasAll<E>(src: Iterable<E>, target: Set<E>): boolean {
    for (let s of src) {
      if (!target.has(s)) {
        return false;
      }
    }
    return true;
  }

  export function toPromise<E = any>(elm: any): Promise<E> {
    return new Promise(res => {
      let r = elm;
      if (elm instanceof Observable) {
        r = new Promise(res2 => {
          elm.pipe(first()).subscribe(data => {
            res2(data);
          });
        });
      } else if (!(elm instanceof Promise)) {
        r = new Promise(res2 => {
          res2(elm);
        });
      }
      res(r);
    });
  }

  export function enumToArray(enumm: any) {
    return Object.keys(enumm).map(key => enumm[key]);
  }
}
