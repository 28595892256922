import {AutocompleteInterface, EmitterEvent, EventEmitterService, ObjectName, SrsSearch, SrsSearchInfo, falsey} from '@edtell-common';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CustomerSearchAutocompleteResultDataInterface} from 'src/app/modules/customer/interfaces/customer-search-autocomplete-result-data.interface';
import {CustomerService} from './customer.service';

@Injectable()
@SrsSearchInfo({
  label: 'Customers',
  security: {
    accessLevels: {
      accessLevel: 1,
      objectName: ObjectName.CUSTOMER_PROFILE
    }
  }
})
export class CustomerSearch extends SrsSearch {

  constructor(private customerService: CustomerService,
              private emitterService: EventEmitterService) {
    super();
  }

  async search(router: Router, text: string, autoCompleteObj?:CustomerSearchAutocompleteResultDataInterface) {

    let results = falsey(autoCompleteObj) ? await this.customerService.getCustomerSearchResults(text).toPromise(): await this.customerService.getCustomerSearchResults(autoCompleteObj.customerName).toPromise();
    if (results.length == 1) {
      router.navigate([`/customer/${results[0].id}`]).then(() => {
      });
    } else {
      router.navigate([`/customer`], {queryParams: {'searchString': text}}).then(() => {
      });
    }
  }

  async autocomplete(text: string) {

    let results = await this.customerService.getAutocompleteResults(text).toPromise();
    let options: AutocompleteInterface<CustomerSearchAutocompleteResultDataInterface>[] = [];

    for (let i of results) {
      options.push({
        text: i.schoolCode ? i.customerName + " - " + i.schoolCode : i.customerName,
        object: i
      });
    }

    return options;
  }

}
