import { Type } from "@angular/core";
import { RouterDataConfig } from "../interfaces/router-data.config";
import { RouterProcessor } from "../interfaces/router-processor";

export class RouterDataGenerator {

    /**
     * Geneates a bread crumb with a static title.
     * @param breadcrumbTitle
     * @param pageTitle
     */
    public static generateSimpleRouterData(breadcrumbTitle? : string, pageTitle?: string): RouterDataConfig {
        return {
            "breadcrumbTitle": breadcrumbTitle,
            "pageTitle": pageTitle
        }
    }

    /**
     * Sets a breadcrumb resolver and/or page title resolver
     * @param breadcrumbResolver
     * @param pageTitleResolver
     */
    public static generateDynamicRouterData(breadcrumbResolver? : Type<RouterProcessor>, pageTitleResolver? : Type<RouterProcessor>): RouterDataConfig {
        return {
            "breadcrumbResolver": breadcrumbResolver,
            "pageTitleResolver": pageTitleResolver
        }
    }

  /**
   * Sets a breadcrumb to display structural information instead of being a navigable link
   * @param title
   */
  public static generateNoPathBreadcrumb(breadcrumbTitle: string): RouterDataConfig {
      return {
        'breadcrumbTitle': breadcrumbTitle,
        'noPath': true
      };
    }

  public static generateNoPathResolverData(breadcrumbResolver?: Type<RouterProcessor>, pageTitleResolver? : Type<RouterProcessor>): RouterDataConfig {
    return {
      "breadcrumbResolver": breadcrumbResolver,
      "pageTitleResolver": pageTitleResolver,
      'noPath': true
    };
  }

    public static generateSkip(): RouterDataConfig {
        return {
            "breadcrumbTitle": "",
            "skip": true
        }
    }

}
