import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

/**
 * @deprecated
 */
@Component({
  selector: 'app-edtell-text-cell-renderer',
  template: `<span>{{params.value}}</span>`,
  styles: ["span:hover{cursor: text}"]
})
export class EdtellTextCellRendererComponent implements ICellRendererAngularComp {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params): boolean {
    return true; // By returning true, it doesn't destroy and remake the componentx
  }
  


}
