import { Component, Input, OnInit } from '@angular/core';
import { CodeTextFormatConfig } from '../../interfaces/code-text-format-config.interface';
import { LanguageType } from '../../../edtell-portal/enums/language-type.enum';


// Currently only accepts JSON and XML formats.
// Assumes JSON is a JSON object (not stringified)
@Component({
  selector: 'app-code-text-format',
  templateUrl: './code-text-format.component.html',
  styleUrls: ['./code-text-format.component.scss']
})
export class CodeTextFormat implements OnInit {
  
  @Input()
  codeTextFormatConfig: CodeTextFormatConfig;

  constructor() { }
  
  ngOnInit() {
    
  }
  
  get codeText(): string {
    if (this.codeTextFormatConfig.language === LanguageType.JSON) {
      let json = this.codeTextFormatConfig.text;
      let orderedJson = json;

      if (this.codeTextFormatConfig.sortKeysAlphabetically) {
        // taken from
        // https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key
        orderedJson = Object.keys(json).sort().reduce(
          (obj, key) => { 
            obj[key] = json[key]; 
            return obj;
          }, 
          {}
        );
      }
      return JSON.stringify(orderedJson, null, '\t');
    }
    else if (this.codeTextFormatConfig.language === LanguageType.XML) {
      return this.codeTextFormatConfig.text;
    }
  }
  
  get error(): boolean {
    return this.codeTextFormatConfig.error;
  }

}
