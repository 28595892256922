import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LoginService } from "../../services/login.service";
import { NgZone } from "@angular/core";
import { PreviousRouteService } from "../../services/previous-route.service";

declare const google;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  sub: Subscription;

  private static GOOGLE_API_INITIALIZED = false;

  constructor(private ngZone: NgZone, private router: Router, private loginService : LoginService, private previousRouteService : PreviousRouteService) {}

  ngOnInit() {
    if (LoginComponent.GOOGLE_API_INITIALIZED == false) {
      LoginComponent.GOOGLE_API_INITIALIZED = true;
      google.accounts.id.initialize({
        client_id: "26061527508-suqh7so31d7n5d6slkloos1o5lk17dcr.apps.googleusercontent.com",
        auto_select: true,
        prompt_parent_id: "gsign-in-parent",
        callback: this.onSignIn.bind(this),
        itp_support: true,
      });
    }
  }

  ngAfterViewInit() {
    google.accounts.id.prompt((data) => {
      // console.log(data.isD)
      if(!data.isDisplayed()){
        google.accounts.id.renderButton(document.getElementById('gsign-in-parent'), {
          type: 'standard button',
          // theme: 'filled_blue',
          size: 'large',
          shape: 'rectangular',
        });
      }
    })
  }

  async onSignIn(data) {
    this.ngZone.run(async () => {
      let credential = data.credential;
      let success = await this.loginService.googleSignIn(credential);
      if (success) {
        let targetRoute = this.previousRouteService.getPreviousRoute();
        if(targetRoute == "/"){
          targetRoute = this.previousRouteService.getInitialRoute();
        }
        this.router.navigateByUrl(!targetRoute.includes("/login") ? targetRoute : "/"); // using includes to handle login subpaths for different login methods in SIS
      }
    });
  }

  ngOnDestroy() {

  }

  checkValid() {}

  cleanLocalStorage() {}
}
