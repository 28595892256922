import { AsyncValidatorFn, FormControl, ValidatorFn } from "@angular/forms";
import { _ } from "ag-grid-community";
import { cloneDeep, isEqual, union } from "lodash";
import { LangUtils } from "../../edtell-portal/namespaces/lang-utils.namespace";
import { EdtellFormArray, EdtellFormGroup } from "./edtell-form-group.class";

export class EdtellFormControl extends FormControl {
  private _originalValue: any;
  private originalValueHash: string;
  private validators: ValidatorFn[];
  private asyncValidators: AsyncValidatorFn[];
  private _isOriginal: boolean = true;
  private _data: any;

  public manualDisable: boolean = false;
  public excludeFromCheck: boolean = false; // used in excluding fields from the changed check, or any check across all the controls in a form group

  constructor(
    value?: any,
    validators?: ValidatorFn | ValidatorFn[],
    asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[],
    excludeFromCheck?: boolean
  ) {
    super(value, validators, asyncValidators);
    this.setIsOriginal(true);
    this.setupSubscriptions();

    this.validators = normalizeValidators(validators);
    this.asyncValidators = normalizeValidators(asyncValidators);
    this.excludeFromCheck = excludeFromCheck;
  }

  private setupSubscriptions() {
    this.valueChanges.subscribe((value) => {
      this.checkOriginality(value);
    });
  }

  private checkOriginality(value: any) {
    let valueHash = typeof value == "object" || typeof value == "boolean" ? JSON.stringify(value) : value;
    this.setIsOriginal(
      this.originalValue == value ||
        (this.originalValue == null && valueHash == "") ||
        this.originalValueHash == valueHash
    );
    if (LangUtils.isInstance(this.parent, [EdtellFormArray, EdtellFormGroup])) {
      (<EdtellFormGroup | EdtellFormArray>this.parent).changeTracker.valueChanged(this);
    }
  }

  public reset() {
    super.reset(this.originalValue);
  }

  public getValidators(): ValidatorFn[] {
    return this.validators;
  }

  public getAsyncValidators(): AsyncValidatorFn[] {
    return this.asyncValidators;
  }

  /**
   * Manually disables Control. Will override the disabled callback on the SrsFormElement.
   * @param opts
   */
  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.manualDisable = true;
    super.disable(opts);
  }

  /**
   * Manually enables Control. Also enables the SrsFormElement disabled callback if it is implemented for this Control.
   * If the disabled callback returns true, the EdtellFormControl will remain disabled.
   * @param opts
   */
  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.manualDisable = false;
    super.enable(opts);
  }

  /**
   * DO NOT USE
   * Should only be called by the DisableControlDirective.
   */
  directiveDisable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {emitEvent: false}) {
    super.disable(opts);
  }

  /**
   * DO NOT USE
   * Should only be called by the DisableControlDirective.
   */
  directiveEnable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {emitEvent: false}) {
    super.enable(opts);
  }

  setOriginalValueToCurrentValue() {
    this.setIsOriginal(true);
  }

  // Added markAsDirty to the options
  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
      markAsDirty?: boolean;
    }
  ): void {
    // change emitViewToModelChange default
    if (options == null) {
      options = {
        emitViewToModelChange: false
      }
    } else {
      options.emitViewToModelChange = options.emitViewToModelChange ? true : false;
    }
    
    super.setValue(value, options);
    if (options != null && options.markAsDirty) {
      this.parent.markAsDirty();
    }
  }

  setIsOriginal(isOriginal: boolean) {
    this._isOriginal = isOriginal;
    if (isOriginal) {
      this.setOriginalValue(this.value);
      if(this.parent instanceof EdtellFormGroup || this.parent instanceof EdtellFormArray) {
        this.parent.changeTracker.removeValueDifference(this)
      }
    }
  }

  private setOriginalValue(value: any) {
    this._originalValue = typeof this.value == "object" ? cloneDeep(value) : this.value; // unfortunalty we have to clone in the case of objects
    this.originalValueHash = typeof this.value == "object" || typeof this.value == "boolean" ? JSON.stringify(this.value) : this.value;
  }

  get isOriginal() {
    return this._isOriginal;
  }

  setData(data: any) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  get originalValue() {
    return this._originalValue;
  }
}

function normalizeValidators(val: ValidatorFn | ValidatorFn[] | AsyncValidatorFn | AsyncValidatorFn[] | null): any[] {
  if (val === undefined) return [];
  if (Array.isArray(val)) return val;
  return [val];
}
