import { CanActivate, CanActivateChild, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ObjectService } from '../../edtell-admin/services/object.service';
import { AccessGuard } from './access.guard';
import { take } from 'rxjs/operators';

declare const google;

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private loginService: LoginService,
    private objectService: ObjectService,
    private accessGuard : AccessGuard,
    private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(route.data.skipAuth == true){
      return true;
    }

    // Check to make sure the user is logged in and if not attempt to refresh jwt
    let loggedIn = this.loginService.isLoggedIn()
    if (!loggedIn) {
      loggedIn = await this.loginService.checkForExistingLogin()
    }

    // Check if user is logged in again after refresh attempt
    if(!loggedIn){
      this.router.navigate([`/login`], {skipLocationChange: false});
      return this.router.parseUrl("/login");
    }

    if(this.loginService.isJwtRefreshQueued()){
      try{
        await this.loginService.refreshJwtSession()
      }catch(err){
        await this.loginService.signOut()
        this.router.navigate([`/login`], {skipLocationChange: true});
        return this.router.parseUrl("/login");
      }
    }
    
    // start object load
    await this.objectService.getObjectMap().pipe(take(1)).toPromise()

    return await this.accessGuard.canActivate(route, state);
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.canActivate(childRoute, state);
  }


}
