import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {GridOptions} from 'ag-grid-community';
import * as _ from 'lodash';
import { ObjectName } from '../../../edtell-admin/enums/object-name.enum';
import { EntityControlLinkComponent } from '../../../edtell-ag-grid/components/entity-control-link/entity-control-link.component';
import { GridResetButtonComponent } from '../../../edtell-ag-grid/components/grid-reset-button/grid-reset-button.component';
import { StatusBarPlusComponent } from '../../../edtell-ag-grid/components/status-bar-plus/status-bar-plus.component';
import { EdtellAgGridConfig } from '../../../edtell-ag-grid/interfaces/edtell-ag-grid-config.interface';
import { globalGridOptions } from '../../../edtell-ag-grid/interfaces/global-grid-config';
import { EntityControlLinkConfig } from '../../../edtell-controls/interfaces/entity-control-link-config.interface';
import { MoreButtonDataInterface } from '../../../edtell-controls/interfaces/more-button-data.interface';
import { GridPreferenceName } from '../../../edtell-portal/enums/grid-preference-name.enum';
import { GridPreferenceService } from '../../../edtell-portal/services/grid-preference.service';
import { DocumentPickerDataInterface } from '../../interfaces/document-picker-data.interface';
import { AssetService } from '../../services/asset.service';

@Component({
  selector: 'app-asset-document-picker-dialog',
  templateUrl: './asset-document-picker-dialog.component.html',
  styleUrls: ['./asset-document-picker-dialog.component.scss']
})
export class AssetDocumentPickerDialogComponent implements OnInit {

  providerId: number;

  gridData: MoreButtonDataInterface[];
  
  documentTypeText;

  entityLinkConfig: EntityControlLinkConfig = {
    callback: (rowData) => {
      this.submit(rowData.itemId);
    }
  };

  gridOptions: GridOptions = {

    defaultColDef: {
      filter: 'agSetColumnFilter'
    },

    columnTypes: {}
  };
  columnDefs = [
    {
      headerName: 'Document Name',
      field: 'itemName',
      cellRendererFramework: EntityControlLinkComponent,
      cellRendererParams: this.entityLinkConfig,
      width: 200
    },
    {
      headerName: 'Date Modified',
      field: 'dateModified',
      type: 'date'
    },
    {
      headerName: 'Modified By',
      field: 'userName',
      type: 'text'
    }
  ];

  agGridConfig: EdtellAgGridConfig;

  constructor(public dialogRef: MatDialogRef<AssetDocumentPickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DocumentPickerDataInterface,
              private assetService: AssetService) {
  }


  async ngOnInit() {
    switch (this.data.assetTypeId) {
      case 1000:
        this.documentTypeText = "Logo";
        break;
      case 1003:
        this.documentTypeText = "Course Syllabus";
        break;
      case 1004:
        this.documentTypeText = "Educere Enrollment Form";
        break;
      case 1005:
        this.documentTypeText = "Enrollment Form";
        break;
      case 1006:
        this.documentTypeText = "Proctor Request Form";
        break;
      case 1007:
        this.documentTypeText = "Technical Requirements";
        break;
    }
    this.initGridConfig();
    this.gridData = await this.assetService.getAssetsByAssetType(this.data.assetTypeId).toPromise();
  }

  initGridConfig() {
    this.agGridConfig = {
      columnDefs: this.columnDefs,
      data: () => { return this.gridData },
      gridPreferenceName: GridPreferenceName.ASSET_DOCUMENT_PICKER,
      gridOptions: this.gridOptions,
      maxHeight: 555,
      object: ObjectName.ASSET
    }
  }

  submit(i) {
    this.dialogRef.close(i);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
