<div *secureStructural="security">
    <div class="row">
        <div class="col-12 d-flex">
            <div class="tabset-header">
                <div *ngFor="let tab of tabComponentArray" (click)="setTabActive(tab)" class="d-inline-block">
                    <div *secureStructural="tab.security" [ngClass]="['edtell-tab', tab.tabOpen ? 'active' : 'inactive']">
                        {{ tab.title }}
                    </div>
                </div>
            </div>
            <div class="ml-auto" *ngIf="entityControlConfig">
                <app-entity-control [config]="entityControlConfig"></app-entity-control>
            </div>
        </div>
    </div>
    <div [ngClass]="['tab-content', config?.border ? 'border' : '']">
        <ng-content></ng-content>
    </div>
</div>