import { Directive, DoCheck, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleSecurityConfig, SecurityConfig } from '../../edtell-admin/interfaces/route-security-config.interface';
import { SecurityService } from '../../edtell-portal/services/security.service';
import { SecurityFailureBehavior } from '../enums/access-secure-behavior.enum';
import { AccessSecureCallback } from '../interfaces/access-secure-failure-callback';
@Directive({
  selector: '[secure]'
})
export class AccessSecureDirective implements OnInit, DoCheck {

  @Input()
  behavior: SecurityFailureBehavior;

  @Input('success')
  successCallback: AccessSecureCallback;

  @Input('failure')
  failureCallback: AccessSecureCallback;

  @Input('data')
  data: any;

  private element: HTMLElement;
  private previouslyChecked: boolean;
  private securityConfig: SecurityConfig[] = []; // Set in the secure setter

  private storedOwnershipIds: OwnerSecurityObject[] = [];

  constructor(
    element: ElementRef,
    private securityService: SecurityService,
    private route: ActivatedRoute
  ) {
    this.element = element.nativeElement;
    this.element.hidden = true;
    this.previouslyChecked = false;
  }

  ngOnInit(): void {
    this.checkAccess();
  }

  ngDoCheck() {
    let checkAccess = false;

    for (let storedSecurityOwnershipObj of this.storedOwnershipIds) {
      if (storedSecurityOwnershipObj.owner != (storedSecurityOwnershipObj.securityConfig.ownershipData == null ? null : storedSecurityOwnershipObj.securityConfig.ownershipData.owner)) {
        checkAccess = true;
        break;
      }
    }

    if (checkAccess) {
      this.checkAccess();
    }
  }

  @Input()
  set secure(security: SecurityConfig | SecurityConfig[]) {

    if (security == null) {
      this.element.hidden = false;
      this.previouslyChecked = true;
      return;
    } else if (Array.isArray(security)) {
      this.securityConfig = security;
    } else {
      this.securityConfig = [security];
    }
  }

  async checkAccess() {

    // True if have access, False if don't
    let pass = await this.securityService.hasPermission(this.route.snapshot, this.securityConfig);

    this.element.hidden = false;
    

    switch (this.behavior) {

      case SecurityFailureBehavior.DISABLE: {
        if(!pass) {
          this.element.setAttribute('disabled','');
          this.element.outerHTML = `<div class="secure-disabled-wrapper">${this.element.outerHTML}</div>`
        }
        break;
      }
      default: {
        this.element.hidden = !pass;
      }

    }

    if (this.failureCallback != null && !pass) { // if there is a call back and they dont have access
      this.failureCallback(this.element, this.data);
    } else if (this.successCallback != null && pass) { // if there is a call back and they do have access
      this.successCallback(this.element, this.data);
    }

    this.storedOwnershipIds = [];

    for (let securityConf of this.securityConfig) {
      this.storedOwnershipIds.push({
        owner: securityConf.ownershipData == null ? null : securityConf.ownershipData.owner,
        securityConfig: securityConf
      });
    }
  }

  checkRoles(config: RoleSecurityConfig) {

    try {
      return this.securityService.hasRoles(config);
    } catch (err) {
      console.error(err);
    }

    return false;
  }

}

interface OwnerSecurityObject {
  securityConfig: SecurityConfig;
  owner: number;
}
