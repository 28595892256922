import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SidebarButtonConfig } from '../interfaces/sidebar-config.interface';

@Directive({
  selector: '[sidebarDropdown]'
})
export class SidebarDropdownDirective{

  @Input("buttonConfig")
  btnConfig: SidebarButtonConfig

  constructor(
    private elementRef: ElementRef,
  ) { }

  @HostListener('window:click')
  onClick() {

    let elm: HTMLElement = this.elementRef.nativeElement
    let target: Node = <any>event.target

    if (elm != target && !this.isChildOf(target, elm)) {
      this.closeDropdown(this.btnConfig)
    }

  }

  closeDropdown(btn: SidebarButtonConfig) {
    btn._showDropdown = false;
  }

  isChildOf(child: Node, parent: Node): boolean {
    let current = child;
    while (current != null) {
      if (current.parentNode == parent) {
        return true;
      }
      current = current.parentNode
    }
    return false;
  }

}
