import {AfterContentInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import {EmitterEvent} from '../../edtell-portal/enums/emitter-events.enum';
import {EventEmitterService} from '../../edtell-portal/services/event-emitter.service';


// from https://www.codementor.io/yomateo/auto-focus-with-angular-7-the-directive-osfcl7rrv
@Directive({
  selector: '[autoFocus]'
})
export class AutofocusDirective implements AfterContentInit, OnDestroy {

  @Input() public autoFocus: boolean;

  private sub : Subscription

  public constructor(private el: ElementRef, private eventEmitterService: EventEmitterService) {
    this.sub = this.eventEmitterService.subscribe(EmitterEvent.FORM_SWITCH_TO_EDIT_EVENT, () => {
      this.focus();
    });
  }

  public ngAfterContentInit() {
    this.focus();
  }

  focus() {
    if (this.autoFocus) {
      setTimeout(() => {

        this.el.nativeElement.focus();

      }, 300);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
