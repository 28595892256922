import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DocumentationUIService } from '../../../../services/documentationUI.service';
import { LanguageType } from '../../../../../edtell-portal/enums/language-type.enum';
import { CodeTextFormatConfig } from '../../../../../edtell-utilities/interfaces/code-text-format-config.interface';

@Component({
  selector: 'app-api-console',
  templateUrl: './api-console.component.html',
  styleUrls: ['./api-console.component.scss']
})
export class ApiConsoleComponent implements OnInit {

  dropdownOpen = false;
  requestType = "GET";
  url = {value: ""};
  headers = 
  [
    {
      "key" : "",
      "value" : ""
    }
  ];
  body =
  [
    {
      "key": "",
      "value": ""
    }
  ];
  status = "";
  dataLoaded = false;
  httpOptions;
  
  codeTextFormatConfig: CodeTextFormatConfig = {
    error: false,
    language: LanguageType.JSON,
    text: null
  }

  constructor(private http: HttpClient, private documentationUIService: DocumentationUIService) {
  }
  
  ngOnInit() {
    this.documentationUIService.currGroupId = null;
    this.url = this.documentationUIService.baseUrl;
    let navigatedCommand = this.documentationUIService.navigatedCommand;
    console.log(navigatedCommand);
    if (navigatedCommand === undefined) {
      this.headers.unshift({
        key: "Content-Type",
        value: "application/json"
      })
    } else {
      this.url.value = navigatedCommand.url;
      this.headers.unshift({
        key: "Content-Type",
        value: _.find(navigatedCommand.headers, {title: 'Content-Type'}).description
      });
      this.requestType = navigatedCommand.requestType;
      let requestBody = navigatedCommand.requestBody;
      if (requestBody !== null) {
        this.body.shift();
        for (let field of requestBody) {
          this.body.push({
            key: field.field,
            value: ""
          })
        }
        this.body.push(
          {
            "key": "",
            "value": ""
          }
        )
      }
    }
    this.headers.unshift({
      key: "Authorization",
      value: "Bearer " + sessionStorage.getItem("JWT")
    })
    this.dataLoaded = true;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  setRequestType(type) {
    // if (this.requestType != type) {
      this.requestType = type;
      this.toggleDropdown();
    // }
  }

  checkAddRow(index) {
    if (this.headers.length == index + 1) {
      this.headers.push(
        {
          "key" : "",
          "value" : ""
        }
      );
    }
  }
  
  checkAddBodyRow(index) {
    if (this.body.length == index + 1) {
      this.body.push(
        {
          "key" : "",
          "value" : ""
        }
      );
    }
  }

  removeRow(index) {
    if (this.headers.length > 1) {
      this.headers.splice(index, 1);
    }
  }
  
  removeBodyRow(index) {
    if (this.body.length > 1) {
      this.body.splice(index, 1);
    }
  }

  sendRequest() {
    if (this.headers.length > 1) {
      var headerObj = {};
      for (let i in this.headers) {
        if (+i + 1 < this.headers.length) {
          let header = this.headers[+i];
          headerObj[header.key] = header.value;
        }
      }
    }
    
    let reqBody = {};
    
    for (let attr of this.body) {
      let value = attr.value;
      try {
        value = JSON.parse(value)
      } catch {
        // not json nbd
      }
      reqBody[attr.key] = value;
    }
    
    let reqHeaders = { headers: new HttpHeaders(headerObj)}
    
    if (this.requestType == "GET") {
      this.http.get(this.url.value, reqHeaders).subscribe(response => {
        console.log(response)
        this.codeTextFormatConfig.text = response;
        this.status = response["status"] + " - " + response["statusText"];
        this.codeTextFormatConfig.error = false;
      }, error => {
        console.log(error)
        this.codeTextFormatConfig.text = error;
        this.status = error.status + " - " + error.statusText;
        this.codeTextFormatConfig.error = true;
      })
    } else if (this.requestType == "POST") {
      this.http.post(this.url.value, reqBody, reqHeaders
      ).subscribe(response => {
        this.codeTextFormatConfig.text = response;
        this.status = response["status"] + " - " + response["statusText"];
        this.codeTextFormatConfig.error = false;
      }, error => {
        this.codeTextFormatConfig.text = error;
        this.status = error.status + " - " + error.statusText;
        this.codeTextFormatConfig.error = true;
      })
    }
    else if (this.requestType == "PUT") {
      this.http.put(this.url.value, reqBody, reqHeaders
      ).subscribe(response => {
        this.codeTextFormatConfig.text = response;
        this.status = response["status"] + " - " + response["statusText"];
        this.codeTextFormatConfig.error = false;
      }, error => {
        this.codeTextFormatConfig.text = error;
        this.status = error.status + " - " + error.statusText;
        this.codeTextFormatConfig.error = true;
      })
    }
    else if (this.requestType == "DEL") {
      this.http.delete(this.url.value, reqHeaders
      ).subscribe(response => {
        this.codeTextFormatConfig.text = response;
        this.status = response["status"] + " - " + response["statusText"];
        this.codeTextFormatConfig.error = false;
      }, error => {
        this.codeTextFormatConfig.text = error;
        this.status = error.status + " - " + error.statusText;
        this.codeTextFormatConfig.error = true;
      })
    }
  }
}
