import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {apiURL} from 'app-environment';
import {ObjectName} from '../../edtell-admin/enums/object-name.enum';
import {AttributeListUpdate} from '../../edtell-admin/interfaces/attribute-list-update.interface';
import {CacheableMethod} from '../../edtell-caching/decorators/cacheable-method.decorator';
import {Attribute} from '../interfaces/attribute.interface';
import {LoginService} from './login.service';

@Injectable({providedIn: 'root'})
export class AttributeService {
  private httpOptions: {};

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
  }

  // ########## BASE ##########

  deleteNotLinkedAttributesById(ids: number[]) {
    return this.http.put<boolean>(`${apiURL}/administration-app/attribute/not-linked/batch`, ids, this.httpOptions);
  }

  // ########## OBJECT ##########

  getObjectAttributesByObjectId(itemId) {
    return this.http.get<Attribute[]>(`${apiURL}/administration-app/attribute/object?itemId=${itemId}`, this.httpOptions);
  }

  upsertObjectAttribute(attribute: Attribute) {
    return this.http.post<Attribute>(`${apiURL}/administration-app/attribute/object`, attribute, this.httpOptions);
  }

  deleteObjectAttribute(attributeId) {
    return this.http.delete<boolean>(`${apiURL}/administration-app/attribute/object?id=${attributeId}`, this.httpOptions);
  }

  getAttributeByObjectName(objectName: ObjectName, attributeName) {
    let objectNameStr = objectName.replace(/_/g, '.').toLowerCase();
    return this.http.get<Attribute>(`${apiURL}/administration-app/attribute/object/name?objectName=${objectNameStr}&attributeName=${attributeName}`, this.httpOptions);
  }

  getAttributeValueByItemIdAndAttributeName(itemId: number, attributeName: string) {
    return this.http.get<Attribute>(`${apiURL}/administration-app/attribute/object/value?itemId=${itemId}&attributeName=${attributeName}`, this.httpOptions);
  }

  upsertObjectAttributeList(req: AttributeListUpdate) {
    return this.http.post<Attribute[]>(`${apiURL}/administration-app/attribute/object/list`, req, this.httpOptions);
  }

  // ########## USER ##########

  getUserAttributesByUserId(itemId) {
    return this.http.get<Attribute[]>(`${apiURL}/administration-app/attribute/user?itemId=${itemId}`, this.httpOptions);
  }

  upsertUserAttribute(attribute: Attribute) {
    return this.http.post<Attribute>(`${apiURL}/administration-app/attribute/user`, attribute, this.httpOptions);
  }

  deleteUserAttribute(attributeId) {
    return this.http.delete<boolean>(`${apiURL}/administration-app/attribute/user?id=${attributeId}`, this.httpOptions);
  }

  getAttributeByUserIdAndAttributeName(userId, attributeName) {
    return this.http.get<Attribute>(`${apiURL}/administration-app/attribute/user/name?userId=${userId}&attributeName=${attributeName}`, this.httpOptions);
  }

  upsertUserAttributeList(req: AttributeListUpdate) {
    return this.http.post<Attribute[]>(`${apiURL}/administration-app/attribute/user/list`, req, this.httpOptions);
  }

  // ########## CUSTOMER ##########

  getCustomerAttributesByCustomerId(itemId) {
    return this.http.get<Attribute[]>(`${apiURL}/administration-app/attribute/customer?itemId=${itemId}`, this.httpOptions);
  }

  upsertCustomerAttribute(attribute: Attribute) {
    return this.http.post<Attribute>(`${apiURL}/administration-app/attribute/customer`, attribute, this.httpOptions);
  }

  upsertCustomerAttributeList(req: AttributeListUpdate) {
    return this.http.post<Attribute[]>(`${apiURL}/administration-app/attribute/customer/list`, req, this.httpOptions);
  }

  deleteCustomerAttribute(attributeId) {
    return this.http.delete<boolean>(`${apiURL}/administration-app/attribute/customer?id=${attributeId}`, this.httpOptions);
  }

  // ########## Provider ##########

  getProviderAttributesByProviderId(itemId) {
    return this.http.get<Attribute[]>(`${apiURL}/administration-app/attribute/provider?itemId=${itemId}`, this.httpOptions);
  }

  upsertProviderAttribute(attribute) {
    return this.http.post<Attribute>(`${apiURL}/administration-app/attribute/provider`, attribute, this.httpOptions);
  }

  upsertProviderAttributeList(req: AttributeListUpdate) {
    return this.http.post<Attribute[]>(`${apiURL}/administration-app/attribute/provider/list`, req, this.httpOptions);
  }

  deleteProviderAttribute(attributeId) {
    return this.http.delete<boolean>(`${apiURL}/administration-app/attribute/provider?id=${attributeId}`, this.httpOptions);
  }

  // ######## External Application ########

  getExternalApplicationAttributesByApplicationId(itemId: number) {
    return this.http.get<Attribute[]>(`${apiURL}/administration-app/attribute/external-application?itemId=${itemId}`, this.httpOptions);
  }

  upsertExternalApplicationAttribute(attribute: Attribute) {
    return this.http.post<Attribute>(`${apiURL}/administration-app/attribute/external-application`, attribute, this.httpOptions);
  }

  deleteExternalApplicationAttribute(attributeId) {
    return this.http.delete<boolean>(`${apiURL}/administration-app/attribute/external-application?id=${attributeId}`, this.httpOptions);
  }

  getExternalApplicationAttributeValueByItemIdAndAttributeName(itemId: number, attributeName: string) {
    return this.http.get<Attribute>(`${apiURL}/administration-app/attribute/external-application/value?itemId=${itemId}&attributeName=${attributeName}`, this.httpOptions);
  }

  upsertExternalApplicationAttributeList(req: AttributeListUpdate) {
    return this.http.post<Attribute[]>(`${apiURL}/administration-app/attribute/external-application/list`, req, this.httpOptions);
  }

  @CacheableMethod()
  getAttributeByExternalApplicationName(applicationName: string, attributeName: string) {
    return this.http.get<Attribute>(`${apiURL}/administration-app/attribute/external-application/name?objectName=${applicationName}&attributeName=${attributeName}`, this.httpOptions);
  }

  getAgGridLicenseKey() {
    return this.http.get<Attribute>(`${apiURL}/administration-app/attribute/system/ag-grid-license-key`, this.httpOptions);
  }

}
