import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";
import { GridApi, ColDef } from "ag-grid-community";
import { SrsMultiElementWidgetConfig } from "../../../srs-forms/interfaces/widgets/srs-multi-element-widget-config";
import { EdtellFormGroup } from "../../../srs-forms/classes/edtell-form-group.class";
import { SrsFormState } from "../../../srs-forms/enumerations/form-state.enum";
import { EdtellFormControl } from "../../../srs-forms/classes/edtell-form-control";
import { Validators } from "@angular/forms";
import { SrsFormFieldComponent } from "../../../srs-forms/components/elements/srs-form-field/srs-form-field.component";
import { LangUtils } from "../../../edtell-portal/namespaces/lang-utils.namespace";
import { SrsFormFieldConfig } from "../../../srs-forms/interfaces/elements/srs-form-field-config.interface";
import { PreviousRouteService } from "../../../edtell-portal/services/previous-route.service";
import { NotificationService } from "../../../edtell-portal/services/notification.service";
import { RolePermissionSliderComponent } from "./role-permissions-slider.component";
import { SystemRole } from "../../interfaces/system-role.interface";
import { SystemRoleResolver } from "../../resolvers/system-role.resolver";
import { SystemRoleService } from "../../services/system-role.service";
import { ObjectService } from "../../../edtell-admin/services/object.service";
import { ObjectName } from "../../../edtell-admin/enums/object-name.enum";
import { globalGridOptions } from "../../../edtell-ag-grid/interfaces/global-grid-config";
import { EntityFormControlConfig } from "../../../edtell-controls/interfaces/entity-form-control-config";
import { EdtellAgGridConfig } from "../../../edtell-ag-grid/interfaces/edtell-ag-grid-config.interface";

@Component({
  selector: "app-role-editor",
  templateUrl: "./role-editor.component.html",
  styleUrls: ["./role-editor.component.scss"],
})
export class RoleEditorComponent implements OnInit {
  loaded: boolean = false;
  saving: boolean = false;

  role: SystemRole = {};
  entityFormControlConfig: EntityFormControlConfig;

  columnDefs: ColDef[] = [
    { headerName: "Object Name", field: "objectName", width: 500, sortable: true },
    {
      headerName: "Access Level",
      field: "accessLevel",
      cellRendererFramework: RolePermissionSliderComponent,
      colId: "params",
      width: 500,
    },
  ];

  formGroup: EdtellFormGroup;
  formConfig: SrsMultiElementWidgetConfig<any>;

  agGridConfig: EdtellAgGridConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private roleService: SystemRoleService,
    private notificationService: NotificationService,
    private objectService: ObjectService,
    private previousRouteService: PreviousRouteService,
    private systemRoleResolver: SystemRoleResolver
  ) {}

  async ngOnInit() {
    let params = await this.activatedRoute.params.pipe(take(1)).toPromise();
    if (params.systemRoleId != null) {
      this.role = await this.roleService
        .getRole(+params.systemRoleId)
        .pipe(take(1))
        .toPromise();
    } else {
      // Grab Object name id pairs
      let objs = await this.objectService.getAllObjects().pipe(take(1)).toPromise();
      this.role.roleAccessLevelMappingSet = [];
      for (let o of objs) {
        this.role.roleAccessLevelMappingSet.push({
          accessLevel: 0,
          objectId: o.id,
          objectName: o.objectName,
        });
      }
    }

    this.formGroup = new EdtellFormGroup(SrsFormState.WRITE, {
      title: new EdtellFormControl(
        this.role.title,
        [Validators.required],
        [
          async (control) => {
            return new Promise((res) => {
              let val = control.value;
              if (val == this.role.title) {
                res(null);
              }

              setTimeout(async () => {
                if (val != control.value) {
                  res(null);
                }

                let isUnique = await this.roleService.isRoleTitleUnique(control.value).pipe(take(1)).toPromise();
                res(
                  !isUnique.value
                    ? {
                        uniqueError: "uniqueError",
                      }
                    : null
                );
              }, 500);
            });
          },
        ]
      ),
    });

    this.formConfig = {
      formGroup: this.formGroup,
      elements: [
        [
          {
            component: SrsFormFieldComponent,
            config: LangUtils.type<SrsFormFieldConfig>({
              key: "title",
              size: 6,
              title: "Role Title",
              validators: [
                {
                  key: "required",
                  message: "Role title is required",
                },
                {
                  key: "uniqueError",
                  message: "Role title is not unique",
                },
              ],
            }),
          },
        ],
      ],
    };

    this.initEntityFormControl(this.role.id != null ? 3 : 2);
    this.initGridConfig();
    this.loaded = true;
  }

  initGridConfig() {
    this.agGridConfig = {
      columnDefs: this.columnDefs,
      data: () => { return this.role.roleAccessLevelMappingSet },
      gridPreferenceName: null,
      heightOffset: 300,
      object: ObjectName.OBJECT
    }
  }

  save() {
    // Submit Role
    this.formGroup.submitted = true;
    if (this.formGroup.valid) {
      this.role.title = this.formGroup.get("title").value;
      this.saving = true;
      // The role access values are updated by refrence in the role-permissions-slider component

      let saveCall = this.role.id != null ? this.roleService.updateRole(this.role) : this.roleService.createRole(this.role);
      saveCall.subscribe(
        (r) => {
          this.notificationService.sendNotification({ message: "Successfully saved " + r.title + " role.", duration: 5000 });
          this.systemRoleResolver.previousRole = null;
          this.router.navigate(["../"], { relativeTo: this.activatedRoute });
        },
        (err) => {
          this.saving = false;
          throw err;
        }
      );
    }
  }

  cancel() {
    this.router.navigate([this.previousRouteService.getPreviousRoute()]);
  }

  initEntityFormControl(accessLevel: 2 | 3) {
    this.entityFormControlConfig = {
      title: accessLevel == 2 ? "Create Role" : `Edit ${this.role.title} Role`,
      entityControl: {
        securityObject: ObjectName.OBJECT,
        controlButtons: [
          {
            text: "Cancel",
            callback: () => {
              this.cancel();
            },
            icon: "",
            security: {
              accessLevels: {
                accessLevel: 0,
                objectName: ObjectName.OBJECT,
              },
            },
          },
          {
            text: "Save",
            disabledValidator: () => {
              return this.saving || !this.formGroup.valid;
            },
            callback: () => {
              this.save();
            },
            security: {
              accessLevels: {
                accessLevel: accessLevel,
                objectName: ObjectName.OBJECT,
              },
            },
          },
        ],
      },
    };
  }
}
