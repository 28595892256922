import { Injectable, Injector, Type } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { filter, map } from 'rxjs/operators';
import { RouterDataConfig } from '../interfaces/router-data.config';
import { RouterDataInfo } from '../interfaces/router-data-info';
import { EventEmitterService } from './event-emitter.service';
import { RouterProcessor } from '../interfaces/router-processor';
import { EmitterEvent } from '../enums/emitter-events.enum';

@Injectable({
  providedIn: 'root'
})
export class RouterDataService {

  private trail: RouterDataInfo[];

  public trailState: Observable<RouterDataInfo[]>;
  private trailObserver: Observer<RouterDataInfo[]>;

  public breadcrumbChangeState: Subject<NavigationEnd>;
  public currentRoute: ActivatedRoute;

  private _hasBreadcrumbs: boolean = true;
  private resolverMap: Map<Type<RouterProcessor>, string> = new Map();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private injector: Injector
  ) {

    this.trail = [];
    this.breadcrumbChangeState = new Subject();

    this.trailState = new Observable<any>((observer) => {
      this.trailObserver = observer;
      this.trailObserver.next([...this.trail]);
    });

    this.setupRouterData();

  }

  /** returns false if a route in the current route path has the noBreadcrumbs property set to true in it's data field
   *  otherwise returns true
   */
  currentRouteHasBreadCrumbs(): boolean {
    return this._hasBreadcrumbs;
  }

  setupRouterData() {

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map((route) => {
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(async (route) => {
        this.currentRoute = route;
        await this.processRoute(this.currentRoute);
      });

    this.eventEmitterService.subscribe(EmitterEvent.RERENDER_BREADCRUMB_TRAIL, async () => {
      if (this.currentRoute != null) {
        await this.processRoute(this.currentRoute);
      }
    });

  }

  async processRoute(route: ActivatedRoute) {
    this.breadcrumbChangeState.next();

    this.trail = [{
      title: 'Home',
      path: ''
    }];

    let title = 'Home';

    let previousRoute: ActivatedRoute = null;

    this._hasBreadcrumbs = true;

    let lastPageTitleConfig;
    let lastPageTitleRoute;

    while (!!route) {

      let snapshot = route.snapshot;
      let configs: RouterDataConfig | RouterDataConfig[] = route.snapshot.data.routerData;

      // get the last config/route for page title in the router tree
      if (Array.isArray(configs)) {
        for (let config of configs) {
          if (config.pageTitle != null || config.pageTitleResolver != null) {
            lastPageTitleConfig = config;
            lastPageTitleRoute = route;
          }
        }
      } else if (configs) {
        if (configs.pageTitle != null || configs.pageTitleResolver != null) {
          lastPageTitleConfig = configs;
          lastPageTitleRoute = route;
        }
      }


      if (route.snapshot.data.noBreadcrumbs != null) {
        this._hasBreadcrumbs = !route.snapshot.data.noBreadcrumbs;
      }

      if (!Array.isArray(configs)) {
        configs = [configs];
      }

      for (let config of configs) {
        if (config != null && snapshot.url != null && (config.breadcrumbTitle != null || config.breadcrumbResolver != null) && snapshot.url.length != 0) {

          // Basic Info
          if (title == config.breadcrumbTitle && config.breadcrumbResolver == null) {
            route = route.firstChild;
            if(route == null){
              return;
            }

            continue;
          }

          title = config.breadcrumbTitle;

          let breadcrumb: RouterDataInfo = {
            title: title,
            path: this.getResolvedUrl(snapshot)
          };
          if (config.skip != null && config.skip == true) {
            breadcrumb.skip = true;
          } else {
            breadcrumb.skip = false;
          }

          // If there is a resolver, execute resolve function
          if (config.breadcrumbResolver != null) {
            try {
              let resolver: RouterProcessor = this.injector.get(config.breadcrumbResolver);
              await resolver.process(route.snapshot, breadcrumb);
              this.resolverMap.set(config.breadcrumbResolver, breadcrumb.title);

            } catch (err) {
              breadcrumb.title = 'RESOLVER ERROR';
              console.error(err);
            }
          }

          if (!this.trail.find(item => item.title == breadcrumb.title)) {
            this.trail.push(breadcrumb);
          }
        } else if (config != null && config.noPath) {

          title = config.breadcrumbTitle;

          let breadcrumb: RouterDataInfo = {
            title: title
          };

          if (config.skip != null && config.skip == true) {
            breadcrumb.skip = true;
          } else {
            breadcrumb.skip = false;
          }

          // If there is a resolver, execute resolve function
          if (config.breadcrumbResolver != null) {
            try {
              let resolver: RouterProcessor = this.injector.get(config.breadcrumbResolver);
              await resolver.process(route.snapshot, breadcrumb);
              this.resolverMap.set(config.breadcrumbResolver, breadcrumb.title);

            } catch (err) {
              breadcrumb.title = 'RESOLVER ERROR';
              console.error(err);
            }
          }

          if (!this.trail.find(item => item.title == breadcrumb.title)) {
            this.trail.push(breadcrumb);
          }
        }

      }

      previousRoute = route;
      route = route.firstChild;
    }


    if (this.trailObserver != null) {
      this.trailObserver.next(this.trail);
    }
    this.setPageTitle(lastPageTitleConfig, lastPageTitleRoute)
  }

  async setPageTitle(config: RouterDataConfig, route: ActivatedRoute) {
    let title;

    if (config == null) {
        title = "Student Registration System"
    } else {
        let RouterDataConfig: RouterDataInfo = {
            title: null
        }
        // If there is a resolver, execute resolve function
        if (config.pageTitleResolver != null) {
            try {
                if (this.resolverMap.has(config.pageTitleResolver)) {
                  title = this.resolverMap.get(config.pageTitleResolver);
                } else {
                  let resolver: RouterProcessor = this.injector.get(config.pageTitleResolver);
                  await resolver.process(route.snapshot, RouterDataConfig);
                  title = RouterDataConfig.title;
                }
            } catch (err) {
                title = 'RESOLVER ERROR';
                console.error(err);
            }
        } else {
            title = config.pageTitle;
        }
    }

    document.querySelector('title').textContent = title;
  }

  // https://stackoverflow.com/questions/50250361/how-to-elegantly-get-full-url-from-the-activatedroutesnapshot
  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
  }
  
}
