import { Injectable } from "@angular/core";
import { Observable, Observer, Subject, Subscription } from "rxjs";
import { EventEmitterCallback } from "../interfaces/event-emitter-callback.interface";
import { EmitterEvent } from "../enums/emitter-events.enum";

@Injectable({
    providedIn: "root"
})
export class EventEmitterService{

    private eventSubscriptionMap : Map<EmitterEvent, Set<Observer<any>>>

    constructor(){
        this.eventSubscriptionMap = new Map<EmitterEvent, Set<Observer<any>>>();
    }

    emit(event : EmitterEvent, data : any){
        let emitters = this.eventSubscriptionMap.get(event)
        if(emitters != null){
            for(let o of emitters){
                o.next(data)
            }
        }
    }

    subscribe(event : EmitterEvent, callback : EventEmitterCallback): Subscription {
        
        // Observable Stream initialization
        let obs : Observer<any>
        let subscription = new Observable((o) => {
            obs = o
        }).subscribe((data : any) => {
            callback(data)
        })

        // Handles Mapping an Event to an Emitter
        let eventSubs = this.eventSubscriptionMap.get(event)
        if(eventSubs == null){
            eventSubs = new Set()
            this.eventSubscriptionMap.set(event, eventSubs)
        }
        eventSubs.add(obs)

        // Handles unsubscribing from function
        let originalUnsubFunction = subscription.unsubscribe.bind(subscription)
        subscription.unsubscribe = () => {
            this.unsubscribe(event, obs);
            originalUnsubFunction();
        }

        return subscription;
    }

    unsubscribe(event : EmitterEvent, observer : Observer<any>){
        let events = this.eventSubscriptionMap.get(event)
        if(events != null){
            events.delete(observer)
            if(events.size == 0){
                this.eventSubscriptionMap.delete(event)
            }
        }
    }

}