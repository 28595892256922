import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { apiURL } from 'app-environment';
import { ApiDocSidebarEntry } from '../interfaces/api-doc-sidebar-entry.interface';
import { DocEntryGroup } from '../interfaces/doc-entry-group.interface';
import { DocEntry, DocEntryUpdateRequest } from '../interfaces/doc-entry.interface';
import { LoginService } from '../../edtell-portal/services/login.service';
import { DocEntryStatus } from '../../edtell-admin/interfaces/doc-entry-status.interface';


@Injectable({
  providedIn: "root"
})
export class ApiDocService {

  public httpOptions: { headers?: HttpHeaders };

  pendingEntriesObj = undefined;
  selectedPendingEntryObjIndex = -1;
  indexChange: Subject<number> = new Subject<number>();

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.httpOptions = loginService.httpOptions;
  }

  getAllDocEntries() {
    return this.http.get<DocEntry[]>(`${apiURL}/api-documentation-app/apidoc/entry/all`, this.httpOptions);
  }
  
  getAllActiveDocEntries() {
    return this.http.get<DocEntry[]>(`${apiURL}/api-documentation-app/apidoc/entry/all/active`, this.httpOptions);
  }
  
  getSidebarData() {
    return this.http.get<ApiDocSidebarEntry[]>(`${apiURL}/api-documentation-app/apidoc/entry/sidebar`, this.httpOptions);
  }

  createDocEntry(entry) {
    return this.http.post(`${apiURL}/api-documentation-app/apidoc/entry`, entry, this.httpOptions);
  }

  getDocEntryById(id) {
    return this.http.get<DocEntry>(`${apiURL}/api-documentation-app/apidoc/entry?id=${id}`, this.httpOptions);
  }

  updateDocEntry(entry: DocEntryUpdateRequest) {
    return this.http.put<DocEntry>(`${apiURL}/api-documentation-app/apidoc/entry`, entry, this.httpOptions);
  }

  getAllDocEntryGroups() {
    return this.http.get<DocEntryGroup[]>(`${apiURL}/api-documentation-app/apidoc/group/all`, this.httpOptions);
  }
  
  getDocEntryGroupById(id: number) {
    return this.http.get<DocEntryGroup>(`${apiURL}/api-documentation-app/apidoc/entry/group?id=${id}`, this.httpOptions);
  }
  
  getAllDocStatus() {
    return this.http.get<DocEntryStatus[]>(`${apiURL}/api-documentation-app/apidoc/status/all`, this.httpOptions)
  }
  
  updateDocStatus(api: DocEntryStatus) {
    return this.http.put<DocEntryStatus>(`${apiURL}/api-documentation-app/apidoc/status`, api, this.httpOptions)
  }
  
  deleteDocEntry(id: number) {
    return this.http.delete<boolean>(`${apiURL}/api-documentation-app/apidoc/entry?id=${id}`, this.httpOptions);
  }
  
  deleteAllInactiveEntries() {
    return this.http.delete<boolean>(`${apiURL}/api-documentation-app/apidoc/entry/all/inactive`, this.httpOptions);
  }
}
