import { RadioButtonConfig, SrsRadioGroupConfig } from './../../../interfaces/elements/srs-radio-group.config.interface';
import { SrsFormFieldComponent } from './../../elements/srs-form-field/srs-form-field.component';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SrsFormWidget } from '../../../abstracts/srs-form-widget.abstract';
import { CallScheduleWidgetConfig } from '../../../interfaces/widgets/call-schedule-widget-config.interface';
import { Validators } from '@angular/forms';
import { EdtellFormGroup } from '../../../classes/edtell-form-group.class';
import { SrsMultiElementWidgetConfig } from '../../../interfaces/widgets/srs-multi-element-widget-config';
import { EdtellFormControl } from '../../../classes/edtell-form-control';
import { SrsFormState } from '../../../enumerations/form-state.enum';
import { SrsRadioButtonComponent } from '../../elements/srs-radio-button/srs-radio-button.component';
import { LangUtils } from '../../../../edtell-portal/namespaces/lang-utils.namespace';
import { SrsCheckboxComponent } from '../../elements/srs-checkbox/srs-checkbox.component';
import { SrsCheckboxConfig } from '../../../interfaces/elements/srs-checkbox-config.interface';
import { EdtellDatetimePickerComponent } from '../../elements/edtell-datetime-picker/edtell-datetime-picker.component';
import { SrsDateTimePickerConfig } from '../../../interfaces/elements/srs-datetime-picker-config';

@Component({
  selector: 'app-call-schedule-widget',
  templateUrl: './call-schedule-widget.component.html',
  styleUrls: ['./call-schedule-widget.component.scss']
})
export class CallScheduleWidgetComponent extends SrsFormWidget<CallScheduleWidgetConfig> {

  formGroup: EdtellFormGroup;
  firstConfig: SrsMultiElementWidgetConfig;
  globalBitMap: string = "0000000";


  //@Output()
  //onScheduleWidgetInit = new EventEmitter<EdtellFormGroup>();

  constructor(private dialog: MatDialog) {
    super();
  }

  async onWidgetInit() {
    this.globalBitMap = this.config.formGroup.get(this.config.bitMapKey).value ?? this.globalBitMap;
    this.formGroup = new EdtellFormGroup(
      SrsFormState.WRITE,
      {
        'scheduleInterval': new EdtellFormControl(this.config.formGroup.get(this.config.scheduleIntervalKey).value, [Validators.required]),
        'startDate': new EdtellFormControl(this.config.formGroup.get(this.config.startDateKey).value, [Validators.required]),
        'endDate': new EdtellFormControl(this.config.formGroup.get(this.config.endDateKey).value, [Validators.required]),
        'sunday': new EdtellFormControl(Boolean(+this.globalBitMap[0])),
        'monday': new EdtellFormControl(Boolean(+this.globalBitMap[1])),
        'tuesday': new EdtellFormControl(Boolean(+this.globalBitMap[2])),
        'wednesday': new EdtellFormControl(Boolean(+this.globalBitMap[3])),
        'thursday': new EdtellFormControl(Boolean(+this.globalBitMap[4])),
        'friday': new EdtellFormControl(Boolean(+this.globalBitMap[5])),
        'saturday': new EdtellFormControl(Boolean(+this.globalBitMap[6])),
        'status' : new EdtellFormControl(this.config.formGroup.get(this.config.statusKey).value),
        'startTime': new EdtellFormControl(this.config.formGroup.get(this.config.startTimeKey).value, [Validators.required]),
      }
    )
    //this.onScheduleWidgetInit.emit(this.formGroup);
    this.firstConfig = {
      title: 'Schedule',
      formGroup: this.formGroup,
      elements: [
        [
          {
            component: SrsRadioButtonComponent,
            config: LangUtils.type<SrsRadioGroupConfig>({
              size: 12,
              key: 'scheduleInterval',
              title: 'Interval',
              options: this.getScheduleTypeOptions(),
              optionWidth: 96,
              settings: {
                //class: "pl-5 mt-1 mb-1",
              },
              onChange: (control) => {
                this.setScheduleType();
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              size: 1.5,
              key: 'sunday',
              title: 'Sunday',
              settings: {
                padding: 0,
                class: "ml-2 mr-1 mb-2 mt-2",
              },
              onChange: (control) => {
                let char = control.value ? "1" : "0";
                this.globalBitMap = this.replaceAt(0, this.globalBitMap, char)
                this.setBitMap();
                this.setCronExpression();
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              size: 1.5,
              key: 'monday',
              title: 'Monday',
              settings: {
                padding: 0,
                class: "ml-2 mr-1 mb-2 mt-2",
              },
              onChange: (control) => {
                let char = control.value ? "1" : "0";
                this.globalBitMap = this.replaceAt(1, this.globalBitMap, char)
                this.setBitMap();
                this.setCronExpression();
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              size: 1.5,
              key: 'tuesday',
              title: 'Tuesday',
              settings: {
                padding: 0,
                class: "ml-2 mr-1 mb-2 mt-2",
              },
              onChange: (control) => {
                let char = control.value ? "1" : "0";
                this.globalBitMap = this.replaceAt(2, this.globalBitMap, char)
                this.setBitMap();
                this.setCronExpression();
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              size: 1.5,
              key: 'wednesday',
              title: 'Wednesday',
              settings: {
                padding: 0,
                class: "ml-2 mr-1 mb-2 mt-2",
              },
              onChange: (control) => {
                let char = control.value ? "1" : "0";
                this.globalBitMap = this.replaceAt(3, this.globalBitMap, char)
                this.setBitMap();
                this.setCronExpression();
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              size: 1.5,
              key: 'thursday',
              title: 'Thursday',
              settings: {
                padding: 0,
                class: "ml-2 mr-1 mb-2 mt-2",
              },
              onChange: (control) => {
                let char = control.value ? "1" : "0";
                this.globalBitMap = this.replaceAt(4, this.globalBitMap, char)
                this.setBitMap();
                this.setCronExpression();
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              size: 1.5,
              key: 'friday',
              title: 'Friday',
              settings: {
                padding: 0,
                class: "ml-2 mr-1 mb-2 mt-2",
              },
              onChange: (control) => {
                let char = control.value ? "1" : "0";
                this.globalBitMap = this.replaceAt(5, this.globalBitMap, char)
                this.setBitMap();
                this.setCronExpression();
              }
            })
          },
          {
            component: SrsCheckboxComponent,
            config: LangUtils.type<SrsCheckboxConfig>({
              size: 1.5,
              key: 'saturday',
              title: 'Saturday',
              settings: {
                padding: 0,
                class: "ml-2 mr-2 mb-2 mt-2",
              },
              onChange: (control) => {
                let char = control.value ? "1" : "0";
                this.globalBitMap = this.replaceAt(6, this.globalBitMap, char)
                this.setBitMap();
                this.setCronExpression();
              }
            })
          },
          {
            component: EdtellDatetimePickerComponent,
            config: LangUtils.type<SrsDateTimePickerConfig>({
              key: 'startDate',
              size: 6,
              title: 'Start Date',
              dateTime: 'date',
              validators: [
                {
                  key: 'required',
                  message: 'Start Date is required.'
                }
              ],
              onChange: () => {
                this.setStartDate();
              }
            })
          },
          {
            component: EdtellDatetimePickerComponent,
            config: LangUtils.type<SrsDateTimePickerConfig>({
              key: 'endDate',
              size: 6,
              title: 'End Date',
              dateTime: 'date',
              validators: [
                {
                  key: 'required',
                  message: 'End Date is required.'
                }
              ],
              onChange: () => {
                this.setEndDate();
              }
            })
          },
          {
            component: EdtellDatetimePickerComponent,
            config: LangUtils.type<SrsDateTimePickerConfig>({
              key: 'startTime',
              size: 6,
              title: 'Start Time',
              dateTime: 'time',
              validators: [
                {
                  key: 'required',
                  message: 'Start Time is required.'
                }
              ],
              onChange: () => {
                this.setStartTime()
              }
            })
          },
          {
            component: SrsRadioButtonComponent,
            config: {
              size: 6,
              key: 'status',
              title: 'Enabled',
              options: this.getYesNoOptions(),
              settings: {},
              validators: [
                {
                  key: 'required',
                  message: 'Status is required.'
                }
              ],
              onChange: () => {
                this.setStatus();
              }
            }
          },
        ]
      ]
    }
  }

  setCronExpression(){
    this.config.formGroup.get(this.config.cronKey).setValue("");
  }

  setBitMap(){
    this.config.formGroup.get(this.config.bitMapKey).setValue(this.globalBitMap);
  }

  setStartDate(){
    this.config.formGroup.get(this.config.startDateKey).setValue(this.formGroup.get('startDate').value);
  }

  setEndDate(){
    this.config.formGroup.get(this.config.endDateKey).setValue(this.formGroup.get('endDate').value);
  }

  setStatus(){
    this.config.formGroup.get(this.config.statusKey).setValue(this.formGroup.get('status').value);
  }

  setScheduleType(){
    this.config.formGroup.get(this.config.scheduleIntervalKey).setValue(this.formGroup.get('scheduleInterval').value);
  }

  setStartTime(){
    this.config.formGroup.get(this.config.startTimeKey).setValue(this.formGroup.get('startTime').value);
  }

  getYesNoOptions(){

    let yesNoOptions: RadioButtonConfig[] = [
      {
        text: 'Yes',
        value: true
      },
      {
        text: 'No',
        value: false
      }
    ];
    return yesNoOptions;
  }


    private replaceAt(index: number, original: string, replacement: string) {
      original = original.substring(0, index) + replacement + original.substring(index + replacement.length);
      return original;
    }

  parseWeekDayBitMap (stringKey, bitNumber): boolean{
    if(stringKey.charAt[bitNumber] == 0){
      return false;
    }
    return true;
  }
  
  getScheduleTypeOptions(){
    //this at least gives the appearance of 5 radio buttons
    //i guess I could pass nothing as the key, save the value on save and then just
    //push a true false to whichever radio had the selection on it... lazy though
    let scheduleTypeOptions: RadioButtonConfig[] = [
      {
        text: 'Daily',
      },
      {
        text: 'Weekly',
      },
      {
        text: 'Bi-Weekly',
      },
      {
        text: 'Monthly',
      },
      {
        text: 'One Time',
      },
    ];
    return scheduleTypeOptions;
  }
}
