import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EntityControlComponent} from './components/entity-control/entity-control.component';
import {EntityControlButtonsComponent} from './components/entity-control-buttons/entity-control-buttons.component';
import {EdtellButtonComponent} from './components/entity-control-buttons/edtell-button/edtell-button.component';
import {EntityFormControlComponent} from './components/entity-form-control/entity-form-control.component';
import {LoadingIconComponent} from './components/loading-icon/loading-icon.component';
import {OwnershipDialogComponent} from './components/ownership-dialog/ownership-dialog.component';
import {OwnershipLinkComponent} from './components/ownership-link/ownership-link.component';
import {SecurityLinkComponent} from './components/security-link/security-link.component';
import {SrsSecurityModule} from '../srs-security/srs-security.module';
import {DeleteLinkDirective} from './directives/delete-link.directive';
import {MatDialogModule} from '@angular/material/dialog';
import {SrsFormsModule} from '../srs-forms/srs-forms.module';
import {EdtellMaterialModule} from '../material/material.module';


@NgModule({
  declarations: [
    EntityControlComponent,
    EntityControlButtonsComponent,
    EdtellButtonComponent,
    EntityFormControlComponent,
    LoadingIconComponent,
    OwnershipDialogComponent,
    OwnershipLinkComponent,
    SecurityLinkComponent,
    DeleteLinkDirective
  ],
  imports: [
    CommonModule,
    SrsSecurityModule,
    MatDialogModule,
    SrsFormsModule,
    EdtellMaterialModule
  ],
  exports: [
    EntityControlComponent,
    EntityControlButtonsComponent,
    EdtellButtonComponent,
    EntityFormControlComponent,
    LoadingIconComponent,
    OwnershipDialogComponent,
    OwnershipLinkComponent,
    SecurityLinkComponent,
    DeleteLinkDirective
  ]
})
export class EdtellControlsModule {
}
