<div>
    <ng-container>
        <label class="w-100 label-bold">{{config.title}} <span class="required-asterisk">{{ required ? "*" : "" }}</span></label>

        <div class="form-row">
            <div class="col">
                <app-srs-select-box [config]="minConfig"></app-srs-select-box>
            </div>

            <div class="col-xxl-1 col-2 d-flex justify-content-center align-items-center">
                <span class="pb-3">to</span>
            </div>

            <div class="col">
                <app-srs-select-box [config]="maxConfig"></app-srs-select-box>
            </div>
        </div>
    </ng-container>

</div>